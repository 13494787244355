import { Fragment, useEffect, useState } from 'react';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { LimitTooltip } from '../../../../../components/LimitTooltip';
import { ModalV3 } from '../../../../../components/ModalV3';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { ManufacturingCycle, ManufacturingProduct, ReportType } from '../../../../../api';
import { ReportCreationFormContext } from '../Import';
import { format } from 'date-fns';
import cn from 'classnames';
import { InputV3 } from '../../../../../components/InputV3';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import { useDynamicHeight } from '../../../../../hooks/useDynamicHeight';
import { simplify } from '../../../shared';
import { SelectProductsModal } from './SelectProductsModal';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';
import { useProfile } from '../../../../../hooks/useProfile';

interface Props {
  reportTitle: string;
  reportType: ReportType;
  dataFromCsv: boolean;
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
  cycle?: ManufacturingCycle;
  onNext: () => void;
}

export const CreateReport = (props: Props) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const formik = useFormikContext<ReportCreationFormContext>();
  const [createLimit, setCreateLimit] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const height = useDynamicHeight(containerRef, showWarning, true);
  const { routes } = useAppRoutes();
  const profile = useProfile();

  useEffect(() => {
    if (props.dataFromCsv && formik.values.unresolved > 0) {
      setShowWarning(true);
    }
  }, [props.dataFromCsv, formik.values.unresolved]);

  return (
    <div className='flex flex-col gap-y-4 relative'>
      {showWarning && (
        <div className='flex items-center gap-2 border border-amber-400 bg-amber-50 w-full px-3 py-1.5 text-sm text-neutral-700 rounded-md'>
          <FontAwesomeIcon size='lg' className='text-amber-400' icon={regular('exclamation-triangle')} />
          <div>Some products in your uploaded file do not exist in this workspace and were left out of the import.</div>
        </div>
      )}

      <div ref={setContainerRef}>
        <FieldArray
          name='products'
          render={(arrayModel) => {
            return (
              <InfiniteScroll height={height} next={() => {}} hasMore={false} loader={<></>} dataLength={formik.values.products.length}>
                <div className='text-sm grid grid-cols-[7fr_5fr_6fr_1fr_1fr] gap-x-4 divide-y'>
                  <div className='px-6 sticky top-0 bg-white capitalize font-semibold col-span-5 grid grid-cols-subgrid items-center h-14 border-y'>
                    <div>name</div>
                    <div>id</div>
                    <div>
                      {props.reportType !== 'forecast' && props.dateRange ? (
                        <div className='flex text-center'>
                          Manufactured quantity ({format(new Date(props.dateRange.startDate), 'dd/MM/yyyy')}-
                          {format(new Date(props.dateRange.endDate), 'dd/MM/yyyy')})
                        </div>
                      ) : (
                        <div>Number of Units Manufactured per {props.cycle}</div>
                      )}
                    </div>
                    <div className='text-center'>
                      <FontAwesomeIcon icon={light('file-chart-column')} />
                    </div>
                    <div></div>
                  </div>

                  {formik.values.products.map((p: ManufacturingProduct, i: number) => (
                    <Fragment key={i}>
                      <div
                        key={i}
                        className='py-2 col-span-5 gap-x-4 grid grid-cols-subgrid items-center border-zinc-200 px-6 even:bg-slate-50'
                      >
                        {p.models.length === 0 && (
                          <>
                            <div title={p.name} className='truncate'>
                              {p.name}
                            </div>
                            <div title={p.skuId} className='truncate'>
                              {p.skuId}
                            </div>
                            <div>
                              {p.selected && (
                                <Field name={`products.${i}.count`}>
                                  {(model: FieldProps<number>) => {
                                    return (
                                      <div className='flex gap-x-4'>
                                        <InputV3 number integer model={model} />
                                        <ModalV3
                                          size='narrow-'
                                          title='Are you sure you want to proceed?'
                                          body={<div className='-mt-6'>This will overwrite some previously filled cells.</div>}
                                          confirmLabel='Apply to All'
                                          onConfirm={() => {
                                            formik.setValues((oldValues) => {
                                              oldValues.products.forEach((product) => {
                                                product.count = model.field.value;
                                                product.models.map((it) => (it.count = model.field.value));
                                              });
                                              return { ...oldValues };
                                            });
                                          }}
                                        >
                                          <button
                                            type='button'
                                            className={cn('flex-shrink-0 rounded-md text-brand', {
                                              hidden: !model.field.value,
                                            })}
                                          >
                                            Apply to All
                                          </button>
                                        </ModalV3>
                                      </div>
                                    );
                                  }}
                                </Field>
                              )}
                            </div>
                          </>
                        )}
                        <div className='text-center'>
                          {p.models.length === 0 && (
                            <NavLink
                              to={routes.products.productOverview.production(p.id, `/workspaces/${profile.selectedWorkspace.workspaceSid}`)}
                              target='_blank'
                              className='text-brandDark hover:underline'
                            >
                              View
                            </NavLink>
                          )}
                        </div>
                        <div className='text-center'>
                          {p.models.length === 0 && (
                            <button
                              type='button'
                              className='rounded-sm font-semibold hover:text-g transition'
                              onClick={arrayModel.handleRemove(i)}
                            >
                              <FontAwesomeIcon icon={light('trash-can')} />
                            </button>
                          )}
                        </div>
                        {p.models.map((m, i2) => (
                          <div key={i2} className='col-span-5 gap-x-4 grid grid-cols-subgrid items-center'>
                            <div className='truncate'>{m.name}</div>
                            <div className='flex'>
                              <div className='bg-violet-200 px-1 flex self-start rounded-md text-zinc-700 text-[10px] font-semibold uppercase'>
                                model
                              </div>
                            </div>
                            <div>
                              <Field key={i2} name={`products.${i}.models.${i2}.count`}>
                                {(model: FieldProps<number>) => (
                                  <div className='flex gap-x-4'>
                                    <InputV3 number integer model={model} />
                                    <ModalV3
                                      size='narrow-'
                                      title='Are you sure you want to proceed?'
                                      body={<div className='-mt-6'>This will overwrite some previously filled cells.</div>}
                                      confirmLabel='Apply to All'
                                      onConfirm={() => {
                                        formik.setValues((oldValues) => {
                                          oldValues.products.forEach((product) => {
                                            product.count = model.field.value;
                                            product.models.map((it) => (it.count = model.field.value));
                                          });
                                          return { ...oldValues };
                                        });
                                      }}
                                    >
                                      <button
                                        type='button'
                                        className={cn('flex-shrink-0 rounded-md text-brand', {
                                          hidden: !model.field.value,
                                        })}
                                      >
                                        Apply to All
                                      </button>
                                    </ModalV3>
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className='text-center'>
                              <NavLink
                                to={routes.products.modelOverview(p.id, m.id)}
                                target='_blank'
                                className='rounded-sm text-brandDark hover:cursor-pointer'
                              >
                                View
                              </NavLink>
                            </div>
                            <div className='text-center'>
                              <button
                                type='button'
                                className='rounded-sm font-semibold hover:text-g transition'
                                onClick={arrayModel.handleRemove(i)}
                              >
                                <FontAwesomeIcon icon={light('trash-can')} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </InfiniteScroll>
            );
          }}
        />
      </div>
      <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
        <div className='px-12 py-4 flex justify-between items-center w-full max-w-screen-xl'>
          <div className='flex items-center gap-x-4'>
            <div>Total volumes added:</div>
            {[
              {
                label: 'Products',
                count: formik.values.products.length + formik.values.products.flatMap(({ models }) => models).length,
              },
              {
                label: 'Units',
                count:
                  formik.values.products.reduce((acc, { count }) => acc + count, 0) +
                  formik.values.products.flatMap(({ models }) => models).reduce((acc, { count }) => acc + count, 0),
              },
            ].map((item, i) => (
              <div key={i} className='flex items-center gap-x-1 p-2 pr-4 border rounded-full'>
                <div className='text-[12px] text-brand px-[6px] py-[2px] bg-slate-100 rounded-full'>{simplify(item.count)}</div>
                <div className='text-sm text-zinc-500'>{item.label}</div>
              </div>
            ))}
          </div>

          {props.dataFromCsv && props.reportType === ReportType.Forecast && (
            <SelectProductsModal>
              <button className='bg-[#e8eaf5] text-[#220065] text-sm font-semibold py-2 px-4 rounded-full'>
                Add more products manually
              </button>
            </SelectProductsModal>
          )}

          <div className='flex items-center gap-4'>
            {props.dataFromCsv && formik.values.unresolved === 0 && (
              <div className='flex items-center gap-2 border border-emerald-700 text-emerald-700 font-semibold py-1.5 px-3 rounded-lg'>
                <FontAwesomeIcon size='lg' icon={regular('check')} />
                All records matched successfully
              </div>
            )}
            {props.dataFromCsv && formik.values.unresolved > 0 && (
              <div className='flex items-center gap-2 border border-red-500 text-red-500 font-semibold py-1.5 px-3 rounded-lg'>
                <FontAwesomeIcon size='lg' icon={regular('exclamation-circle')} />
                {formik.values.products.length} records matched. {formik.values.unresolved} missing
              </div>
            )}

            <LimitTooltip
              enabled={createLimit}
              entityName='forecasts limit'
              valueKey='maxManufacturingReportCount'
              onDismiss={() => setCreateLimit(false)}
            >
              <button
                type='button'
                disabled={formik.isSubmitting || createLimit}
                className={cn(
                  'text-sm flex items-center gap-2.5 border-2 border-brand bg-brand rounded-full px-4 py-2 text-white font-semibold disabled:bg-transparent disabled:text-neutral-500 [&:active:not(:disabled)]:scale-95',
                  createLimit ? 'border-f' : 'border-brand',
                  {
                    'cursor-wait': formik.isSubmitting,
                  },
                )}
                onClick={async () => {
                  props.onNext();
                  await formik.submitForm();
                }}
              >
                {props.reportType === 'forecast' && 'Generate Forecast'}
                {props.reportType === 'baseline' && 'Calculate my baseline'}
                {props.reportType === 'historical' && 'Generate report'}
              </button>
            </LimitTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
