import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Component, createProductModelV3, ProductReport, ProductType } from '../../../../../../api';
import { ContextualExampleTile } from '../../../../../../components/ContextualExampleTile';
import { getImprovementTipsIcons } from '../../../../../../icons/ImprovementTips';
import { CollapsibleSection } from '../../CollapsibleSection';
import laptop from '../../modelling-tool-macbook.png';
import { NavigationButtons } from '../components/NavigationButtons';
import { ResourceUse } from '../components/ResourceUse';
import { LimitTooltip } from '../../../../../../components/LimitTooltip';
import { useAppRoutes } from '../../../../../../hooks/useAppRoutes';

interface Props {
  data: ProductReport;
}

export const Interpretation = (props: Props) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const [creatingModel, setCreatingModel] = useState(false);
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);

  const createModel = () => {
    const productId = props.data.product.id;
    setCreatingModel(true);

    createProductModelV3(productId).call({
      ok: ({ model, errorCode }) => {
        setCreatingModel(false);
        if (errorCode) {
          setModelsCreateLimit(true);
        } else {
          window.open(routes.products.modelGraph(productId, model.id), '_blank');
        }
      },
      fail: () => setCreatingModel(false),
    });
  };

  return (
    <div className='flex flex-col gap-6 px-6'>
      <div className='flex justify-between gap-8'>
        <div>
          An additional interpretation of the Life Cycle Impact Assessment results presented in the previous section, providing some further
          contextualisation, an overview of the current limitations and software improvements to come and finishing off by looking at some
          potential next steps for you on your road to improvement!
        </div>
        <NavigationButtons type='icons' back={{ path: '../lcia' }} next={{ path: '../appendix' }} />
      </div>
      {props.data.interpretation.contextualExamples.length !== 0 && (
        <CollapsibleSection title='What does any of it mean?'>
          <div className='flex flex-col gap-3'>
            <div>
              Not everyone has a PhD to understand what exactly those impacts represent. To give you some context and help you understand,
              here are some contextual examples. Unfortunately, not every impact category can be easily compared to something out of our
              everyday lives (or we actually could, but it might unrightfully scare anyone off and we don’t want that!), but where a
              comparison to something we all can relate to is possible, we’ve tried to include it here. We’ve also considered the PEF
              suggested weightings to determine which impact categories to focus on.
            </div>
            <div className='text-brandDark'>
              It is important to note that the examples you see use ‘simplified’ language which is not truly reflective of the complexity of
              the science behind those impact calculations. They are intended as hints to help you contextualise the size/magnitude of an
              impact rather than understand its full meaning. You can find the pure scientific equivalences by hovering over the cards or by
              visiting our{' '}
              <NavLink to='../appendix' onClick={() => window.scrollTo(0, 0)} className='hover:text-brand font-semibold underline'>
                Appendix
              </NavLink>{' '}
              where a detailed account of how these were calculated and what they truly mean from a scientific point of view is presented.
            </div>
            <div className='mt-3'>
              We hope it helps you put some of the results into focus in your head as to grasp the extent of the impact we’re talking about
              here.{' '}
              <span className='font-semibold'>
                This analysis being at the product level ({props.data.product.name} - {props.data.product.amount.value}
                {props.data.product.amount.unit.name}
              </span>
              ), these should all be rather small, but we hope it is helpful in making you understand what some of those scientific units
              correspond to in layman’s terms.
            </div>
            <div className='grid grid-cols-3 gap-6 print:block'>
              {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
                <ContextualExampleTile key={i} contextualExample={contextualExample} />
              ))}
            </div>
          </div>
        </CollapsibleSection>
      )}

      <CollapsibleSection title='Estimated resource needs'>
        <div className='flex flex-col gap-6'>
          {props.data.product.productType === ProductType.Final && (
            <>
              <div>
                While the focus of this report is on the environmental impact produced throughout this product’s life cycle, some of the
                inputs used in the impact calculations can provide an interesting additional lens to the problem. In fact, a key area of
                interest nowadays lies on how resource intensive a process is, both from an energy and water perspective.
              </div>
              <div>
                As such, we have extracted the estimated direct energy and water usage at the production and use life cycle stages. These
                are a direct result of the production and preparation steps happening within those life cycle stages, and reflect how much
                energy (and what mix of electricity vs. gas is being assumed) and how much water is being used to create and prepare this
                product.
              </div>
              <div>
                As such, we have extracted the estimated direct energy and water usage at the production and use life cycle stages. These
                are a direct result of the production and preparation steps happening within those life cycle stages, and reflect how much
                energy (and what mix of electricity vs. gas is being assumed) and how much water is being used to create and prepare this
                product.
              </div>
            </>
          )}
          {props.data.product.productType !== ProductType.Final && (
            <>
              <div>
                While the focus of this report is on the environmental impact produced throughout this product’s life cycle, some of the
                inputs used in the impact calculations can provide an interesting additional lens to the problem. In fact, a key area of
                interest nowadays lies on how resource intensive a process is, both from an energy and water perspective.
              </div>
              <div>
                As such, we have extracted the estimated direct energy and water usage at the production life cycle stage. These are a
                direct result of the production and preparation steps happening within those life cycle stages, and reflect how much energy
                (and what mix of electricity vs. gas is being assumed) and how much water is being used to create this product.
              </div>
            </>
          )}
          <div className='flex flex-col gap-6 items-center justify-center'>
            {props.data?.analysis.stageResourceUse.map(
              (it, i) =>
                !isEmpty(it.resources) && (
                  <div key={i} className='flex flex-col text-dark'>
                    <div className='flex-1 self-center text-lg font-semibold'>
                      {it.id === 'production' && !isEmpty(it.resources) && <div>Production life cycle stage</div>}
                      {it.id === 'use' && !isEmpty(it.resources) && <div>Use life cycle stage</div>}
                    </div>
                    <div key={i} className='flex justify-center gap-10 mt-3'>
                      <ResourceUse data={it.resources} />
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Validity'>
        This analysis is valid only for a single functional unit of this product ({props.data.product.name} -{' '}
        {props.data.product.amount.value}
        {props.data.product.amount.unit.name}), based on the inputs and geographical locations provided by the client (presented in the{' '}
        <button
          onClick={() => {
            window.scrollTo({ top: 0 });
            navigate('../details');
          }}
          className='underline font-semibold hover:text-brandDark'
        >
          Product Details
        </button>{' '}
        section) under the aforementioned adopted assumptions. Where any change is required, the analysis would have to be reconducted.
      </CollapsibleSection>
      <CollapsibleSection title='Limitations'>
        <div>The analysis presented in this report has a number of limitations associated with it, including:</div>
        <ul className='flex flex-col gap-3 list-disc ml-6 mt-3'>
          <li>
            In the absence of primary data entry capability about how a given agricultural produce is actually being grown, default
            agricultural processes are assumed in calculating impacts. These defaults come from the databases mentioned above and have been
            derived from a number of scientific studies of the impact of ingredients on the environment. We are working on incorporating
            your primary data into this life cycle stage
          </li>
          <li>
            Where secondary data is not available, ingredients are mapped to the closest identified proxies or custom datasets are created
            using a combination of databases and international peer reviewed published LCA studies
          </li>
          <li>
            Any packaging in which your ingredients may be getting to your production facility is generally not considered in terms of
            impact of production and disposal, currently
          </li>
          <li>
            Transportation assumptions are regionalised. The world was divided into 11 regions, and distance and transportation modes
            assumptions are used to estimate the transportation impact between and inside those regions, or within a country. The impact of
            extra packaging used during transportation phases (example: pallets or plastic films used to protect them) is not currently
            accounted for
          </li>
          <li>
            The impact calculation of production steps is predominantly country specific since the impact of the electricity mix grid of the
            country of manufacturing is considered. The impact of water consumption and treatment of these steps are not country-specific,
            yet. We currently use a global dataset from the aforementioned secondary databases. These calculations use our underlying
            assumptions, made available on our Impact Reports, and do not use your company's primary data, yet
          </li>
          <li>The impact of emissions generated during the processing steps are currently being captured but not calculated.</li>
          <li>
            Recyclability rates of each material types are regionalised (by the 11 regions defined) and are not country specific.
            Additionally only landfill, incineration and recycling options are currently considered
          </li>
        </ul>
      </CollapsibleSection>
      <CollapsibleSection title='Upcoming Tool Capabilities'>
        <div>
          This report was created using{' '}
          <span className='text-brandDark font-semibold'>Sustained Impact v.{props.data.metadata.webAppVersion}</span>, and a number of
          improvements are in the pipeline to ensure increased accuracy and actionability of the assessment provided. Continuous improvement
          is also essential to ensure we keep up to date with the latest guidelines and frameworks releases. This section highlights a few
          of the most important ones to come:
        </div>
        <ul className='flex flex-col gap-3 list-disc ml-6 mt-3'>
          <li>Incorporation of production waste and emissions impact into impact calculations</li>
          <li>Data quality indicators to give an overview of our confidence in our estimates</li>
          <li>Ability to upload primary data at the production facility level rather than just product level</li>
          <li>Additional disposal options including reverse logistics</li>
          <li>Refined default transportation calculations using distance API</li>
          <li>Option to provide primary data on actual food waste across the life cycle</li>
        </ul>
      </CollapsibleSection>
      <CollapsibleSection title='Product Improvement Tips'>
        {(() => {
          const majorImpacts = props.data.analysis.impactStagesMatrix.filter(({ isMajorImpact }) => isMajorImpact);
          const majorLifeCycleStages = props.data.analysis.lifecycleStageImpacts.filter(({ isMajorImpact }) => isMajorImpact);
          const majorProcessInStages = props.data.analysis.lifecycleStageImpacts.filter(({ isMajorImpact, isMajor }) => isMajor);

          const renderComponents = (components: Component[], index: number) => {
            const renderNext = (components: Component[], i: number) => {
              let j = i + 1;

              return (
                <div key={j}>
                  {components
                    .filter(({ isMajor }) => isMajor)
                    .map((component, k) => (
                      <div className='flex gap-2' key={k}>
                        <div>—</div>
                        {component.name}
                        {renderNext(component.components, j)}
                      </div>
                    ))}
                </div>
              );
            };

            return renderNext(components, index);
          };

          return (
            <div>
              <div className='mt-3'>
                There is a lot of potential to improve your product’s environmental impact. Results of this life cycle assessment have shown
                that your product’s environmental footprint is primarily caused by{' '}
                <span className='font-semibold text-brandDark'>{majorImpacts.length} impact category(-ies) </span>
                out of the 16 total analysed with the worst being the impact on{' '}
                <span className='font-semibold text-brandDark'>{props.data.analysis.impactStagesMatrix[0]?.impactName}</span>
                <span>.</span>
              </div>
              {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) ? (
                <div className='flex flex-col gap-3'>
                  <div className='mt-3'>
                    The first step to improving your process is to focus on the areas which contribute the most to the environmental impact
                    of this product, namely:
                  </div>
                  <div className='flex flex-col gap-1'>
                    {majorLifeCycleStages.map((stage, i) => (
                      <div key={i} className='text-brandDark font-semibold'>
                        {stage.name}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='pt-6'>
                  The first step to improving your process is to focus on the life cycle stage which contributes the most to the
                  environmental impact of this product (out of the two life cycles analysed), namely the{' '}
                  <span className='font-semibold text-brandDark'>
                    {
                      props.data.analysis.lifecycleStageImpacts.reduce((acc, current) => {
                        return acc && acc.absImpactSharePercent > current.absImpactSharePercent ? acc : current;
                      }).name
                    }
                  </span>{' '}
                  stage.
                </div>
              )}
              <div className='flex flex-col gap-3'>
                <div className='mt-3'>
                  To help you focus your efforts even further, the highest impact processes identified through the LCA are the following
                  ones:
                </div>
                {majorProcessInStages.map((stage, i) => (
                  <ul className='list-disc ml-3.5 text-brandDark font-semibold' key={i}>
                    <li>
                      <div className='flex gap-1'>
                        <div>{stage.name}</div>
                        <div className='flex flex-col gap-1'>{renderComponents(stage.components, i)}</div>
                      </div>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          );
        })()}

        <div className='flex flex-col items-center justify-center'>
          <img className='h-72 w-auto' src={laptop} alt='Modelling tool' />
          <LimitTooltip
            placement='top-start'
            enabled={modelsCreateLimit}
            entityName='models limit'
            valueKey='maxProductModelCount'
            onDismiss={() => setModelsCreateLimit(false)}
          >
            <button
              disabled={creatingModel}
              onClick={createModel}
              className='text-3xl text-zinc-600 underline hover:text-brandDark disabled:cursor-wait'
            >
              Try it here!
            </button>
          </LimitTooltip>
        </div>

        <div className='mt-10 mb-3 flex items-center gap-2'>
          <FontAwesomeIcon className='text-3xl text-yellow-300' icon={solid('lightbulb-on')} />
          <span className='text-lg'>Looking for additional help? Here are some of our expert’s top tips on how to improve your score!</span>
        </div>
        <div className='flex flex-col gap-4 ml-10'>
          {props.data.improvementTips.map((item, i) => (
            <div key={i} className='flex gap-3 items-center'>
              <div className='h-8 p-2 aspect-square bg-brand/5 text-brand rounded-xl'>{getImprovementTipsIcons(item.iconId)}</div>
              <div className='flex items-center'>{item.text}</div>
            </div>
          ))}
        </div>
      </CollapsibleSection>
      <NavigationButtons type='buttons' back={{ path: '../lcia', label: 'LCIA' }} next={{ path: '../appendix', label: 'Appendix' }} />
    </div>
  );
};
