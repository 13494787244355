import { Route, Routes, useNavigate } from 'react-router';
import { Main } from './Main';
import { AddCustomer } from './AddCustomer';
import { useApiQuery } from '../../../hooks/useApiQuery';
import { getCustomers } from '../../../api';
import { CustomerOverview } from './Overview';
import { useAppRoutes } from '../../../hooks/useAppRoutes';

export const Customers = () => {
  const { data, reload } = useApiQuery(getCustomers());
  const navigate = useNavigate();
  const { routes } = useAppRoutes();

  if (!data) {
    return <></>;
  }

  return (
    <div className='-mt-10 mx-6'>
      <Routes>
        <Route
          path='new-customer'
          element={
            <AddCustomer
              onDone={(data) => {
                reload();
                navigate(`${routes.root}/customers/${data.customerSid}/workspaces/${data.defaultWorkspace.workspaceSid}`, { state: data });
              }}
            />
          }
        />
        <Route path=':customerId/*' element={<CustomerOverview data={data} onBack={() => navigate('/customers')} />} />
        <Route path='*' element={<Main data={data} />} />
      </Routes>
    </div>
  );
};
