import { RefObject } from 'react';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { SelectProducts } from '../../../../components/Lists/SelectProducts';
import { ReportType } from '../../../../../../../api';
import { useAppRoutes } from '../../../../../../../hooks/useAppRoutes';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  onNext: () => void;
  parentRef: RefObject<HTMLDivElement | null>;
  step: number;
}

export const Step3 = (props: Props) => {
  const { routes } = useAppRoutes();

  return (
    <>
      <div className='flex flex-col gap-y-4'>
        <div className='font-semibold text-lg'>
          Selecting all products manufactured between <span>{format(props.startDate!, 'dd/MM/yyyy')}</span> and{' '}
          <span>{format(props.endDate!, 'dd/MM/yyyy')}</span>
        </div>
        <div>
          Remember, for your baseline to be accurate you should make sure all the products your company manufactured over this time period
          have been created under the&nbsp;
          <NavLink className='underline hover:text-brand font-semibold' to={routes.products.list} target='_blank'>
            Products tab
          </NavLink>{' '}
          first. You will later need to provide accurate volume data to calculate your baseline impact.
        </div>
      </div>
      <SelectProducts onNext={props.onNext} reportType={ReportType.Baseline} />
    </>
  );
};
