import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { getLists, Lists } from '../api';
import { useApiQuery } from './useApiQuery';

const Context = createContext<Lists>({} as any);

export const useLists = () => useContext(Context);

export const ListsProvider = (props: PropsWithChildren<{}>) => {
  const resp = useApiQuery(getLists());
  const [data, setData] = useState(resp.data);

  useEffect(() => {
    if (resp.data) {
      setData(resp.data);
    }
    return () => {
      setData(undefined);
    };
  }, [resp]);

  if (!data) {
    return <></>;
  }

  return <Context.Provider value={data}>{props.children}</Context.Provider>;
};
