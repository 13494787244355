import cn from 'classnames';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  ConsumptionNode,
  ConsumptionStepNode,
  Facility,
  FacilityType,
  ImpactDelta,
  ModellingPayload,
  NodeType,
  ProductModelV3,
  facilityOptions,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { defaultGrids } from '../Details/dataModel';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { getConsumptionLocations, newNodeId } from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data?: ConsumptionNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<ConsumptionNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: Facility): ConsumptionNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.Consumption,
  flagged: false,
  edges: new Array<string>(),
  steps: new Array<ConsumptionStepNode>(),
  layoutGrid: defaultGrids.consumptionLocation,
  facility,
});

export const ConsumptionLocationDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm<ConsumptionNode | { facility: Facility[] }>
      size='narrow'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add consumption locations'}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          edit={!!props.data}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Where are the consumers of your product located? This can be a whole country, a city or an even smaller area you want to analyse
            impact for.
          </div>
          <div>
            Consumption location is important for transportation estimates, the impact of any preparation required by the consumer and also
            for the recyclability of packaging which differs between countries and areas.
          </div>
          <div>
            You can add one or many for this single, it all depends on the granularity of your assessment and the insights you want to get
            out of it!
          </div>
        </div>
      }
      emptyData={{
        facility: new Array<Facility>(),
      }}
      data={props.data}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'location' : undefined}
      onSave={({ values, closeModal }) => {
        if (props.data) {
          props.onSave({ values: [values as ConsumptionNode], closeModal });
        } else {
          props.onSave({ values: (values.facility as Facility[]).map(toNode), closeModal });
        }
      }}
      hideSave={props.readOnlyMode || !!props.data}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const { payload, productFormik } = props;
  const formik = useFormikContext<ConsumptionNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  useInteractiveImpact<ConsumptionNode>({
    payload,
    productFormik,
    toNode: props.edit ? undefined : (toNode as any),
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  return (
    <div className='flex flex-col gap-1'>
      <div className='pl-1.5'>
        Select {props.edit ? 'a ' : ''}consumption location{props.edit ? '' : 's'}
      </div>
      <div className={cn({ 'w-2/3': props.edit })}>
        <Field name='facility'>
          {(model: FieldProps<Facility>) => (
            <SelectV3<Facility>
              autoFocus
              multi={!props.edit}
              model={model}
              disabled={props.edit}
              menuPortalTarget={props.formRef.current}
              loadOptions={(input, callback) => {
                setNewFacilityName(input);
                getFacilities({
                  contains: input,
                  type: FacilityType.Consumption,
                }).ok(({ facilities }) =>
                  callback(
                    facilities.filter(
                      ({ id }) =>
                        !getConsumptionLocations(props.productFormik)
                          .filter(({ id }) => id !== formik.values.id)
                          .some(({ facility }) => id === facility.id),
                    ),
                  ),
                );
              }}
              menuFooter={
                !newFacilityForm && (
                  <SelectFooterAddButton
                    onClick={() => setNewFacilityForm(true)}
                    name={newFacilityName}
                    label={facilityOptions.find(({ id }) => id === FacilityType.Consumption)!.name.toLowerCase()}
                  />
                )
              }
            />
          )}
        </Field>
      </div>
      {newFacilityForm && (
        <div className='mt-6'>
          <NewFacilityForm
            name={newFacilityName}
            typeOfFacility={FacilityType.Consumption}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', props.edit ? newFacility : [...(formik.values.facility as any), newFacility]);
              setNewFacilityForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
