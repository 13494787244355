import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BaselinePefReport, ForecastReport, HistoricalPefReport, LifeCycleStageName, ReportType } from '../../../../api';
import { HorizontalBarChart } from '../../../../components/charts/HorizontalBarChart';
import { HorizontalShareBarChart } from '../../../../components/charts/HorizontalShareBarChart';
import { LifeCycleStagesLegend } from '../../../../components/charts/LifeCycleStagesLegend';
import {
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
  SunburstChart,
} from '../../../../components/charts/SunburstChart/SunburstChart';
import { ContextualExamplesAssumptions } from '../../../../components/ContextualExamplesAssumptions';
import { HeatMapTableByImpact } from '../../../../components/HeatMapTableByImpact';
import { ImpactSelect } from '../../../../components/ImpactSelect';
import { SingleSelect } from '../../../../components/SingleSelect';
import { CollapsibleSection } from '../../Products/Report/CollapsibleSection';
import { NavigationButtons } from '../../Products/Report/Sku/components/NavigationButtons';
import { NormalisationFactor } from '../../Products/Report/Sku/components/NormalisationFactor';
import { ImpactValueType } from '../../Products/Report/Sku/Overview';
import { simplify } from '../../shared';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

interface Props {
  data: BaselinePefReport | HistoricalPefReport | ForecastReport;
  reportType: ReportType;
}

export const Appendix = (props: Props) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string }>(overallImpact);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const [selectedLifeCycleStage, setSelectedLifeCycleStage] = useState<LifeCycleStageName>(
    props.data.analysis.lifecycleStagesByProduct[0].stageName,
  );
  const [expanded, setExpanded] = useState(Array<string>());
  const { routes } = useAppRoutes();

  const handleExpandedState = (item1: string) =>
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-6'>
          {props.reportType === ReportType.Forecast && (
            <>
              <div>
                This section of the report contains a number of Appendices with additional information on the impact of the selected
                products/models, our methodology and more. For ease of navigation and reference, these have been broken down into separate
                sections.
              </div>

              <div>
                If you are left with some questions pertaining to the environmental impact of your volume forecast after reading this
                report, please{' '}
                <a href='mailto:impact@sustained.com' className='font-semibold underline rounded-sm'>
                  contact us
                </a>{' '}
                and we’ll be happy to help you in any way we can!
              </div>
            </>
          )}

          {[ReportType.Baseline, ReportType.Historical].includes(props.reportType) && (
            <>
              <div>
                This section of the report contains a number of Appendices with additional information on the impact of your product range,
                our methodology and more. For ease of navigation and reference, these have been broken down into separate sections.
              </div>
              <div>
                If you are left with some questions pertaining to the environmental impact of your volume output after reading this report,
                please{' '}
                <a href='mailto:impact@sustained.com' className='font-semibold underline rounded-sm'>
                  contact us
                </a>{' '}
                and we’ll be happy to help you in any way we can!
              </div>
            </>
          )}
        </div>
        <NavigationButtons type='icons' back={{ path: '../interpretation' }} />
      </div>
      <CollapsibleSection title='Appendix 1 - Detailed breakdown by product'>
        <div className='flex flex-col gap-6'>
          <div>
            The first step is to deep dive into the performance of the product range, looking at the highest impact products when taking
            into account production volumes for each impact category, in their absolute physical units and total impact (in weighted
            person.year). We also look at the final environmental impact and contribution to the overall total environmental impact of the
            most relevant impact products (80% contribution) for each life cycle stage.
          </div>

          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By Impact Category</div>

          <div>
            The horizontal bar chart below shows from highest to lowest impact, products in your volume output, in both total impact
            (weighted person.year) and physical values for each impact category. Toggle between impact categories to see the difference.
          </div>

          <ImpactSelect
            theme='light'
            selectedImpact={selectedImpact}
            setSelectedImpact={setSelectedImpact}
            selectedImpactValueType={selectedImpactUnit}
            setSelectedImpactValueType={setSelectedImpactUnit}
            impacts={[
              overallImpact,
              ...props.data.analysis.impactStageMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
              })),
            ]}
          />

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              {props.reportType === ReportType.Baseline && 'Product overall impact as part of baseline assessment'}
              {props.reportType === ReportType.Historical && 'Product overall impact as part of historical assessment'}
              {props.reportType === ReportType.Forecast && 'Product overall impact as part of forecast assessment'}
            </div>
            <div className='flex justify-center'>
              <div className='p-6 flex items-center justify-center w-full 2xl:w-2/3'>
                <HorizontalBarChart
                  impact={
                    selectedImpact.id === 'overall_impact'
                      ? {
                          impactId: 'overall_impact',
                          impactName: 'Total environmental impact',
                          unit: 'weighted person.year',
                          products: props.data.analysis.highestImpactProducts.map((product) => ({
                            ...product,
                            impactValue: product.value,
                          })),
                        }
                      : props.data.analysis.impactsByProduct.find(({ impactId }) => impactId === selectedImpact.id)!
                  }
                  valueType={selectedImpact.id === 'overall_impact' ? ImpactValueType.Points : selectedImpactUnit}
                  products={props.data.products}
                />
              </div>
            </div>
          </div>

          <div>
            The table below shows for each impact category the products ranked from highest to lowest impact contribution. This is for all
            units manufactured over the given cycle (eg. impact of manufacturing 200 of a given product). Both physical values and final
            environmental impact are provided for reference.
          </div>

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Highest impact products by impact category including physical and total environmental impact
            </div>
            {(() => {
              const gridFrames = 'grid-cols-[8fr_8fr_6fr_6fr_6fr_1fr]';

              return (
                <div className='gap-x-2 px-3 divide-zinc-300 divide-y text-sm text-dark pb-2'>
                  <div className={cn('col-span-6 grid gap-2 text-xs uppercase mt-6 border-zinc-300 border-t py-3', gridFrames)}>
                    <div className='pl-3'>impact category</div>
                    <div>product id</div>
                    <div>production volumes</div>
                    <div>physical value</div>
                    <div>final env. impact</div>
                    <div />
                  </div>

                  {props.data.analysis.impactsByProduct.map((impact, i) => {
                    const renderTable = impact.products.map((product, j) => {
                      const productReference = props.data.products.find(({ id }) => id === product.id);
                      const productAmount = productReference
                        ? `(${productReference.amount.value}${productReference.amount.unit.name})`
                        : '';
                      return (
                        <div key={j} className={cn('col-span-6 grid gap-2 truncate py-2.5 border-t', gridFrames)}>
                          <div className='flex truncate pl-[34px]'>
                            <div title={`${product.name} ${productAmount}`} className='truncate'>
                              {product.name} {productAmount}
                            </div>
                          </div>
                          <div className='truncate' title={productReference?.sku}>
                            {productReference?.sku}
                          </div>
                          <div>{productReference?.count}</div>
                          <div>
                            {simplify(product.value)} {impact.unit}
                          </div>
                          <div>{simplify(product.impactPoints)} Impact points</div>
                          <div className='flex items-center justify-center'>
                            {productReference && (
                              <NavLink
                                className='print:hidden'
                                target='_blank'
                                to={routes.products.productOverview.production(product.id!)}
                              >
                                <FontAwesomeIcon className='rotate-45 text-brand' icon={regular('arrow-up')} />
                              </NavLink>
                            )}
                          </div>
                        </div>
                      );
                    });

                    return (
                      <div key={i} className={cn('col-span-6 grid gap-x-2', gridFrames)}>
                        <button
                          title={impact.impactName}
                          onClick={() => handleExpandedState(impact.impactId)}
                          className='flex items-center gap-2 truncate pl-3 py-2.5 hover:text-brandDark'
                        >
                          <FontAwesomeIcon
                            className={cn({ 'rotate-90': expanded.includes(impact.impactId) })}
                            icon={light('chevron-circle-right')}
                          />
                          <div className='truncate font-semibold'>{impact.impactName}</div>
                        </button>
                        <div className='py-2'></div>
                        <div className='py-2' />
                        {(() => {
                          const impactReference = props.data.analysis.impactStageMatrix.find(
                            ({ impactId }) => impact.impactId === impactId,
                          )!;

                          return (
                            <>
                              <div
                                className='flex items-center truncate font-semibold py-2'
                                title={`${simplify(impactReference.physicalValue)} ${impactReference.unit}`}
                              >
                                {`${simplify(impactReference.physicalValue)} ${impactReference.unit}`}
                              </div>
                              <div
                                className='flex items-center truncate font-semibold py-2'
                                title={`${simplify(impactReference.impactPoints)} Impact points`}
                              >
                                {simplify(impactReference.impactPoints)} Impact points
                              </div>
                              <div />
                            </>
                          );
                        })()}

                        <div className='print:hidden col-span-6'>{expanded.includes(impact.impactId) && renderTable}</div>
                        <div className='hidden print:block col-span-6'>{renderTable}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })()}
          </div>

          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By life cycle stage</div>

          <div>
            The horizontal bar chart below shows from highest to lowest impact, products in your volume output, in both total impact
            (weighted person.year) and physical values for each impact category. Toggle between impact categories to see the difference. The
            stacked horizontal bar chart below shows the products included in this volume output report from highest to lowest contribution
            to the total environmental impact (weighted person.year) These are available for each of the five life cycle stages analysed,
            which you can visualise by selecting in the dropdown.
          </div>

          <div className='flex w-full justify-center print:hidden'>
            <div className='w-72'>
              <SingleSelect
                value={{
                  value: selectedLifeCycleStage,
                  label: selectedLifeCycleStage,
                }}
                options={[
                  {
                    id: LifeCycleStageName.RawMaterials,
                    name: LifeCycleStageName.RawMaterials,
                  },
                  {
                    id: LifeCycleStageName.Production,
                    name: LifeCycleStageName.Production,
                  },
                  {
                    id: LifeCycleStageName.Distribution,
                    name: LifeCycleStageName.Distribution,
                  },
                  {
                    id: LifeCycleStageName.Use,
                    name: LifeCycleStageName.Use,
                  },
                  {
                    id: LifeCycleStageName.EndOfLife,
                    name: LifeCycleStageName.EndOfLife,
                  },
                ]}
                setSelectedId={(value) => setSelectedLifeCycleStage(value as LifeCycleStageName)}
              />
            </div>
          </div>

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Product contribution to the overall {props.reportType === ReportType.Baseline ? 'baseline' : 'historical '} impact by
              lifecycle stage
            </div>
            <div className='flex justify-center'>
              {(() => {
                const stage = props.data.analysis.lifecycleStagesByProduct.find(({ stageName }) => stageName === selectedLifeCycleStage)!;
                return (
                  <div className='p-6 pr-12 flex items-center justify-center w-full lg:w-2/3'>
                    <HorizontalShareBarChart
                      data={stage.products.map((product) => {
                        const productReference = props.data.products.find(({ id }) => id === product.id)!;
                        return {
                          id: product.id,
                          name: product.name,
                          value: product.value,
                          count: productReference?.count,
                          skuId: productReference?.sku,
                          parentId: productReference?.parentId,
                        };
                      })}
                      fixedHeight
                      withTooltip
                    />
                  </div>
                );
              })()}
            </div>
          </div>

          <div>
            The table below shows for each impact category the products ranked from highest to lowest impact contribution. This is for all
            units manufactured over the given cycle (eg. impact of manufacturing 200 of a given product). Both physical values and final
            environmental impact are provided for reference. The table below shows for each product included in the volume output (produced
            in its specified volume), the impact of each of the five life cycle stages, both in terms of final environmental impact and
            contribution to the total (%).
          </div>

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Highest impact products by life cycle stage including total environmental impact and contribution
            </div>
            {(() => {
              const gridFrames = 'grid-cols-[8fr_8fr_6fr_6fr_1fr]';

              return (
                <div className='gap-x-2 px-3 divide-zinc-300 divide-y text-sm text-dark pb-2'>
                  <div className={cn('col-span-5 grid gap-2 text-xs uppercase mt-6 border-zinc-300 border-t py-3', gridFrames)}>
                    <div className='pl-3'>life cycle stage</div>
                    <div>product id</div>
                    <div>production volumes</div>
                    <div>final env. impact</div>
                    <div />
                  </div>

                  {props.data.analysis.lifecycleStagesByProduct.map((stage, i) => {
                    const renderTable = stage.products.map((product, j) => {
                      const productReference = props.data.products.find(({ id }) => id === product.id);

                      const productAmount = productReference
                        ? `(${productReference.amount.value}${productReference.amount.unit.name})`
                        : '';
                      return (
                        <div key={j} className={cn('col-span-5 grid gap-2 truncate py-2.5 border-t', gridFrames)}>
                          <div className='flex truncate pl-[34px]'>
                            <div title={`${product.name} ${productAmount}`} className='truncate'>
                              {product.name} {productAmount}
                            </div>
                          </div>
                          <div className='truncate' title={productReference?.sku}>
                            {productReference?.sku}
                          </div>
                          <div>{productReference?.count}</div>
                          <div>{simplify(product.impactPoints)} Impact points</div>
                          <div className='flex items-center justify-center'>
                            {productReference && (
                              <NavLink
                                className='print:hidden'
                                target='_blank'
                                to={routes.products.productOverview.production(product.id!)}
                              >
                                <FontAwesomeIcon className='rotate-45 text-brand' icon={regular('arrow-up')} />
                              </NavLink>
                            )}
                          </div>
                        </div>
                      );
                    });
                    return (
                      <div key={i} className={cn('col-span-5 grid gap-x-2', gridFrames)}>
                        <button
                          title={stage.stageName}
                          onClick={() => handleExpandedState(stage.stageName)}
                          className='flex items-center gap-2 truncate pl-3 py-2.5 hover:text-brandDark'
                        >
                          <FontAwesomeIcon
                            className={cn({ 'rotate-90': expanded.includes(stage.stageName) })}
                            icon={light('chevron-circle-right')}
                          />
                          <div className='truncate font-semibold'>{stage.stageName}</div>
                        </button>
                        <div className='py-2' />
                        <div className='py-2' />
                        {(() => {
                          const stageReference = props.data.analysis.lifecycleStageImpacts.find(({ name }) => stage.stageName === name)!;

                          return (
                            <>
                              <div
                                className='flex items-center truncate font-semibold py-2'
                                title={`${simplify(stageReference.impactPoints)} Impact points`}
                              >
                                {simplify(stageReference.impactPoints)} Impact points
                              </div>
                              <div />
                            </>
                          );
                        })()}

                        <div className='print:hidden col-span-5'>{expanded.includes(stage.stageName) && renderTable}</div>
                        <div className='hidden print:block col-span-5'>{renderTable}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })()}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Appendix 2 - Detailed breakdown by impact category'>
        <div className='flex flex-col gap-6'>
          <div>
            The interactive sunburst chart below shows the detailed breakdown by life cycle stage and underlying processes of each impact
            category. Choose between the total impact or the physical impact and toggle between impact categories to see the difference or
            click on various portions of the chart to better explore a branch of the tree.
          </div>

          <ImpactSelect
            theme='light'
            impacts={[
              overallImpact,
              ...props.data.analysis.impactStageMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
              })),
            ]}
            selectedImpact={selectedImpact}
            setSelectedImpact={setSelectedImpact}
            selectedImpactValueType={selectedImpactUnit}
            setSelectedImpactValueType={setSelectedImpactUnit}
          />

          <div className='flex flex-col items-center'>
            <div className='mt-6 h-[400px] flex justify-center'>
              {selectedImpact.id === 'overall_impact' ? (
                <SunburstChart size={350} data={convertFromLifecycleStageImpacts(props.data.analysis.lifecycleStageImpacts)} />
              ) : (
                <SunburstChart
                  size={350}
                  data={convertFromImpactStagesMatrix(
                    props.data.analysis.impactStageMatrix.find(({ impactName }) => impactName === selectedImpact.name)!.stages,
                  )}
                  selectedCategory={props.data.analysis.impactStageMatrix.find(({ impactId }) => selectedImpact.id === impactId)}
                  showPhysicalImpact={selectedImpactUnit === ImpactValueType.Physical}
                  selectedCategoryPhysicalUnit={
                    props.data.analysis.impactStageMatrix.find(({ impactId }) => selectedImpact.id === impactId)!.unit
                  }
                />
              )}
            </div>

            <LifeCycleStagesLegend renderMajor />
          </div>

          <div className='flex flex-col gap-3'>
            <div>
              The table below shows the detailed breakdown by life cycle stage and underlying processes of each impact category. This is
              done using physical values of each impact categories to understand the absolute impact of volume the produce range specified
              at scale.
            </div>

            <div>
              Each row is colour coded independently from one another, showing for each impact category and process, the absolute impacts at
              the life cycle stage level, colour coded according to its relative importance: a red to green colour scale, from highest to
              lowest impact.
            </div>

            <div>
              This heat map is included to provide visual cues as to which life cycle stages or processes are the most concerning for each
              impact category, to identify general trends and later help you improve your volume output.
            </div>

            <div className='text-dark mt-10 rounded-xl print:rounded-none shadow-regular print:shadow-none print:-mx-8'>
              <HeatMapTableByImpact
                data={props.data.analysis.impactStageMatrix.map((impact) => ({
                  ...impact,
                  stages: impact.stages,
                }))}
              />
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title='Appendix 3 - Normalisation and weighting factors'>
        <NormalisationFactor data={props.data.metadata.methodFactors} />
      </CollapsibleSection>

      <CollapsibleSection title='Appendix 4 - Assumptions behind the contextual examples'>
        <ContextualExamplesAssumptions />
      </CollapsibleSection>

      <NavigationButtons type='buttons' back={{ path: '../interpretation', label: 'Interpretation' }} />
    </div>
  );
};
