import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from '../Tooltip';
import { Fragment } from 'react';
import { roundToShort } from '../../Root/Main/shared';
import { useAppRoutes } from '../../hooks/useAppRoutes';

interface Props {
  data: {
    id?: string;
    skuId?: string;
    name: string;
    value: number;
    count?: number;
    parentId: string | undefined;
  }[];
  fixedHeight: boolean;
  withTooltip?: boolean;
}

export const HorizontalShareBarChart = (props: Props) => {
  const { routes } = useAppRoutes();

  return (
    <div className='grid grid-cols-[2fr_4fr] min-w-[400px]'>
      {props.data.map((product, i) => {
        const productLink = () => (
          <Link
            to={
              product.parentId
                ? routes.products.modelOverview(product.parentId, product.id!)
                : routes.products.productOverview.production(product.id!)
            }
            target='_blank'
            className={cn('text-neutral-500 truncate hover:underline hover:cursor-pointer', {
              'pointer-events-none': product.id === undefined,
            })}
          >
            {product.name}
          </Link>
        );

        return (
          <Fragment key={i}>
            <div className='flex truncate border-r border-zinc-300 pr-3 font-semibold'>
              {props.withTooltip && (
                <Tooltip
                  content={
                    <div className='p-3 flex flex-col gap-3 min-w-[280px]'>
                      <div className='flex flex-col gap-1'>
                        <div className='text-lg'>{product.name}</div>
                        <div className='text-sm'>
                          <div>PRODUCT ID: {product.skuId}</div>
                        </div>
                      </div>
                      <div className='flex flex-col gap-1'>
                        <div className='flex items-center justify-between gap-6'>
                          <div>Manufactured units:</div>
                          <div>{product.count}</div>
                        </div>
                        <div className='flex items-center justify-between gap-6'>
                          <div>Impact share:</div>
                          <div>{roundToShort(product.value)}%</div>
                        </div>
                      </div>
                    </div>
                  }
                >
                  {productLink()}
                </Tooltip>
              )}
              {!props.withTooltip && productLink()}
            </div>
            <div className='flex items-center w-full relative'>
              {product.value > 0 ? (
                <div
                  className='bg-[#b495fb] h-[10px] relative rounded-sm'
                  style={{
                    width: product.value + '%',
                    left: props.data.slice(0, i).reduce((acc, curr) => acc + curr.value, 0) + '%',
                  }}
                >
                  <div className='relative text-center text-dark text-[12px] top-[-18px] font-semibold'>{roundToShort(product.value)}%</div>
                </div>
              ) : (
                <div className='pl-0.5 text-[12px] font-semibold text-dark'>{'<0 Impact'}</div>
              )}
            </div>
          </Fragment>
        );
      })}
      {props.data.length < 10 &&
        props.fixedHeight &&
        (() =>
          new Array(10 - props.data.length).fill(null).map((_, i) => (
            <Fragment key={i}>
              <div className='border-r border-zinc-300 font-semibold pr-1 h-[20px]'></div>
              <div />
            </Fragment>
          )))()}
      <div />

      <div className='border-t border-zinc-300 flex justify-between text-[12px]'>
        <div>0%</div>
        <div>20%</div>
        <div>40%</div>
        <div>60%</div>
        <div>80%</div>
        <div>100%</div>
      </div>
    </div>
  );
};
