import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Component, ProductReport, ProductType } from '../../../../../../api';
import { ByImpactContributionTable } from '../../../../../../components/ByImpactContributionTable';
import { ByStageContributionTable, fromStageImpactsComponent } from '../../../../../../components/ByStageContributionTable';
import { LifeCycleStagesLegend } from '../../../../../../components/charts/LifeCycleStagesLegend';
import { PieChart } from '../../../../../../components/charts/PieChart';
import { RadarChart } from '../../../../../../components/charts/RadarChart';
import { SunburstChart, convertFromLifecycleStageImpacts } from '../../../../../../components/charts/SunburstChart/SunburstChart';
import { useCsvByImpactCategoryHref } from '../../../../../../hooks/useCsvByImpactCategoryHref';
import { useCsvByLifeCycleStageHref } from '../../../../../../hooks/useCsvByLifeCycleStageHref';
import { simplify, roundToLong } from '../../../../shared';
import { CollapsibleSection } from '../../CollapsibleSection';
import { NavigationButtons } from '../components/NavigationButtons';
import { useAppRoutes } from '../../../../../../hooks/useAppRoutes';

interface Props {
  data: ProductReport;
}

interface ComponentInternal extends Component {
  uuid: string;
}

export const Lcia = (props: Props) => {
  const { routes } = useAppRoutes();
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact', unit: undefined };

  const csvByImpactCategoryHref = useCsvByImpactCategoryHref({
    data: props.data.analysis,
  });
  const csvByLifeCycleStageHref = useCsvByLifeCycleStageHref({
    data: props.data.analysis,
  });

  const setInternalId: (components: Component[]) => ComponentInternal[] = (components: Component[]) =>
    components.map((it) => ({
      ...it,
      uuid: uuidv4(),
      components: it.components.length > 0 ? setInternalId(it.components) : [],
    }));

  props.data.analysis.lifecycleStageImpacts = props.data.analysis.lifecycleStageImpacts.map((it) => ({
    ...it,
    uuid: uuidv4(),
    components: setInternalId(it.components),
  }));

  return (
    <div className='flex flex-col gap-6 px-6'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-y-3 text-zinc-500'>
          <div>
            A comprehensive look at your product's environmental impact. A product’s total environmental impact is calculated across a
            number of impact categories, 16 in total, each with their own unit of measurement. The absolute impact of each category is
            referred to as the ‘physical output’ in this section and is shown in scientific units (eg. kg CO2e for climate change).
          </div>
          <div>
            In order to make these physical outputs comparable and obtain the final environmental impacts, normalisation and weightings are
            then applied (using the factors presented in the{' '}
            <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
              Appendix
            </NavLink>
            ), as per the PEF framework. These final environmental impacts are calculated in weighted person year, which compares the impact
            of your product to the impact an average person has in one year (similarly weighted). For more details, you can read our{' '}
            <NavLink
              to={routes.methodology}
              target='_blank'
              className='hover:text-brand font-semibold underline'
              onClick={() => window.scrollTo({ top: 0 })}
            >
              methodology
            </NavLink>
            .
          </div>
          {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) && (
            <div>
              It is important to note that these LCIA results are relative expressions and do not predict impacts on category endpoints, the
              exceeding of thresholds, safety margins or risks.
            </div>
          )}
        </div>
        <NavigationButtons type='icons' back={{ path: '../goal-and-scope' }} next={{ path: '../interpretation' }} />
      </div>
      <div className='flex flex-col gap-8 mt-6'>
        <CollapsibleSection title='Total Environmental Impact'>
          <div className='flex flex-col gap-6'>
            <div>
              For all 16 impact categories, impacts in person years are calculated and added together to obtain the overall impact of your
              product, or the total environmental impact the production of your product has as compared to that of an average person over
              one year. This is presented below.
            </div>
            <div>
              To help you more easily grasp the primary outputs of the analysis, the result in 'weighted person.year', we have created the
              impact points, which are simply the results multiplied by 1 million to scale up the results and remove the exponents which can
              be hard to easily interpret. This full report shows both for completeness wherever possible.
            </div>
            <div className='items-center flex flex-col gap-4'>
              <div className='text-center text-dark font-semibold'>Total Environmental Impact</div>
              <div className='p-6 rounded-3xl bg-lightBg border border-lightBgBorder2 text-dark'>
                <div className='flex flex-col justify-center items-center gap-2'>
                  <div className='flex items-end gap-2 font-semibold'>
                    <div title={roundToLong(props.data.overallImpact)} className='text-2xl text-brandDark leading-7'>
                      {simplify(props.data.overallImpact)}
                    </div>{' '}
                    weighted person.year
                  </div>
                  <div className='text-lg'>or</div>
                  <div className='flex items-end gap-2 font-semibold'>
                    <div title={roundToLong(props.data.impactPoints)} className='text-2xl text-brandDark leading-7'>
                      {simplify(props.data.impactPoints)}
                    </div>{' '}
                    Impact points
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CollapsibleSection>

        <CollapsibleSection title='Total Environmental Impact Breakdown'>
          <div className='mt-3'>
            As previously mentioned, the total environmental impact of your product is derived from the impact for each impact category
            based on the product’s life cycles and their underlying processes. This section provides a breakdown of this impact across all
            three levels.
          </div>
          <div className='rounded-md font-semibold text-brandDark py-1 mb-3 mt-8 bg-slate-200 text-lg text-center'>By Impact Category</div>
          <div className='mt-3'>
            The first step in analysing the results of the life cycle assessment is to look at the impact by category.
          </div>
          <div className='mt-3'>
            Following PEF recommendations, a relevance analysis - a mechanism to understand the contribution each impact category has on
            your product’s total environmental impact - should be carried out. The guidelines recommend that you identify the categories
            that contribute at least 80% of the total environmental impact of your product to then further dive into. This in-depth analysis
            for the most relevant categories in included in the&nbsp;
            <NavLink to='../appendix' onClick={() => window.scrollTo(0, 0)} className='hover:text-brand font-semibold underline'>
              Appendix
            </NavLink>
            .
          </div>

          <div className='mt-6'>The breakdown by impact categories is displayed in the form of:</div>
          <ul className='list-disc ml-6 mt-3'>
            <li className='mb-3'>
              A&nbsp;
              <span className='font-semibold text-brandDark'>radar chart&nbsp;</span>
              (or spider chart) comparing the final environmental impact for each impact category. The closer an impact data point is to the
              edge of the graphic, the larger its impact is.
            </li>
            <li className='mb-3'>
              A&nbsp;
              <span className='font-semibold text-brandDark'>table&nbsp;</span>
              showing from highest to lowest impact contribution, the physical outputs (in their respective units), the final environmental
              impacts in weighted person years (after normalisation and weighting), and the relevance (%) of each impact category to the
              total impact. The impact categories highlighted in yellow in the table are those contributing the most to the total
              environmental impact of the product (80% total minimum). Improving your product’s impact at those categories level should
              yield the biggest overall improvements!
            </li>
          </ul>

          <div className='rounded-regular shadow-regular mt-6 print:mt-12 text-dark break-inside-avoid'>
            <div className='text-lg font-semibold text-center p-4'>Final environmental impact per impact category</div>
            <div className='flex justify-center p-6 border-t'>
              <RadarChart
                height={450}
                width={650}
                meta={[{ dataKey: 'impactPoints', color: '#4f00ff' }]}
                payload={props.data.analysis.impactStagesMatrix.map((it) => ({
                  ...it,
                  impactName: it.impactName,
                  keyName: 'Impact',
                  isMajor: it.isMajorImpact,
                }))}
                tooltipType='single'
              />
            </div>
          </div>

          <div className='flex flex-col mt-6 rounded-2xl shadow-regular overflow-hidden'>
            <div className='flex'>
              <div className='flex-1' />
              <div className='text-lg font-semibold text-center p-4 text-dark'>
                Physical value, final environmental impact and contribution per impact category
              </div>
              <div className='print:hidden flex-1 flex justify-end'>
                <a
                  className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-xs'
                  download={`${props.data!.product.name} - ${props.data!.product.skuId} - Impact by category - (${format(
                    new Date(),
                    'dd.MM.yy',
                  )}).csv`}
                  href={csvByImpactCategoryHref}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </a>
              </div>
            </div>
            <ByImpactContributionTable
              data={props.data.analysis.impactStagesMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
                contributionPercent: impact.absSharePercent,
              }))}
              totalImpact={props.data.overallImpact}
              totalPoints={props.data.impactPoints}
            />
          </div>

          <div className='rounded-md font-semibold text-brandDark py-1 mb-3 mt-8 bg-slate-200 text-lg text-center'>By Life Cycle Stage</div>
          <div className='mt-3'>
            Another important element when trying to understand your product’s environmental impact is understanding which portion of its
            entire life cycle is contributing the most. This is why a breakdown by life cycle stages is provided.
          </div>
          <div className='mt-3'>
            Results are displayed in the form of a<span className='text-brandDark font-semibold'>&nbsp;pie chart&nbsp;</span>
            showing the breakdown of the final environmental impact by life cycle stage. Contributions by life cycle stages are also
            presented in the table below (under By Processes section). The life cycles highlighted in yellow in the diagram (and table) are
            those contributing the most to the total environmental impact of the product (80% total minimum) out of the five total life
            cycle stages assessed. These are the areas where you should focus your efforts!
          </div>

          <div className='rounded-regular shadow-regular mt-6 text-dark break-inside-avoid'>
            <div className='text-lg font-semibold text-center p-4'>Contribution per life cycle stage</div>
            <div className='flex flex-col justify-center items-center pb-6 border-t'>
              <PieChart
                showTicks
                size={460}
                outerRadius={150}
                selectedType='impactPoints'
                data={props.data.analysis.lifecycleStageImpacts.map((impact) => ({
                  name: impact.name,
                  bgColor: impact.bgColor!,
                  isMajor: impact.isMajorImpact,
                  value: impact.absImpactSharePercent,
                  impactPoints: impact.impactValue,
                }))}
              />
              <LifeCycleStagesLegend renderMajor />
            </div>
          </div>

          <div className='rounded-md font-semibold text-brandDark py-1 mb-3 mt-8 bg-slate-200 text-lg text-center'>By Processes</div>
          <div className='mt-3'>
            At the heart of the impact calculation and of each life cycle stage are what we refer to as Processes. These are the underlying
            elements that together contribute to the product’s total impact. Results are displayed in the form of a
            <span className='text-brandDark font-semibold'>&nbsp;sunburst chart&nbsp;</span>
            showing the breakdown of the final environmental impact by life cycle stage, as well as the contribution of the various
            underlying elements and processes to the final environmental impact.
          </div>
          <div className='mt-3'>
            The life cycles and processes highlighted in yellow in the diagram are those contributing the most to the total environmental
            impact of the product (80% total minimum) out of the five total life cycle stages and all processes assessed. These are the
            areas where you should focus your efforts!
          </div>

          <div className='rounded-regular shadow-regular mt-6 text-dark break-inside-avoid'>
            <div className='text-lg font-semibold text-center p-4'>Contribution per life cycle stage and underlying processes</div>
            <div className='flex flex-col justify-center items-center pb-6 border-t'>
              <div className='mb-10 mt-14'>
                <SunburstChart size={400} data={convertFromLifecycleStageImpacts(props.data.analysis.lifecycleStageImpacts)} />
              </div>
              <LifeCycleStagesLegend renderMajor />
            </div>
          </div>

          <div className='mt-6'>
            In addition to the charts above, a<span className='text-brandDark font-semibold'>&nbsp;table&nbsp;</span>
            with the underlying data is presented below. This table shows from highest to lowest life cycle stage contribution, the final
            environmental impacts in weighted person years (after normalisation and weighting), and the contribution (%) of each life cycle
            stage to the total impact. It also presents the underlying Processes within each life cycle stage to understand the root cause
            of the impact in more detail.
          </div>

          <div className='mt-3'>
            Rows highlighted in yellow or displaying the exclamation icon in the table, for both life cycle stages and processes represent
            those contributing the most to the total environmental impact of the product (80% total minimum). These are the most relevant
            processes or the most influential ones. It should be noted that in order to highlight the relevance of any credits (e.g. from
            recycling), absolute values are taken when calculating the contribution. Hence, negative impact processes may show up as ‘Most
            relevant’ processes. When highlighted as most relevant, it shows how much influence these processes have on your overall impact.
          </div>

          <div className='mt-6 rounded-2xl shadow-regular overflow-hidden flex flex-col'>
            <div className='flex'>
              <div className='flex-1' />
              <div className='text-lg font-semibold text-center p-4 text-dark'>
                Final environmental impact and contribution per life cycle stage and underlying process
              </div>
              <div className='print:hidden flex-1 flex justify-end'>
                <a
                  className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-xs'
                  download={`${props.data!.product.name} - ${props.data!.product.skuId} - Impact by life cycle stage - (${format(
                    new Date(),
                    'dd.MM.yy',
                  )}).csv`}
                  href={csvByLifeCycleStageHref}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </a>
              </div>
            </div>
            <div className='p-3 border-t'>
              <ByStageContributionTable
                productName={props.data.product.name}
                selectedImpact={overallImpact}
                data={props.data.analysis.lifecycleStageImpacts.map((impact) => ({
                  ...impact,
                  value: impact.impactValue,
                  absSharePercent: impact.absImpactSharePercent,
                  components: fromStageImpactsComponent(impact.components),
                }))}
                totalImpact={props.data.overallImpact}
                totalPoints={props.data.impactPoints}
              />
            </div>
          </div>

          <div className='mt-6'>
            In addition to the information displayed above, which helps you understand where to focus your efforts to improve your product’s
            footprint, a detailed analysis of the impact of the five life cycle stages on each of the 16 impact categories has also been
            carried out. Results in the form of individual bar charts and a summary table are presented in the&nbsp;
            <NavLink to='../appendix' onClick={() => window.scrollTo(0, 0)} className='hover:text-brand font-semibold underline'>
              Appendix
            </NavLink>
            . This is important if you wish to focus on the improvement of certain impact categories that are relevant to different life
            cycle stages.
          </div>
        </CollapsibleSection>
        <NavigationButtons
          type='buttons'
          next={{ path: '../interpretation', label: 'Interpretation' }}
          back={{ path: '../goal-and-scope', label: 'Goal & Scope' }}
        />
      </div>
    </div>
  );
};
