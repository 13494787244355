import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  FacilityType,
  FinalDestinationNode,
  ImpactDelta,
  ModellingPayload,
  NodeType,
  ProductModelV3,
  ProductType,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { newNodeId } from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';
import { OriginalAwareProvider } from './useOriginalAware';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data?: FinalDestinationNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<FinalDestinationNode>;
  onOpenChange?: (open: boolean) => void;
}>;

export const FinalDestinationDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm
      size='narrow'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add final destination'}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          edit={!!props.data}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>This is the final destination of your product, where it gets handed over to your customer.</div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.FinalDestination,
        flagged: false,
        edges: new Array<string>(),
        facility: undefined as any as Facility,
      }}
      data={props.data}
      validationSchema={yup.object().shape({
        facility: yup.object().required(),
      })}
      entityName='final destination'
      onSave={props.onSave}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const { payload, productFormik } = props;
  const formik = useFormikContext<FinalDestinationNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  useInteractiveImpact<FinalDestinationNode>({
    payload,
    productFormik,
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Select a facility</div>
          <div className='w-2/3'>
            <Field name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  model={model}
                  disabled={props.edit}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ type }) =>
                            props.productFormik.values.productType !== ProductType.Intermediate ||
                            [FacilityType.Production, FacilityType.Warehouse, FacilityType.Store].includes(type),
                        ),
                      ),
                    );
                  }}
                  menuFooter={
                    !newFacilityForm && (
                      <SelectFooterAddButton onClick={() => setNewFacilityForm(true)} name={newFacilityName} label='facility' />
                    )
                  }
                />
              )}
            </Field>
          </div>
          {newFacilityForm && (
            <NewFacilityForm
              enableFacilityOwnerForm
              name={newFacilityName}
              formRef={props.formRef}
              onCancel={() => setNewFacilityForm(false)}
              onCreated={(newFacility) => {
                formik.setFieldValue('facility', newFacility);
                setNewFacilityForm(false);
              }}
            />
          )}
        </div>
      </div>
    </OriginalAwareProvider>
  );
};
