import { CollapsibleSection } from '../../Products/Report/CollapsibleSection';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingGhgReport, BaselinePefReport, ForecastReport, HistoricalPefReport, ProductType } from '../../../../api';
import { simplify } from '../../shared';
import cn from 'classnames';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

interface Props {
  data: BaselinePefReport | ManufacturingGhgReport | HistoricalPefReport | ForecastReport;
  description: string;
  tableProps: {
    title: string;
    columnName: string;
  };
}

export const ProductionVolumes = (props: Props) => {
  const { routes } = useAppRoutes();
  return (
    <CollapsibleSection open title='Production Volumes'>
      <div className='flex flex-col gap-6'>
        <div>{props.description}</div>
        <div className='pt-3 text-dark rounded-2xl shadow-regular bg-white overflow-hidden'>
          <div className='text-center pb-3 text-lg font-semibold'>{props.tableProps.title}</div>
          <div className='grid grid-cols-5 rounded-b-2xl overflow-hidden divide-y'>
            <div className='grid grid-cols-[6fr_4fr_2fr_3fr_1fr] px-6 border-t gap-x-10 py-3 col-span-5 text-xs text-zinc-500 uppercase'>
              <div>product name</div>
              <div>product id</div>
              <div>product type</div>
              <div>{props.tableProps.columnName}</div>
              <div></div>
            </div>
            {props.data.products.map((product, i) => (
              <div
                key={i}
                className={cn('grid grid-cols-[6fr_4fr_2fr_3fr_1fr] px-6 gap-x-10 py-3 col-span-5 items-center', {
                  'text-brandDark': product.parentId,
                })}
              >
                <div className='flex justify-between gap-1 truncate'>
                  {product.parentId ? (
                    <>
                      <div className='flex items-center gap-2 truncate'>
                        <FontAwesomeIcon icon={light('code-compare')} />
                        <div>{product.name}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='truncate'>{product.name}</div>
                    </>
                  )}
                  <div>
                    {product.amount.value}
                    {product.amount.unit.name}
                  </div>
                </div>
                <div title={product.parentId ? 'Model' : product.sku} className='truncate'>
                  {product.parentId ? 'Model' : product.sku}
                </div>
                <div className='truncate'>
                  <div title={product.productType} className='flex items-center gap-1 truncate'>
                    {
                      {
                        [ProductType.Final]: 'Final',
                        [ProductType.Intermediate]: 'Intermediate',
                        [ProductType.Internal]: 'Internal',
                      }[product.productType!]
                    }
                  </div>
                </div>
                <div>
                  <div className='grid grid-cols-2 items-center'>{simplify(product.count)}</div>
                </div>
                <NavLink
                  to={
                    product.parentId
                      ? routes.products.modelOverview(product.parentId, product.id!)
                      : routes.products.productOverview.production(product.id!)
                  }
                  target='_blank'
                  type='button'
                  className='flex self-center justify-self-center active:scale-90 print:hidden'
                >
                  <FontAwesomeIcon className='rotate-45 text-brand' icon={light('arrow-up')} />
                </NavLink>
              </div>
            ))}
            <div className='grid grid-cols-[6fr_4fr_2fr_3fr_1fr] px-6 gap-x-10 py-3 col-span-5 items-center bg-slate-50 text-brand text-sm'>
              <div className='font-semibold'>Total</div>
              <div />
              <div className='flex items-center gap-1 text-sm'></div>
              <div>{simplify(props.data.totalUnitCount)} units</div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </CollapsibleSection>
  );
};
