import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { NewSupplierForm, SubmitButtonApi } from './NewSupplierForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { ModalApi } from '../../../../components/ModalV3';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

export const CreateSupplier = () => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const formRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<ModalApi>(null);
  const submitButtonRef = useRef<SubmitButtonApi>(null);
  const [waiting, setWaiting] = useState(false);

  return (
    <div className='flex flex-col gap-8'>
      <Helmet title='New Provider' />
      <div className='flex items-center gap-4 px-6'>
        <button
          onClick={() => navigate(-1)}
          className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
        >
          <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
        </button>
        <div className='text-2xl font-semibold text-dark'>New Provider</div>
      </div>
      <div className='flex justify-center bg-neutral-50 border-y border-zinc-300 p-6 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col gap-4 w-full max-w-screen-xl px-12 pt-10 pb-16'>
          <div className='text-xl font-semibold'>Tell us more about your service providers</div>
          <div>
            The first thing we need to know is who you work with. We’re talking about all the suppliers and providers who provide services
            to your company throughout the life cycle of your products: from upstream ingredient and packaging suppliers, to transport
            services suppliers, and manufacturers or retailers. All we need is a name, and ID (if you have one), which type of services they
            provide and whether you have operational control over this company.
          </div>
          <NewSupplierForm
            formWide
            name=''
            requiredServices={[]}
            ref={submitButtonRef}
            formRef={formRef}
            modalRef={modalRef}
            onCancel={() => {}}
            onCreated={() => navigate(routes.manage.suppliers.root('all'))}
            waiting={waiting}
            setWaiting={setWaiting}
          />
        </div>
      </div>
      <div className='flex justify-between px-6 mb-12'>
        <button
          type='button'
          onClick={() => navigate(-1)}
          className='text-center border-2 border-transparent bg-[#E8EAF5] text-brandDarkPurple2 rounded-full px-4 py-1 font-semibold active:scale-95'
        >
          Cancel
        </button>
        <button
          type='button'
          disabled={waiting}
          className={cn(
            'text-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
            '[&:active:not(:disabled)]:scale-95 disabled:bg-transparent disabled:text-brand',
            { 'cursor-wait': waiting },
          )}
          onClick={() => submitButtonRef.current?.submit()}
        >
          Create provider
        </button>
      </div>
    </div>
  );
};
