import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account, getAccount, getSelectedWorkspaceId, setSelectedWorkspaceId, Workspace } from '../api';
import { useApiQuery } from './useApiQuery';
import { useLocation } from 'react-router';

interface Profile extends Account {
  selectedWorkspace: Workspace;
  onWorkspaceChanged: (id: string) => void;
}

const Context = createContext<Profile>({} as any);

export const useProfile = () => useContext(Context);

export const ProfileProvider = (props: PropsWithChildren<{}>) => {
  const { data, waiting, reload } = useApiQuery(getAccount());
  const [actualWorkspace, setActualWorkspace] = useState<Workspace>();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const workspaceIdFromPath = pathname.split('/')[2];
  const lastSelectedWorkspaceId = getSelectedWorkspaceId();

  useEffect(() => {
    if (data) {
      setActualWorkspace(
        data.workspaces.find(({ isDefault, workspaceSid }) =>
          workspaceIdFromPath
            ? workspaceIdFromPath === workspaceSid
            : lastSelectedWorkspaceId
            ? lastSelectedWorkspaceId === workspaceSid
            : isDefault,
        )!,
      );
    }

    return () => {
      setActualWorkspace(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return data && !waiting ? (
    <Context.Provider
      value={{
        ...data,
        selectedWorkspace: actualWorkspace!,
        onWorkspaceChanged: (id: string) => {
          setSelectedWorkspaceId(id);
          setActualWorkspace(data.workspaces.find(({ workspaceSid }) => workspaceSid === id)!);
          reload();
          navigate('/workspaces/' + id);
        },
      }}
    >
      {props.children}
    </Context.Provider>
  ) : (
    <></>
  );
};
