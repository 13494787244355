import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';
import { Customer } from '../../../api';
import { useAppRoutes } from '../../../hooks/useAppRoutes';

interface CustomersListProps {
  data: Customer[];
}

export const Main = (props: CustomersListProps) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();

  return (
    <div className='flex flex-col mb-20'>
      <div className='sticky top-0 bg-white py-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
        <div>
          <div className='flex flex-1 justify-center'>
            <div className='flex justify-between items-center px-12 w-[theme(screens.xl)]'>
              <div className='text-lg font-semibold leading-tight text-zinc-900'>Sustained Impact Customers</div>
              <button
                className='rounded-full px-3 py-1.5 bg-brand text-white text-sm font-semibold'
                type='button'
                onClick={() => navigate(routes.customers.newCustomer)}
              >
                New Customer
              </button>
            </div>
          </div>
          <div className='h-[1px] w-full bg-zinc-200 pb-px mt-6'></div>
        </div>
      </div>

      <div className='grid gap-x-3 grid-cols-[3fr_5fr_40px]'>
        <div className='col-span-3 sticky top-[calc(5rem+1px)] bg-slate-50 py-2'>
          <div className='grid gap-x-3 grid-cols-[3fr_5fr_40px] px-2 text-xs text-zinc-600 uppercase tracking-wide'>
            <div>name</div>
            <div>id</div>
            <div></div>
          </div>
        </div>

        {props.data.map((customer, i) => (
          <div
            onClick={() => navigate(routes.customers.editCustomer(customer.customerSid))}
            className='col-span-3 grid items-center grid-cols-subgrid gap-x-3 py-4 p-2 border-b border-zinc-200 text-sm hover:bg-slate-100 cursor-pointer'
            key={i}
          >
            <div className='flex items-center gap-x-3 truncate'>
              {customer.logoUrl ? (
                <img className='shrink-0 size-8 rounded-full overflow-hidden' src={customer.logoUrl} alt='' />
              ) : (
                <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                  <FontAwesomeIcon icon={regular('building')} />
                </div>
              )}
              <div title={customer.companyName} className='truncate'>
                {customer.companyName}
              </div>
            </div>
            <div className='flex items-center gap-x-2 leading-relaxed truncate'>
              <div className='truncate'>{customer.customerSid}</div>
              <button
                className='active:scale-95 hover:text-brand'
                onClick={async (e) => {
                  e.stopPropagation();
                  await navigator.clipboard.writeText(customer.customerSid);
                }}
              >
                <FontAwesomeIcon icon={regular('copy')} />
              </button>
            </div>
            <FontAwesomeIcon className='flex justify-self-center' icon={regular('pencil')} />
          </div>
        ))}
      </div>
    </div>
  );
};
