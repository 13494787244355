import { Fragment } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { AddUser } from './AddUser';
import { Details } from './Details';
import { Users } from './Users';
import { Workspaces } from './Workspaces';
import { EditWorkspace } from './EditWorkspace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Customer } from '../../../../api';
import cn from 'classnames';

enum Tabs {
  Details = 'details',
  Workspaces = 'workspaces',
  Users = 'users',
}

export const CustomerOverview = (props: { data: Customer[]; onBack: () => void }) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { pathname } = useLocation();
  const selectedCustomer = props.data.find((customer) => customer.customerSid === customerId)!;

  return (
    <div className='xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
      <Routes>
        <Route path='users/new' element={<AddUser customer={selectedCustomer} />} />
        <Route path='workspaces/new' element={<EditWorkspace new customer={selectedCustomer} />} />
        <Route path='workspaces/:workspaceId' element={<EditWorkspace customer={selectedCustomer} />} />
        <Route
          path='*'
          element={
            <Fragment>
              <div className='flex flex-1 justify-center py-6 border-b border-zinc-200'>
                <div className='flex justify-start gap-x-4 items-center px-12 w-full xl:w-[theme(screens.xl)] font-semibold text-lg text-zinc-900'>
                  <button type='button' onClick={props.onBack} className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'>
                    <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                  </button>
                  <div>{selectedCustomer?.companyName} Organisation</div>
                </div>
              </div>
              <div className='flex flex-1 justify-center pt-6'>
                <div className='flex flex-col gap-y-6 justify-between px-12 w-[theme(screens.xl)]'>
                  <div className='flex items-center'>
                    {[Tabs.Details, Tabs.Workspaces, Tabs.Users].map((tab, i) => (
                      <Fragment key={i}>
                        {i === 1 && <div className='w-px h-6 bg-zinc-300 mx-4'></div>}
                        <button
                          className={cn(
                            'text-xs font-semibold px-3 py-1.5 border-b-2 uppercase',
                            pathname.endsWith(tab) ? 'text-zinc-800 border-brand' : 'text-zinc-500 hover:text-brand border-transparent',
                          )}
                          onClick={() =>
                            navigate(
                              {
                                [Tabs.Details]: './details',
                                [Tabs.Workspaces]: './workspaces',
                                [Tabs.Users]: './users',
                              }[tab],
                            )
                          }
                        >
                          {tab}
                        </button>
                        {i === 1 && <div className='w-px h-6 bg-zinc-300 mx-4'></div>}
                      </Fragment>
                    ))}
                  </div>
                  <Routes>
                    <Route path='details' element={<Details selectedCustomer={selectedCustomer} />} />
                    <Route path='workspaces' element={<Workspaces customerSid={customerId!} />} />
                    <Route path='users/*' element={<Users customerId={customerId!} />} />
                    <Route path='*' element={<Navigate to='details' replace />} />
                  </Routes>
                </div>
              </div>
            </Fragment>
          }
        />
      </Routes>
    </div>
  );
};
