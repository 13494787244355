import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Dispatch, Fragment, SetStateAction, useEffect, useReducer, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Grade,
  ImpactDeltaType,
  ProductGeneral,
  ProductModelListItem,
  ProductRevisionPreview,
  ProductStage,
  ProductState,
  ProductType,
  ProductWarning,
  SearchProductsParams,
  createProductModelV3,
  deleteProductModelV3,
  deleteProductV3,
  duplicateProduct,
  getListOfRevisionsV3,
  getMethodologyVersion,
  getProductModelsV3,
  searchProducts,
} from '../../../../api';
import { Filters } from '../../../../components/Filters';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { Menu, MenuApi } from '../../../../components/Menu';
import { Modal } from '../../../../components/Modal';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { ViewToggle } from '../../../../components/ViewToggle';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import { useProfile } from '../../../../hooks/useProfile';
import { simplify, roundToLong } from '../../shared';
import { createProduct } from '../Details/dataModel';
import { RevisionsModal } from '../Report/RevisionsModal';
import { ReadOnlyWarning } from '../../../../components/ReadOnlyWarning';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

enum View {
  Production = 'production',
  Consumer = 'consumer',
}

interface ProductOrModel {
  product: ProductGeneral;
  model?: ProductModelListItem;
}

const types = [
  { value: ProductType.Final, label: 'Final' },
  { value: ProductType.Intermediate, label: 'Intermediate' },
  { value: ProductType.Internal, label: 'Internal' },
];

const stages = [
  { value: ProductStage.Production, label: 'Production' },
  { value: ProductStage.Development, label: 'Development' },
];

const defaultSearchParams: SearchProductsParams = {
  pageSize: 50,
  sortBy: 'updatedAt',
  sortAscending: false,
  contains: '',
  pageToken: '',
};

export const List = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const profile = useProfile();
  const [creating, setCreating] = useState(false);
  const [createLimit, setCreateLimit] = useState(false);
  const [compare, setCompare] = useState(false);
  const [selectedForCompare, setSelectedForCompare] = useState(new Array<ProductOrModel>());
  const [loading, setLoading] = useState(true);
  const [selectedView, setSelectedView] = useState<View>(View.Production);
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchInputValue = useDebounce(searchInputValue, 300);
  const [searchParams, setSearchParams] = useState(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.get('state') && urlSearchParams.get('stage')) {
      return {
        ...defaultSearchParams,
        state: urlSearchParams.get('state') as ProductState,
        stage: urlSearchParams.get('stage') as ProductStage,
      };
    }

    if (urlSearchParams.get('stage') && urlSearchParams.get('sortAscending') && urlSearchParams.get('sortBy')) {
      return {
        ...defaultSearchParams,
        stage: urlSearchParams.get('stage') as ProductStage,
        sortBy: urlSearchParams.get('sortBy') as SearchProductsParams['sortBy'],
        sortAscending: urlSearchParams.get('sortAscending') === 'true',
      };
    }

    if (urlSearchParams.get('stage')) {
      return {
        ...defaultSearchParams,
        stage: urlSearchParams.get('stage') as ProductStage,
      };
    }

    if (urlSearchParams.get('state')) {
      return {
        ...defaultSearchParams,
        state: urlSearchParams.get('state') as ProductState,
      };
    }

    if (urlSearchParams.get('sortBy')) {
      return {
        ...defaultSearchParams,
        sortBy: urlSearchParams.get('sortBy') as SearchProductsParams['sortBy'],
        sortAscending: urlSearchParams.get('sortAscending') === 'true',
      };
    }

    return defaultSearchParams;
  });
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [results, setResults] = useState<{ list: ProductGeneral[]; totalCount: number; state?: ProductState }>({
    list: [],
    totalCount: 0,
    state: defaultSearchParams.state,
  });
  const [reloadCounter, reload] = useReducer((state) => state + 1, 0);

  const filterFields = [
    {
      label: 'Type',
      items: [{ label: 'All', value: '' }, ...types],
      field: 'type',
      value: searchParams.type,
      disabled: selectedView === View.Consumer,
    },
    {
      label: 'Status',
      items: [{ label: 'All', value: '' }, ...stages],
      field: 'stage',
      value: searchParams.stage,
      disabled: false,
    },
  ];

  useEffectOnNextRenders(() => {
    setLoading(true);
    setSearchParams((oldParams) => ({ ...oldParams, contains: debouncedSearchInputValue }));
  }, [debouncedSearchInputValue]);

  useEffect(() => {
    setLoading(true);
    searchProducts(searchParams).ok(({ products, totalResults, nextPageToken }) => {
      setNextPageToken(nextPageToken);
      setResults({ list: products, totalCount: totalResults, state: searchParams.state });
      setLoading(false);
    });
  }, [searchParams, reloadCounter]);

  const sortIcon = (field: SearchProductsParams['sortBy']) => (
    <FontAwesomeIcon
      className={cn('text-brand', {
        invisible: field !== searchParams.sortBy,
      })}
      icon={searchParams.sortAscending ? regular('arrow-up') : regular('arrow-down')}
    />
  );

  const onSortClick = (field: SearchProductsParams['sortBy']) =>
    setSearchParams((oldParams) =>
      field === searchParams.sortBy
        ? { ...oldParams, sortAscending: !oldParams.sortAscending }
        : { ...oldParams, sortBy: field, sortAscending: true },
    );

  const title = !searchParams.state ? 'All Products' : searchParams.state === ProductState.Complete ? 'Products' : 'Draft Products';

  const onCreate = () => {
    setCreating(true);
    createProduct().call({
      ok: ({ id, errorCode }) => {
        setCreating(false);

        if (errorCode) {
          setCreateLimit(true);
        } else {
          navigate(routes.products.productGraph(id));
        }
      },
      fail: () => {
        setCreating(false);
      },
    });
  };

  const fetchNextPage = () => {
    searchProducts({ ...searchParams, pageToken: nextPageToken }).ok(({ products, totalResults, nextPageToken }) => {
      setNextPageToken(nextPageToken);
      setResults((oldResults) => ({ ...oldResults, list: [...oldResults.list, ...products], totalCount: totalResults }));
    });
  };

  return (
    <div className='flex flex-col px-6 mb-40'>
      <Helmet title={title} />
      {compare && (
        <div className='z-10 fixed bottom-0 inset-x-0 flex justify-center bg-slate-50 shadow-[0_1px_10px_rgba(0,0,0,0.15)]'>
          <div className='px-12 py-5 flex justify-between w-full max-w-screen-xl'>
            <div className='flex gap-4'>
              {[0, 1]
                .map((index) => selectedForCompare[index])
                .map((item, i) => (
                  <div
                    key={i}
                    className='flex flex-col justify-between w-96 h-20 px-4 py-2 border border-zinc-200 text-zinc-800 bg-slate-100 rounded-lg'
                  >
                    {item && (
                      <>
                        <div className='font-semibold truncate'>{item.model?.title ?? item.product.name}</div>
                        {!item.model && (
                          <div className='flex items-center gap-2'>
                            <div className='font-semibold text-lg'>{simplify(item.product.impactPoints)}</div>
                            <div className='uppercase tracking-wider text-xs mt-0.5'>impact points</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
            <div className='flex items-center gap-4'>
              <div className='uppercase tracking-wider text-xs font-semibold text-neutral-700'>
                {selectedForCompare.length} {selectedForCompare.length === 1 ? 'product' : 'products'} selected
              </div>
              <NavLink
                to={`${routes.products.list}/compare/${selectedForCompare
                  .map(({ product, model }) => `${product.id}/${model?.id ?? '-'}`)
                  .join('/')}`}
                className={cn(
                  'flex self-center rounded-full px-4 py-1.5 text-white text-sm font-semibold active:scale-95',
                  selectedForCompare.length < 2 ? 'bg-neutral-300 pointer-events-none' : 'bg-brand',
                )}
              >
                Compare products
              </NavLink>
            </div>
          </div>
        </div>
      )}
      <div className='flex justify-between items-center border-b border-neutral-400/50 pb-6'>
        <div className='text-2xl font-semibold text-dark'>{title}</div>
        <div className='text-sm flex items-center gap-4'>
          <button type='button' className='flex items-center gap-2' onClick={() => setCompare((value) => !value)}>
            <div className={cn('flex w-7 h-4 p-0.5 rounded-full', compare ? 'justify-end bg-brand' : 'bg-gray-300')}>
              <div className='bg-white rounded-full w-3 aspect-square'></div>
            </div>
            <div>Compare</div>
          </button>
          <LimitTooltip
            enabled={createLimit}
            entityName='products limit'
            valueKey='maxProductSkuCount'
            onDismiss={() => setCreateLimit(false)}
          >
            <div className='flex relative'>
              <Menu
                zIndex={10}
                placement='bottom-end'
                items={[
                  {
                    label: 'Create manually',
                    icon: regular('file-pen'),
                    onClick: onCreate,
                  },
                  {
                    label: 'Import file',
                    icon: regular('file-import'),
                    onClick: () => navigate(`${routes.products.list}/import`),
                  },
                ]}
              >
                {() => (
                  <div>
                    <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                      <button
                        type='button'
                        disabled={creating || !profile.selectedWorkspace.permissions.productManagement}
                        className={cn(
                          'flex self-center border-2 bg-brand rounded-full px-4 py-1 text-white font-semibold',
                          '[&:active:not(:disabled)]:scale-95',
                          createLimit ? 'border-f' : 'border-brand',
                          creating
                            ? 'disabled:cursor-wait'
                            : 'disabled:bg-neutral-300 disabled:border-transparent disabled:cursor-not-allowed',
                        )}
                      >
                        New Product
                      </button>
                    </ReadOnlyWarning>
                  </div>
                )}
              </Menu>
            </div>
          </LimitTooltip>
        </div>
      </div>
      <div className='flex mt-6 h-10'>
        <div className='flex-1 flex'>
          <ViewToggle
            theme='light'
            button1={{
              label: 'Production',
              active: selectedView === View.Production,
            }}
            button2={{
              label: 'Consumer',
              active: selectedView === View.Consumer,
            }}
            toggleView={() => {
              setSelectedView((current) => (current === View.Production ? View.Consumer : View.Production));
              setSearchParams((oldParams) => {
                const newParams = { ...oldParams };
                if (selectedView === View.Consumer) {
                  delete newParams.type;
                } else {
                  newParams.type = ProductType.Final;
                }
                return newParams;
              });
            }}
          />
        </div>
        <div className='flex items-center text-xs my-0.5 border-zinc-300'>
          {[
            {
              label: 'all',
              state: undefined,
              active: !searchParams.state,
            },
            { label: 'completed', state: ProductState.Complete, active: searchParams.state === ProductState.Complete },
            { label: 'drafts', state: ProductState.Draft, active: searchParams.state === ProductState.Draft },
          ].map((item, i, arr) => (
            <Fragment key={i}>
              <button
                onClick={() =>
                  setSearchParams((oldParams) => {
                    const newParams = { ...oldParams };

                    if (item.state) {
                      newParams.state = item.state;
                    } else {
                      delete newParams.state;
                    }

                    return newParams;
                  })
                }
                className={cn('flex items-center gap-2 h-full border-b-2 px-1', item.active ? 'border-brand' : 'border-white', {
                  'opacity-60': !item.active,
                })}
              >
                <div className='font-semibold uppercase'>{item.label}</div>
                {item.state === results.state && (
                  <div className='text-brand bg-lightBg2 py-0.5 px-2 rounded-full'>{results.totalCount}</div>
                )}
              </button>
              <div className={cn('border-r border-zinc-300 h-[calc(100%_-_3px)] mx-3', { invisible: i === arr.length - 1 })} />
            </Fragment>
          ))}
        </div>
        <div className='flex-1 flex items-center justify-end gap-4'>
          <Filters<ProductType | ProductStage | undefined, SearchProductsParams>
            count={[searchParams.stage, searchParams.type].filter((value) => value).length}
            filterFields={filterFields}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          <div className='flex items-center relative h-full text-sm'>
            <input
              autoFocus
              type='search'
              className='rounded-full border [&:not(:disabled)]:border-neutral-600 h-full pl-12 pr-6'
              placeholder='Find Product…'
              value={searchInputValue}
              onChange={({ target }) => setSearchInputValue(target.value)}
            />
            <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute left-6 text-light' />
          </div>
        </div>
      </div>
      {searchParams.state !== ProductState.Draft && (
        <div className='mt-10 px-3'>
          {selectedView === View.Production
            ? 'The table below presents the total environmental impact of manufacturing the net amount of each of your product (eg. For a 250g product, the impact of making those 250g)'
            : 'The table below presents the corresponding consumer grades (A to G) of each of your product, assessed on a per kg basis to allow for comparison'}
        </div>
      )}
      <div className='flex self-end mt-4 mb-2 mr-3 text-xs font-semibold'>Methodology version V.{getMethodologyVersion()}</div>
      <InfiniteScroll
        dataLength={uniqBy(results.list, 'id').length}
        next={fetchNextPage}
        hasMore={nextPageToken !== ''}
        loader={
          <div className='py-3 text-center'>
            <FontAwesomeIcon size='2x' pulse icon={duotone('loader')} />
          </div>
        }
      >
        <table className='border-collapse w-full text-sm'>
          <thead>
            <tr className='border-t border-b border-gray-300/50 text-dark h-12'>
              {compare && <th />}
              <th />
              <th className='px-1'>
                <button className='flex gap-2 items-center' onClick={() => onSortClick('name')} type='button'>
                  Name
                  {sortIcon('name')}
                </button>
              </th>
              <th className='px-1'>Product type</th>
              <th className='px-1'>Status</th>
              <th className='px-1'>
                <button className='flex justify-center items-center gap-2' onClick={() => onSortClick('updatedAt')} type='button'>
                  Product update
                  {sortIcon('updatedAt')}
                </button>
              </th>
              {selectedView === View.Production && (
                <th className='px-1'>
                  <button className='flex gap-2 items-center' onClick={() => onSortClick('amount')} type='button'>
                    Net amount
                    {sortIcon('amount')}
                  </button>
                </th>
              )}
              <th className='px-1'>ID</th>
              <th className='px-1'>
                <button className='flex gap-2 items-center' onClick={() => onSortClick('firstPartyDataPercentage')} type='button'>
                  First-party data
                  {sortIcon('firstPartyDataPercentage')}
                </button>
              </th>
              {selectedView === View.Production && (
                <th className='px-1'>
                  <button className='flex gap-2 items-center text-center' onClick={() => onSortClick('totalImpact')} type='button'>
                    Total Impact
                    {sortIcon('totalImpact')}
                  </button>
                </th>
              )}
              {selectedView === View.Consumer && <th className='px-2 text-center'>Grade</th>}
              <th className='text-center'>
                <FontAwesomeIcon size='lg' icon={regular('file-chart-column')} />
              </th>
              <th className='text-center'>
                <FontAwesomeIcon size='lg' icon={regular('code-compare')} />
              </th>
              <th className='w-10 text-center'></th>
            </tr>
          </thead>
          <tbody>
            {uniqBy(results.list, 'id').map((product, i) => (
              <Row
                key={product.id}
                index={i}
                item={product}
                reload={reload}
                view={selectedView}
                selectedView={selectedView}
                searchString={debouncedSearchInputValue}
                compare={compare}
                selectedForCompare={selectedForCompare}
                setSelectedForCompare={setSelectedForCompare}
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>

      {results.list.length === 0 && isEqual(searchParams, defaultSearchParams) && !loading && (
        <div className='h-[calc(100vh/3)] flex justify-center items-center'>
          <div className='flex flex-col gap-6 rounded-2xl border py-6 px-12'>
            <div className='flex align-middle self-center items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
              <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
            </div>
            <div className='flex flex-col gap-2 items-center justify-center'>
              <div className='text-xl font-semibold'>Next steps</div>
              {searchParams.state === ProductState.Draft
                ? 'Once you create a few products, you’ll be able to see and manage all of them on this table.'
                : 'Complete your product drafts first before you can see and manage them on this table.'}
            </div>
            <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
              <button
                type='button'
                disabled={creating || !profile.selectedWorkspace.permissions.productManagement}
                className={cn(
                  'flex self-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
                  '[&:active:not(:disabled)]:scale-95',
                  creating ? 'disabled:cursor-wait' : 'disabled:bg-neutral-300 disabled:border-transparent disabled:cursor-not-allowed',
                )}
                onClick={onCreate}
              >
                Create your first product
              </button>
            </ReadOnlyWarning>
          </div>
        </div>
      )}
    </div>
  );
};

const Row = (props: {
  item: ProductGeneral;
  index: number;
  reload: () => void;
  view: View;
  selectedView: View;
  searchString: string;
  compare: boolean;
  selectedForCompare: ProductOrModel[];
  setSelectedForCompare: Dispatch<SetStateAction<ProductOrModel[]>>;
}) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const profile = useProfile();
  const { item } = props;
  const [waiting, setWaiting] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [creatingModel, setCreatingModel] = useState(false);
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const [productsCreateLimit, setProductsCreateLimit] = useState(false);
  const [models, setModels] = useState<ProductModelListItem[]>([]);
  const [revisions, setRevisions] = useState<ProductRevisionPreview[]>([]);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const revisionsModalRef = useRef<ModalApi>(null);
  const confirmationModalRef = useRef<ModalApi>(null);
  const menuRef = useRef<MenuApi>(null);
  const [selectedRevision, setSelectedRevision] = useState<{ id: string; updatedAt: string } | undefined>();
  const bgColor = props.index % 2 ? 'bg-slate-100/50' : 'bg-white';
  const compareIncompatibleType =
    props.selectedForCompare.length === 1 &&
    props.selectedForCompare[0].product.id !== item.id &&
    props.selectedForCompare[0].product.productType !== item.productType;
  const compareMaxSelected = props.selectedForCompare.length === 2;

  useEffect(() => {
    setModels([]);
  }, [item.id]);

  useEffect(() => {
    if (expanded) {
      getProductModelsV3(item.id).ok((response) => {
        setModels(response.models);
      });
    }
  }, [item.id, expanded]);

  const onDelete = (close: () => void) => {
    setWaiting(true);
    deleteProductV3(item.id).ok((err) => {
      if (!err?.errorCode) {
        close();
        props.reload();
        setWaiting(false);
      } else {
        setDeleteErrorMessage(err.message);
        setWaiting(false);
      }
    });
  };

  return (
    <>
      <tr className={cn('h-12', bgColor, { 'text-zinc-400': item.state === ProductState.Draft })}>
        {props.compare && (
          <td>
            <TooltipV3
              content={
                <div className='bg-[#330099] text-white text-center text-xs rounded-lg p-2 w-44'>
                  You can only compare products of the same type.
                </div>
              }
              placement='top-start'
              offsetCross={-8}
              disabled={!compareIncompatibleType}
            >
              <div>
                <input
                  type='checkbox'
                  className='m-1'
                  checked={props.selectedForCompare.some(({ product, model }) => !model && product.id === item.id)}
                  disabled={
                    compareIncompatibleType ||
                    (compareMaxSelected && !props.selectedForCompare.some(({ product, model }) => !model && product.id === item.id))
                  }
                  onChange={() =>
                    props.setSelectedForCompare((oldValues) =>
                      oldValues.some(({ product }) => product.id === item.id)
                        ? oldValues.filter(({ product }) => product.id !== item.id)
                        : [...oldValues, { product: item }],
                    )
                  }
                />
              </div>
            </TooltipV3>
          </td>
        )}
        <td>
          <button
            type='button'
            className={cn('flex justify-center items-center px-1 h-6 hover:text-brandDark aspect-square transition-colors', {
              invisible: item.modelCount === 0 || item.state === ProductState.Draft,
            })}
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon className={cn('transition-all', { 'rotate-90': expanded })} icon={regular('chevron-right')} />
          </button>
        </td>
        <td className='px-1'>
          <div className='flex items-center gap-1.5 max-w-[180px] truncate whitespace-nowrap'>
            <div title={item.name} className={cn('truncate', { 'text-zinc-400': !item.name })}>
              {item.name || '(Unnamed)'}
            </div>
            {item.state === ProductState.Draft && (
              <div className='text-xs text-rose-500 bg-rose-50 px-1.5 py-px rounded-md font-semibold'>Draft</div>
            )}
            {item.state === ProductState.Complete && item.warnings.some(({ id }) => id === ProductWarning.InternalDraft) && (
              <TooltipV3
                placement='bottom-start'
                content={
                  <div className='w-52 leading-normal whitespace-normal px-2 py-1 rounded-lg text-[10px] text-white bg-brandDarkPurple2 shadow-[0_0_4px_2px_rgba(0,0,0,0.15)]'>
                    Interim results - this product includes some incomplete internal product (draft).
                  </div>
                }
              >
                <div className='text-amber-600 bg-amber-50 px-2 py-px rounded-lg'>
                  <FontAwesomeIcon icon={regular('exclamation-triangle')} />
                </div>
              </TooltipV3>
            )}
          </div>
        </td>
        <td className='px-1'>{types.find(({ value }) => value === item.productType)!.label}</td>
        <td className='px-1'>{stages.find(({ value }) => value === item.stage)!.label}</td>
        <td className='px-1'>{format(new Date(item.updatedAt), 'dd/MM/yyyy')}</td>
        {props.selectedView === View.Production && (
          <td className='px-1'>{item.amount?.value && item.amount?.unit && `${item.amount.value}${item.amount.unit.name}`}</td>
        )}
        <td className='px-1 max-w-[120px] truncate'>{item.skuId}</td>
        <td className='px-1'>{item.state === ProductState.Complete && `${item.firstPartyDataPercentage} %`}</td>
        {props.selectedView === View.Production && (
          <td className='px-1'>
            <div className='truncate'>
              {item.impactPoints !== 0 ? (
                <div title={roundToLong(item.impactPoints)} className='truncate'>
                  {simplify(item.impactPoints)} Impact points
                </div>
              ) : (
                <div className='text-neutral-400'>Not assessed</div>
              )}
            </div>
          </td>
        )}
        {props.view === View.Consumer && (
          <td>
            <div className='px-1 flex justify-center'>
              {(() => {
                if (item.state === ProductState.Draft) {
                  return '—';
                }

                return item.totalGrade !== '' ? (
                  <div
                    className={cn('flex justify-center items-center text-white font-semibold rounded-full w-7 aspect-square', {
                      'bg-aa': item.totalGrade === Grade.AA,
                      'bg-a': item.totalGrade === Grade.A,
                      'bg-b': item.totalGrade === Grade.B,
                      'bg-c': item.totalGrade === Grade.C,
                      'bg-d': item.totalGrade === Grade.D,
                      'bg-e': item.totalGrade === Grade.E,
                      'bg-f': item.totalGrade === Grade.F,
                      'bg-g': item.totalGrade === Grade.G,
                    })}
                  >
                    {item.totalGrade}
                  </div>
                ) : (
                  <div className='text-neutral-400'>Not assessed</div>
                );
              })()}
            </div>
          </td>
        )}
        <td className='text-center'>
          <NavLink
            to={(() => {
              switch (props.selectedView) {
                case View.Production:
                  return `${routes.products.productOverview.production(item.id)}`;
                case View.Consumer:
                  return `${routes.products.productOverview.consumer(item.id)}`;
                default:
                  return `${routes.products.productOverview.production(item.id)}`;
              }
            })()}
            className={cn('px-2 text-brandDarkPurple2 text-xs font-semibold hover:underline ', {
              'text-zinc-400 pointer-events-none': !props.item.reportCalculable,
            })}
          >
            View
          </NavLink>
        </td>
        <td className='text-center'>
          <LimitTooltip
            enabled={modelsCreateLimit}
            entityName='models limit'
            valueKey='maxProductModelCount'
            onDismiss={() => setModelsCreateLimit(false)}
          >
            <div>
              <button
                type='button'
                disabled={creatingModel || item.state === ProductState.Draft}
                className={cn(
                  '[&:hover:not(:disabled)]:underline text-xs font-semibold',
                  modelsCreateLimit ? 'text-f' : 'text-brandDarkPurple2',
                  creatingModel ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed',
                  { 'disabled:text-zinc-400': item.state === ProductState.Draft },
                )}
                onClick={() => {
                  setCreatingModel(true);
                  createProductModelV3(item.id).call({
                    ok: ({ model, errorCode }) => {
                      if (errorCode) {
                        setModelsCreateLimit(true);
                        setCreatingModel(false);
                      } else {
                        setCreatingModel(false);
                        navigate(routes.products.modelGraph(props.item.id, model.id));
                      }
                    },
                    fail: () => setCreatingModel(false),
                  });
                }}
              >
                Model
              </button>
            </div>
          </LimitTooltip>
        </td>
        <LimitTooltip
          enabled={productsCreateLimit}
          entityName='products limit'
          valueKey='maxProductSkuCount'
          onDismiss={() => setProductsCreateLimit(false)}
        >
          <td>
            <ModalV3
              noCloseOnConfirm
              noCloseOnCancel
              size='narrow-'
              ref={confirmationModalRef}
              confirmLabel='View product'
              cancelLabel='Close'
              title='Restore complete'
              body={
                selectedRevision && (
                  <div className='-mt-6'>
                    <span>
                      Your product was successfully restored to an earlier version from{' '}
                      <span className='font-semibold'>
                        {formatInTimeZone(parseISO(selectedRevision.updatedAt), 'UTC', 'd MMM yy HH:mm')}.
                      </span>
                    </span>
                  </div>
                )
              }
              onClose={() => {
                setSelectedRevision(undefined);
                confirmationModalRef.current!.close();
                menuRef.current!.close();
                props.reload();
              }}
              onCancel={() => {
                setSelectedRevision(undefined);
                confirmationModalRef.current!.close();
                menuRef.current!.close();
                props.reload();
              }}
              onConfirm={() => {
                setSelectedRevision(undefined);
                navigate(routes.products.productGraph(selectedRevision!.id));
              }}
            />

            {(() => {
              const menuEnabled = profile.selectedWorkspace.permissions.productManagement;
              const button = (
                <button
                  className={cn(
                    'flex mx-auto items-center justify-center rounded-md h-6 aspect-square transition-colors',
                    bgColor === 'bg-white' ? 'hover:bg-slate-50' : 'hover:bg-white',
                  )}
                >
                  <FontAwesomeIcon className='text-dark' icon={solid('ellipsis-vertical')} />
                </button>
              );

              return menuEnabled ? (
                <Menu
                  ref={menuRef}
                  zIndex={10}
                  onOpen={() => {
                    setWaiting(true);
                    getListOfRevisionsV3(item.id).ok(({ revisions }) => {
                      setWaiting(false);
                      setRevisions(revisions);
                    });
                  }}
                  placement='bottom-end'
                  items={[
                    {
                      label: 'Edit',
                      icon: regular('pen-to-square'),
                      disabled: !profile.selectedWorkspace.permissions.productManagement,
                      onClick: () => navigate(`${routes.products.productGraph(item.id)}`),
                    },
                    {
                      waiting,
                      label: 'Edit history',
                      icon: regular('clock-rotate-left'),
                      disabled: waiting || revisions.length === 0,
                      modal: (button, onOpenChange) => {
                        return (
                          <RevisionsModal
                            menuRef={menuRef}
                            modalRef={revisionsModalRef}
                            product={item}
                            onOpenChange={onOpenChange}
                            onViewClick={({ id, revisionNumber }) => {
                              if (revisionNumber === 0) {
                                window.open(`${routes.products.productGraph(id)}`, '_blank')!.open();
                              } else {
                                navigate(routes.products.revisions.overview(id, revisionNumber));
                              }
                            }}
                            revisionsList={revisions}
                            onRestored={(data) => {
                              setSelectedRevision(data);
                              confirmationModalRef.current!.open();
                            }}
                          >
                            {button}
                          </RevisionsModal>
                        );
                      },
                    },
                    {
                      label: 'Duplicate',
                      icon: regular('clone'),
                      disabled: !profile.selectedWorkspace.permissions.productManagement,
                      onClick: () =>
                        duplicateProduct(item.id).call({
                          ok: ({ id, errorCode }) => {
                            if (errorCode) {
                              setProductsCreateLimit(true);
                            } else {
                              navigate(`${routes.products.productGraph(id)}/info?duplicated=true`);
                            }
                          },
                        }),
                    },
                    {
                      label: 'Delete',
                      icon: regular('trash-can'),
                      disabled: !profile.selectedWorkspace.permissions.productManagement,
                      modal: (button, onOpenChange) => (
                        <Modal
                          icon={regular('trash-can')}
                          title={`Delete ${item.name || 'Draft'}?`}
                          body={deleteErrorMessage ? deleteErrorMessage : 'You will not be able to recover it.'}
                          noSubmit={deleteErrorMessage !== ''}
                          confirmLabel='Delete'
                          waiting={waiting}
                          onConfirm={(_, close) => onDelete(close)}
                          cancelLabel={deleteErrorMessage ? 'OK' : undefined}
                          onCancel={deleteErrorMessage ? () => setDeleteErrorMessage('') : undefined}
                          onOpenChange={onOpenChange}
                        >
                          {button}
                        </Modal>
                      ),
                    },
                  ]}
                >
                  {() => button}
                </Menu>
              ) : (
                <ReadOnlyWarning show>{button}</ReadOnlyWarning>
              );
            })()}
          </td>
        </LimitTooltip>
      </tr>
      {expanded && models.length > 0 && (
        <>
          <tr className={bgColor}>
            {props.compare && <td />}
            <td />
            <td className='px-1 py-2 font-semibold text-xs text-brand uppercase' colSpan={100}>
              models
            </td>
          </tr>
          {orderBy(models, ({ updatedAt }) => updatedAt, 'desc').map((model, i) => (
            <Fragment key={model.id}>
              <tr className={cn('border-t', i === 0 && 'border-none', bgColor)}>
                {props.compare && <td />}
                <td />
                <td className='px-1 py-2' colSpan={3}>
                  <div className='flex items-center gap-4'>
                    {props.compare && (
                      <TooltipV3
                        content={
                          <div className='bg-[#330099] text-white text-center text-xs rounded-lg p-2 w-44'>
                            You can only compare products of the same type.
                          </div>
                        }
                        placement='top-start'
                        offsetCross={-8}
                        disabled={!compareIncompatibleType}
                      >
                        <div>
                          <input
                            type='checkbox'
                            checked={props.selectedForCompare.some((selected) => selected.model?.id === model.id)}
                            disabled={
                              compareIncompatibleType ||
                              (compareMaxSelected && !props.selectedForCompare.some((selected) => selected.model?.id === model.id))
                            }
                            onChange={() =>
                              props.setSelectedForCompare((oldValues) =>
                                oldValues.some((selected) => selected.model?.id === model.id)
                                  ? oldValues.filter((selected) => selected.model?.id !== model.id)
                                  : [...oldValues, { product: item, model }],
                              )
                            }
                          />
                        </div>
                      </TooltipV3>
                    )}
                    <div className='flex flex-col gap-1 max-w-[330px]'>
                      <div className='truncate' title={model.title}>
                        {model.title}
                      </div>
                      <div className='flex items-center text-zinc-500 gap-x-2 text-xs'>
                        <FontAwesomeIcon icon={light('user')} />
                        {model.author}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='px-1'>{format(new Date(model.updatedAt), 'dd/MM/yyyy')}</td>
                {props.selectedView === View.Production && <td colSpan={2} />}
                <td className='px-1'>
                  {model.proposedChanges} change{model.proposedChanges === 1 ? '' : 's'}
                </td>
                <td className='px-1'>
                  {model.impactDelta.type === ImpactDeltaType.Zero ? (
                    <div className='text-neutral-400'>No impact change</div>
                  ) : (
                    <div className={model.impactDelta.type === ImpactDeltaType.Lower ? 'text-emerald-700' : 'text-red-500'}>
                      {`${model.impactDelta.formatted}% ${model.impactDelta.type === ImpactDeltaType.Lower ? 'lower' : 'higher'} impact`}
                    </div>
                  )}
                </td>
                {props.selectedView === View.Consumer && <td />}
                <td className='text-center'>
                  <NavLink
                    to={routes.products.modelOverview(item.id, model.id)}
                    className='text-brandDarkPurple2 text-xs font-semibold hover:underline'
                  >
                    View
                  </NavLink>
                </td>
                <td />
                <td>
                  <Menu
                    placement='bottom-end'
                    zIndex={10}
                    items={[
                      {
                        label: 'Edit',
                        icon: regular('pen-to-square'),
                        onClick: () => navigate(routes.products.modelGraph(item.id, model.id)),
                      },
                      {
                        label: 'Delete',
                        icon: regular('trash-can'),
                        modal: (button, onOpenChange) => (
                          <Modal
                            icon={regular('trash-can')}
                            title='Delete model?'
                            body='You will not be able to recover it.'
                            confirmLabel='Yes'
                            waiting={waiting}
                            onConfirm={(_, close) => {
                              setWaiting(true);
                              deleteProductModelV3(item.id, model.id).ok(() =>
                                getProductModelsV3(item.id).ok(({ models }) => {
                                  close();
                                  setWaiting(false);
                                  setModels(models);
                                }),
                              );
                            }}
                            onOpenChange={onOpenChange}
                          >
                            {button}
                          </Modal>
                        ),
                      },
                    ]}
                  >
                    {() => (
                      <button className='flex mx-auto items-center justify-center rounded-md h-8 aspect-square hover:bg-gray-50 transition-colors'>
                        <FontAwesomeIcon className='text-dark' icon={solid('ellipsis-vertical')} />
                      </button>
                    )}
                  </Menu>
                </td>
              </tr>
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};
