import { Route, Routes } from 'react-router';
import { Main } from './Main';
import { Helmet } from 'react-helmet-async';

export const ProgressTracking = () => {
  return (
    <>
      <Helmet title='Progress tracking' />
      <Routes>
        <Route path='*' element={<Main />} />
      </Routes>
    </>
  );
};
