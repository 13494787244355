import { createProductModelV3, ProductWithAmount } from '../../../api';
import { simplify, roundToShort } from '../shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { TooltipV3 } from '../../../components/TooltipV3';
import { useState } from 'react';
import { LimitTooltip } from '../../../components/LimitTooltip';
import { useAppRoutes } from '../../../hooks/useAppRoutes';

interface Props {
  products: ProductWithAmount[];
  showReportButton: boolean;
  showModellingButton: boolean;
}

export const ListOfProducts = (props: Props) => {
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const [creatingModel, setCreatingModel] = useState(false);
  const { routes } = useAppRoutes();

  if (!props.products) {
    return <></>;
  }

  return (
    <div className='-mt-12'>
      <div className='border rounded-lg overflow-hidden mt-6'>
        <table className='w-full'>
          <thead className='font-semibold'>
            <tr className='h-12'>
              <td className='pl-3'>Name</td>
              <td className='px-2'>Net amount</td>
              <td>Units</td>
              <td className='px-2'>ID</td>
              <td>Impact Points</td>
              <td>Contribution</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr className='border-b border-zinc-200'></tr>
            {props.products.map((product, i) => (
              <tr className={cn('h-12', product.isMajor ? 'bg-yellow-50' : i % 2 ? '' : 'bg-slate-50')} key={product.id}>
                <td className='max-w-[180px] truncate whitespace-nowrap pl-3'>{product.name}</td>
                <td className='px-2'>
                  {product.amount.value}
                  {product.amount.unit.name}
                </td>
                <td>{product.count}</td>
                <td className='px-2'>{product.sku}</td>
                <td className='font-semibold'>{simplify(product.impactPoints)}</td>
                <td className='font-semibold'>{product.sharePercent < 0 ? '<0%' : roundToShort(product.sharePercent)}%</td>
                <td className='pr-2'>
                  <div className='grid grid-cols-2 items-center'>
                    {props.showReportButton && (
                      <TooltipV3
                        content={
                          <div className='flex items-center gap-2 rounded-lg shadow-md px-2 py-1 bg-[#330099] text-white whitespace-nowrap'>
                            Open full report
                          </div>
                        }
                      >
                        <NavLink
                          to={routes.products.productOverview.production(product.id)}
                          target='_blank'
                          className='flex justify-self-center'
                        >
                          <FontAwesomeIcon
                            className='p-2 bg-[#e8eaf5] rounded-lg text-lg hover:text-brand hover:cursor-pointer'
                            icon={light('file-chart-column')}
                          />
                        </NavLink>
                      </TooltipV3>
                    )}
                    {props.showModellingButton && (
                      <TooltipV3
                        content={
                          <div className='flex items-center gap-2 rounded-lg shadow-md px-2 py-1 bg-[#330099] text-white whitespace-nowrap'>
                            Create new model
                          </div>
                        }
                      >
                        <LimitTooltip
                          placement='top-start'
                          enabled={modelsCreateLimit}
                          entityName='models limit'
                          valueKey='maxProductModelCount'
                          onDismiss={() => setModelsCreateLimit(false)}
                        >
                          <div>
                            <button
                              disabled={creatingModel}
                              onClick={() => {
                                setCreatingModel(true);
                                createProductModelV3(product.id).call({
                                  ok: ({ model, errorCode }) => {
                                    setCreatingModel(false);
                                    if (errorCode) {
                                      setModelsCreateLimit(true);
                                    } else {
                                      window.open(routes.products.modelGraph(product.id, model.id), '_blank');
                                    }
                                  },
                                });
                              }}
                            >
                              <FontAwesomeIcon
                                className={cn(
                                  'p-2 bg-[#e8eaf5] rounded-lg text-lg hover:text-brand',
                                  creatingModel ? 'cursor-wait' : 'cursor-pointer',
                                )}
                                icon={light('arrows-repeat')}
                              />
                            </button>
                          </div>
                        </LimitTooltip>
                      </TooltipV3>
                    )}
                    <TooltipV3
                      disabled={!product.isMajor}
                      content={
                        <div className='flex items-center gap-2 rounded-lg px-2 py-1 bg-[#330099] text-white whitespace-nowrap shadow-[0_0_4px_rgba(0,0,0,0.15)]'>
                          Most relevant
                        </div>
                      }
                    >
                      <span>
                        <FontAwesomeIcon
                          className={cn('w-full self-center justify-self-center text-amber-400 text-xl', {
                            invisible: !product.isMajor,
                          })}
                          icon={solid('seal-exclamation')}
                        />
                      </span>
                    </TooltipV3>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
