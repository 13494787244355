import { forwardRef } from 'react';
import { TimeframeDisplay } from '../../../components/TimeframeDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BasicProductInfo, HighestImpactProducts, ProductType, ProductWithAmount } from '../../../../../../api';
import { simplify } from '../../../../shared';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { format } from 'date-fns';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAppRoutes } from '../../../../../../hooks/useAppRoutes';

interface Props {
  title: string;
  data?: {
    timeframe: {
      start: string;
      end: string;
    };
    products: {
      lowestImpactProducts: BasicProductInfo[];
      highestImpactProducts: HighestImpactProducts[];
    };
    productsRefs: ProductWithAmount[];
  };
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
}

export const ProductionWinnersLosersAtVolumes = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { routes } = useAppRoutes();

  if (!props.data || props.selectedType === 'physical') return <></>;

  const timeframe = {
    start: new Date(props.data.timeframe.start),
    end: new Date(props.data.timeframe.end),
  };

  const bestProduct = (() => {
    const bestRef = props.data.productsRefs.find((p) => {
      return p.id === props.data!.products.lowestImpactProducts[0].id;
    })!;

    return {
      ...props.data.products.lowestImpactProducts[0],
      count: bestRef.count,
    };
  })();

  const worstProduct = (() => {
    const worstRef = props.data.productsRefs.find((p) => {
      return p.id === props.data!.products.highestImpactProducts[0].id;
    })!;

    return {
      ...props.data.products.highestImpactProducts[0],
      count: worstRef.count,
    };
  })();

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        <TimeframeDisplay start={timeframe.start} end={timeframe.end} />
      </div>

      <div className='flex flex-col gap-y-6 self-start max-w-[968px] p-6 pb-4 bg-white rounded-2xl border'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-x-1.5 text-base'>
            <div className='size-8 flex items-center justify-center bg-slate-100 rounded-lg '>
              <FontAwesomeIcon icon={light('box-circle-check')} />
            </div>
            <div className='font-semibold text-zinc-800'>Products</div>
          </div>
          <ModalV3
            title=''
            hideConfirm
            size='wide+'
            cancelLabel='Close'
            body={
              <div className='pt-3 text-dark rounded-2xl shadow-regular bg-white overflow-hidden -mt-4 border'>
                {timeframe && (
                  <div className='text-center pb-3 text-lg font-semibold'>{`Production volumes per product during reporting timeframe (${format(
                    new Date(timeframe.start),
                    'dd/MM/yy',
                  )} - ${format(new Date(timeframe.end), 'dd/MM/yy')})`}</div>
                )}
                <div className='grid grid-cols-6 rounded-b-2xl overflow-hidden divide-y'>
                  <div className='grid grid-cols-[6fr_3fr_2fr_3fr_3fr_1fr] px-6 border-t gap-x-10 py-3 col-span-6 text-xs text-zinc-500 uppercase'>
                    <div>product name</div>
                    <div>product id</div>
                    <div>product type</div>
                    <div>production volumes</div>
                    <div>impact points</div>
                    <div></div>
                  </div>
                  {props.data.productsRefs.map((product, i) => (
                    <div
                      key={i}
                      className={cn('grid grid-cols-[6fr_3fr_2fr_3fr_3fr_1fr] px-6 gap-x-10 py-3 col-span-6 items-center', {
                        'text-brandDark': product.parentId,
                      })}
                    >
                      <div className='flex justify-between gap-1 truncate'>
                        {product.parentId ? (
                          <>
                            <div className='flex items-center gap-2 truncate'>
                              <FontAwesomeIcon icon={light('code-compare')} />
                              <div>{product.name}</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='truncate'>{product.name}</div>
                          </>
                        )}
                        <div>
                          {product.amount.value}
                          {product.amount.unit.name}
                        </div>
                      </div>
                      <div title={product.parentId ? 'Model' : product.sku} className='truncate'>
                        {product.parentId ? 'Model' : product.sku}
                      </div>
                      <div className='truncate'>
                        <div title={product.productType} className='flex items-center gap-1 truncate'>
                          {
                            {
                              [ProductType.Final]: 'Final',
                              [ProductType.Intermediate]: 'Intermediate',
                              [ProductType.Internal]: 'Internal',
                            }[product.productType!]
                          }
                        </div>
                      </div>
                      <div>
                        <div className='grid grid-cols-2 items-center'>{simplify(product.count)}</div>
                      </div>
                      <div>
                        <div className='grid grid-cols-2 items-center'>{simplify(product.impactPoints)}</div>
                      </div>
                      <NavLink
                        to={
                          product.parentId
                            ? routes.products.modelOverview(product.parentId, product.id)
                            : routes.products.productOverview.production(product.id)
                        }
                        target='_blank'
                        type='button'
                        className='flex self-center justify-self-center active:scale-90 print:hidden'
                      >
                        <FontAwesomeIcon className='rotate-45 text-brand' icon={light('arrow-up')} />
                      </NavLink>
                    </div>
                  ))}
                  <div className='grid grid-cols-[6fr_3fr_2fr_3fr_3fr_1fr] px-6 gap-x-10 py-3 col-span-6 items-center bg-slate-50 text-brand text-sm'>
                    <div className='font-semibold'>Total</div>
                    <div />
                    <div className='flex items-center gap-1 text-sm'></div>
                    <div>{simplify(props.data.productsRefs.reduce((acc, { count }) => acc + count, 0))}</div>
                    <div>{simplify(props.data.productsRefs.reduce((acc, { impactPoints }) => acc + impactPoints, 0))}</div>
                    <div></div>
                  </div>
                </div>
              </div>
            }
          >
            <button>
              <FontAwesomeIcon className='size-4 text-zinc-400' icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
            </button>
          </ModalV3>
        </div>
        {bestProduct && worstProduct ? (
          <>
            <div className='flex flex-col gap-y-2'>
              <div title={bestProduct.name} className='flex gap-x-1 text-zinc-800 text-sm truncate pl-0.5'>
                <div className='truncate'>{bestProduct.name}</div>
                <div>{`- ${bestProduct.count} units`}</div>
              </div>
              <div className='flex self-start gap-x-2 pl-2 pr-3 py-1 bg-emerald-50 rounded-2xl text-base text-emerald-700'>
                <div>{simplify(bestProduct.impactPoints)}</div>
                <div className='text-[10px] uppercase'>IMPACT P.</div>
              </div>
            </div>
            <div className='flex flex-col gap-y-2'>
              <div title={worstProduct.name} className='flex gap-x-1 text-zinc-800 text-sm truncate pl-0.5'>
                <div className='truncate'>{worstProduct.name}</div>
                <div>{`- ${worstProduct.count} units`}</div>
              </div>
              <div className='flex self-start gap-x-2 pl-2 pr-3 py-1 bg-red-50 rounded-2xl text-base text-red-500'>
                <div>{simplify(worstProduct.impactPoints)}</div>
                <div className='text-[10px] uppercase'>IMPACT P.</div>
              </div>
            </div>
          </>
        ) : (
          <div className='text-zinc-500 rounded-2xl pl-2 pr-3 py-1 bg-gray-100 text-sm'>No products available</div>
        )}
      </div>
    </div>
  );
});
