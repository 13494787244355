import { Route, Routes } from 'react-router';
import { Overview } from './Overview';
import { CreateSupplier } from './Suppliers/CreateSupplier';
import { CreateFacility } from './Facilities/CreateFacility';
import { WorkspaceProvider } from '../../../hooks/useWorspace';

export const Manage = () => (
  <WorkspaceProvider>
    <Routes>
      <Route path='new-supplier' element={<CreateSupplier />} />
      <Route path='new-location' element={<CreateFacility />} />
      <Route path=':category' element={<Overview />} />
      <Route path=':category/:subcategory' element={<Overview />} />
      <Route path=':category/:subcategory/:id' element={<Overview />} />
      <Route path='*' element={<Overview />} />
    </Routes>
  </WorkspaceProvider>
);
