import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BaselinePefReport, ForecastReport, HistoricalPefReport, ReportType } from '../../../../api';
import { ByImpactContributionTable } from '../../../../components/ByImpactContributionTable';
import { ByProductContributionTable } from '../../../../components/ByProductContributionTable';
import { ByStageContributionTable, fromMatrixComponent, fromStageImpactsComponent } from '../../../../components/ByStageContributionTable';
import { ImpactSelect } from '../../../../components/ImpactSelect';
import { HorizontalShareBarChart } from '../../../../components/charts/HorizontalShareBarChart';
import { LifeCycleStagesLegend } from '../../../../components/charts/LifeCycleStagesLegend';
import { PieChart } from '../../../../components/charts/PieChart';
import { RadarChart } from '../../../../components/charts/RadarChart';
import {
  SunburstChart,
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
} from '../../../../components/charts/SunburstChart/SunburstChart';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../../components/charts/shared';
import { useCsvByImpactCategoryHref } from '../../../../hooks/useCsvByImpactCategoryHref';
import { useCsvByLifeCycleStageHref } from '../../../../hooks/useCsvByLifeCycleStageHref';
import { useCsvByProductHref } from '../../../../hooks/useCsvByProductHref';
import { CollapsibleSection } from '../../Products/Report/CollapsibleSection';
import { ImpactValueType } from '../../Products/Report/Sku/Overview';
import { simplify, roundToLong } from '../../shared';

interface Props {
  data: BaselinePefReport | HistoricalPefReport | ForecastReport;
  description: ReactNode;
  reportType: ReportType;
}

export const Lcia = (props: Props) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string }>(overallImpact);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const csvByProductHref = useCsvByProductHref({
    data: props.data,
  });
  const csvByImpactCategoryHref = useCsvByImpactCategoryHref({
    data: props.data.analysis,
  });
  const csvByLifeCycleStageHref = useCsvByLifeCycleStageHref({
    data: props.data.analysis,
    hideLevelsAbove3: true,
  });

  return (
    <div className='flex flex-col gap-8'>
      {props.description}
      <CollapsibleSection title='Total Environmental Impact'>
        <div className='flex flex-col gap-6'>
          <div>
            For all 16 impact categories, impacts in person years are calculated and added together to obtain the overall impact of each
            product, or the total environmental impact the production of each product has as compared to that of an average person over one
            year. This impact is multiplied by the number of units of each products produced over the timeframe and everything is added up
            to obtain the total environmental impact of your production. This is presented below.
          </div>
          <div>
            To help you more easily grasp the primary outputs of the analysis, the results in ‘weighted person.year’, we have created the
            Impact points, which are simply the results multiplied by 1 million to scale up the results and remove the exponents which can
            be hard to easily interpret. This full report shows both for completeness wherever possible.
          </div>
          <div className='items-center flex flex-col gap-4'>
            <div className='text-center text-dark font-semibold'>Total Environmental Impact</div>
            <div className='p-6 rounded-3xl bg-lightBg border border-lightBgBorder2 text-dark'>
              <div className='flex flex-col justify-center items-center gap-2'>
                <div className='flex items-end gap-2 font-semibold'>
                  <div title={roundToLong(props.data.overallImpact)} className='text-2xl text-brandDark leading-7'>
                    {simplify(props.data.overallImpact)}
                  </div>{' '}
                  weighted person.year
                </div>
                <div className='text-lg'>or</div>
                <div className='flex items-end gap-2 font-semibold'>
                  <div title={roundToLong(props.data.impactPoints)} className='text-2xl text-brandDark leading-7'>
                    {simplify(props.data.impactPoints)}
                  </div>{' '}
                  Impact points
                </div>
              </div>
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title='Total Environmental Impact Breakdown'>
        <div className='flex flex-col gap-6'>
          <div>
            As previously mentioned, the total environmental impact of your production is derived from the impact of each product in its
            produced quantities, which itself comes from the impact of each impact category based on the product’s life cycles and their
            underlying processes. This section provides a breakdown of this impact across all four levels.
          </div>
          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By product</div>
          <div>
            The first step in analysing the results of the life cycle assessment is to look at the impact by product, to identify the worst
            offenders, or those products contributing the most to your total impact on which you should focus first.
            {props.reportType === ReportType.Forecast ? (
              <div>
                This section provides visibility on how the individual products, or models, when produced at scale perform as compared to
                one another.
              </div>
            ) : (
              <div>
                This section provides visibility on how the individual products when produced at scale perform as compared to one another.
              </div>
            )}
          </div>
          <div>
            While PEF only covers product-level analysis, the concept of the relevance analysis was applied to this section as well, to help
            you identify those ‘most relevant’ products, or those contributing at least 80% of the total environmental impact of your
            overall impact.
          </div>
          <div>The breakdown by product is displayed in the form of:</div>
          <ul className='list-disc ml-3.5'>
            <li>
              A chart showing the percentage contribution to your total {props.reportType} impact of each product when produced at volume
              (using the production volumes specified in the previous section), from highest to lowest contribution.
            </li>

            <li>
              A table listing all products included in this assessment from highest to lowest contribution, along with their production
              volumes and final environmental impact (of all units produced). The products highlighted in yellow in the table are those
              contributing the most to your overall environmental impact.
            </li>
          </ul>
          <div>
            All results are shown in total environmental impact by default but the toggle below can be used to view results for a specific
            impact category, in impact points or physical units.
          </div>

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Product contribution to the overall {props.reportType} impact at scale
            </div>
            <div className='flex justify-center'>
              <div className='p-6 pr-12 flex w-full 2xl:w-2/3'>
                <HorizontalShareBarChart
                  data={props.data.analysis.highestImpactProducts.map((product) => {
                    const productReference = props.data.products.find((product2) => product2.id === product.id);
                    return {
                      ...product,
                      skuId: productReference?.sku,
                      count: productReference?.count,
                      parentId: productReference?.parentId,
                    };
                  })}
                  fixedHeight
                  withTooltip
                />
              </div>
            </div>
          </div>
          <ByProductContributionTable
            title={
              <div className='print:hidden flex py-3'>
                <div className='flex-1' />
                <div className='text-lg font-semibold'>Environmental impact and contribution by product</div>
                <div className='flex-1 flex justify-end'>
                  <a
                    className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-sm'
                    download={`${
                      props.reportType === ReportType.Baseline ? 'Baseline Annual' : 'Historical Overall'
                    } Volume Footprint - Impact by product.csv`}
                    href={csvByProductHref}
                  >
                    <FontAwesomeIcon icon={solid('download')} />
                    Export as CSV
                  </a>
                </div>
              </div>
            }
            overallImpact={props.data.overallImpact}
            impactPoints={props.data.impactPoints}
            products={props.data.products}
          />
          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By impact category</div>
          <div className='flex flex-col gap-3'>
            <div>
              Results presented in this section provide an overview of the performance of the selected product range across a number of
              environmental impact categories.
            </div>

            <div>
              The same relevance analysis carried out at the product level as recommended by PEF - a mechanism to understand the
              contribution each impact category has on your product’s total environmental impact - has been applied to this volume lens to
              help you identify the categories that contribute at least 80% of the total environmental impact of your selected product range
              to then further dive into.
            </div>

            <div>The breakdown by impact categories is displayed in the form of:</div>
            <ul className='list-disc ml-3.5'>
              <li>
                A <span className='text-brandDark font-semibold'>radar chart</span> (or ‘spider net graphic’) comparing the overall final
                environmental impact (in weighted person.year) calculated for the entire product range, for each impact category. The closer
                an impact data point is to the edge of the graphic, the larger its impact is.
              </li>

              <li>
                A <span className='text-brandDark font-semibold'>table</span> showing from highest to lowest impact contribution, the
                physical outputs (in their respective units), the final environmental impacts in impact points (after normalisation and
                weighting), and the contribution (%) of each impact category to the total impact for the entire product range. The impact
                categories highlighted in orange in the table are those contributing the most to the total environmental impact of the
                selected range (80% total minimum). Improving your product range impact at those categories level should yield the biggest
                overall improvements!
              </li>
            </ul>
          </div>
          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Final environmental impact per impact category
            </div>
            <div className='py-6 flex items-center justify-center'>
              <RadarChart
                height={400}
                width={650}
                meta={[{ dataKey: 'impactPoints', color: '#4f00ff' }]}
                payload={props.data.analysis.impactStageMatrix.map((impact) => ({
                  ...impact,
                  name: impact.impactName,
                  keyName: 'Impact',
                  isMajor: impact.isMajorImpact,
                }))}
                tooltipType='single'
              />
            </div>
          </div>
          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none overflow-hidden'>
            <div className='flex'>
              <div className='flex-1' />
              <div className='text-lg font-semibold text-dark text-center py-3 border-zinc-300'>
                Physical impact, final environmental impact and contribution per impact category
              </div>
              <div className='print:hidden flex-1 flex justify-end'>
                <a
                  className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-xs'
                  download={`${
                    props.reportType === ReportType.Baseline ? 'Baseline Annual' : 'Historical Overall'
                  } Volume Footprint - Impact by category.csv`}
                  href={csvByImpactCategoryHref}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </a>
              </div>
            </div>
            <ByImpactContributionTable
              data={props.data.analysis.impactStageMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
                contributionPercent: impact.absSharePercent,
              }))}
              totalImpact={props.data.overallImpact}
              totalPoints={props.data.impactPoints}
            />
          </div>
          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By life cycle stage</div>
          <div className='flex flex-col gap-3'>
            <div>
              Another important element when trying to understand your product range’s environmental impact is understanding which portion
              of your products’ life cycle is contributing the most overall. This is why a breakdown by life cycle stages is provided.
            </div>

            <div>
              Results are displayed in the form of a <span className='text-brandDark font-semibold'>pie chart</span> showing the breakdown
              of the final environmental impact by life cycle stage. Contributions by life cycle stages are also presented in the table
              below (under By Processes section). The life cycles highlighted in yellow in the diagram (and table) are those contributing
              the most to the total environmental impact of the product (80% total minimum) out of the life cycle stages assessed. These are
              the areas where you should focus your efforts!
            </div>

            <div>
              It is important to remember when analysing these results that intermediate products only include three life cycle stages (no
              use or end of life) out of the five considered for final products. Depending on the product types included in this assessment
              this may affect the results you see in this section.
            </div>
          </div>
          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none overflow-hidden'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-zinc-300'>Contribution per life cycle stage</div>

            <div className='flex flex-col gap-6 items-center justify-center pb-6'>
              <PieChart
                showTicks
                size={380}
                outerRadius={130}
                selectedType='impactPoints'
                data={props.data.analysis.lifecycleStageImpacts
                  .map((impact) => ({
                    ...impact,
                    bgColor: setColor({ key: impact.name }, lifeCycleStagesColorHexSchema),
                  }))
                  .map((impact) => ({
                    ...impact,
                    name: impact.name,
                    isMajor: impact.isMajorImpact,
                    value: impact.absImpactSharePercent,
                  }))}
              />
              <div className='px-6 text-sm'>
                <LifeCycleStagesLegend renderMajor />
              </div>
            </div>
          </div>
          <div className='rounded-md font-semibold text-brandDark py-1 bg-slate-200 text-lg text-center'>By processes</div>
          <div className='flex flex-col gap-3'>
            <div>
              At the heart of the impact calculation and of each life cycle stage are what we refer to as Processes. These are the
              underlying elements that together contribute to each product’s total impact. Results are displayed in the form of a sunburst
              chart showing the breakdown of the final environmental impact by life cycle stage, as well as the contribution of the various
              underlying elements and processes to your overall environmental impact (across all products in the selected range in their
              produced quantities).
            </div>

            <div>
              The life cycles and processes highlighted in yellow in the diagram are those contributing the most to your overall
              environmental impact (80% total minimum) out of the five total life cycle stages and all processes assessed. These are the
              areas where you should focus your efforts!
            </div>
          </div>

          <ImpactSelect
            theme='light'
            selectedImpact={selectedImpact}
            setSelectedImpact={setSelectedImpact}
            selectedImpactValueType={selectedImpactUnit}
            setSelectedImpactValueType={setSelectedImpactUnit}
            impacts={[
              overallImpact,
              ...props.data.analysis.impactStageMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
              })),
            ]}
          />

          <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none overflow-hidden h-[550px]'>
            <div className='text-lg font-semibold text-dark text-center py-3 border-b border-zinc-300'>
              Contribution per life cycle stage and underlying processes
            </div>

            <div className='flex flex-col items-center'>
              <div className='h-[400px] flex justify-center'>
                {selectedImpact.id === 'overall_impact' ? (
                  <SunburstChart size={350} data={convertFromLifecycleStageImpacts(props.data.analysis.lifecycleStageImpacts)} />
                ) : (
                  <SunburstChart
                    size={350}
                    data={convertFromImpactStagesMatrix(
                      props.data.analysis.impactStageMatrix.find(({ impactName }) => impactName === selectedImpact.name)!.stages,
                    )}
                    selectedCategory={props.data.analysis.impactStageMatrix.find(({ impactId }) => selectedImpact.id === impactId)}
                    showPhysicalImpact={selectedImpactUnit === ImpactValueType.Physical}
                    selectedCategoryPhysicalUnit={
                      props.data.analysis.impactStageMatrix.find(({ impactId }) => selectedImpact.id === impactId)!.unit
                    }
                  />
                )}
              </div>

              <LifeCycleStagesLegend renderMajor />
            </div>
          </div>

          <div className='flex flex-col gap-3'>
            <div>
              In addition to the charts above, a <span className='text-brandDark font-semibold'>table</span> with the underlying data is
              presented below. This table shows from highest to lowest life cycle stage contribution, the final environmental impacts in
              weighted person years (after normalisation and weighting), and the contribution (%) of each life cycle stage to the total
              impact. It also presents the underlying Processes within each life cycle stage to understand the root cause of the impact in
              more detail.
            </div>
            <div>
              Rows highlighted in yellow or displaying the exclamation icon in the table, for both life cycle stages and processes represent
              those contributing the most to the total environmental impact of the product (80% total minimum). These are the most relevant
              processes or the most influential ones. It should be noted that in order to highlight the relevance of any credits (e.g. from
              recycling), absolute values are taken when calculating the contribution. Hence, negative impact processes may show up as ‘Most
              relevant’ processes. When highlighted as most relevant, it shows how much influence these processes have on your overall
              impact.
            </div>
          </div>

          <div className='flex flex-col border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none overflow-hidden p-3'>
            <div className='flex pb-3'>
              <div className='flex-1' />
              <div className='text-lg font-semibold text-dark text-center border-zinc-300'>
                Final environmental impact and contribution per life cycle stage and underlying process
              </div>
              <div className='print:hidden flex-1 flex justify-end'>
                <a
                  className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-xs'
                  download={`${
                    props.reportType === ReportType.Baseline ? 'Baseline Annual' : 'Historical Overall'
                  } Volume Footprint - Impact by life cycle stage.csv`}
                  href={csvByLifeCycleStageHref}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </a>
              </div>
            </div>
            <div className='print:hidden'>
              <ByStageContributionTable
                unit={props.data.analysis.impactStageMatrix.find(({ impactId }) => impactId === selectedImpact.id)?.unit}
                showPhysicalValues={selectedImpactUnit === 'physical'}
                data={
                  selectedImpact.id === 'overall_impact'
                    ? props.data.analysis.lifecycleStageImpacts.map((stage) => ({
                        ...stage,
                        value: stage.impactValue,
                        absSharePercent: stage.absImpactSharePercent,
                        components: fromStageImpactsComponent(stage.components),
                      }))
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map((stage) => ({
                          ...stage,
                          absSharePercent: stage.absSharePercent,
                          value: selectedImpactUnit === 'physical' ? stage.physicalValue : stage.weightedNormalisedValue,
                          isMajorImpact: stage.isMajorStage,
                          components: fromMatrixComponent(stage.components, selectedImpactUnit === 'physical'),
                        }))
                }
                totalImpact={
                  selectedImpact.id === 'overall_impact'
                    ? props.data.overallImpact
                    : selectedImpactUnit === 'physical'
                    ? props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ physicalValue }) => physicalValue)
                        .reduce((a, b) => a + b, 0)
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ weightedNormalisedValue }) => weightedNormalisedValue)
                        .reduce((a, b) => a + b, 0)
                }
                totalPoints={
                  selectedImpact.id === 'overall_impact'
                    ? props.data.impactPoints
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ impactPoints }) => impactPoints)
                        .reduce((a, b) => a + b, 0)
                }
              />
            </div>

            <div className='hidden print:block'>
              <ByStageContributionTable
                data={props.data.analysis.lifecycleStageImpacts.map((impact) => ({
                  ...impact,
                  value: impact.impactValue,
                  absSharePercent: impact.absImpactSharePercent,
                  components: fromStageImpactsComponent(impact.components),
                }))}
                totalImpact={props.data.overallImpact}
                totalPoints={props.data.impactPoints}
              />
            </div>
          </div>

          {props.reportType === ReportType.Forecast ? (
            <div>
              In addition to the information displayed in this section of the report, a detailed analysis of the impact by impact category
              and by product has also been carried out. Results are presented in the{' '}
              <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
                Appendix
              </NavLink>
              . This is important if you wish to focus on certain impact categories that are relevant to different life cycle stages or
              certain products with the highest impact overall.
            </div>
          ) : (
            <div>
              In addition to the information displayed in this section of the report, which helps you understand where to focus your efforts
              to improve your volume footprint, a detailed analysis of the impact by impact category and by product has also been carried
              out. Results are presented in the{' '}
              <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
                Appendix
              </NavLink>
              . This is important if you wish to focus on the improvement of certain impact categories that are relevant to different life
              cycle stages or certain products with the highest impact overall.
            </div>
          )}
        </div>
      </CollapsibleSection>
    </div>
  );
};
