import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { AgreementType, StaticEntity, Supplier, SupplierService, supplierServices } from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { RadioInputs } from '../../../../components/RadioInputs';
import { SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { useLists } from '../../../../hooks/useLists';
import { useProfile } from '../../../../hooks/useProfile';

export const EditSupplierForm = () => {
  const formik = useFormikContext<Supplier>();
  const profile = useProfile();
  const lists = useLists();

  const isAgreementRequired = !formik.values.controlledByCustomer && formik.values.services.includes(SupplierService.Transport);

  return (
    <>
      <div className='grid grid-cols-[1fr_3fr] items-center gap-4 bg-white p-6 border-b border-zinc-200'>
        <div className='text-zinc-500'>Name</div>
        {formik.values.pinned ? (
          <div className='pl-3 py-[7px] text-zinc-400'>{formik.values.name}</div>
        ) : (
          <div className='flex flex-col w-1/2'>
            <Field name='name'>
              {(model: FieldProps<string>) => <InputV3 hideInputBorder model={model} placeholder='Type…' disabled={formik.values.pinned} />}
            </Field>
          </div>
        )}

        <div className='text-zinc-500'>ID</div>
        <div className='flex flex-col w-1/2'>
          <Field name='alias'>{(model: FieldProps<string>) => <InputV3 hideInputBorder model={model} placeholder='Type…' />}</Field>
        </div>

        <div className='text-zinc-500'>Controlled by {profile.user.company}</div>
        <Field name='controlledByCustomer'>
          {(model: FieldProps) => (
            <div className='relative flex items-center gap-4 mt-1.5 pl-4'>
              <input
                disabled={formik.values.pinned}
                {...model.field}
                onChange={(event) => {
                  model.field.onChange(event);
                  if (event.target.value) {
                    formik.setFieldValue('agreement', undefined);
                  }
                }}
                className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0 disabled:cursor-not-allowed'
                type='checkbox'
              />
              <div
                className={cn(
                  'border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6',
                  model.field.value ? (formik.values.pinned ? 'bg-brand/50' : 'bg-brand') : 'bg-lightBg',
                )}
              >
                <div
                  className={cn('absolute rounded-full h-2.5 aspect-square transition-[left] duration-25', {
                    'left-3 bg-white': model.field.value,
                    'left-px bg-brand': !model.field.value,
                  })}
                />
              </div>
              <div>{model.field.value ? 'Yes' : 'No'}</div>
            </div>
          )}
        </Field>

        {isAgreementRequired && (
          <div className='col-span-2 grid grid-cols-[1fr_3fr] gap-4'>
            <div className='flex items-center self-start gap-x-1  text-zinc-500'>
              <div>Agreement</div>
              <TooltipV3
                content={
                  <div className='bg-[#330099] rounded-lg shadow px-2 py-1 text-white text-xs'>
                    Specify if you have a formal contractual agreement directly with the facility for products transitioning through it
                    (‘Direct contract’ option) or if you’re not explicitly paying for the services in question (‘Indirect agreement’). This
                    will affect which scope 3 sub-category to attribute those emissions to.
                  </div>
                }
              >
                <div className='flex'>
                  <FontAwesomeIcon icon={light('info-circle')} />
                </div>
              </TooltipV3>
            </div>
            <div className='flex gap-x-2'>
              <div className='flex flex-col gap-y-2 w-1/2 pl-4'>
                <Field name='agreement'>
                  {(model: FieldProps<StaticEntity<AgreementType>>) => <RadioInputs model={model} options={lists.agreements} />}
                </Field>
              </div>
              {formik.values.agreement?.default ? (
                <div className='flex self-start text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                  default
                </div>
              ) : (
                <></>
              )}
            </div>

            {formik.values.agreement?.default && (
              <>
                <div />
                <div className='flex gap-x-3 p-2 bg-slate-200 border border-brand rounded-lg'>
                  <FontAwesomeIcon className='text-brand text-xl pt-1' icon={light('info-circle')} />
                  <div>
                    We introduced this new setting and filled it out automatically for you (default value). If you’re happy with the answer
                    you don’t need to do anything, otherwise you can change it. This setting automatically applies to all products the
                    facility is related to. You can also set a different answer for each product at the product level.
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        <div className='col-span-2 grid grid-cols-[1fr_3fr] gap-x-4'>
          <div className='text-zinc-500 mt-2'>Type of provider</div>
          <div className='flex flex-col'>
            <FieldArray
              name='services'
              render={(arrayModel) => (
                <Field name={arrayModel.name}>
                  {(model: FieldProps<any[]>) => (
                    <SelectV3
                      hideInputBorder
                      multi
                      model={model}
                      options={supplierServices}
                      onActions={{
                        add: (value) => model.form.setFieldValue(model.field.name, [...model.field.value, value.id]),
                        remove: (value) => {
                          if (value.id === SupplierService.Transport) {
                            formik.setFieldValue('agreement', undefined);
                          }
                          model.form.setFieldValue(
                            model.field.name,
                            model.field.value.filter((item) => item !== value.id),
                          );
                        },
                        clear: () => {},
                      }}
                      convertOptions={{
                        toModel: (values: { id: SupplierService; name: string }[]) => values.map(({ id }) => id),
                        fromModel: (values: SupplierService[]) =>
                          values ? values?.map((id) => ({ id, name: supplierServices.find((item) => item.id === id)?.name })) : [],
                      }}
                    />
                  )}
                </Field>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
