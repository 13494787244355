import { forwardRef, Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RadarChart } from '../../../../../../components/charts/RadarChart';
import { PieChart } from '../../../../../../components/charts/PieChart';
import { TimeframeDisplay } from '../../../components/TimeframeDisplay';
import { Methodology } from '../../../../Prototype/types';
import MultiRef from 'react-multi-ref';
import cn from 'classnames';
import { TooltipV3 } from '../../../../../../components/TooltipV3';
import { simplify, roundToShort } from '../../../../shared';
import { BaselinePefReport, HistoricalPefReport } from '../../../../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  title: string;
  methodology: Methodology;
  radarData?: { keyName?: string }[];
  baseline?: BaselinePefReport;
  historical?: HistoricalPefReport;
  pieData?: {
    value: number;
    name: string;
    isMajor: boolean;
    bgColor: string;
    impactPoints: number;
  }[];
  timeframe?: {
    start: Date;
    end: Date;
  };
  selectedImpact: { id: string; name: string };
  selectedType?: 'physical' | 'impactPoints';
  impactUnit?: string;
}

export const ImpactDetails = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [sharesRefs] = useState(() => new MultiRef<number, HTMLDivElement>());
  const [namesRefs] = useState(() => new MultiRef<number, HTMLDivElement>());
  const [render, setRender] = useReducer((s) => s + 1, 0);
  const productRankingRef = useRef(null);
  const data = props.baseline ?? props.historical;
  const [products, setProducts] = useState<
    {
      id?: string;
      name: string;
      points: number;
      physical?: number;
      contribution: number;
      count: number;
      isMajor?: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (productRankingRef.current) {
      setRender();
    }
  }, [productRankingRef, props.selectedImpact]);

  useEffect(() => {
    if (data) {
      if (props.selectedImpact.id === 'overall') {
        const totalKnownCount = data.analysis
          .highestImpactProducts!.flatMap((product) => data.products.filter(({ id }) => id === product.id))
          .reduce((acc, product) => acc + product!.count, 0);

        const productsData = data.analysis.highestImpactProducts.map((product) => {
          const productRef = data.products.find((p) => p.id === product.id);
          return {
            id: product.id,
            name: product.name,
            points: product.impactPoints,
            contribution: product.value,
            count: productRef?.count ?? totalKnownCount,
            isMajor: props.selectedImpact.id === 'overall' && productRef?.isMajor,
          };
        });

        setProducts(productsData);
      } else {
        const selectedImpactProducts = data.analysis.impactsByProduct.find(
          (impact) => impact.impactId === props.selectedImpact.id,
        )?.products;

        const totalContribution = selectedImpactProducts!.reduce((acc, product) => acc + product.impactPoints, 0);

        const totalKnownCount = selectedImpactProducts!
          .flatMap((product) => data.products.filter(({ id }) => id === product.id))
          .reduce((acc, product) => acc + product!.count, 0);

        const othersCount = data.totalUnitCount - totalKnownCount;

        const productsData = selectedImpactProducts?.map((product) => {
          const productRef = data.products.find((p) => p.id === product.id);
          return {
            id: product.id,
            name: product.name,
            points: product.impactPoints,
            physical: product.value,
            contribution: (product.impactPoints / totalContribution) * 100,
            count: productRef?.count ?? othersCount,
            isMajor: props.selectedImpact.id === 'overall' && productRef?.isMajor,
          };
        })!;
        setProducts(productsData);
      }
    }
  }, [props.selectedImpact, data]);

  if (!props.radarData || !props.pieData) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
        <div className='grid grid-cols-[5fr_3fr_2fr] gap-4'>
          <div
            className='loading-skeleton'
            style={{
              height: '348px',
              width: '100%',
              borderRadius: '0.5rem',
            }}
          />
          <div
            className='loading-skeleton'
            style={{
              height: '348px',
              width: '100%',
              borderRadius: '0.5rem',
            }}
          />
          <div
            className='loading-skeleton'
            style={{
              height: '348px',
              width: '100%',
              borderRadius: '0.5rem',
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        {props.timeframe && <TimeframeDisplay start={props.timeframe.start} end={props.timeframe.end} />}
      </div>
      <div className={cn('grid gap-2', props.selectedImpact.id === 'overall' ? 'grid-cols-[5fr_3fr_4fr]' : 'grid-cols-[4fr_5fr_3fr]')}>
        {props.selectedImpact.id === 'overall' && (
          <div className='flex flex-col bg-white border border-zinc-200 rounded-lg'>
            <div className='font-semibold text-base text-zinc-800 p-4 pb-0'>By impact category</div>
            <div className='flex h-full self-center justify-center items-center'>
              <RadarChart
                height={300}
                width={450}
                outerRadius={90}
                meta={[{ dataKey: props.selectedType === 'impactPoints' ? 'impactPoints' : 'physicalValue', color: '#4f00ff' }]}
                payload={props.radarData}
                selectedType={props.selectedType}
                tooltipType='single'
              />
            </div>
          </div>
        )}
        <div className='flex flex-col p-4 bg-white border border-zinc-200 rounded-lg'>
          <div className='font-semibold text-base text-zinc-800'>By life cycle stage</div>
          <div className='flex-1 flex justify-center items-center -mx-6'>
            <PieChart
              unit={props.impactUnit}
              selectedType={props.selectedType}
              showTicks
              size={250}
              outerRadius={65}
              data={props.pieData}
            />
          </div>
        </div>
        <div className='flex flex-col gap-y-3 p-4 bg-white border border-zinc-200 rounded-lg'>
          <div className='font-semibold text-base text-zinc-800'>Product ranking</div>
          {products.length > 0 && (
            <div className='h-full'>
              <div className='relative grid grid-cols-[50px_3fr] gap-x-8 h-full'>
                <div className='overflow-hidden'>
                  {products.map((product, index, arr) => {
                    return (
                      <TooltipV3
                        ref={sharesRefs.ref(index)}
                        key={index}
                        placement='right'
                        content={
                          <div className='flex flex-col gap-y-2 bg-white rounded-lg p-3 border shadow text-xs text-zinc-600 min-w-60'>
                            <div className='flex justify-between items-center gap-x-6'>
                              <div className='font-semibold text-zinc-600'>{product.name}</div>
                              {product.isMajor && <FontAwesomeIcon className='size-4 text-amber-400' icon={solid('seal-exclamation')} />}
                            </div>
                            <div className='grid grid-cols-2 gap-x-6 items-center text-zinc-500'>
                              {[
                                {
                                  title: 'Volume',
                                  value: simplify(product.count),
                                  unit: 'units',
                                },
                                ...(() =>
                                  props.selectedType === 'physical'
                                    ? [
                                        {
                                          title: 'Physical',
                                          value: simplify(product.physical),
                                          unit: props.impactUnit,
                                        },
                                      ]
                                    : [])(),
                                ...(() =>
                                  props.selectedType === 'impactPoints'
                                    ? [
                                        {
                                          title: 'Impact',
                                          value: simplify(product.points),
                                          unit: 'impact p.',
                                        },
                                      ]
                                    : [])(),
                                {
                                  title: 'Contribution',
                                  value: roundToShort(product.contribution),
                                  unit: '%',
                                },
                              ].map(({ title, value, unit }, index) => (
                                <Fragment key={index}>
                                  <div>{title}</div>
                                  <div className='flex items-center gap-x-1'>
                                    <div className='text-zinc-900 text-sm'>{value}</div>
                                    <div className='uppercase text-[10px] text-zinc-600 whitespace-nowrap'>{unit}</div>
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            height: product.contribution + '%',
                          }}
                          className={cn(
                            'relative text-xs flex items-center justify-center',
                            index === 0 && 'rounded-t-[3px]',
                            arr.length - 1 === index && 'rounded-b-[3px]',
                            product.isMajor && 'border-amber-400 border',
                            [
                              'bg-indigo-50',
                              'bg-indigo-200',
                              'bg-green-50',
                              'bg-sky-200',
                              'bg-green-200',
                              'bg-yellow-50',
                              'bg-yellow-200',
                              'bg-sky-50',
                              'bg-purple-200',
                              'bg-zinc-100',
                              'bg-pink-50',
                            ][index],
                          )}
                        >
                          {product.contribution >= 10 && (
                            <div className='flex items-center gap-x-0.5 text-base text-zinc-900'>
                              <div className='text-sm font-semibold'>{roundToShort(product.contribution)}</div>
                              <div className='text-xs'>%</div>
                            </div>
                          )}
                        </div>
                      </TooltipV3>
                    );
                  })}
                </div>
                <div ref={productRankingRef} className='flex flex-col truncate'>
                  {products.map((product, index) => (
                    <div
                      key={index}
                      ref={namesRefs.ref(index)}
                      className='relative'
                      style={{
                        height: product.contribution + '%',
                        minHeight: '20px',
                      }}
                    >
                      <div className='flex h-full items-center'>
                        {product.id ? (
                          <NavLink
                            to={`/products/${product.id}/report/production/overview`}
                            target='_blank'
                            title={product.name}
                            className='text-xs hover:underline truncate'
                          >
                            {product.name}
                          </NavLink>
                        ) : (
                          <div title={product.name} className='text-xs truncate'>
                            {product.name}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <Lines render={render} sharesRefs={sharesRefs} namesRefs={namesRefs} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

interface LinesProps {
  sharesRefs: MultiRef<number, HTMLDivElement>;
  namesRefs: MultiRef<number, HTMLDivElement>;
  render: number;
}

const Lines = (props: LinesProps) => {
  const [shares, setShares] = useState<[number, HTMLDivElement][]>([]);

  useEffect(() => {
    setShares(Array.from(props.sharesRefs.map.entries()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.render]);

  if (!shares.length) return null;

  return (
    <Fragment>
      {shares.map(([index, ref]) => {
        const nameRef = props.namesRefs.map.get(index);

        const startX = ref.offsetLeft + ref.offsetWidth + 8;
        const startY = ref.offsetTop + ref.offsetHeight / 2;
        const endX = ref.offsetLeft + ref.offsetWidth + 18;
        const endY = nameRef!.offsetTop + nameRef!.offsetHeight / 2;

        return (
          <Fragment key={index}>
            <svg
              className='absolute'
              height={Math.abs(startY - endY) > 0 ? Math.abs(startY - endY) : 1}
              width={Math.abs(startX - endX) + 20}
              style={{
                top: Math.min(startY, endY),
                left: Math.min(startX, endX) - 10,
              }}
              key={index}
            >
              <line
                x1={0}
                y1={startY < endY ? 0 : Math.abs(startY - endY)}
                x2={10}
                y2={startY < endY ? 0 : Math.abs(startY - endY)}
                stroke='#d4d4d8'
                strokeWidth='1'
              />
              <line
                x1={10}
                y1={startY < endY ? 0 : Math.abs(startY - endY)}
                x2={(startX < endX ? Math.abs(startX - endX) : 0) + 10}
                y2={startY < endY ? Math.abs(startY - endY) : 0}
                stroke='#d4d4d8'
                strokeWidth='1'
              />
              <line
                x1={startX < endX ? Math.abs(startX - endX) + 10 : 0}
                y1={startY < endY ? Math.abs(startY - endY) : 0}
                x2={startX < endX ? Math.abs(startX - endX) + 20 : 0}
                y2={startY < endY ? Math.abs(startY - endY) : 0}
                stroke='#d4d4d8'
                strokeWidth='1'
              />
            </svg>
          </Fragment>
        );
      })}
    </Fragment>
  );
};
