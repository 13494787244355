import cn from 'classnames';
import { FieldProps } from 'formik';
import { ForwardedRef, forwardRef } from 'react';
import { useControlEvents } from '../hooks/useControlEvents';

interface Props {
  type?: string;
  value?: string;
  model?: FieldProps;
  number?: boolean;
  integer?: boolean;
  positive?: boolean;
  password?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  hideInputBorder?: boolean;
}

export const InputV3 = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const controlEvents = useControlEvents();
  const step = props.integer ? 1 : 0.01;
  const error = props.error || props.model?.meta.error;

  return (
    <input
      ref={ref}
      type={props.type ? props.type : props.number || props.integer ? 'number' : props.password ? 'password' : 'text'}
      min={props.positive ? step : 0}
      step={step}
      autoComplete='off'
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      placeholder={props.placeholder ?? (props.number || props.integer ? (props.integer ? '0' : '0.0') : undefined)}
      className={
        props.hideInputBorder
          ? cn(
              'px-3 py-1.5 border [&:hover:not(:focus)]:bg-gray-100 focus:shadow-md rounded-md',
              (() => {
                if (error) {
                  return 'border-[#FA4D0A] focus:ring-focusRingError focus:ring-4';
                }

                return 'focus:border-zinc-200 border-transparent focus:ring-0';
              })(),
            )
          : cn(
              'rounded-lg border px-3 py-1.5 placeholder:text-zinc-400',
              (() => {
                if (error) {
                  return 'border-[#FA4D0A] focus:ring-focusRingError';
                }

                if (props.disabled) {
                  return 'text-neutral-400 border-neutral-400 bg-neutral-100';
                }

                return 'border-zinc-500';
              })(),
            )
      }
      {...(props.model?.field ?? {})}
      value={props.model?.field.value ?? props.value ?? ''}
      onChange={(event) => {
        if (controlEvents) {
          controlEvents.touched(props.model);
        }

        if (event.target.value === '') {
          props.model!.form.setFieldValue(props.model!.field.name, null);
        } else {
          props.model!.field.onChange(event);
        }
      }}
      onMouseDown={() => {
        if (controlEvents) {
          controlEvents.touched(props.model);
        }
      }}
      onWheel={(event) => {
        // prevent number input change on scroll over the input
        (event.target as HTMLElement).blur();
      }}
    />
  );
});
