import { useEffect, useReducer, useRef, useState } from 'react';
import { ApiRequest } from '../api';

export const useApiQuery = <T>(request: ApiRequest<T>, options?: { cancel: boolean }) => {
  const requestRef = useRef(request);
  const [reloadCounter, reload] = useReducer((x: number) => x + 1, 1);
  const [data, setData] = useState<T>();
  const [waiting, setWaiting] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!options?.cancel) {
      setWaiting(true);
      setError(false);

      requestRef.current.call({
        ok: (data) => {
          setData(data);
          setWaiting(false);
        },
        fail: () => {
          setError(true);
          setWaiting(false);
        },
      });
    }

    return () => {};
  }, [reloadCounter, options?.cancel]);

  return {
    data,
    update: setData,
    waiting,
    error,
    reload,
  };
};
