import { useEffect, useRef, useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import { AddressResolver } from '../../../../components/AddressResolver';
import { InputV3 } from '../../../../components/InputV3';
import { RadioInputs } from '../../../../components/RadioInputs';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { NewSupplierForm } from '../Suppliers/NewSupplierForm';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AgreementType,
  Facility,
  FacilityType,
  OwnershipStatus,
  OwnershipStatusType,
  StaticEntity,
  Supplier,
  facilityOptions,
  getSuppliers,
} from '../../../../api';
import { useLists } from '../../../../hooks/useLists';
import cn from 'classnames';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

export const EditFacilityForm = () => {
  const lists = useLists();
  const formik = useFormikContext<Facility>();
  const [newSupplierName, setNewSupplierName] = useState('');
  const formRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<ModalApi>(null);
  const submitButtonRef = useRef<{ submit: () => void }>(null);
  const { routes } = useAppRoutes();

  useEffect(() => {
    if (!formik.values.ownershipStatus) {
      if (formik.values.owner?.controlledByCustomer) {
        formik.setFieldValue('ownershipStatus', lists.facilityOwnerStatuses.find(({ type }) => type === OwnershipStatusType.Owned)!);
      } else {
        formik.setFieldValue('ownershipStatus', undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.owner]);

  return (
    <>
      <div className='grid grid-cols-[1fr_3fr] items-center gap-4 bg-white p-6 border-b border-zinc-200'>
        <div className='text-zinc-500'>Name</div>
        <div className='flex flex-col w-1/2'>
          <Field name='name'>
            {(model: FieldProps<string>) => <InputV3 hideInputBorder model={model} placeholder='Name the location…' />}
          </Field>
        </div>

        <div className='text-zinc-500'>ID</div>
        <div className='flex flex-col w-1/2'>
          <Field name='alias'>
            {(model: FieldProps<string>) => <InputV3 hideInputBorder model={model} placeholder='Your internal ID…' />}
          </Field>
        </div>

        {/* type of location is read-only */}
        <div className='text-zinc-500'>Type of location</div>
        <Field name='type'>
          {(model: FieldProps<FacilityType>) => (
            <div className='w-1/2 pl-3 text-zinc-500'>
              <TooltipV3
                placement='bottom-start'
                content={
                  <div className='p-3 text-sm bg-[#330099] text-white rounded-2xl w-2/3'>
                    Changing facility type is disabled to prevent breaking the products currently using it
                  </div>
                }
              >
                <div>{facilityOptions.find((option) => option.id === model.field.value)!.name}</div>
              </TooltipV3>
            </div>
          )}
        </Field>

        {formik.values.type && formik.values.type !== FacilityType.Consumption && (
          <>
            <div className='text-zinc-500'>Owned by</div>
            <Field name='owner'>
              {(model: FieldProps<{ id: string; name: string }>) => (
                <div className='w-1/2'>
                  <SelectV3<Supplier>
                    hideInputBorder
                    model={model}
                    loadOptions={(input, callback) => {
                      setNewSupplierName(input);
                      getSuppliers({
                        contains: input,
                      }).ok(({ suppliers }) => callback(suppliers));
                    }}
                    onActions={{
                      add: (value) => {
                        if (value.controlledByCustomer) {
                          formik.setFieldValue(
                            'ownershipStatus',
                            lists.facilityOwnerStatuses.find(({ type }) => type === OwnershipStatusType.Owned)!,
                          );
                        } else {
                          formik.setFieldValue('ownershipStatus', undefined);
                        }
                        model.form.setFieldValue('owner', value);
                      },
                      remove: () => {
                        formik.setFieldValue('ownershipStatus', undefined);
                        model.form.setFieldValue('owner', undefined);
                      },
                      clear: () => {
                        formik.setFieldValue('ownershipStatus', undefined);
                        model.form.setFieldValue('owner', undefined);
                      },
                    }}
                    inputValueButton={() => (
                      <Link
                        to={routes.manage.suppliers.details('all', model.field.value.id)}
                        target='_blank'
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        className='ml-1.5'
                      >
                        <FontAwesomeIcon className='rotate-45 text-brand' icon={regular('arrow-up')} />
                      </Link>
                    )}
                    menuFooter={
                      <SelectFooterAddButton onClick={() => modalRef.current!.open()} name={newSupplierName} label='new provider' />
                    }
                  />
                </div>
              )}
            </Field>
          </>
        )}

        <div className='text-zinc-500'>Country</div>
        <Field name='location'>
          {(model: FieldProps<{ text: string }>) => (
            <div className='w-1/2'>
              <SelectV3
                hideInputBorder
                model={model}
                options={lists.countries}
                onActions={{
                  add: (value) => {
                    model.form.setFieldValue('address', undefined);
                    model.form.setFieldValue('location', value);
                  },
                  remove: () => {
                    model.form.setFieldValue('address', undefined);
                    model.form.setFieldValue('location', undefined);
                  },
                  clear: () => {
                    model.form.setFieldValue('address', undefined);
                    model.form.setFieldValue('location', undefined);
                  },
                }}
              />
            </div>
          )}
        </Field>

        {formik.values.location && (
          <>
            <div className='text-zinc-500'>Address</div>
            <Field name='address'>
              {(model: FieldProps<{ text: string }>) => (
                <div title={model.field.value?.text ?? ''}>
                  <AddressResolver hideInputBorder model={model} countryId={formik.values.location?.id ?? ''} />
                </div>
              )}
            </Field>
          </>
        )}

        {formik.values.type && formik.values.type !== FacilityType.Consumption && (
          <Field name='ownershipStatus'>
            {(model: FieldProps<OwnershipStatus>) => (
              <>
                <div className='text-zinc-500'>Facility status</div>
                <div className='flex items-center gap-2'>
                  <div className={cn('flex flex-col w-1/2', { 'text-zinc-500': formik.values.owner?.controlledByCustomer })}>
                    <SelectV3
                      hideInputBorder
                      model={model}
                      options={
                        formik.values.owner?.controlledByCustomer
                          ? lists.facilityOwnerStatuses
                          : lists.facilityOwnerStatuses.filter(({ type }) => type !== OwnershipStatusType.Owned)
                      }
                      disabled={formik.values.owner?.controlledByCustomer}
                      onFocus={() => {
                        model.form.setFieldValue('ownershipStatus', {
                          ...model.field.value,
                          default: false,
                        });
                      }}
                      onActions={{
                        add: (value) => {
                          if (!isEqual(value.type, model.field.value?.type)) {
                            model.form.setFieldValue('ownershipStatus', {
                              ...value,
                              default: false,
                              agreement: undefined,
                            });
                          }
                        },
                        remove: () => {
                          model.form.setFieldValue('ownershipStatus', undefined);
                        },
                        clear: () => {
                          model.form.setFieldValue('ownershipStatus', undefined);
                        },
                      }}
                    />
                  </div>
                  {formik.values.ownershipStatus?.default ? (
                    <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                      default
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </Field>
        )}

        {formik.values.ownershipStatus?.type === OwnershipStatusType.ThirdParty && formik.values.type !== FacilityType.Production && (
          <div className='col-span-2 grid grid-cols-[1fr_3fr] gap-4'>
            <div className='flex items-center self-start gap-x-1 text-zinc-500'>
              <div>Agreement</div>
              <TooltipV3
                content={
                  <div className='bg-violet-900 rounded-lg shadow px-2 py-1 text-white text-xs'>
                    Specify if you have a formal contractual agreement directly with the facility for products transitioning through it
                    (‘Direct contract’ option) or if you’re not explicitly paying for the services in question (‘Indirect agreement’). This
                    will affect which scope 3 sub-category to attribute those emissions to.
                  </div>
                }
              >
                <div className='flex'>
                  <FontAwesomeIcon icon={light('info-circle')} />
                </div>
              </TooltipV3>
            </div>
            <div className='flex gap-x-2'>
              <Field name='ownershipStatus'>
                {(parentModel: FieldProps<OwnershipStatus>) => (
                  <Field name='ownershipStatus.agreement'>
                    {(model: FieldProps<StaticEntity<AgreementType>>) => (
                      <div className='flex flex-col gap-y-2 pl-4'>
                        <RadioInputs
                          model={model}
                          options={lists.agreements}
                          onChange={(value) => {
                            if (!isEqual(value.type, parentModel.field.value?.agreement?.type)) {
                              formik.setFieldValue('ownershipStatus', {
                                ...parentModel.field.value,
                                agreement: value,
                                default: false,
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                  </Field>
                )}
              </Field>
              {formik.values.ownershipStatus?.default ? (
                <div className='flex self-start text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                  default
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        {formik.values.ownershipStatus?.default && (
          <>
            <div />
            <div className='flex gap-x-3 p-2 bg-slate-200 border border-brand rounded-lg'>
              <FontAwesomeIcon className='text-brand text-xl pt-1' icon={light('info-circle')} />
              <div>
                We introduced this new setting and filled it out automatically for you (default value). If you’re happy with the answer you
                don’t need to do anything, otherwise you can change it. This setting automatically applies to all products the facility is
                related to. You can also set a different answer for each product at the product level.
              </div>
            </div>
          </>
        )}
      </div>
      <ModalV3
        size='wide'
        ref={modalRef}
        onConfirm={() => submitButtonRef.current!.submit()}
        onCancel={() => {
          setNewSupplierName('');
          modalRef.current!.close();
        }}
        noCloseOnConfirm
        title='New Provider'
        body={
          <div ref={formRef} className='flex flex-col gap-4 col-span-2 bg-[#F5F7FA] p-3 rounded-xl shadow-regular -mt-4'>
            <NewSupplierForm
              ref={submitButtonRef}
              formRef={formRef}
              name={newSupplierName}
              requiredServices={[]}
              onCancel={() => {}}
              onCreated={(newSupplier) => {
                formik.setFieldValue('owner', newSupplier);
                modalRef.current!.close();
              }}
            />
          </div>
        }
      />
    </>
  );
};
