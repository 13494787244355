import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { useFormikContext } from 'formik';
import orderBy from 'lodash/orderBy';
import { Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  ImpactDeltaType,
  ManufacturingProduct,
  ProductGeneral,
  ProductModelListItem,
  ProductState,
  ReportType,
  SearchProductsParams,
  getProductModelsV3,
  searchProducts,
} from '../../../../../api';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import { LimitTooltip } from '../../../../../components/LimitTooltip';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { useEffectOnNextRenders } from '../../../../../hooks/useEffectOnNextRenders';
import { createProduct } from '../../../Products/Details/dataModel';
import { simplify } from '../../../shared';
import { useProfile } from '../../../../../hooks/useProfile';
import { ReadOnlyWarning } from '../../../../../components/ReadOnlyWarning';
import { useDynamicHeight } from '../../../../../hooks/useDynamicHeight';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';

export interface ListItem extends ProductGeneral {
  selected: boolean;
  disabled: boolean;
  models: {
    id: string;
    skuId: string;
    name: string;
    author: string;
    parentId: string;
    count: number;
  }[];
}

interface Props {
  reportType: ReportType;
  onNext: () => void;
}

const defaultSearchParams: SearchProductsParams = {
  pageSize: 50,
  sortBy: 'updatedAt',
  sortAscending: false,
  contains: '',
  pageToken: '',
  state: ProductState.Complete,
};

export const SelectProducts = (props: Props) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const profile = useProfile();
  const [loading, setLoading] = useState(true);

  const [createLimit, setCreateLimit] = useState(false);

  const [searchString, setSearchString] = useState('');
  const debouncedSearchInputValue = useDebounce(searchString, 300);

  const [searchParams, setSearchParams] = useState({
    ...defaultSearchParams,
    actualEligible: (props.reportType === 'historical' || props.reportType === 'baseline') ?? undefined,
    forecastEligible: props.reportType === 'forecast' ?? undefined,
  });
  const [nextPageToken, setNextPageToken] = useState<string>('');

  const [list, setList] = useState<ListItem[]>([]);
  const firstRender = useRef(true);

  const formik = useFormikContext<{ products: ManufacturingProduct[] }>();

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const height = useDynamicHeight(containerRef, undefined, true);

  useEffectOnNextRenders(() => {
    setLoading(true);
    firstRender.current = false;
    setSearchParams((oldParams) => ({ ...oldParams, contains: debouncedSearchInputValue }));
  }, [debouncedSearchInputValue]);

  useEffect(() => {
    setLoading(true);
    searchProducts(searchParams).ok(({ products, nextPageToken }) => {
      setNextPageToken(nextPageToken);
      setList((currentValues) => {
        const selectedItems = currentValues.filter((item) => item.selected || item.models.find(({ parentId }) => parentId === item.id));
        return [
          ...selectedItems,
          ...products
            .filter(({ totalImpact }) => totalImpact !== 0)
            .filter(({ id, totalImpact }) => totalImpact !== 0 && !selectedItems.find(({ id: id2 }) => id === id2))
            .map((product) => ({
              ...product,
              id: product.id,
              sku: product.skuId,
              count: 0,
              selected: false,
              models: [],
              disabled:
                formik.values.products.find(({ id }) => id === product.id) !== undefined ||
                selectedItems.find((value) => value.id === product.id) !== undefined,
            })),
        ];
      });
      setLoading(false);
      firstRender.current = false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const empty = list.length === 0;

  const fetchNextPage = () =>
    searchProducts({ ...searchParams, pageToken: nextPageToken }).ok(({ products, nextPageToken }) => {
      setNextPageToken(nextPageToken);
      setList((current) => [
        ...current,
        ...products
          .filter(({ totalImpact }) => totalImpact !== 0)
          .map((product) => ({
            ...product,
            selected: false,
            models: [],
            disabled: formik.values.products.find(({ id }) => id === product.id) !== undefined,
          })),
      ]);
    });

  const onSortClick = (field: SearchProductsParams['sortBy']) => {
    setSearchParams((oldParams) =>
      field === searchParams.sortBy
        ? { ...oldParams, sortAscending: !oldParams.sortAscending }
        : { ...oldParams, sortBy: field, sortAscending: true },
    );
  };

  const sortIcon = (field: SearchProductsParams['sortBy']) => (
    <FontAwesomeIcon
      className={cn('text-brand', {
        invisible: field !== searchParams.sortBy,
      })}
      icon={searchParams.sortAscending ? regular('arrow-up') : regular('arrow-down')}
    />
  );

  const selectedCount = list.filter((it) => it.selected).length + list.filter((it) => it.models.length > 0).length;

  return (
    <div className='flex flex-col'>
      {empty && firstRender.current && !loading ? (
        <div className='flex flex-col justify-center items-end text-brand'>
          <div>
            <LimitTooltip
              enabled={createLimit}
              entityName='products limit'
              valueKey='maxProductSkuCount'
              onDismiss={() => setCreateLimit(false)}
            >
              <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                <button
                  type='button'
                  disabled={loading || !profile.selectedWorkspace.permissions.productManagement}
                  className={cn(
                    'flex items-center gap-2 border-2 bg-brand rounded-full px-6 py-2 text-white font-semibold',
                    '[&:active:not(:disabled)]:scale-95',
                    createLimit ? 'border-f' : 'border-brand',
                    loading ? 'disabled:cursor-wait' : 'disabled:bg-neutral-300 disabled:border-transparent disabled:cursor-not-allowed',
                  )}
                  onClick={() => {
                    setLoading(true);
                    createProduct().call({
                      ok: ({ id, errorCode }) => {
                        setLoading(false);

                        if (errorCode) {
                          setCreateLimit(true);
                          setLoading(false);
                        } else {
                          setLoading(false);
                          navigate(routes.products.productGraph(id));
                        }
                      },
                      fail: () => {
                        setLoading(false);
                      },
                    });
                  }}
                >
                  New Product
                </button>
              </ReadOnlyWarning>
            </LimitTooltip>
          </div>
          <div className='flex mt-6 text-xl'>
            <div>
              It's time to create your first <span className='font-semibold'>Product</span>!
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col gap-y-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center relative'>
              <input
                type='search'
                className='rounded-full border [&:not(:disabled)]:border-neutral-500 h-full pl-12 pr-6 py-2'
                autoFocus
                placeholder='Find Product…'
                onChange={(event) => setSearchString(event.target.value)}
              />
              <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute left-6 text-light' />
            </div>
          </div>
          <div ref={setContainerRef}>
            {height && (
              <InfiniteScroll height={height} dataLength={list.length} next={fetchNextPage} hasMore={nextPageToken !== ''} loader={<></>}>
                <div className='w-full sticky top-0 bg-white py-1 gap-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center border-y text-[90%] font-semibold px-3'>
                  <div className='grid grid-cols-[50px_auto] items-center'>
                    <input
                      type='checkbox'
                      className='w-5 aspect-square'
                      disabled={list.length === 0}
                      checked={
                        list.length > 0 &&
                        list.filter(({ disabled }) => !disabled).every((product) => product.selected || product.models.length > 0)
                      }
                      onChange={(event) => {
                        setList((current) =>
                          current.map((product) => ({
                            ...product,
                            models: [],
                            selected: !product.disabled ? event.target.checked : false,
                          })),
                        );
                      }}
                    />
                    <button type='button' onClick={() => onSortClick('name')} className='flex items-center gap-2'>
                      Name
                      {sortIcon('name')}
                    </button>
                  </div>
                  <div>
                    <button className='flex justify-center items-center gap-2' onClick={() => onSortClick('updatedAt')} type='button'>
                      Last Updated
                      {sortIcon('updatedAt')}
                    </button>
                  </div>
                  <div>
                    <button className='flex gap-2 items-center' onClick={() => onSortClick('amount')} type='button'>
                      Net amount
                      {sortIcon('amount')}
                    </button>
                  </div>
                  <div>ID</div>
                  <div>
                    <button
                      className='flex gap-2 items-center text-center'
                      onClick={() => onSortClick('firstPartyDataPercentage')}
                      type='button'
                    >
                      First-party data
                      {sortIcon('firstPartyDataPercentage')}
                    </button>
                  </div>
                  <div className='text-center'>
                    <button className='flex gap-2 items-center text-center' onClick={() => onSortClick('totalImpact')} type='button'>
                      Total Impact (per net amount)
                      {sortIcon('totalImpact')}
                    </button>
                  </div>
                  <div className='flex items-center justify-center'>
                    <FontAwesomeIcon className='text-lg' icon={regular('file-chart-column')} />
                  </div>
                </div>

                <div className='divide-y'>
                  {list
                    .filter(({ totalImpact }) => totalImpact !== 0)
                    .map((item, index) => (
                      <Fragment key={index}>
                        <Row
                          item={item}
                          index={index}
                          searchString={searchString}
                          reportType={props.reportType}
                          list={list}
                          setList={setList}
                          disabled={item.disabled}
                        />
                      </Fragment>
                    ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
          <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
            <div className='px-12 py-4 flex justify-between w-full max-w-screen-xl'>
              <div className='flex items-center gap-x-1 p-2 pr-4 border rounded-full'>
                <div className='text-center text-[12px] text-brand px-[6px] py-[2px] bg-slate-100 rounded-full min-w-[22px]'>
                  {simplify(selectedCount)}
                </div>
                <div className='text-sm text-zinc-500'>Product{selectedCount === 1 ? '' : 's'} selected</div>
              </div>
              <button
                type='button'
                disabled={selectedCount === 0}
                className={cn(
                  'flex text-sm items-center gap-2.5 border-2 rounded-full px-4 py-2 font-semibold',
                  '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
                  'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
                )}
                onClick={() => {
                  formik.setFieldValue(
                    'products',
                    list
                      .filter(({ selected, models }) => selected || models.length > 0)
                      .map((item) => ({
                        ...item,
                        id: item.id,
                        sku: item.skuId,
                        name: item.name,
                        count: 0,
                        models: item.models,
                        selected: item.selected,
                        resolved: true,
                      })),
                  );

                  props.onNext();
                }}
              >
                Add Selected Products
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Row = (props: {
  item: ListItem;
  searchString: string;
  index: number;
  reportType: ReportType;
  list: ListItem[];
  setList: (value: SetStateAction<ListItem[]>) => void;
  disabled: boolean;
}) => {
  const item = props.item;
  const [expanded, setExpanded] = useState(false);
  const [models, setModels] = useState<ProductModelListItem[]>([]);
  const showExpand = item.modelCount > 0;
  const { routes } = useAppRoutes();

  useEffect(() => {
    setModels([]);
  }, [item.id]);

  useEffect(() => {
    if (!showExpand) {
      setExpanded(false);
    }
  }, [showExpand]);

  useEffect(() => {
    if (item.models.length > 0) {
      setExpanded(true);
    }
  }, [item.models]);

  useEffect(() => {
    if (expanded) {
      getProductModelsV3(item.id).ok((response) => {
        setModels(response.models);
      });
    } else {
      props.setList((listItems) =>
        listItems.map((listItem) => ({
          ...listItem,
          models: listItem.id === item.id ? (item.models.length > 0 ? item.models : []) : listItem.models,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, expanded]);

  return (
    <>
      <div
        className={cn(
          { 'text-zinc-400': props.disabled },
          'odd:bg-slate-50 w-full gap-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center text-[90%] px-3',
        )}
      >
        <div
          className={cn(
            'h-12 grid items-center truncate',
            props.reportType === ReportType.Forecast && showExpand ? 'grid-cols-[30px_auto]' : 'grid-cols-[50px_auto]',
          )}
          title={item.name ?? ''}
        >
          <input
            type='checkbox'
            disabled={props.disabled}
            className='w-5 aspect-square'
            checked={props.list.find(({ id }) => id === item.id)?.selected ?? false}
            onChange={() => {
              props.setList((current) =>
                current.map((currentItem) =>
                  currentItem.id === item.id
                    ? { ...currentItem, selected: !currentItem.selected, models: !currentItem.selected ? [] : currentItem.models }
                    : { ...currentItem },
                ),
              );
            }}
          />

          {props.reportType === ReportType.Forecast && showExpand ? (
            <button
              disabled={props.disabled}
              type='button'
              className='flex items-center gap-2 truncate'
              onClick={() => setExpanded(!expanded)}
            >
              <FontAwesomeIcon
                className={cn('h-3 aspect-square transition-all', { 'rotate-90': expanded })}
                icon={solid('chevron-right')}
              />
              <div className='truncate'>{item.name || '(Unnamed)'}</div>
            </button>
          ) : (
            <div className='truncate'>{item.name || '(Unnamed)'}</div>
          )}
        </div>
        <div className=''>{format(parseISO(item.updatedAt), 'dd/MM/yyyy')}</div>
        <div className=''>{item.amount?.value && item.amount?.unit && `${item.amount.value}${item.amount.unit.name}`}</div>
        <div className='truncate' title={item.skuId}>
          {item.skuId}
        </div>
        <div className='text-center'>{item.firstPartyDataPercentage} %</div>
        <div>
          <div className='flex items-center justify-center'>{simplify(item.impactPoints)} Impact points</div>
        </div>
        <div className='px-6 text-center'>
          <NavLink
            className='text-brandDarkPurple2 hover:underline font-semibold text-[90%]'
            to={routes.products.productOverview.production(item.id)}
            target='_blank'
          >
            {item.totalImpact === 0 ? 'Generate' : 'View'}
          </NavLink>
        </div>
      </div>

      {props.reportType === ReportType.Forecast && expanded && models.length > 0 && (
        <div>
          <div className={cn('py-2 flex items-center text-brand pl-[60px] text-xs font-semibold uppercase')}>models</div>
          <div className='divide-y'>
            {orderBy(models, ({ updatedAt }) => updatedAt, 'desc').map((model, i) => (
              <div key={i} className={cn('w-full gap-x-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center text-[90%] px-3')}>
                <div className='h-14 grid items-center truncate grid-cols-[50px_auto]' title={model.title}>
                  <input
                    type='checkbox'
                    className='w-5 aspect-square'
                    checked={props.list.find(({ id }) => id === item.id)?.models.find(({ id }) => id === model.id)?.id !== undefined}
                    onChange={() =>
                      props.setList((current) =>
                        current.map((product) => ({
                          ...product,
                          selected:
                            product.id === item.id
                              ? (product.models.find((model2) => model2.id === model.id) && product.models.length === 0) !== undefined
                              : product.selected,
                          models:
                            item.id === product.id
                              ? product.models.find((m2) => m2.id === model.id)
                                ? []
                                : [
                                    {
                                      id: model.id,
                                      skuId: item.skuId,
                                      name: model.title,
                                      author: model.author,
                                      parentId: item.id,
                                      count: 0,
                                    },
                                  ]
                              : product.models,
                        })),
                      )
                    }
                  />

                  <div className='flex flex-col gap-y-1 truncate'>
                    <div className='truncate'>{model.title}</div>
                    <div className='flex items-center text-zinc-500 gap-x-2 text-[95%]'>
                      <FontAwesomeIcon icon={light('user')} />
                      {model.author}
                    </div>
                  </div>
                </div>
                <div>{format(parseISO(model.updatedAt), 'dd/MM/yyyy')}</div>
                <div></div>
                <div></div>
                <div className='text-center'>
                  {model.proposedChanges} change{model.proposedChanges === 1 ? '' : 's'}
                </div>
                <div
                  className={cn('text-center', {
                    'text-red-500': model.impactDelta.type === ImpactDeltaType.Higher,
                    'text-emerald-700': model.impactDelta.type === ImpactDeltaType.Lower,
                    'text-neutral-400': model.impactDelta.type === ImpactDeltaType.Zero,
                  })}
                >
                  {model.impactDelta.type === ImpactDeltaType.Zero
                    ? 'No impact change'
                    : `${model.impactDelta.display} ${model.impactDelta.type === ImpactDeltaType.Higher ? 'higher' : 'lower'} impact`}
                </div>
                <div className='text-center'>
                  <NavLink
                    className='text-brandDarkPurple2 hover:underline font-semibold text-[90%]'
                    to={routes.products.modelOverview(item.id, model.id)}
                    target='_blank'
                  >
                    View
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
