import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { simplify, roundToLong } from '../Root/Main/shared';
import { ImpactStageMatrix } from '../api';
import { TooltipV3 } from './TooltipV3';
import { useAppRoutes } from '../hooks/useAppRoutes';

interface Props {
  data: ImpactStageMatrix[];
}

export const HeatMapTableByImpact = (props: Props) => {
  const [expanded, setExpanded] = useState(new Array<string>());
  const { routes } = useAppRoutes();

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  const grid = 'grid grid-cols-[320px_1fr_1fr_1fr_1fr_1fr_160px] print:grid print:grid-cols-[200px_1fr_1fr_1fr_1fr_1fr_120px] items-center';

  return (
    <>
      <div className='text-center py-3 font-semibold text-lg border-b border-zinc-200'>
        Final environmental impact and contribution per life cycle stage and underlying process
      </div>
      <div className='p-3'>
        <div className='w-full text-sm'>
          <div className={cn('mt-3 uppercase text-zinc-500 text-xs h-10 border-y', grid)}>
            <div className='pl-5'>impact category</div>
            {props.data[0].stages.map((stage, i) => (
              <div className='text-center py-2' key={i}>
                {stage.name}
              </div>
            ))}
            <div className='text-center'>Total Impact</div>
          </div>

          <div className='text-sm'>
            {props.data.map((impact, i1) => {
              return (
                <div className={cn({ 'bg-slate-100/75': i1 % 2 })} key={i1}>
                  <div className={cn('divide-y divide-x divide-white', grid)}>
                    <button
                      disabled={impact.stages.length === 0}
                      onClick={() => handleExpandedState(impact.impactId)}
                      className='flex items-center gap-2 pl-3 h-10'
                    >
                      <FontAwesomeIcon
                        className={cn('text-sm transition-all', {
                          'rotate-90': expanded.includes(impact.impactId),
                        })}
                        icon={light('circle-chevron-right')}
                      />
                      <div className='truncate font-semibold'>{impact.impactName}</div>
                    </button>
                    {impact.stages.map((stage, j) => (
                      <div
                        title={roundToLong(stage.physicalValue)}
                        style={{ backgroundColor: `hsl(${stage.hue}, 70%, 90%)` }}
                        key={j}
                        className='text-center px-2 flex items-center justify-center h-10'
                      >
                        {simplify(stage.physicalValue)}
                      </div>
                    ))}
                    <div title={roundToLong(impact.physicalValue)} className='pl-3 text-center flex justify-center items-center h-10'>
                      {simplify(impact.physicalValue)} {impact.unit}
                    </div>
                  </div>

                  <div className={cn('flex flex-col', { hidden: !expanded.includes(impact.impactId) }, 'print:block')}>
                    {impact.stages.map((stage) =>
                      stage.components.map((component1, i2) => (
                        <div key={i2}>
                          <div className={cn('transition-all divide-y divide-x', i1 % 2 ? 'divide-white' : 'divide-slate-100', grid)}>
                            <button
                              disabled={component1.components.length === 0}
                              className={cn('flex items-center gap-2 h-10 pl-6 border-t', i1 % 2 ? 'border-white' : 'border-slate-100')}
                              onClick={() => handleExpandedState(`${impact.impactId}_${component1.id}`)}
                            >
                              {component1.components.length > 0 && (
                                <FontAwesomeIcon
                                  className={cn('h-2.5 aspect-square transition-all', {
                                    'rotate-90': expanded.includes(`${impact.impactId}_${component1.id}`),
                                  })}
                                  icon={solid('chevron-right')}
                                />
                              )}
                              <div title={component1.name} className='truncate font-semibold'>
                                {component1.name}
                              </div>
                            </button>
                            {impact.stages.map((component1Stage, j) =>
                              stage.name === component1Stage.name ? (
                                <div className='flex items-center justify-center h-10' key={j}>
                                  {simplify(component1.physicalValue)}
                                </div>
                              ) : (
                                <div key={j} className='h-10' />
                              ),
                            )}
                            <div className='h-10 border' />
                          </div>

                          {component1.components.map((component2, i3) => (
                            <Fragment key={i3}>
                              <div
                                className={cn(
                                  {
                                    hidden: !expanded.includes(`${impact.impactId}_${component1.id}`),
                                  },
                                  'transition-all divide-y divide-x',
                                  i1 % 2 ? 'divide-white' : 'divide-slate-100',
                                  grid,
                                )}
                              >
                                <button
                                  disabled={component2.components.length === 0}
                                  className={cn('h-10 flex items-center gap-2 pl-9 border-t', i1 % 2 ? 'border-white' : 'border-slate-100')}
                                  onClick={() => handleExpandedState(`${impact.impactId}_${component1.id}_${component2.id}`)}
                                >
                                  <FontAwesomeIcon
                                    className={cn('h-2.5 aspect-square transition-all', {
                                      invisible: component2.components.length === 0,
                                      'rotate-90': expanded.includes(`${impact.impactId}_${component1.id}_${component2.id}`),
                                    })}
                                    icon={solid('chevron-right')}
                                  />
                                  <div title={component2.name} className='truncate'>
                                    {component2.reference ? (
                                      <div
                                        className='underline text-brandDark'
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          window.open(routes.products.productOverview.production(component2.reference?.id!));
                                        }}
                                      >
                                        {component2.name}
                                      </div>
                                    ) : (
                                      component2.name
                                    )}
                                  </div>
                                </button>
                                {impact.stages.map((component2Stage, j) =>
                                  stage.name === component2Stage.name ? (
                                    <div
                                      key={j}
                                      title={roundToLong(component2.physicalValue)}
                                      className='h-10 flex items-center justify-center'
                                    >
                                      {simplify(component2.physicalValue)}
                                    </div>
                                  ) : (
                                    <div key={j} className='h-10' />
                                  ),
                                )}
                                <div className='h-10' />
                              </div>

                              {component2.components.map((component3, i4) => (
                                <Fragment key={i4}>
                                  <div
                                    className={cn('transition-all divide-y divide-x', i1 % 2 ? 'divide-white' : 'divide-slate-100', grid, {
                                      hidden: !expanded.includes(`${impact.impactId}_${component1.id}_${component2.id}`),
                                    })}
                                  >
                                    <button
                                      disabled={component3.components.length === 0}
                                      className={cn(
                                        'h-10 flex items-center gap-2 pl-12 border-t',
                                        i1 % 2 ? 'border-white' : 'border-slate-100',
                                      )}
                                      onClick={() =>
                                        handleExpandedState(`${impact.impactId}_${component1.id}_${component2.id}_${component3.id}`)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className={cn(
                                          'h-1.5 aspect-square transition',
                                          { invisible: component3.components.length === 0 },
                                          !expanded.includes(`${impact.impactId}_${component1.id}_${component2.id}_${component3.id}`)
                                            ? 'rotate-90'
                                            : 'rotate-180',
                                        )}
                                        icon={solid('triangle')}
                                      />
                                      <div className='flex gap-1'>
                                        <div title={component3.name} className='truncate'>
                                          {component3.name}
                                        </div>
                                        {component3.comment && (
                                          <TooltipV3
                                            content={
                                              <div className='rounded-lg text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 max-w-[250px] shadow-[0_0_4px_rgba(0,0,0,0.15)]'>
                                                {component3.comment}
                                              </div>
                                            }
                                          >
                                            <span>
                                              <FontAwesomeIcon className='text-brandDark' icon={regular('circle-info')} />
                                            </span>
                                          </TooltipV3>
                                        )}
                                      </div>
                                    </button>
                                    {impact.stages.map((component3Stage, j) =>
                                      stage.name === component3Stage.name ? (
                                        <div
                                          key={j}
                                          title={roundToLong(component3.physicalValue)}
                                          className='flex items-center justify-center h-10'
                                        >
                                          {simplify(component3.physicalValue)}
                                        </div>
                                      ) : (
                                        <div key={j} className='h-10' />
                                      ),
                                    )}
                                    <div className='h-10' />
                                  </div>

                                  {component3.components.map((component4, i5) => (
                                    <Fragment key={i5}>
                                      <div
                                        className={cn(
                                          {
                                            hidden: !expanded.includes(
                                              `${impact.impactId}_${component1.id}_${component2.id}_${component3.id}`,
                                            ),
                                          },
                                          'transition-all divide-y divide-x',
                                          i1 % 2 ? 'divide-white' : 'divide-slate-100',
                                          grid,
                                        )}
                                      >
                                        <div
                                          className={cn(
                                            'text-ellipsis h-10 flex items-center pl-16 border-t',
                                            i1 % 2 ? 'border-white' : 'border-slate-100',
                                          )}
                                        >
                                          <div title={component4.name} className='truncate'>
                                            {component4.name}
                                          </div>
                                        </div>
                                        {impact.stages.map((component4Stage, j) =>
                                          stage.name === component4Stage.name ? (
                                            <div
                                              key={j}
                                              title={roundToLong(component4.physicalValue)}
                                              className='flex items-center justify-center h-10'
                                            >
                                              {simplify(component4.physicalValue)}
                                            </div>
                                          ) : (
                                            <div key={j} className='h-10' />
                                          ),
                                        )}
                                        <div className='h-10' />
                                      </div>
                                    </Fragment>
                                  ))}
                                </Fragment>
                              ))}
                            </Fragment>
                          ))}
                        </div>
                      )),
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
