import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BaselinePefReport, Component, ForecastReport, HistoricalPefReport, ReportType, createProductModelV3 } from '../../../../api';
import { ContextualExampleTile } from '../../../../components/ContextualExampleTile';
import { CollapsibleSection } from '../../Products/Report/CollapsibleSection';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { simplify } from '../../shared';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

interface Props {
  data: BaselinePefReport | HistoricalPefReport | ForecastReport;
  reportType: ReportType;
}

export const Interpretation = (props: Props) => {
  const [creatingModel, setCreatingModel] = useState(false);
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const { routes } = useAppRoutes();

  return (
    <>
      <CollapsibleSection title='What does any of it mean?'>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-3'>
            <div>
              Not everyone has a PhD to understand what exactly those impacts represent. To give you some context and help you understand,
              here are some contextual examples. Unfortunately, not every impact category can be easily compared to something out of our
              everyday lives (or we actually could, but it might unrightfully scare anyone off and we don’t want that!), but where a
              comparison to something we all can relate to is possible, we’ve tried to include it here. We’ve also considered the PEF
              suggested weightings to determine which impact categories to focus on.
            </div>
            <div className='text-brandDark'>
              It is important to note that the examples you see use ‘simplified’ language which is not truly reflective of the complexity of
              the science behind those impact calculations. They are intended as hints to help you contextualise the size/magnitude of an
              impact rather than understand its full meaning. You can find the pure scientific equivalences by hovering over the cards or by
              visiting our Appendix where a detailed account of how these were calculated and what they truly mean from a scientific point
              of view is presented.
            </div>
            {props.reportType === ReportType.Baseline && (
              <div>
                We hope it helps you put some of the results into focus in your head as to grasp the extent of the impact we’re talking
                about here. The impact of producing the volume of products specified, of{' '}
                <span className='font-semibold'>{simplify(props.data.totalUnitCount)}</span> units across{' '}
                <span className='font-semibold'>{props.data.productCount}</span> products over the baseline year (
                <span className='font-semibold'>
                  {`${format(new Date((props.data as BaselinePefReport).startDate), 'dd/MM/yy')} - ${format(
                    new Date((props.data as BaselinePefReport).endDate),
                    'dd/MM/yy',
                  )}`}
                </span>
                ), had an estimated environmental impact equivalent to the following:
              </div>
            )}

            {props.reportType === ReportType.Historical && (
              <div>
                We hope it helps you put some of the results into focus in your head as to grasp the extent of the impact we’re talking
                about here. The impact of producing the volume of products specified, of{' '}
                <span className='font-semibold'>{simplify(props.data.totalUnitCount)}</span> units across{' '}
                <span className='font-semibold'>{props.data.productCount}</span> over the specified timeframe (
                <span className='font-semibold'>
                  {`${format(new Date((props.data as HistoricalPefReport).startDate), 'dd/MM/yy')} - ${format(
                    new Date((props.data as HistoricalPefReport).endDate),
                    'dd/MM/yy',
                  )}`}
                </span>
                ), had an estimated environmental impact equivalent to the following:
              </div>
            )}

            {props.reportType === ReportType.Forecast && (
              <div>
                We hope it helps you put some of the results into focus in your head as to grasp the extent of the impact we’re talking
                about here. The impact of producing the volume of products specified, of 323,384 units across 10 products over the specified
                timeframe ({(props.data as ForecastReport).cycle}), would have an estimated environmental impact equivalent to the
                following:
              </div>
            )}
          </div>

          <div className='grid grid-cols-3 gap-6 print:block'>
            {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
              <ContextualExampleTile key={i} contextualExample={contextualExample} />
            ))}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Product Improvement Tips'>
        {(() => {
          const majorProducts = props.data.products.filter(({ isMajor }) => isMajor);
          const majorImpacts = props.data.analysis.impactStageMatrix.filter(({ isMajorImpact }) => isMajorImpact);
          const majorLifeCycleStages = props.data.analysis.lifecycleStageImpacts.filter(({ isMajorImpact }) => isMajorImpact);
          const majorProcessInStages = props.data.analysis.lifecycleStageImpacts.filter(({ isMajorImpact, isMajor }) => isMajor);

          const renderComponents = (components: Component[], index: number) => {
            const renderNext = (components: Component[], i: number) => {
              let j = i + 1;

              return (
                <div key={j}>
                  {components
                    .filter(({ isMajor }) => isMajor)
                    .map((component, k) => (
                      <div className='flex gap-2' key={k}>
                        <div>—</div>
                        {component.name}
                        {renderNext(component.components, j)}
                      </div>
                    ))}
                </div>
              );
            };

            return renderNext(components, index);
          };

          return (
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col gap-3'>
                <div>
                  {props.reportType === ReportType.Forecast && <span>There is a lot of potential to improve this forecasted impact.</span>}
                  {[ReportType.Baseline, ReportType.Historical].includes(props.reportType) && (
                    <span>There is a lot of potential to improve your volume output environmental impact.</span>
                  )}{' '}
                  Let’s start by getting a sense of which products are the ones contributing the most to your overall environmental impact.
                  Based on the life cycle assessment carried out, we suggest you start focusing your efforts on the{' '}
                  <span className='font-semibold'>{majorProducts.filter(({ isMajor }) => isMajor).length}</span> products contributing the
                  most to your total impact:
                </div>

                <div className='flex flex-col gap-1'>
                  {majorProducts.map((product, i) => (
                    <NavLink
                      target='_blank'
                      className='text-brandDark hover:underline font-semibold flex self-start'
                      key={i}
                      to={routes.products.productGraph(product.id)}
                    >
                      {`${product.name} (view impact report)`}
                    </NavLink>
                  ))}
                </div>
              </div>

              <div className='flex flex-col gap-3'>
                <div>
                  When looking at the impact as whole, results of the analysis reveal that your overall environmental impact is concentrated
                  mostly in <span className='font-semibold text-brandDark'>{majorImpacts.length} impact categories</span> out of the{' '}
                  <span className='font-semibold'>16</span> total analysed with the worst being the impact on{' '}
                  <span className='text-brandDark font-semibold'>{majorImpacts[0].impactName}</span>.
                </div>
              </div>

              <div className='flex flex-col gap-3'>
                {props.reportType === ReportType.Historical && (
                  <div>Finally, the life cycle stages which contribute the most to the modelled environmental impact are:</div>
                )}
                {props.reportType === ReportType.Baseline && (
                  <div>Finally, the life cycle stages which contribute the most to the modelled environmental impact over a year are:</div>
                )}
                {props.reportType === ReportType.Forecast && (
                  <div>
                    Finally, the life cycle stages which contribute the most to the modelled environmental impact over a{' '}
                    {(props.data as ForecastReport).cycle} are:
                  </div>
                )}

                <div className='flex flex-col gap-1'>
                  {majorLifeCycleStages.map((stage, i) => (
                    <div key={i} className='text-brandDark font-semibold'>
                      {stage.name}
                    </div>
                  ))}
                </div>
              </div>

              <div className='flex flex-col gap-3'>
                <div>
                  To help you focus your efforts even further, the highest impact processes identified through the LCA are the following
                  ones:
                </div>
                {majorProcessInStages.map((stage, i) => (
                  <ul className='list-disc ml-3.5 text-brandDark font-semibold' key={i}>
                    <li className=''>
                      <div className='flex gap-1'>
                        <div>{stage.name}</div>
                        <div className='flex flex-col gap-1'>{renderComponents(stage.components, i)}</div>
                      </div>
                    </li>
                  </ul>
                ))}
              </div>

              <div className='flex flex-col gap-3 print:hidden'>
                <div>
                  You can simulate scenarios and model how any small change to your product’s life cycle would affect its overall
                  environmental footprint using our <span className='font-semibold'>Impact Change modelling tool</span>
                </div>

                <div>
                  Created to help you improve your business, the tool allows you to play with your current existing processes to see how you
                  could improve any product’s impact, showing you in real time how any change could increase or decrease the impact. It also
                  shows you the cumulative change of multiple changes on the total environmental impact.
                </div>

                <div>
                  Finally, you can select the impact category you’re interested in (perhaps you could focus on that highest impact category
                  to start with), and see how the impact at the category level varies to make sure you optimise for what matters to you
                  most!
                </div>
              </div>

              <div className='text-2xl font-semibold text-center print:hidden'>Try modelling changes to your highest impact products!</div>

              <div className='print:hidden w-96 self-center grid grid-cols-2 items-center gap-3 text-dark'>
                <div className='col-span-2'>
                  <LimitTooltip
                    placement='top-start'
                    enabled={modelsCreateLimit}
                    entityName='models limit'
                    valueKey='maxProductModelCount'
                    onDismiss={() => setModelsCreateLimit(false)}
                  >
                    <div />
                  </LimitTooltip>
                </div>
                {props.data.products
                  .filter(({ isMajor }) => isMajor)
                  .map((product, i) => (
                    <Fragment key={i}>
                      <div title={product.name} className='text-lg font-semibold truncate'>
                        {product.name}
                      </div>
                      <div className='flex'>
                        <button
                          disabled={creatingModel}
                          className={cn(
                            'border border-brand p-2 rounded-md bg-slate-50 font-semibold flex gap-2 items-center active:scale-95',
                            {
                              'cursor-wait': creatingModel,
                            },
                          )}
                          onClick={() => {
                            setCreatingModel(true);

                            product.id &&
                              createProductModelV3(product.id).call({
                                ok: ({ model, errorCode }) => {
                                  setCreatingModel(false);
                                  if (errorCode) {
                                    setModelsCreateLimit(true);
                                  } else {
                                    window.open(routes.products.modelGraph(product.id, model.id), '_blank');
                                  }
                                },
                                fail: () => setCreatingModel(false),
                              });
                          }}
                        >
                          <div className='h-7 flex items-center justify-center aspect-square rounded-md bg-slate-200'>
                            <FontAwesomeIcon className='rotate-90' icon={light('arrows-repeat')} />
                          </div>
                          Model changes
                        </button>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          );
        })()}

        <div className='mt-10 mb-3 flex items-center gap-2'>
          <FontAwesomeIcon className='text-3xl text-yellow-300' icon={solid('lightbulb-on')} />
          <span className='text-lg'>Looking for additional help? Here are some of our expert’s top tips on how to improve your score!</span>
        </div>
        <div className='flex flex-col gap-4 ml-10'>
          {props.data.improvementTips.map((item, i) => (
            <div key={i} className='flex gap-3 items-center'>
              <div className='flex items-center justify-center h-8 aspect-square p-2 bg-brand/5 text-dark rounded-xl'>
                <FontAwesomeIcon
                  className=''
                  icon={
                    {
                      'tip-balancing': regular('scale-unbalanced-flip'),
                      'tip-products': regular('box-circle-check'),
                      'tip-lifecycles': regular('arrows-spin'),
                      'tip-processes': regular('gears'),
                    }[item.iconId]!
                  }
                />
              </div>
              <div className='flex items-center'>{item.text}</div>
            </div>
          ))}
        </div>
      </CollapsibleSection>
    </>
  );
};
