import { forwardRef, Fragment } from 'react';
import { PieChart } from '../../../../../../components/charts/PieChart';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Grade, Methodology, ProductGeneral, ProductType } from '../../../../../../api';
import { useProfile } from '../../../../../../hooks/useProfile';
import { format } from 'date-fns';
import cn from 'classnames';

interface Props {
  title: string;
  products?: { [key: string]: ProductGeneral[] };
}

export const EcoLabellingDetails = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const isFoundationEarthWorkspace = useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  if (!props.products) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
        <div
          className='loading-skeleton'
          style={{
            height: '336px',
            width: '690px',
            borderRadius: '1rem',
          }}
        />
      </div>
    );
  }

  const flattenProducts = Object.values(props.products).flat();

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='self-start flex gap-20 p-6 bg-white border border-zinc-200 rounded-2xl'>
        <div className='flex flex-col gap-2'>
          {Object.entries(props.products)
            .filter(([grade]) => grade !== Grade.AA || isFoundationEarthWorkspace)
            .map(([grade, products]) => (
              <Fragment key={grade}>
                <ModalV3
                  title={
                    <div className='flex justify-between items-center'>
                      <div>Eco-labelling details</div>
                    </div>
                  }
                  hideConfirm
                  size='wide+'
                  body={<ModalBody products={flattenProducts.filter((product) => product.totalGrade === grade)} />}
                >
                  <button
                    disabled={products.length === 0}
                    className='hover:bg-slate-50 disabled:bg-transparent flex items-center gap-2 p-1 pr-3 rounded-full border border-zinc-200'
                  >
                    <div
                      className={cn(
                        'size-6 flex justify-center items-center text-base rounded-full text-white',
                        {
                          [Grade.AA]: 'bg-aa',
                          [Grade.A]: 'bg-a',
                          [Grade.AA]: 'bg-a',
                          [Grade.B]: 'bg-b',
                          [Grade.C]: 'bg-c',
                          [Grade.D]: 'bg-d',
                          [Grade.E]: 'bg-e',
                          [Grade.F]: 'bg-f',
                          [Grade.G]: 'bg-g',
                        }[grade],
                      )}
                    >
                      {grade}
                    </div>
                    <div className='text-zinc-700'>{products.length} products</div>
                  </button>
                </ModalV3>
              </Fragment>
            ))}
        </div>
        <div className='flex items-center -my-6'>
          <PieChart
            showTicks
            size={320}
            outerRadius={110}
            data={Object.entries(props.products).map(([grade, products]) => {
              const totalProducts = Object.values(props.products!).reduce((acc, products) => acc + products.length, 0);
              return {
                value: (products.length / totalProducts) * 100,
                impactPoints: products.length,
                name: `Grade: ${grade}`,
                isMajor: false,
                bgColor: {
                  [Grade.A]: '#5F8E25',
                  [Grade.AA]: '#388E25',
                  [Grade.B]: '#82B841',
                  [Grade.C]: '#FFB444',
                  [Grade.D]: '#F39609',
                  [Grade.E]: '#F37214',
                  [Grade.F]: '#E82E2E',
                  [Grade.G]: '#B90000',
                }[grade as Grade],
              };
            })}
          />
        </div>
        <div>
          <ModalV3
            size='wide'
            hideConfirm
            cancelLabel='Close'
            title={
              <div className='flex justify-between items-center'>
                <div>Eco-labelling details</div>
              </div>
            }
            body={<ModalBody products={flattenProducts} />}
          >
            <button type='button' className='text-zinc-400 flex justify-center items-center size-8'>
              <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
            </button>
          </ModalV3>
        </div>
      </div>
    </div>
  );
});

interface BodyProps {
  products: ProductGeneral[];
}

const ModalBody = (props: BodyProps) => {
  return (
    <table className='border-collapse w-full -mt-4'>
      <thead>
        <tr className='border-y border-zinc-200 text-zinc-800 whitespace-nowrap h-11'>
          <th className='pl-6 pr-2'>SKU name</th>
          <th className='px-2'>Product type</th>
          <th className='px-2'>Product update</th>
          <th className='px-2'>SKU ID</th>
          <th className='pl-2 pr-6'>Grade</th>
        </tr>
      </thead>
      <tbody>
        {props.products.map((product, i) => (
          <tr
            key={product.skuId}
            className={cn('h-12 text-zinc-600', {
              'bg-slate-50': i % 2 === 1,
            })}
          >
            <td className='pl-6 pr-2'>{product.name}</td>
            <td className='px-2'>
              {
                {
                  [ProductType.Final]: 'Final',
                  [ProductType.Intermediate]: 'Intermediate',
                  [ProductType.Internal]: 'Internal',
                }[product.productType]
              }
            </td>
            <td className='px-2'>{format(new Date(product.updatedAt), 'dd/MM/yyyy')}</td>
            <td className='px-2'>
              <div className='w-20 truncate'>{product.skuId}</div>
            </td>
            <td className='pl-2 pr-6'>
              <div
                className={cn(
                  'size-6 flex justify-center items-center text-base rounded-full text-white',
                  {
                    [Grade.A]: 'bg-a',
                    [Grade.AA]: 'bg-a',
                    [Grade.B]: 'bg-b',
                    [Grade.C]: 'bg-c',
                    [Grade.D]: 'bg-d',
                    [Grade.E]: 'bg-e',
                    [Grade.F]: 'bg-f',
                    [Grade.G]: 'bg-g',
                    '': '',
                  }[product.totalGrade],
                )}
              >
                {product.totalGrade}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
