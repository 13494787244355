import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { ModalApi } from '../../../components/ModalV3';
import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { useAppRoutes } from '../../../hooks/useAppRoutes';
import { useProfile } from '../../../hooks/useProfile';
import { getGhgProductReport, getProductReport, GhgProductReport, Lens, Methodology, ProductReport } from '../../../api';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../components/charts/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { simplify } from '../shared';
import { NavLink } from 'react-router-dom';
import { ProductionOverview } from '../Products/Report/Sku/Production/Overview';
import { ConsumerOverview } from '../Products/Report/Sku/Consumer/Overview';
import { GhgOverview } from '../Products/Report/Sku/Ghg/Overview';

export const ProductOverviewModal = forwardRef((props: { productId: string; workspaceId: string }, ref: ForwardedRef<ModalApi>) => {
  const [open, setOpen] = useState(false);

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const { floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getFloatingProps } = useInteractions([useClick(context), useFocusTrap(context), useRole(context, { role: 'dialog' })]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  return (
    <FloatingPortal>
      {open && (
        <Content
          productId={props.productId}
          workspaceId={props.workspaceId}
          floating={floating}
          getFloatingProps={getFloatingProps}
          setOpen={context.onOpenChange}
        />
      )}
    </FloatingPortal>
  );
});

const Content = (props: {
  productId: string;
  workspaceId: string;
  floating: (node: HTMLElement | null) => void;
  getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
  setOpen: (open: boolean) => void;
}) => {
  const [, reRender] = useReducer((x) => x + 1, 0);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const { routes } = useAppRoutes();
  const profile = useProfile();
  const [lens, setLens] = useState<Lens>(Lens.Production);
  const [data, setData] = useState<ProductReport | GhgProductReport>();

  const [productionReport, setProductionReport] = useState<ProductReport>();
  const [consumerReport, setConsumerReport] = useState<ProductReport>();
  const [ghgReport, setGhgReport] = useState<GhgProductReport>();

  useEffect(() => {
    (async () => {
      switch (lens) {
        case Lens.Production:
          if (!productionReport) {
            getProductReport(props.productId, {
              search: { lens, scaleToAmount: 1, scaleUnit: 'kg' },
              workspaceSid: props.workspaceId,
            }).call({
              ok: (data) => {
                data.analysis.lifecycleStageImpacts.forEach((entity) => {
                  entity.bgColor = setColor({ key: entity.name }, lifeCycleStagesColorHexSchema);
                });
                data.analysis.impactStagesMatrix.forEach((entity) =>
                  entity.stages.map((stage) => ({
                    ...stage,
                    bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema),
                  })),
                );

                setProductionReport(data);
                setData(data);
              },
              fail: () => props.setOpen(false),
            });
          } else {
            setData(productionReport);
          }
          break;
        case Lens.Consumer:
          if (!consumerReport) {
            getProductReport(props.productId, {
              search: { lens, scaleToAmount: 1, scaleUnit: 'kg' },
              workspaceSid: props.workspaceId,
            }).call({
              ok: (data) => {
                setConsumerReport(data);
                setData(data);
              },
              fail: () => props.setOpen(false),
            });
          } else {
            setData(consumerReport);
          }
          break;
        case Lens.Ghg:
          if (!ghgReport) {
            getGhgProductReport(props.productId, {
              search: { scaleToAmount: 1, scaleUnit: 'kg' },
              workspaceSid: props.workspaceId,
            }).call({
              ok: (data) => {
                setGhgReport(data);
                setData(data);
              },
              fail: () => props.setOpen(false),
            });
          } else {
            setData(ghgReport);
          }
          break;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lens]);

  useLayoutEffect(() => {
    if (headerRef.current && footerRef.current) {
      reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, footerRef.current, data]);

  if (!data) {
    return <></>;
  }

  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center z-50 p-8'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className='max-w-6xl antialiased text-body bg-white shadow-2xl border rounded-xl w-full'
      >
        <div className='flex flex-col p-6'>
          <div ref={headerRef} className='px-12 pb-6 -mx-6 border-b border-zinc-200 flex flex-col font-semibold text-xl text-neutral-900'>
            <div className='flex flex-col gap-y-8'>
              <div className='flex items-center justify-between gap-x-8'>
                <div className='flex items-center gap-x-6 truncate'>
                  <button
                    onClick={() => props.setOpen(false)}
                    className='flex items-center justify-center size-8 shrink-0 bg-slate-100 rounded-lg'
                  >
                    <FontAwesomeIcon className='text-zinc-500' icon={regular('times')} />
                  </button>
                  <div className='text-lg truncate'>{`${data.product.name} - (${simplify(data.product.amount.value)}${
                    data.product.amount.unit.name
                  }) - Report overview`}</div>
                </div>
                <div className='flex gap-x-6 shrink-0 text-base font-normal'>
                  {(() => {
                    const workspaceOfSelectedProduct = profile.workspaces.find((w) => w.workspaceSid === props.workspaceId)!;

                    return (
                      <div className='border max-w-96 p-1 pr-3 rounded-full flex items-center gap-x-2 truncate'>
                        {workspaceOfSelectedProduct.logoUrl ? (
                          <img className='shrink-0 size-8 rounded-full overflow-hidden' src={workspaceOfSelectedProduct.logoUrl} alt='' />
                        ) : (
                          <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                            <FontAwesomeIcon icon={regular('building')} />
                          </div>
                        )}
                        <div className='truncate text-sm'>{workspaceOfSelectedProduct.name}</div>
                      </div>
                    );
                  })()}
                  <NavLink
                    target='_blank'
                    to={routes.products.productGraph(props.productId, '/workspaces/' + props.workspaceId)}
                    className='flex items-center border rounded-full py-1 px-3 bg-slate-200 gap-x-2'
                  >
                    <div className='flex text-purple-950 font-semibold text-sm'>Edit product</div>
                    <FontAwesomeIcon className='text-lg text-brand' icon={light('arrow-up-right')} />
                  </NavLink>
                </div>
              </div>
              <div className='font-normal text-base'>
                Here is a preview of this product’s report. You can decide to edit it or view its full report from here, however this will
                redirect you to the workspace the product belong to (see above).
              </div>
            </div>
          </div>
          <div
            style={
              {
                '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
                '--footer-height': `${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}px`,
              } as CSSProperties
            }
            className='overflow-y-auto pt-12 h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]'
          >
            <OverviewContent
              productId={props.productId}
              workspaceId={props.workspaceId}
              lens={lens}
              setLens={setLens}
              data={(() => {
                switch (lens) {
                  case Lens.Production:
                    return productionReport;
                  case Lens.Consumer:
                    return consumerReport;
                  case Lens.Ghg:
                    return ghgReport;
                }
              })()}
            />
          </div>
          <div ref={footerRef} />
        </div>
      </div>
    </FloatingOverlay>
  );
};

interface OverviewContentProps {
  lens: Lens;
  setLens: (lens: Lens) => void;
  data?: ProductReport | GhgProductReport;
  productId: string;
  workspaceId: string;
}

const OverviewContent = (props: OverviewContentProps) => {
  const isFoundationEarthWorkspace = useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  if (!props.data) {
    return <></>;
  }

  const navigation = {
    production: () => props.setLens(Lens.Production),
    consumer: () => props.setLens(Lens.Consumer),
    ghg: () => props.setLens(Lens.Ghg),
  };

  const fetchReport = (): Promise<ProductReport | GhgProductReport> =>
    new Promise((resolve) =>
      getProductReport(props.productId!, {
        search: { lens: props.lens, scaleToAmount: 1, scaleUnit: 'kg' },
        workspaceSid: props.workspaceId,
      }).ok((data) => resolve(data)),
    );

  return (
    <>
      {(() => {
        switch (props.lens) {
          case Lens.Production:
            return (
              <ProductionOverview
                fetchReport={fetchReport}
                navigation={navigation}
                showHeader={false}
                lens={props.lens}
                setSelectedReportType={props.setLens}
                data={props.data as ProductReport}
                isFoundationEarthWorkspace={isFoundationEarthWorkspace}
              />
            );
          case Lens.Consumer:
            return (
              <ConsumerOverview
                fetchReport={fetchReport}
                navigation={navigation}
                showHeader={false}
                lens={props.lens}
                setSelectedReportType={props.setLens}
                data={props.data as ProductReport}
                isFoundationEarthWorkspace={isFoundationEarthWorkspace}
              />
            );
          case Lens.Ghg:
            return (
              <GhgOverview
                fetchReport={fetchReport}
                navigation={navigation}
                showHeader={false}
                lens={props.lens}
                setSelectedReportType={props.setLens}
                data={props.data as GhgProductReport}
                isFoundationEarthWorkspace={isFoundationEarthWorkspace}
              />
            );
          default:
            return (
              <ProductionOverview
                fetchReport={fetchReport}
                navigation={navigation}
                showHeader={false}
                lens={props.lens}
                setSelectedReportType={props.setLens}
                data={props.data as ProductReport}
                isFoundationEarthWorkspace={isFoundationEarthWorkspace}
              />
            );
        }
      })()}
    </>
  );
};
