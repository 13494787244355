import { useState } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import * as yup from 'yup';

import { Helmet } from 'react-helmet-async';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { StickyHeader } from '../../../../../components/StickyHeader';
import { Step1 } from './Steps/1_Step';
import { Step2 as ImportStep2 } from './Steps/Import/2_Step';
import { Step2 as ManualStep2 } from './Steps/Manual/2_Step';
import { Step3 as ManualStep3 } from './Steps/Manual/3_Step';
import { Step2 as FromReportStep2 } from './Steps/FromReport/2_Step';
import { Step3 as FromReportStep3 } from './Steps/FromReport/3_Step';

import { createManufacturingForecast, ManufacturingCycle, ManufacturingProduct } from '../../../../../api';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ManufacturingReportCreationMethod } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../../../../components/Modal';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InputV3 } from '../../../../../components/InputV3';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';

export const NewForecast = () => {
  const [step, setStep] = useState<number>(1);
  const [reportTitle, setReportTitle] = useState<string>(`Volume Forecast ${format(new Date(), 'dd/MM/yyyy')}`);

  const [waiting, setWaiting] = useState(false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);

  const [selectedMethod, setSelectedMethod] = useState<ManufacturingReportCreationMethod>();
  const [selectedReportId, setSelectedReportId] = useState<string | undefined>();
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const validationSchema = yup.object().shape({
    cycle: yup.string().required(),
    products: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          count: yup
            .number()
            .when('models', {
              is: (models: ManufacturingProduct[]) => models.length > 0 && !models[0].count,
              then: yup.number().integer().positive().required(),
              otherwise: yup.number(),
            })
            .when('selected', {
              is: (selected: boolean) => selected,
              then: yup.number().integer().positive().required(),
              otherwise: yup.number(),
            }),
          models: yup.array().of(
            yup.object().shape({
              count: yup.number().integer().positive().required(),
            }),
          ),
        }),
      ),
  });

  return (
    <div className='flex flex-1 h-full flex-col gap-y-4 -mt-10 relative'>
      <Helmet title={step === 1 ? 'Create a volume forecast' : reportTitle} />
      {!waiting ? (
        <Formik<{ products: ManufacturingProduct[]; cycle: ManufacturingCycle }>
          initialValues={{
            products: [],
            cycle: undefined as any,
          }}
          validateOnBlur={triedSubmitting}
          validateOnChange={triedSubmitting}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setWaiting(true);
            const payload = {
              name: reportTitle,
              cycle: values.cycle!,
              products: [
                ...values.products.filter((it) => it.selected && it.id).map(({ id, count }) => ({ id, count })),
                ...values.products
                  .flatMap(({ models }) => models)
                  .filter((it) => it.id)
                  .map(({ id, count, parentId }) => ({ id, count, parentId })),
              ],
            };
            createManufacturingForecast(payload).call({
              ok: (reportResponse) => {
                navigate(routes.manufacturing.forecast.overview(reportResponse.id), { state: reportResponse });
              },
            });
          }}
        >
          {(formik) => (
            <>
              <StickyHeader className='mx-6'>
                <div className='flex items-center gap-x-4'>
                  <button
                    onClick={() => {
                      if (step === 1) {
                        navigate(-1);
                      } else {
                        setStep((current) => current - 1);
                        formik.setFieldValue('products', []);
                      }
                      formik.resetForm();
                    }}
                    className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
                  >
                    <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
                  </button>

                  <ReportTitleForm reportTitle={reportTitle} setReportTitle={setReportTitle} />
                </div>
                <div className='border rounded-full px-4 py-2 text-xs uppercase'>
                  step {step}/{selectedMethod === ManufacturingReportCreationMethod.Import ? 2 : 3}
                </div>
              </StickyHeader>
              <div className='flex flex-col gap-6 px-6'>
                {(() => {
                  switch (step) {
                    case 1:
                      return <Step1 onNext={() => setStep(2)} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />;

                    case 2:
                      return (() => {
                        switch (selectedMethod) {
                          case ManufacturingReportCreationMethod.FromReport:
                            return (
                              <FromReportStep2
                                selectedReportId={selectedReportId}
                                setSelectedReportId={setSelectedReportId}
                                onNext={() => setStep(3)}
                              />
                            );
                          case ManufacturingReportCreationMethod.Manual:
                            return <ManualStep2 step={step} onNext={() => setStep(3)} />;
                          case ManufacturingReportCreationMethod.Import:
                            return <ImportStep2 reportTitle={reportTitle} onNext={() => setTriedSubmitting(true)} />;
                        }
                      })();

                    case 3:
                      return (() => {
                        switch (selectedMethod) {
                          case ManufacturingReportCreationMethod.FromReport:
                            return (
                              <FromReportStep3
                                reportTitle={reportTitle}
                                setTriedSubmitting={() => setTriedSubmitting(true)}
                                selectedReportId={selectedReportId!}
                              />
                            );
                          case ManufacturingReportCreationMethod.Manual:
                            return <ManualStep3 reportTitle={reportTitle} onNext={() => setTriedSubmitting(true)} />;
                        }
                      })();
                  }
                })()}
              </div>
            </>
          )}
        </Formik>
      ) : (
        <ManufacturingSkeletonLoader />
      )}
    </div>
  );
};

interface ReportTitleFormProps {
  reportTitle: string;
  setReportTitle: (title: string) => void;
}

const ReportTitleForm = (props: ReportTitleFormProps) => {
  return (
    <Formik<{ name: string }>
      initialValues={{
        name: `Volume Forecast ${format(new Date(), 'dd/MM/yyyy')}`,
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required(),
      })}
      onSubmit={(v) => {
        props.setReportTitle(v.name);
      }}
    >
      {(formik) => (
        <Modal<{ name: string }>
          icon={regular('edit')}
          title={`Rename "${formik.values.name}"`}
          onConfirm={({ name }, close) => {
            formik.setFieldValue('name', name);
            props.setReportTitle(name);
            close();
          }}
          formik={{
            initialValues: formik.values,
            validationSchema: yup.object().shape({
              name: yup.string().required(),
            }),
          }}
          bodyContent={<Field name='name'>{(model: FieldProps<string>) => <InputV3 model={model} />}</Field>}
        >
          <div className='flex self-center gap-x-2 items-center ring-0 cursor-pointer hover:text-brandDark'>
            <div className='text-lg font-semibold'>{formik.values.name}</div>
            <FontAwesomeIcon icon={regular('edit')} />
          </div>
        </Modal>
      )}
    </Formik>
  );
};
