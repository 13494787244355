import { useId } from '@floating-ui/react-dom-interactions';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArrayRenderProps, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Amount,
  Entity,
  NodeType,
  PackagingNode,
  PackagingNodeMaterial,
  PackagingSupplierNode,
  ProductState,
  ProductV3,
  Supplier,
  SupplierService,
  getSuppliers,
} from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { ModalForm, ModalFormApi, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { useLists } from '../../../../hooks/useLists';
import { DefaultBadge, renderBadges } from './Badges';
import { ExtractedData } from './ExtractedData';
import { LocationSelect } from './LocationSelect';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer, TouchedAwareFieldArray } from './TaggableFields';
import {
  StepInputAmountSideEffect,
  UpdateSideEffects,
  adjustMaterialSplits,
  adjustSupplierSplits,
  commentSchema,
  getProductionFacilities,
  is100Percent,
  newNodeId,
  shouldAutoAdjustSplit,
} from './dataModel';

const toSupplierOption = (supplier: Supplier, config?: { noId?: boolean }): SupplierNode => ({
  id: config?.noId ? '' : newNodeId(),
  type: NodeType.PackagingSupplier,
  displayName: supplier.name,
  flagged: false,
  edges: new Array<string>(),
  supplier,
  location: null as any as Entity,
  splitPercent: null as any as number,
  autoAdjustSplit: true,
});

interface SupplierNode extends PackagingSupplierNode {
  autoAdjustSplit: boolean;
}

interface MaterialNode extends PackagingNodeMaterial {
  autoAdjustSplit: boolean;
}

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  data?: PackagingNode;
  onSave: ModalFormSaveCallback<PackagingNode, { sideEffects: UpdateSideEffects }>;
  onOpenChange?: (open: boolean) => void;
}>;

export const PackagingDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();
  const bodyRef = useRef<BodyApi>(null);

  return (
    <ModalForm
      ref={props.modalRef}
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `${props.data.amount.value}g of ${props.data.displayName}` : 'New packaging'}
      body={<Body ref={bodyRef} productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            In what packaging is your product sold to your customers? Select the type of packaging (ie. bottle, box, wrap etc.) from the
            dropdown, the amount you are procuring, as well as which supplier(s) you’re getting it from.
          </div>
          <div>
            We know that you don’t always get raw materials from the same supplier year round so here you can specify what we call the
            split, or the percentage of time you got the packaging from one supplier versus another when looking 3 years back.
          </div>
          <div>
            On the other hand, if you always get a different amount of the packaging from different suppliers, just add it again in the
            graph, as many times as you need.
          </div>
          <div>
            The only thing left is to specify the composition of this packaging. Which material(s) is it made of? Add as many materials as
            needed, whether they are virgin or recycled materials, and specify what the breakdown is, making sure it all adds up to 100%!
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Packaging,
        flagged: false,
        edges: new Array<string>(),
        materials: new Array<PackagingNodeMaterial>(),
        nodes: new Array<PackagingSupplierNode>(),
        // needs to be null not undefined, otherwise .shape() validation fails
        packaging: null as any as Entity,
        amount: undefined as any as Amount,
      }}
      data={
        props.data
          ? {
              ...props.data,
              nodes: props.data.nodes.map(
                (node) =>
                  ({
                    ...node,
                    autoAdjustSplit: shouldAutoAdjustSplit(props.data!, node, formik, 'nodes', 'splitPercent'),
                  } as SupplierNode),
              ),
              materials: props.data.materials.map(
                (material) =>
                  ({
                    ...material,
                    autoAdjustSplit: shouldAutoAdjustSplit(props.data!, material, formik, 'materials', 'compositionPercent'),
                  } as MaterialNode),
              ),
            }
          : undefined
      }
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        packaging: yup.object().shape(commentSchema()).required(),
        amount: yup.object().shape({
          value: yup.number().positive().required(),
        }),
        materials: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              comment: yup.string().when(['placeholder', 'subType.placeholder'], {
                is: (placeholder: boolean, subTypePlaceholder: boolean) => placeholder || subTypePlaceholder,
                then: (schema) => schema.required(),
              }),
              subType: yup.object().when('placeholder', {
                is: true,
                otherwise: (schema) => schema.required(),
              }),
              compositionPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'compositionNot100', function () {
            const parent = this.parent as PackagingNode;
            return (
              parent.materials.length === 0 ||
              (parent.materials.every(({ compositionPercent }) => typeof compositionPercent === 'number') &&
                is100Percent(parent.materials.map(({ compositionPercent }) => compositionPercent)))
            );
          }),
        nodes: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              location: yup.object().required(),
              splitPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'splitsNot100', function () {
            const parent = this.parent as PackagingNode;
            return (
              parent.nodes.length === 0 ||
              (parent.nodes.every(({ splitPercent }) => typeof splitPercent === 'number') &&
                is100Percent(parent.nodes.map(({ splitPercent }) => splitPercent)))
            );
          }),
      })}
      getCustomErrors={(errors) => [
        { message: 'The suppliers split must add up to 100%.', expected: 'splitsNot100', actual: errors.nodes },
        { message: 'Packaging composition must add up to 100%.', expected: 'compositionNot100', actual: errors.materials },
      ]}
      entityName='packaging'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        props.onSave({
          values: values as PackagingNode,
          sideEffects: { stepInputAmounts: bodyRef.current!.getSideEffects() },
          ...rest,
        });
      }}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
}

interface BodyApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Body = forwardRef<BodyApi, BodyProps>((props, ref) => {
  const lists = useLists();
  const formik = useFormikContext<PackagingNode>();
  const originalAmountValue = useRef(formik.values.amount?.value);
  const updateStepInputCheckboxId = useId();
  const [updateStepInput, setUpdateStepInput] = useState(true);
  const packaging = lists.packagingTypes.find((type) => type.id === formik.values.packaging?.id);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  const getSubTypes = (materialId: string) =>
    lists.materialSubTypes.filter((subType) =>
      lists.packagingMaterials.find(({ id }) => id === materialId)!.materialSubTypes.includes(subType.id),
    );

  const getSingleStepInputUsingPackaging = () => {
    const inputs = getProductionFacilities(props.productFormik)
      .flatMap(({ steps }) => steps)
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === formik.values.id);
    return inputs.length === 1 ? inputs[0] : undefined;
  };

  const canUpdateStepInput =
    props.edit &&
    typeof originalAmountValue.current === 'number' &&
    typeof formik.values.amount?.value === 'number' &&
    formik.values.amount.unit &&
    originalAmountValue.current !== formik.values.amount?.value &&
    getSingleStepInputUsingPackaging();

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateStepInput && updateStepInput) {
        const { step, input } = getSingleStepInputUsingPackaging()!;
        return [{ stepId: step.id, inputId: input.id, value: formik.values.amount.value }];
      }

      return [];
    },
  }));

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      delete newValues.index;
      newValues.displayName = '';
      newValues.materials = [];
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.packaging?.id]);

  useEffectOnNextRenders(() => {
    if ((formik.values.nodes as SupplierNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustSupplierSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.nodes.length]);

  useEffectOnNextRenders(() => {
    if ((formik.values.materials as MaterialNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustMaterialSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.materials.length]);

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='grid grid-cols-2 gap-4'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Type</div>
          <div>
            <TaggableField name='packaging'>
              {(model: FieldProps<Entity>) => (
                <SelectV3<Entity> autoFocus model={model} menuPortalTarget={props.formRef.current} options={lists.packagingTypes} />
              )}
            </TaggableField>
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Amount</div>
          <div>
            <TaggableField name='amount.value'>
              {(model: FieldProps<number>) => (
                <UnitInputV3
                  model={model}
                  unit={{
                    options: [{ id: '', name: 'g' }],
                  }}
                />
              )}
            </TaggableField>
          </div>
        </div>
        {canUpdateStepInput && (
          <div className='col-span-2 flex gap-2 ml-2'>
            <input
              id={updateStepInputCheckboxId}
              type='checkbox'
              checked={updateStepInput}
              onChange={() => setUpdateStepInput((value) => !value)}
            />
            <label htmlFor={updateStepInputCheckboxId} className='select-none'>
              Automatically change input amount of the production step ({getSingleStepInputUsingPackaging()!.step.displayName}) to{' '}
              {formik.values.amount!.value}
              {formik.values.amount!.unit.name}
            </label>
          </div>
        )}
        {formik.values.packaging?.placeholder && (
          <div className='col-span-2 flex flex-col gap-1'>
            <div className='pl-1.5'>Comment for type</div>
            <div className='flex flex-col'>
              <Field name='packaging.comment'>
                {(model: FieldProps<string>) => <InputV3 model={model} placeholder='Describe the type…' />}
              </Field>
            </div>
          </div>
        )}
        <div className='col-span-2 flex flex-col gap-1'>
          <div className='pl-1.5'>Display name</div>
          <div className='flex flex-col'>
            <Field name='displayName'>
              {(model: FieldProps<string>) => (
                <InputV3 model={model} placeholder={formik.values.packaging?.name ?? 'Defaults to packaging name if left empty…'} />
              )}
            </Field>
          </div>
        </div>
        <TouchedAwareFieldArray name='nodes'>
          {(arrayModel) => (
            <>
              <div className='col-span-2 flex flex-col gap-1'>
                <div className='pl-1.5'>Suppliers</div>
                <div>
                  <Field name={arrayModel.name}>
                    {(model: FieldProps<PackagingSupplierNode[]>) => (
                      <SelectV3<PackagingSupplierNode>
                        multi
                        multiRepeated
                        model={model}
                        getOptionValue={({ supplier }) => supplier.id}
                        getOptionLabel={({ supplier }) => supplier.name}
                        menuPortalTarget={props.formRef.current}
                        loadOptions={(input, callback) => {
                          setNewSupplierName(input);
                          getSuppliers({
                            contains: input,
                            service: SupplierService.Packaging,
                          }).ok(({ suppliers }) => callback(suppliers.map((supplier) => toSupplierOption(supplier, { noId: true }))));
                        }}
                        adjustChange={(value: PackagingSupplierNode[]) =>
                          value.map((option) => ({ ...option, id: option.id || newNodeId() }))
                        }
                        renderOptionBadge={({ supplier }) => (supplier.default ? <DefaultBadge /> : <></>)}
                        menuFooter={
                          !showSupplierForm && (
                            <SelectFooterAddButton onClick={() => setShowSupplierForm(true)} name={newSupplierName} label='new provider' />
                          )
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              {showSupplierForm && (
                <div className='bg-[#F5F7FA] col-span-2 p-3 rounded-xl shadow-regular mt-3'>
                  <NewSupplierForm
                    name={newSupplierName}
                    formRef={props.formRef}
                    requiredServices={[SupplierService.Packaging]}
                    onCancel={() => setShowSupplierForm(false)}
                    onCreated={(newSupplier) => {
                      formik.setFieldValue('nodes', [...formik.values.nodes, toSupplierOption(newSupplier)]);
                      setShowSupplierForm(false);
                    }}
                  />
                </div>
              )}
              <div className='col-span-2 grid grid-cols-2 gap-4 my-4'>
                {formik.values.nodes.map((node, index) => (
                  <SupplierCard key={node.id} index={index} data={node as SupplierNode} arrayModel={arrayModel} {...props} />
                ))}
              </div>
            </>
          )}
        </TouchedAwareFieldArray>
        {packaging && (
          <TouchedAwareFieldArray name='materials'>
            {(arrayModel) => (
              <>
                <div className='col-span-2 flex flex-col gap-1'>
                  <div className='pl-1.5'>Materials</div>
                  <div>
                    <Field name={arrayModel.name}>
                      {(model: FieldProps<PackagingNodeMaterial[]>) => (
                        <SelectV3
                          multi
                          multiRepeated
                          model={model}
                          menuPortalTarget={props.formRef.current}
                          options={lists.packagingMaterials
                            .filter(({ id }) => packaging.packagingMaterials.includes(id))
                            .map(
                              (material) =>
                                ({
                                  ...material,
                                  id: newNodeId(),
                                  materialId: material.id,
                                  ...(getSubTypes(material.id).length === 1 && !material.placeholder
                                    ? { subType: getSubTypes(material.id)[0] }
                                    : {}),
                                  compositionPercent: null as any as number,
                                  autoAdjustSplit: true,
                                } as MaterialNode),
                            )}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='col-span-2 grid grid-cols-2 gap-4 mt-4'>
                  {formik.values.materials.map((material, index) => (
                    <MaterialCard
                      key={material.id}
                      index={index}
                      data={material as MaterialNode}
                      parentNode={formik.values}
                      arrayModel={arrayModel}
                      getSubTypes={getSubTypes}
                      {...props}
                    />
                  ))}
                </div>
              </>
            )}
          </TouchedAwareFieldArray>
        )}
      </div>
    </TaggableFieldsContainer>
  );
});

const SupplierCard = (
  props: {
    index: number;
    data: SupplierNode;
    arrayModel: FieldArrayRenderProps;
  } & BodyProps,
) => {
  const formik = useFormikContext<PackagingNode>();
  const splitPercentRef = useRef<HTMLInputElement>(null);

  useEffectOnNextRenders(() => {
    if (document.activeElement === splitPercentRef.current) {
      adjustSupplierSplits(formik, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.splitPercent]);

  return (
    <div
      key={props.data.id}
      className='flex flex-col justify-between gap-8 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'
    >
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 font-semibold text-lg text-neutral-900'>
          <div className='-ml-3 flex-1'>
            <Field name={`${props.arrayModel.name}.${props.index}.supplier`}>
              {(model: FieldProps<Entity>) => (
                <div title={model.field.value.name}>
                  <SelectV3
                    model={model}
                    menuPortalTarget={props.formRef.current}
                    loadOptions={(input, callback) => {
                      getSuppliers({
                        contains: input,
                        service: SupplierService.Packaging,
                      }).ok(({ suppliers }) => callback(suppliers));
                    }}
                    hideInputBorder
                    noClear
                  />
                </div>
              )}
            </Field>
          </div>
          <button
            type='button'
            className='flex justify-center items-center rounded-sm w-7 aspect-square'
            onClick={props.arrayModel.handleRemove(props.index)}
          >
            <FontAwesomeIcon icon={regular('times')} size='lg' />
          </button>
        </div>
        {props.data.supplier.default ? (
          <div className='flex'>
            <DefaultBadge />
          </div>
        ) : (
          renderBadges(props.data, props.productFormik, { target: 'supplier/id' })
        )}
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Split</div>
          <div>
            <TaggableField name={`${props.arrayModel.name}.${props.index}.splitPercent`} card>
              {(model: FieldProps<number>) => (
                <UnitInputV3 inputRef={splitPercentRef} model={model} unit={{ options: [{ id: '', name: '%' }] }} />
              )}
            </TaggableField>
          </div>
        </div>
        <LocationSelect
          name={`${props.arrayModel.name}.${props.index}.location`}
          includeOption={(option) =>
            !formik.values.nodes
              .filter(({ id }) => id !== props.data.id)
              .filter(({ supplier }) => supplier.id === props.data.supplier.id)
              .some((node) => node?.location?.id === option.id)
          }
          formRef={props.formRef}
        />
      </div>
    </div>
  );
};

const MaterialCard = (
  props: {
    index: number;
    data: MaterialNode;
    parentNode: PackagingNode;
    arrayModel: FieldArrayRenderProps;
    getSubTypes: (materialId: string) => Entity[];
  } & BodyProps,
) => {
  const formik = useFormikContext<PackagingNode>();
  const compositionPercentRef = useRef<HTMLInputElement>(null);

  useEffectOnNextRenders(() => {
    if (document.activeElement === compositionPercentRef.current) {
      adjustMaterialSplits(formik, props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.compositionPercent]);

  return (
    <div key={props.data.id} className='flex flex-col gap-8 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 font-semibold text-lg text-neutral-900'>
          <div className='truncate'>{props.data.name}</div>
          <button
            type='button'
            className='flex justify-center items-center rounded-sm w-7 aspect-square'
            onClick={props.arrayModel.handleRemove(props.index)}
          >
            <FontAwesomeIcon icon={regular('times')} size='lg' />
          </button>
        </div>
        {renderBadges(props.parentNode, props.productFormik, { target: `materials/${props.data.id}/id` })}
      </div>
      <div className='flex-1 flex flex-col justify-between gap-4'>
        {!props.data.placeholder && (
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Type</div>
            <div>
              <TaggableField name={`${props.arrayModel.name}.${props.index}.subType`} card>
                {(model: FieldProps<Entity>) => (
                  <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={props.getSubTypes(props.data.materialId)} />
                )}
              </TaggableField>
            </div>
          </div>
        )}
        {(props.data.placeholder || props.data.subType?.placeholder) && (
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Comment</div>
            <div>
              <TaggableField name={`${props.arrayModel.name}.${props.index}.comment`} card>
                {(model: FieldProps<string>) => (
                  <InputV3 model={model} placeholder={`Describe the ${props.data.subType?.placeholder ? 'type' : 'material'}…`} />
                )}
              </TaggableField>
            </div>
          </div>
        )}
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Composition</div>
          <div>
            <TaggableField name={`${props.arrayModel.name}.${props.index}.compositionPercent`} card>
              {(model: FieldProps<number>) => (
                <UnitInputV3 inputRef={compositionPercentRef} model={model} unit={{ options: [{ id: '', name: '%' }] }} />
              )}
            </TaggableField>
          </div>
        </div>
      </div>
    </div>
  );
};
