import { ForwardedRef, forwardRef, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from '../../../components/InfiniteScroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Facility, getSharedFacilityProducts, getSharedSupplierProducts, ProductType, SharedProduct, Supplier } from '../../../api';
import { simplify, roundToLong } from '../shared';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppRoutes } from '../../../hooks/useAppRoutes';
import { ModalApi } from '../../../components/ModalV3';
import { useProfile } from '../../../hooks/useProfile';
import { ProductOverviewModal } from './ProductOverviewModal';
import { Modal } from './Modal';
import { WorkspaceContext } from '../../../hooks/useWorspace';

type ProductListModalProps = {
  type: 'supplier' | 'facility';
  initialValues: {
    entity: Supplier | Facility | null;
    totalResults: number;
    nextPageToken: string;
    list: SharedProduct[];
  };
};

const defaultProductsSearchParams = {
  pageSize: 50,
  sortBy: 'totalImpact',
  sortAscending: 'false',
};

export const SuppliersFacilitiesProductListModal = forwardRef(
  (props: PropsWithChildren<ProductListModalProps>, ref: ForwardedRef<ModalApi>) => {
    const { routes } = useAppRoutes();
    const overviewModalRef = useRef<ModalApi>(null);
    const profile = useProfile();
    const [canFetch, setCanFetch] = useState(false);
    const [modalBodyRef, setModalBodyRef] = useState();
    const { setWorkspaceId } = useContext(WorkspaceContext);

    const [data, setData] = useState<{
      totalResults: number;
      nextPageToken: string;
      list: SharedProduct[];
    }>(props.initialValues);

    const [searchString, setSearchString] = useState('');
    const debouncedSearchString = useDebounce(searchString, 300);

    const getParentReference = useCallback((node) => {
      if (node) {
        setModalBodyRef(node);
      }
    }, []);

    useEffect(() => {
      setData(props.initialValues);
    }, [props.initialValues]);

    const onNext = () =>
      (props.type === 'facility' ? getSharedFacilityProducts : getSharedSupplierProducts)(props.initialValues.entity!.id, {
        ...defaultProductsSearchParams,
        pageToken: data.nextPageToken,
      }).ok((data) => {
        setData((current) => ({
          nextPageToken: data.nextPageToken,
          list: [...current.list, ...data.products],
          totalResults: data.totalResults,
        }));
      });

    useEffect(() => {
      if (canFetch) {
        (props.type === 'facility' ? getSharedFacilityProducts : getSharedSupplierProducts)(props.initialValues.entity!.id, {
          ...{ contains: debouncedSearchString, ...defaultProductsSearchParams },
        }).ok((data) =>
          setData({
            nextPageToken: data.nextPageToken,
            list: data.products,
            totalResults: data.totalResults,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchString]);

    return (
      <Modal
        ref={ref}
        preventDismiss
        scrollableContainer={getParentReference}
        title={
          props.initialValues.entity && (
            <div className='flex flex-col'>
              <div>Products using {props.initialValues.entity!.name}</div>
              <div className='flex items-center gap-6 self-end'>
                <div className='text-base text-zinc-500 bg-slate-100 py-1.5 px-2.5 rounded-lg'>
                  {data.totalResults} Product SKU{data.totalResults === 1 ? '' : "'s"}
                </div>
                <div className='flex items-center relative text-base'>
                  <input
                    type='search'
                    className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                    autoFocus
                    placeholder='Find…'
                    value={searchString}
                    onChange={(event) => setSearchString(event.target.value)}
                  />
                  <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
                </div>
              </div>
            </div>
          )
        }
        onOpenChange={(open) => {
          if (open) {
            setCanFetch(true);
          } else {
            setCanFetch(false);
            setSearchString('');
            setData({
              totalResults: 0,
              nextPageToken: '',
              list: [],
            });
          }
        }}
        body={
          data.list.length > 0 ? (
            <>
              <div className='grid gap-x-2 grid-cols-[4fr_2fr_2fr_3fr_2fr_1fr] sticky top-0 py-3 bg-white border-b border-b-zinc-200 font-semibold text-base'>
                <div className='pl-3'>Product name</div>
                <div>Product type</div>
                <div>Net amount</div>
                <div>Sku id</div>
                <div>Impact points</div>
                <div />
              </div>
              {data.list.length > 0 && modalBodyRef && (
                <InfiniteScroll
                  next={onNext}
                  loader={<></>}
                  hasMore={data.nextPageToken !== ''}
                  dataLength={data.list.length}
                  scrollableTarget={modalBodyRef}
                >
                  <div className='divide-y'>
                    {data.list.map((product, i) => (
                      <div key={i} className='grid gap-x-2 grid-cols-[4fr_2fr_2fr_3fr_2fr_1fr] items-center *:py-2'>
                        <div title={product.name} className='truncate pl-3'>
                          {product.name}
                        </div>
                        <div>
                          {
                            {
                              [ProductType.Final]: 'Final',
                              [ProductType.Intermediate]: 'Intermediate',
                              [ProductType.Internal]: 'Internal',
                            }[product.type]
                          }
                        </div>
                        <div>{product.netAmount}</div>
                        <div title={product.skuId} className='truncate'>
                          {product.skuId}
                        </div>
                        <div className='font-semibold' title={product.impactPoints ? roundToLong(product.impactPoints) : undefined}>
                          {simplify(product.impactPoints)}
                        </div>
                        <div className='font-semibold px-3'>
                          <ProductOverviewModal ref={overviewModalRef} productId={product.id} workspaceId={product.workspaceSid} />
                          <button
                            disabled={!product.reportCalculable}
                            title={product.workspaceSid}
                            onClick={async () => {
                              if (profile.selectedWorkspace.workspaceSid === product.workspaceSid) {
                                window.open(routes.products.productOverview.production(product.id));
                              } else {
                                setWorkspaceId(product.workspaceSid);
                                overviewModalRef.current?.open();
                              }
                            }}
                            className='flex self-center mx-auto bg-slate-200 p-2 rounded-lg disabled:text-zinc-400 hover:text-brand [&:active:not(:disabled)]:scale-95'
                          >
                            <FontAwesomeIcon className='text-lg' icon={regular('file-chart-column')} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </>
          ) : (
            <div className='py-8 text-center'>No results found</div>
          )
        }
      >
        {props.children}
      </Modal>
    );
  },
);
