import { PropsWithChildren } from 'react';
import macbook from './macbook.png';

type Props = PropsWithChildren<{}>;

export const LoginFrame = (props: Props) => (
  <div className='w-full min-h-screen flex'>
    <div className='flex-1 flex justify-center px-16'>
      <div className='w-full h-full flex flex-col justify-center items-center gap-10'>
        <a href='https://sustained.com' target='_blank' rel='noreferrer' className='w-44'>
          <svg viewBox='0 0 170 46'>
            <path
              d='M0 14.6784L1.70658 13.0212C3.01808 14.4973 4.37895 15.1174 6.38735 15.1174C8.39574 15.1174 9.57005 14.2888 9.57005 12.8346C9.57005 11.589 8.85668 10.9689 6.64526 10.4147L5.21853 10.0909C1.95901 9.33366 0.460943 7.97278 0.460943 5.59673C0.460943 2.73779 2.65042 0.987305 6.15688 0.987305C8.48354 0.987305 10.3986 1.772 11.8748 3.40725L10.1682 5.06445C8.87863 3.63772 7.74823 3.19873 5.94835 3.19873C4.14848 3.19873 2.99613 4.02733 2.99613 5.45955C2.99613 6.6558 3.80278 7.28137 5.85507 7.78621L7.2818 8.10996C10.6675 8.91661 12.1217 10.2775 12.1217 12.7194C12.1217 15.5289 9.86088 17.3288 6.33796 17.3288C3.66559 17.3288 1.40478 16.4069 0 14.6784Z'
              fill='black'
            />
            <path
              d='M20.5833 10.6451V1.21777H23.1404V10.3927C23.1404 13.345 24.5233 14.9583 27.1024 14.9802C29.6595 14.9583 30.9984 13.4108 30.9984 10.486V1.21777H33.5555V10.5519C33.5555 14.8595 31.2014 17.3288 27.053 17.3508C22.9484 17.3288 20.5778 14.8595 20.5778 10.6451H20.5833Z'
              fill='black'
            />
            <path
              d='M41.7866 14.6784L43.4932 13.0212C44.8047 14.4973 46.1656 15.1174 48.174 15.1174C50.1824 15.1174 51.3567 14.2888 51.3567 12.8346C51.3567 11.589 50.6433 10.9689 48.4319 10.4147L47.0052 10.0909C43.7566 9.32817 42.2585 7.97278 42.2585 5.59673C42.2585 2.73779 44.448 0.987305 47.9545 0.987305C50.2811 0.987305 52.1962 1.772 53.6724 3.40725L51.9658 5.06445C50.6762 3.63772 49.5458 3.19873 47.746 3.19873C45.9461 3.19873 44.7937 4.02733 44.7937 5.45955C44.7937 6.6558 45.6004 7.28137 47.6527 7.78621L49.0794 8.10996C52.4651 8.91661 53.9193 10.2775 53.9193 12.7194C53.9193 15.5289 51.6585 17.3288 48.1356 17.3288C45.4632 17.3288 43.2024 16.4069 41.7976 14.6784H41.7866Z'
              fill='black'
            />
            <path d='M65.7337 17.1258V3.57187H60.614V1.21777H73.3832V3.57187H68.2634V17.1258H65.7282H65.7337Z' fill='black' />
            <path
              d='M90.6465 17.1258L89.1485 13.0706H82.3715L80.8515 17.1258H78.1517L84.3305 1.21777H87.2114L93.3683 17.1258H90.6465ZM83.2001 10.8317H88.3199L86.0865 4.81752L85.7627 3.87368C85.6475 4.26329 85.5762 4.47181 85.4609 4.79557L83.2001 10.8372V10.8317Z'
              fill='black'
            />
            <path d='M101.248 17.1258V1.21777H103.805V17.1258H101.248Z' fill='black' />
            <path
              d='M113.255 17.1258V1.21777H116.229L123.719 12.7688C123.906 13.0212 124.043 13.3011 124.202 13.5754C124.18 13.3011 124.18 13.0431 124.18 12.7688V1.21777H126.671V17.1258H123.697L116.207 5.60222C116.02 5.32784 115.883 5.06994 115.724 4.79557C115.746 5.06994 115.746 5.32784 115.746 5.60222V17.1258H113.255Z'
              fill='black'
            />
            <path
              d='M136.115 17.1258V1.21777H147.041V3.50054H138.672V7.87949H145.817V10.1623H138.672V14.843H147.112V17.1258H136.115Z'
              fill='black'
            />
            <path
              d='M155.683 17.1258V1.21777H161.215C166.285 1.21777 169.929 4.2139 169.929 9.12513C169.929 14.0364 166.263 17.1258 161.215 17.1258H155.683ZM158.24 14.7717H161.193C164.765 14.7717 167.3 12.6974 167.3 9.12513C167.3 5.55283 164.787 3.57187 161.193 3.57187H158.24V14.7717Z'
              fill='black'
            />
            <path d='M1.08099 44.7823V28.8743H3.64361V44.7823H1.08099Z' fill='black' />
            <path
              d='M13.093 44.7823V28.8743H16.3689L20.9784 38.0273L25.5658 28.8743H28.8144V44.7823H26.3231V33.2587C26.3231 32.9843 26.3231 32.7264 26.345 32.474C26.2079 32.7264 26.0707 33.0063 25.928 33.2807L22.1252 40.8643H19.7986L15.9738 33.2368L15.5568 32.474C15.5787 32.7264 15.5787 33.0063 15.5787 33.2807V44.7823H13.0875H13.093Z'
              fill='black'
            />
            <path
              d='M50.358 34.1093C50.358 37.3578 47.8941 39.5034 44.2011 39.5034H40.8154V44.7823H38.2582V28.8743H44.3877C48.0533 28.8743 50.358 30.9046 50.358 34.1093ZM44.1133 37.1987C46.2808 37.1987 47.7295 36.0024 47.7295 34.2025C47.7295 32.3149 46.3686 31.1845 44.1572 31.1845H40.8154V37.1987H44.1133Z'
              fill='black'
            />
            <path
              d='M68.5432 44.7823L67.0452 40.7271H60.2682L58.7482 44.7823H56.0484L62.2272 28.8743H65.1081L71.265 44.7823H68.5432ZM61.0968 38.4882H66.2166L63.9777 32.474L63.654 31.5302C63.5387 31.9198 63.4674 32.1283 63.3521 32.4521L61.0913 38.4937L61.0968 38.4882Z'
              fill='black'
            />
            <path
              d='M77.4603 36.8255C77.4603 31.9637 80.9393 28.6438 85.5762 28.6438C88.0675 28.6438 90.3008 29.659 91.8483 31.4808L89.9332 33.0721C88.8961 31.7606 87.2828 31.0198 85.5762 31.0198C82.4209 31.0198 80.1107 33.3026 80.1107 36.831C80.1107 40.3594 82.3935 42.6422 85.5762 42.6422C87.4638 42.6422 88.94 41.9288 90.0923 40.5186L91.98 42.1099C90.4819 43.9756 88.2211 45.0127 85.5707 45.0127C80.9393 45.0127 77.4548 41.6709 77.4548 36.831L77.4603 36.8255Z'
              fill='black'
            />
            <path d='M103.805 44.7823V31.2284H98.6856V28.8743H111.455V31.2284H106.335V44.7823H103.8H103.805Z' fill='black' />
            <path
              d='M161.912 44.6068C166.379 44.6068 170 40.9855 170 36.5184C170 32.0513 166.379 28.4299 161.912 28.4299C157.444 28.4299 153.823 32.0513 153.823 36.5184C153.823 40.9855 157.444 44.6068 161.912 44.6068Z'
              fill='#4F00FF'
            />
            <path
              d='M136.949 42.1705C140.071 42.1705 142.601 39.64 142.601 36.5185C142.601 33.397 140.071 30.8665 136.949 30.8665C133.828 30.8665 131.297 33.397 131.297 36.5185C131.297 39.64 133.828 42.1705 136.949 42.1705Z'
              fill='#4F00FF'
            />
          </svg>
        </a>
        {props.children}
      </div>
    </div>
    <div className='flex-1 flex px-16 bg-brandDarkPurple3'>
      <div className='ml-8 flex flex-col justify-center'>
        <div className='flex flex-col gap-8 text-white'>
          <img src={macbook} alt='' className='w-80 -ml-4' />
          <div className='font-semibold text-3xl leading-snug'>
            <div>Welcome to Sustained Impact.</div>
            <div className='text-brandLime'>Go beyond just carbon.</div>
          </div>
          <div className='flex flex-col gap-3 mt-2'>
            <div className='font-semibold text-xl'>Self-service product impact assessment. At scale.</div>
            <ul className='list-disc pl-4 ml-2'>
              <li>Understand your production environmental impact</li>
              <li>Plan and action changes to reduce impact</li>
              <li>Share and communicate your impact</li>
            </ul>
          </div>
          <div className='font-semibold text-xl'>Start your journey now!</div>
        </div>
      </div>
    </div>
  </div>
);
