import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { GhgAnalysis, ReportType, getBaselineGhgCsv, getHistoricalGhgCsv, getProductGhgProtocolCsv } from '../../../../../api';
import { ViewToggle } from '../../../../../components/ViewToggle';
import { GhgGraph } from '../../../../../components/charts/GhgGraph';
import { OptionalReportingEmissionsTable } from '../../../Products/Report/Sku/components/OptionalReportingEmissionsTable';
import { RequiredReportingEmissionsTable } from '../../../Products/Report/Sku/components/RequiredReportingEmissionsTable';
import { View } from '../ImpactAnalysisResultSummary';
import { WorkspaceContext } from '../../../../../hooks/useWorspace';

interface Props {
  id: string;
  analysis: GhgAnalysis;
  reportType: ReportType;
}

export const GhgProtocol = (props: Props) => {
  const [selectedView, setSelectedView] = useState<View>(View.Graph);
  const [exporting, setExporting] = useState(false);
  const { workspaceId } = useContext(WorkspaceContext);

  return (
    <div className='flex flex-col'>
      <div className='print:hidden mt-6 grid items-center grid-cols-[1fr_auto_1fr]'>
        <div />
        <ViewToggle
          theme='dark'
          button1={{
            label: 'Graph view',
            icon: regular('chart-simple'),
            active: selectedView === View.Graph,
          }}
          button2={{
            label: 'Table view',
            icon: regular('table'),
            active: selectedView === View.Table,
          }}
          toggleView={() => setSelectedView((current) => (current === View.Graph ? View.Table : View.Graph))}
        />
        {[ReportType.Baseline, ReportType.Historical, ReportType.ProductReport].includes(props.reportType) && (
          <button
            type='button'
            disabled={exporting}
            className='print:hidden flex gap-2 justify-self-end items-center px-4 text-brandDarkPurple2 font-semibold disabled:cursor-wait text-base'
            onClick={() => {
              if (props.reportType === ReportType.ProductReport) {
                setExporting(true);
                getProductGhgProtocolCsv({ productId: props.id! }, workspaceId).call({
                  ok: () => setExporting(false),
                  fail: () => setExporting(false),
                });
              }
              if (props.reportType === ReportType.Historical) {
                getHistoricalGhgCsv(
                  {
                    reportId: props.id,
                    fileName: 'Historical Volume Report Scope-3 outputs',
                  },
                  workspaceId,
                ).call({
                  ok: () => setExporting(false),
                  fail: () => setExporting(false),
                });
              }
              if (props.reportType === ReportType.Baseline) {
                setExporting(true);
                getBaselineGhgCsv(
                  {
                    fileName: 'Baseline Volume Report Scope-3 outputs',
                  },
                  workspaceId,
                ).call({
                  ok: () => setExporting(false),
                  fail: () => setExporting(false),
                });
              }
            }}
          >
            <FontAwesomeIcon icon={solid('download')} />
            Export as CSV
          </button>
        )}
      </div>
      <div>
        {(() => {
          const tables = (
            <div className='flex flex-col gap-y-6'>
              <div className='flex flex-col mt-6'>
                <div className='flex self-center text-[#4100BF] shadow text-sm font-semibold bg-[#E8EAF5] py-2 px-3 my-4 rounded-md'>
                  Required Reporting
                </div>
                <RequiredReportingEmissionsTable data={props.analysis} />
              </div>
              <div className='flex flex-col pb-3'>
                <div className='flex self-center text-[#4100BF] shadow text-sm font-semibold bg-[#E8EAF5] py-2 px-3 my-4 rounded-md'>
                  Optional Reporting
                </div>
                <OptionalReportingEmissionsTable data={props.analysis} />
              </div>
            </div>
          );

          return (
            <>
              {selectedView === View.Graph ? (
                <div className='print:hidden mt-6'>
                  <GhgGraph analysis={props.analysis} />
                </div>
              ) : (
                <div className='print:hidden'>{tables}</div>
              )}
              <div className='hidden print:block'>{tables}</div>
            </>
          );
        })()}
      </div>
    </div>
  );
};
