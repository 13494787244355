import { cloneElement, CSSProperties, ForwardedRef, forwardRef, HTMLProps, isValidElement, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { ModalApi } from '../../../components/ModalV3';
import { FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating, useFocusTrap, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import cn from 'classnames';

interface ModalProps {
  title: ReactNode;
  open?: boolean;
  onClose?: () => void;
  onOpenChange?: (open: boolean) => void;
  preventDismiss?: boolean;
  children: ReactNode;
  body: ReactNode;
  scrollableContainer: (node: ReactNode) => void;
}

export const Modal = forwardRef((props: ModalProps, ref: ForwardedRef<ModalApi>) => {
  const [open, setOpen] = useState(props.open ?? false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);

    if (!newOpen && props.onClose) {
      props.onClose();
    }

    if (props.onOpenChange) {
      props.onOpenChange(newOpen);
    }
  };

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: !props.preventDismiss }),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && <Content {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
      </FloatingPortal>
    </>
  );
});

interface ModalContentProps {
  title: ReactNode;
  body: ReactNode;
  setOpen: (open: boolean) => void;
  floating: (node: HTMLElement | null) => void;
  getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
  scrollableContainer: (node: ReactNode) => void;
}

const Content = (props: ModalContentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center bg-neutral-400/75 z-50'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className='antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full max-w-6xl'
      >
        <div className='flex flex-col'>
          <div ref={headerRef} className='flex flex-col font-semibold text-xl text-neutral-900 p-6'>
            {props.title}
          </div>
          <div
            ref={props.scrollableContainer}
            style={
              {
                '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
                '--footer-height': `${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}px`,
              } as CSSProperties
            }
            className={cn(
              'overflow-y-auto border rounded-2xl mx-6',
              'max-h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]',
            )}
          >
            {props.body}
          </div>
          <div ref={footerRef} className='flex justify-between p-6'>
            <button
              type='button'
              className={cn(
                'flex justify-center border-2 border-brand rounded-full px-4 py-1 text-brand font-semibold whitespace-nowrap',
                'active:scale-95',
              )}
              onClick={() => props.setOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </FloatingOverlay>
  );
};
