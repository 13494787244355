import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getManufacturingData, ImpactId, ImpactTracking, TargetType, ReportType, TargetHintType, Targets } from '../../../../api';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { SingleSelect } from '../../../../components/SingleSelect';
import { ViewToggle } from '../../../../components/ViewToggle';
import { ProgressChart } from '../../../../components/charts/ProgressTracking/ProgressChart';
import { ProgressChartLegend } from '../../../../components/charts/ProgressTracking/ProgressChartLegend';
import { ProgressChartPlaceholder } from '../../../../components/charts/ProgressTracking/ProgressChartPlaceholder';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { getImpactIcon } from '../../../../icons/Impact';
import { createProduct } from '../../Products/Details/dataModel';
import { ImpactValueType } from '../../Products/Report/Sku/Overview';
import { useProfile } from '../../../../hooks/useProfile';
import { trackingData } from './trackingData';
import { useProgressChartEnabled } from '../../../../hooks/useProgressChartEnabled';
import { simplify } from '../../shared';
import { StickyHeader } from '../../../../components/StickyHeader';
import { ReadOnlyWarning } from '../../../../components/ReadOnlyWarning';
import { ImpactTargetHints } from '../../../../components/ImpactTargetHints';
import { convert } from '../../Prototype/sections/trackigDataConverterV2';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

export const Main = () => {
  const [createLimit, setCreateLimit] = useState(false);
  const manufacturing = useApiQuery(getManufacturingData());
  const [impacts, setImpacts] = useState<ImpactTracking[]>([]);
  const [targets, setTargets] = useState<Targets>();
  const [selectedImpact, setSelectedImpact] = useState<ImpactTracking>();
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const [startIndex, setStartIndex] = useState(0);
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const profile = useProfile();
  const enabled = useProgressChartEnabled({
    isBaselineSet: manufacturing.data?.tracking.impacts[0].baseline !== undefined,
    data: manufacturing.data?.tracking.impacts.map((impact) => ({
      id: impact.id,
      reports: impact.reports,
    })),
    selectedImpact: selectedImpact,
  });

  useEffect(() => {
    if (selectedImpact?.id === ImpactId.Overall) {
      setSelectedImpactUnit(ImpactValueType.Points);
    }
  }, [selectedImpact]);

  useEffect(() => {
    if (manufacturing.data) {
      if (manufacturing.data.baseline) {
        setImpacts(manufacturing.data.tracking.impacts);
        setTargets(manufacturing.data.targets);
        setSelectedImpact(manufacturing.data.tracking.impacts[0]);
      } else {
        setImpacts(trackingData.impacts as ImpactTracking[]);
        setSelectedImpact(trackingData.impacts[0] as ImpactTracking);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturing.data?.tracking.impacts]);

  if (!manufacturing.data?.tracking.impacts || !selectedImpact || !impacts) {
    return <></>;
  }

  const createNewProduct = () =>
    createProduct().call({
      ok: ({ id, errorCode }) => {
        if (errorCode) {
          setCreateLimit(true);
        } else {
          navigate(`/products/${id}`);
        }
      },
    });

  return (
    <div className='flex flex-col gap-4 mb-20'>
      <StickyHeader className='px-6'>
        <div className='text-lg font-semibold text-zinc-800'>Progress tracking</div>
      </StickyHeader>
      <div className='flex flex-col gap-y-4 px-6'>
        {!manufacturing.data.baseline && (
          <>
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='flex bg-amber-50 rounded-lg shadow px-6 py-4'>
                <div className='flex gap-x-4'>
                  <div className='flex items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                    <FontAwesomeIcon className='text-xl' icon={light('lightbulb')} />
                  </div>
                  {manufacturing.data.hasCompleteProducts ? (
                    <div className='flex flex-col gap-y-4'>
                      <span>
                        <span className='font-semibold'>Get Started.</span> Calculate your baseline footprint and start measuring your
                        progress against it.
                      </span>
                      <NavLink
                        to={routes.manufacturing.baseline.new}
                        className='flex self-start bg-brand text-white font-semibold px-3.5 py-1.5 rounded-full'
                      >
                        Calculate baseline footprint
                      </NavLink>
                    </div>
                  ) : (
                    <div className='flex flex-col gap-y-4'>
                      <span>
                        <span className='font-semibold'>Create some products to set your baseline.</span> Once you do, you can calculate
                        your baseline footprint and measure your progress against it.
                      </span>
                      <LimitTooltip
                        placement='top-start'
                        enabled={createLimit}
                        entityName='products limit'
                        valueKey='maxProductSkuCount'
                        onDismiss={() => setCreateLimit(false)}
                      >
                        <div>
                          <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                            <button
                              onClick={createNewProduct}
                              disabled={!profile.selectedWorkspace.permissions.productManagement}
                              className='flex self-start shadow-regular [&:active:not(:disabled)]:scale-95 bg-brand text-white font-semibold px-3.5 py-1.5 rounded-full disabled:bg-neutral-300 disabled:cursor-not-allowed'
                            >
                              Create new product
                            </button>
                          </ReadOnlyWarning>
                        </div>
                      </LimitTooltip>
                    </div>
                  )}
                </div>
              </div>

              <div className='grid grid-cols-[1fr_auto_1fr] gap-x-2'>
                <div className='flex flex-col gap-y-2 p-3 bg-neutral-50 rounded-lg border'>
                  <div className='font-semibold line-clamp-2 text-base whitespace-nowrap'>Baseline impact</div>
                  <div className='text-brand text-sm'>Total env. impact</div>
                  <div className='uppercase text-xs tracking-wide text-zinc-800'>No baseline recorded</div>
                </div>
                <FontAwesomeIcon className='flex self-center h-8 aspect-square text-brand' icon={light('right')} />
                <div className='flex flex-col gap-y-2 p-3 bg-neutral-50 rounded-lg border'>
                  <div className='font-semibold line-clamp-2 text-base whitespace-nowrap'>Year to date</div>
                  <div className='text-brand text-sm'>Total env. impact</div>
                  <div className='uppercase text-xs tracking-wide text-zinc-800'>No historical data</div>
                </div>
              </div>
            </div>

            <div className={cn('ml-24 m-6 h-64 opacity-50')}>
              <ProgressChartPlaceholder />
            </div>
          </>
        )}

        {manufacturing.data.baseline &&
          (!enabled ? (
            <div className='flex flex-col gap-y-4'>
              <div className='text-base flex items-center gap-x-4 bg-white p-4 rounded-lg border'>
                <div className='size-10 bg-brandLime flex shrink-0 items-center justify-center rounded-full'>
                  <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
                </div>
                <div>
                  This graph shows progress throughout the years. In order to view it, the baseline needs to be created using one year
                  (either a calendar or tax year) as time frame not custom dates. Uploaded historical data should cover full months as well.
                </div>
              </div>
              <div className={cn('ml-24 m-6 h-64', { 'pointer-events-none opacity-50': !enabled })}>
                <ProgressChartPlaceholder />
              </div>
            </div>
          ) : (
            targets &&
            impacts && (
              <div className='flex flex-col gap-y-6'>
                <div className='flex gap-x-6'>
                  <div className='w-60'>
                    <SingleSelect
                      options={impacts.map((item) => ({ ...item, disabled: false, renderIcon: item.target !== undefined }))}
                      value={{
                        value: selectedImpact.id,
                        label: selectedImpact.name,
                      }}
                      setSelectedId={(v) => {
                        if (v === 'overall') {
                          setSelectedImpactUnit(ImpactValueType.Points);
                        }
                        setSelectedImpact(impacts.find((impact) => impact.id === v)!);
                      }}
                      selectedId={selectedImpact.id}
                    />
                  </div>

                  <div className='w-px bg-zinc-300' />

                  <ViewToggle
                    theme='light'
                    button1={{
                      label: 'Final environmental impact',
                      active: selectedImpactUnit === ImpactValueType.Points,
                      disabled: selectedImpact.id === ImpactId.Overall,
                    }}
                    button2={{
                      label: 'Physical Impact',
                      active: selectedImpactUnit === ImpactValueType.Physical,
                      disabled: selectedImpact.id === ImpactId.Overall,
                    }}
                    toggleView={() => {
                      setSelectedImpactUnit((current) =>
                        current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                      );
                    }}
                  />
                </div>
                <ImpactTargetHints
                  reportType={ReportType.Historical}
                  selectedUnit={selectedImpactUnit}
                  selectedImpact={selectedImpact}
                  targetData={convert(selectedImpact).getTargetData()}
                  targetHint={convert(selectedImpact).getTargetHint()}
                  targets={targets}
                />
                <div className='h-64 flex gap-6 border rounded-lg p-6 pb-2'>
                  <div className='w-full text-xs ml-20'>
                    <ProgressChart targets={targets} selectedImpact={selectedImpact} selectedType={selectedImpactUnit} impacts={impacts} />
                  </div>
                  <div className='text-sm leading-4'>
                    <ProgressChartLegend />
                  </div>
                </div>
              </div>
            )
          ))}

        {(() => {
          const priorityImpactsWithTarget = ['overall', 'pef_climate_change', 'pef_water_scarcity', 'pef_land_use'];
          const targets = manufacturing.data.latestHistorical
            ? manufacturing.data.tracking.impacts.map((item) => ({
                id: item.id,
                name: item.name,
                target: {
                  type: item.target ? item.target.type : TargetType.DISABLED,
                  estimate: item.target?.estimate,
                },
                targetHint: item.targetHint,
              }))
            : manufacturing.data.targets.targetImpacts.map((item) => ({
                id: item.id,
                name: item.name,
                target: {
                  type: item.type,
                  estimate: item.estimate,
                },
                targetHint: undefined,
              }));

          const enabledTargets = targets
            .filter(({ target }) => target.type !== TargetType.DISABLED)
            .sort((a, b) => {
              if (priorityImpactsWithTarget.includes(a.id) && !priorityImpactsWithTarget.includes(b.id)) {
                return -1;
              }
              if (priorityImpactsWithTarget.includes(b.id) && !priorityImpactsWithTarget.includes(a.id)) {
                return 1;
              }
              return 0;
            });

          return (
            <div className='flex flex-col gap-3 py-3 border rounded-lg'>
              <div className='flex justify-between mx-6'>
                <div className='text-zinc-800 text-base font-semibold'>Targets overview</div>
                <NavLink to='../../targets' className='border border-violet-950 text-violet-950 font-semibold px-2 py-1 rounded-lg'>
                  {enabledTargets.length > 0 ? 'Edit targets' : 'Add targets'}
                </NavLink>
              </div>
              {(() => {
                return enabledTargets.length > 0 ? (
                  <div className='flex gap-x-1 mx-2'>
                    <button
                      className={cn('text-brand disabled:text-zinc-400', {
                        invisible: startIndex === 0 || enabledTargets.length <= 4,
                      })}
                      disabled={startIndex === 0}
                      onClick={() => setStartIndex((startIndex - 4 + enabledTargets.length) % enabledTargets.length)}
                    >
                      <FontAwesomeIcon icon={regular('arrow-left')} />
                    </button>
                    <div className='grid grid-cols-4 w-full gap-3'>
                      {enabledTargets.slice(startIndex, startIndex + 4).map((item, i) => (
                        <div key={i} className='flex flex-col gap-2 px-3 py-2 bg-slate-50 rounded-lg'>
                          <div className='text-sm'>{item.name}</div>
                          <div className='text-base font-semibold'>{item.target.estimate}</div>
                          <div
                            className={cn('flex self-start px-1.5 py-0.5 rounded-lg text-white text-xs font-semibold whitespace-nowrap', {
                              'bg-[#047857]': [
                                TargetHintType.LongTermSufficient,
                                TargetHintType.AnnualSufficient,
                                TargetHintType.LongTermOnTrack,
                                TargetHintType.LongTermMeet,
                              ].includes(item.targetHint?.type as TargetHintType),
                              'bg-[#FBBF24]': [TargetHintType.LongTermInsufficient, TargetHintType.AnnualInsufficient].includes(
                                item.targetHint?.type as TargetHintType,
                              ),
                              'bg-[#A1A1AA]': [TargetHintType.UnknownNoTarget, TargetHintType.UnknownNoBaseline].includes(
                                item.targetHint?.type as TargetHintType,
                              ),
                            })}
                          >
                            {item.targetHint?.type === TargetHintType.LongTermMeet && 'Target met'}
                            {item.targetHint?.type === TargetHintType.LongTermOnTrack && 'On Track!'}
                            {[TargetHintType.AnnualSufficient, TargetHintType.LongTermSufficient].includes(item.targetHint?.type!) &&
                              'Sufficient'}
                            {[TargetHintType.LongTermInsufficient, TargetHintType.AnnualInsufficient].includes(item.targetHint?.type!) &&
                              'Insufficient'}
                            {[TargetHintType.UnknownNoTarget, TargetHintType.UnknownNoBaseline].includes(item.targetHint?.type!) &&
                              'Unknown'}
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className={cn('disabled:text-zinc-400 text-brand', {
                        invisible: startIndex + 4 >= enabledTargets.length,
                      })}
                      disabled={startIndex + 4 >= enabledTargets.length}
                      onClick={() => setStartIndex((startIndex + 4) % enabledTargets.length)}
                    >
                      <FontAwesomeIcon icon={regular('arrow-right')} />
                    </button>
                  </div>
                ) : (
                  <div className='grid grid-cols-2 gap-2 mx-6'>
                    <div className='border rounded-xl shadow px-6 py-4'>
                      <div className='flex items-center gap-x-4'>
                        <div className='flex items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                          <FontAwesomeIcon className='text-xl' icon={light('lightbulb')} />
                        </div>

                        <span>
                          <span className='font-semibold'>Set targets.</span>{' '}
                          <span>Once you do, you can start tracking progress against them.</span>
                        </span>
                      </div>
                    </div>

                    <div className='grid grid-cols-3 gap-2'>
                      {['Total env. impact', 'Climate Change', 'Land Use'].map((item, i) => (
                        <div key={i} className='flex flex-col gap-y-2 bg-slate-50 px-3 py-2 rounded-lg'>
                          <div className='text-sm'>{item}</div>
                          <div className='text-xs uppercase'>no target set</div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })()}
            </div>
          );
        })()}

        {manufacturing.data.latestHistorical ? (
          <div className='flex flex-col gap-3 py-3 border rounded-lg'>
            <div className='flex justify-between mx-6'>
              <div className='text-zinc-800 text-base font-semibold'>Impact from latest historical report</div>
              <NavLink
                to={`../../historical-reports/${manufacturing.data.latestHistorical.id}/report/production/overview`}
                className='border border-violet-950 text-violet-950 font-semibold px-2 py-1 rounded-lg'
              >
                See full report
              </NavLink>
            </div>
            <div className='grid grid-cols-4 gap-2 mx-6'>
              <div className='flex flex-col gap-y-2 self-start border border-indigo-200 bg-indigo-50/20 rounded-lg p-4'>
                <div>Total impact</div>
                <div className='flex items-center gap-2 whitespace-nowrap'>
                  <div className='font-semibold'>{simplify(manufacturing.data.latestHistorical.impactPoints)}</div>
                  <div className='uppercase text-sm'>impact points</div>
                </div>
              </div>
              {manufacturing.data.latestHistorical.impacts
                .filter((impact) => [ImpactId.PefClimateChange, ImpactId.PefLandUse, ImpactId.PefWaterScarcity].includes(impact.id))
                .map((item, i) => (
                  <div key={i} className='flex gap-x-4 bg-slate-50 rounded-lg p-4'>
                    <div className='flex h-full w-8 aspect-square text-[#330099]'>
                      {getImpactIcon(
                        {
                          [ImpactId.PefClimateChange]: 'impact-climate-change',
                          [ImpactId.PefWaterScarcity]: 'impact-water-use',
                          [ImpactId.PefLandUse]: 'impact-land-use',
                        }[item.id as ImpactId.PefClimateChange | ImpactId.PefWaterScarcity | ImpactId.PefLandUse],
                      )}
                    </div>
                    <div className='flex flex-col justify-between gap-2'>
                      <div title={item.name} className='text-zinc-700 truncate'>
                        {item.name}
                      </div>
                      <div className='flex items-center gap-2 text-dark'>
                        {simplify(item.physicalValue)}
                        <div className='text-xs uppercase text-zinc-600'>{item.unit}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-3 py-3 border rounded-lg'>
            <div className='flex justify-between mx-6'>
              <div className='text-zinc-800 text-base font-semibold'>Impact from latest historical report</div>
              <NavLink
                to='../../historical-reports/new'
                className='border border-violet-950 text-violet-950 font-semibold px-2 py-1 rounded-lg'
              >
                Upload historical data
              </NavLink>
            </div>
            <div className='flex flex-col gap-y-4 mx-6'>
              <div className='border rounded-xl shadow px-6 py-4'>
                <div className='flex items-center gap-x-4'>
                  <div className='flex items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                    <FontAwesomeIcon className='text-xl' icon={light('lightbulb')} />
                  </div>

                  <span>
                    <span className='font-semibold'>Upload historical data.</span>{' '}
                    <span>This section will display the key metrics for your latest impact at volume. </span>
                  </span>
                </div>
              </div>

              <div className='grid grid-cols-4 gap-2'>
                <div className='flex flex-col gap-y-2 self-start border border-indigo-200 bg-indigo-50/20 rounded-lg p-4'>
                  <div>Total impact</div>
                  <div className='text-xs uppercase text-zinc-600'>no historical data</div>
                </div>
                {[
                  { name: 'Climate change', id: 'impact-climate-change' },
                  { name: 'Water scarcity', id: 'impact-water-use' },
                  { name: 'Land use', id: 'impact-land-use' },
                ].map((item, i) => (
                  <div key={i} className='flex gap-x-4 bg-slate-50 rounded-lg p-4'>
                    <div className='flex h-full w-8 aspect-square text-[#330099]'>{getImpactIcon(item.id)}</div>
                    <div className='flex flex-col justify-between gap-2'>
                      <div className='text-zinc-700 truncate'>{item.name}</div>
                      <div className='text-xs uppercase text-zinc-600'>no historical data</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
