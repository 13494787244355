import { SetStateAction, useEffect, useState } from 'react';
import { Input } from '../components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { authenticate, AuthenticateError, defaultRequiredMessage } from '../api';
import { useNavigate } from 'react-router';

interface AuthenticationForm {
  username: string | null;
  password: string | null;
  newPassword: string | null;
  passwordConfirmation: string | null;
}

interface Props {
  show: boolean;
}

export const TempPasswordChange = (props: Props) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [authenticateError, setAuthenticateError] = useState<{ errorCode: AuthenticateError; message: string }>();
  const navigate = useNavigate();

  if (!props.show) {
    return null;
  }

  return (
    <Formik<AuthenticationForm>
      validateOnBlur={triedToSubmit}
      validateOnChange={triedToSubmit}
      validationSchema={yup.object().shape({
        username: yup.string().nullable().email('Invalid email').required(defaultRequiredMessage),
        password: yup.string().nullable().required(defaultRequiredMessage),
        newPassword: yup
          .string()
          .nullable()
          .required(defaultRequiredMessage)
          .notOneOf([yup.ref('password'), null], 'New password must be different from the current password')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+[\]{}|;:,.<>?]{16,}$/,
            'Password must be at least 16 characters long and contain at least 1 lowercase, 1 uppercase, and 1 number',
          ),
        passwordConfirmation: yup
          .string()
          .nullable()
          .required(defaultRequiredMessage)
          .oneOf([yup.ref('newPassword'), null], 'Password mismatch'),
      })}
      initialValues={{
        username: null,
        password: null,
        newPassword: null,
        passwordConfirmation: null,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const payload = {
          username: values.username!,
          password: values.password!,
          newPassword: values.newPassword!,
        };
        authenticate(payload).call(
          ({ errorCode, message }) => {
            if (errorCode) {
              setAuthenticateError({ errorCode: errorCode!, message: message! });
              setSubmitting(false);
            } else {
              navigate('/');
            }
          },
          () => setSubmitting(false),
        );
      }}
    >
      <Content
        show={props.show}
        setTriedToSubmit={setTriedToSubmit}
        authenticateError={authenticateError}
        setAuthenticateError={setAuthenticateError}
      />
    </Formik>
  );
};

interface ContentProps {
  show: boolean;
  authenticateError?: { errorCode: AuthenticateError; message: string };
  setAuthenticateError: (error?: { errorCode: AuthenticateError; message: string }) => void;
  setTriedToSubmit: (value: SetStateAction<boolean>) => void;
}

const Content = (props: ContentProps) => {
  const formik = useFormikContext<AuthenticationForm>();
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  const renderError = (error?: string) => error && <div className='relative ml-4 text-red-500 text-xs'>{error}</div>;

  useEffect(() => {
    props.setAuthenticateError(undefined);

    return () => props.setAuthenticateError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.password, formik.values.username]);

  return (
    <>
      <div className='flex flex-col gap-y-4 w-96'>
        <div className='text-black text-2xl font-semibold leading-loose'>Please change your password</div>
        <div className='text-neutral-700'>
          This is your first time logging into Sustained Impact. For your security, please change your password.
        </div>
      </div>
      <div className='flex flex-col gap-y-4'>
        <Form noValidate className='flex flex-col items-center gap-4'>
          <Field name='username'>
            {(model: FieldProps<string>) => (
              <div className='w-80 flex flex-col gap-1.5'>
                <div className='text-dark ml-4 text-sm'>Email</div>
                <div className='flex flex-col flex-1'>
                  <Input autoFocus model={model} placeholder='name@company.com' />
                </div>
                {renderError(model.meta.error)}
              </div>
            )}
          </Field>
          <Field name='password'>
            {(model: FieldProps<string>) => (
              <div className='w-80 flex flex-col gap-1.5'>
                <div className='text-dark ml-4 text-sm'>Temporary password</div>
                <div className='flex flex-col flex-1 relative'>
                  <Input password={!currentPasswordVisible} model={model} placeholder='Your current password' />
                  <div className='h-full absolute right-3 flex items-center'>
                    <button type='button' onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)}>
                      <FontAwesomeIcon className='text-zinc-600' icon={currentPasswordVisible ? solid('eye-slash') : solid('eye')} />
                    </button>
                  </div>
                </div>
                {(() => {
                  if (props.authenticateError) {
                    switch (props.authenticateError.errorCode) {
                      case AuthenticateError.InvalidCredentials:
                        return renderError('Wrong email or password. Please try again.');
                      case AuthenticateError.InvalidNewPassword:
                        return renderError('New password must be different from the current password');
                      default:
                        return undefined;
                    }
                  } else {
                    return renderError(model.meta.error);
                  }
                })()}
              </div>
            )}
          </Field>
          <Field name='newPassword'>
            {(model: FieldProps<string>) => (
              <div className='w-80 flex flex-col gap-1.5'>
                <div className='text-dark ml-4 text-sm'>New password</div>
                <div className='flex flex-col flex-1 relative'>
                  <Input password={!newPasswordVisible} model={model} placeholder='A long and strong password' />
                  <div className='h-full absolute right-3 flex items-center'>
                    <button onClick={() => setNewPasswordVisible(!newPasswordVisible)} type='button'>
                      <FontAwesomeIcon className='text-zinc-600' icon={newPasswordVisible ? solid('eye-slash') : solid('eye')} />
                    </button>
                  </div>
                </div>
                {renderError(model.meta.error)}
              </div>
            )}
          </Field>
          <Field name='passwordConfirmation'>
            {(model: FieldProps<string>) => (
              <div className='w-80 flex flex-col gap-1.5'>
                <div className='text-dark ml-4 text-sm'>Confirm new password</div>
                <div className='flex flex-col flex-1 relative'>
                  <Input password={!newPasswordVisible} model={model} placeholder='Retype your new password' />
                  <div className='h-full absolute right-3 flex items-center'>
                    <button onClick={() => setNewPasswordVisible(!newPasswordVisible)} type='button'>
                      <FontAwesomeIcon className='text-zinc-600' icon={newPasswordVisible ? solid('eye-slash') : solid('eye')} />
                    </button>
                  </div>
                </div>
                {renderError(model.meta.error)}
              </div>
            )}
          </Field>
          <button
            onClick={async () => props.setTriedToSubmit(true)}
            className='px-6 py-2 mt-4 bg-black rounded-3xl justify-center items-center text-white font-semibold'
          >
            Change my password
          </button>
        </Form>
      </div>
    </>
  );
};
