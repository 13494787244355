import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Fragment, PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ConsumptionStepNode,
  FinalDestinationNode,
  Metadata,
  NodeType,
  OutputType,
  PackagingWaste,
  ProductType,
  ProductV3,
  ProductionProcess,
  SolidWaste,
  StepOutput,
  StoreNode,
  Tag,
  WarehouseNode,
  WasteSubType,
  WasteType,
} from '../../../../api';
import { simplify, roundToShort } from '../../shared';
import { useLists } from '../../../../hooks/useLists';
import {
  getConsumptionLocationsFromAll,
  getDisposalsFromAll,
  getIngredientsFromAll,
  getOutputsFromAll,
  getPackagingsFromAll,
  getProductionFacilitiesFromAll,
  getProductionWarehousesFromAll,
  getRawMaterialSuppliersFromAll,
  getTransportsFromAll,
} from '../Details/dataModel';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

interface ValueProps {
  metadata: Metadata;
  path: string;
}

const DefaultableValue = (props: PropsWithChildren<ValueProps>) => {
  const hasDefault = props.metadata.user.find(({ path }) => path === props.path)?.tags.includes(Tag.Default);

  return (
    <div className='flex gap-1 items-center'>
      {props.children}
      {hasDefault && (
        <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
          default
        </div>
      )}
    </div>
  );
};

interface Props {
  data: {
    product: ProductV3;
  };
}

export const ExtendedDetails = (props: Props) => {
  const [expanded, setExpanded] = useState(new Array<string>());
  const { routes } = useAppRoutes();
  const { nodes } = props.data.product;
  const lists = useLists();

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  const renderValueWithAmount = (value?: number, unit?: string) => {
    if (value && unit) {
      return (
        <div title={simplify(value) + unit} className='flex items-center gap-0.5 truncate'>
          <div className='truncate'>{simplify(value)}</div>
          <div>{unit}</div>
        </div>
      );
    }
    return <div className='text-zinc-500'>{!value && unit && 'default'}</div>;
  };

  return (
    <>
      {(() => {
        const bottomShadow = 'shadow-[inset_0px_-20px_4px_-17px_rgba(0,0,0,0.05)]';
        const upperShadow = 'shadow-[inset_0px_4px_5px_-2px_rgba(0,0,0,0.05)]';

        const renderSectionTitle = (type: NodeType, itemsCount: number, prefix?: string) => {
          return (
            <button
              type='button'
              onClick={() => handleExpandedState(prefix ? `${prefix}_${type}` : type)}
              className='flex gap-3 items-center ml-3 pb-3'
            >
              <FontAwesomeIcon
                className={cn('w-2 aspect-square transition-all print:hidden', {
                  'rotate-90': expanded.includes(prefix ? `${prefix}_${type}` : type),
                })}
                icon={regular('chevron-right')}
              />
              <div
                className={cn('flex justify-center items-center rounded-md h-7 aspect-square', {
                  'bg-green-50 text-green-900': type === NodeType.Ingredient,
                  'bg-lime-50 text-lime-900': type === NodeType.Packaging,
                  'bg-rose-50 text-rose-900': type === NodeType.Production,
                  'bg-fuchsia-50 text-fuchsia-900': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type),
                  'bg-blue-50 text-blue-900': [NodeType.Store, NodeType.FinalDestination].includes(type),
                  'bg-cyan-50 text-cyan-900': type === NodeType.ConsumptionPreparation,
                  'bg-slate-50 text-slate-900': [NodeType.Disposal, NodeType.Transport].includes(type),
                })}
              >
                {type === NodeType.Ingredient && <FontAwesomeIcon icon={duotone('carrot')} />}
                {type === NodeType.Packaging && <FontAwesomeIcon icon={duotone('box-open')} />}
                {type === NodeType.Transport && <FontAwesomeIcon icon={duotone('truck')} />}
                {type === NodeType.Production && <FontAwesomeIcon icon={duotone('industry-windows')} />}
                {[NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type) && <FontAwesomeIcon icon={duotone('warehouse-full')} />}
                {type === NodeType.Store && <FontAwesomeIcon icon={duotone('store')} />}
                {type === NodeType.FinalDestination && <FontAwesomeIcon icon={duotone('flag-checkered')} />}
                {type === NodeType.ConsumptionPreparation && <FontAwesomeIcon icon={duotone('house-user')} />}
                {type === NodeType.Disposal && <FontAwesomeIcon icon={duotone('dumpster')} />}
              </div>
              <div className='text-sm font-semibold'>
                {type === NodeType.Ingredient && 'Ingredients'}
                {type === NodeType.Packaging && 'Packaging'}
                {type === NodeType.Transport && 'Transport links'}
                {type === NodeType.Production && 'Production facilities'}
                {[NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type) && 'Storage'}
                {type === NodeType.Store && 'Stores'}
                {type === NodeType.FinalDestination && 'Final destination'}
                {type === NodeType.ConsumptionPreparation && 'Use'}
                {type === NodeType.Disposal && 'Disposals'}
              </div>
              <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                {itemsCount}
              </div>
            </button>
          );
        };

        const totalIngredients = getIngredientsFromAll(nodes).length;
        const totalPackagings = getPackagingsFromAll(nodes).length;

        return (
          <>
            <div className='flex flex-col gap-4 rounded-regular shadow-regular p-8'>
              <div className='text-xl font-semibold'>Raw materials</div>
              {totalIngredients > 0 && (
                <>
                  {renderSectionTitle(NodeType.Ingredient, totalIngredients)}
                  <div className={cn('border-x print:block', { hidden: !expanded.includes(NodeType.Ingredient) })}>
                    <div className={cn('text-sm', bottomShadow)}>
                      <div
                        className={cn(
                          'grid grid-cols-[2fr_1fr_2fr_1fr_1fr_40px] gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                          upperShadow,
                        )}
                      >
                        <div className='pl-6'>Name</div>
                        <div>total amount</div>
                        <div>supplier(s)</div>
                        <div>split</div>
                        <div>country</div>
                        <div />
                      </div>
                      <div className='divide-y'>
                        {getIngredientsFromAll(nodes).map((ingredientNode) => (
                          <div key={ingredientNode.id}>
                            <div
                              onClick={() => handleExpandedState(`${NodeType.Ingredient}_${ingredientNode.id}`)}
                              className={cn('grid grid-cols-[2fr_1fr_2fr_1fr_1fr_40px] items-center gap-3 cursor-pointer')}
                            >
                              <div className={cn('pl-6 py-3')}>{ingredientNode.ingredient.name}</div>
                              <div className='py-3'>
                                {renderValueWithAmount(ingredientNode.amount.value, ingredientNode.amount.unit.name)}
                              </div>
                              <div className='flex gap-1 my-auto mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                                <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                  {ingredientNode.nodes.filter((node) => node.type === NodeType.IngredientSupplier).length}
                                </div>
                                Suppliers
                              </div>
                              <div className='col-span-2' />
                              <div className='flex self-center justify-self-center text-zinc-500 pr-6 print:hidden'>
                                <FontAwesomeIcon
                                  size='lg'
                                  className={cn({ 'rotate-90': expanded.includes(`${NodeType.Ingredient}_${ingredientNode.id}`) })}
                                  icon={light('circle-chevron-right')}
                                />
                              </div>
                            </div>

                            {ingredientNode.nodes
                              .filter((node) => node.type === NodeType.IngredientSupplier)
                              .map((node) => (
                                <div
                                  key={node.id}
                                  className={cn('grid grid-cols-[2fr_1fr_2fr_1fr_1fr_40px] gap-3 py-3 print:grid', {
                                    hidden: !expanded.includes(`${NodeType.Ingredient}_${ingredientNode.id}`),
                                  })}
                                >
                                  <div className='col-span-2' />
                                  <div className='pl-7'>{node.supplier.name}</div>
                                  <div>{roundToShort(node.splitPercent)}%</div>
                                  <div>{node.location.name}</div>
                                  <div />
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {totalPackagings > 0 && (
                <>
                  {renderSectionTitle(NodeType.Packaging, totalPackagings)}
                  <div className={cn('text-sm border-x print:block', bottomShadow, { hidden: !expanded.includes(NodeType.Packaging) })}>
                    <div
                      className={cn(
                        'grid grid-cols-[2fr_1fr_2fr_2fr_1fr_1fr_40px] gap-x-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                        upperShadow,
                      )}
                    >
                      <div className='pl-6'>Name</div>
                      <div>total amount</div>
                      <div>composition</div>
                      <div>supplier(s)</div>
                      <div>split</div>
                      <div>country</div>
                      <div />
                    </div>
                    <div className='divide-y'>
                      {getPackagingsFromAll(nodes).map((packagingNode) => (
                        <div key={packagingNode.id}>
                          <div
                            onClick={() => handleExpandedState(`${NodeType.Packaging}_${packagingNode.id}`)}
                            className={cn('grid grid-cols-[2fr_1fr_2fr_2fr_1fr_1fr_40px] gap-x-3 items-center gap-3 cursor-pointer')}
                          >
                            <div className={cn('pl-6 py-3')}>{packagingNode.packaging.name}</div>
                            <div className='py-3'>{renderValueWithAmount(packagingNode.amount.value, packagingNode.amount.unit.name)}</div>
                            <ul className='py-3 flex flex-col gap-2'>
                              {packagingNode.materials.map((material) => (
                                <li key={material.id} className='list-disc'>
                                  {`${material.compositionPercent}% ${material.subType.name} (${material.name})`}
                                </li>
                              ))}
                            </ul>
                            <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                              <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                {packagingNode.nodes.filter((node) => node.type === NodeType.PackagingSupplier).length}
                              </div>
                              Suppliers
                            </div>
                            <div className='col-span-2' />
                            <div className='flex self-center justify-self-center text-zinc-500 pr-6 print:hidden'>
                              <FontAwesomeIcon
                                size='lg'
                                className={cn({ 'rotate-90': expanded.includes(`${NodeType.Packaging}_${packagingNode.id}`) })}
                                icon={light('circle-chevron-right')}
                              />
                            </div>
                          </div>

                          {packagingNode.nodes
                            .filter((node) => node.type === NodeType.PackagingSupplier)
                            .map((node) => (
                              <div
                                key={node.id}
                                className={cn('grid grid-cols-[2fr_1fr_2fr_2fr_1fr_1fr_40px] gap-3 py-3 print:grid', {
                                  hidden: !expanded.includes(`${NodeType.Packaging}_${packagingNode.id}`),
                                })}
                              >
                                <div className='col-span-3' />
                                <div className='pl-[29px]'>{node.supplier.name}</div>
                                <div>{roundToShort(node.splitPercent)}%</div>
                                <div>{node.location.name}</div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              <div>
                {(() => {
                  const ingredientsAndPackagings = [...getIngredientsFromAll(nodes), ...getPackagingsFromAll(nodes)];
                  const transports = getTransportsFromAll(nodes);
                  const suppliers = getRawMaterialSuppliersFromAll(nodes);
                  const productionsAndWarehouses = [...getProductionFacilitiesFromAll(nodes), ...getProductionWarehousesFromAll(nodes)];

                  const transportLinks = ingredientsAndPackagings.map((ingredientOrPackaging) => ({
                    ingredientOrPackaging,
                    suppliers: suppliers
                      .filter(({ id }) => ingredientOrPackaging.nodes.map((item) => ({ ...item })).find((node) => node.id === id))
                      .map((supplier) => ({
                        ...supplier,
                        consumers: transports
                          .filter((item) => supplier.edges.find((id) => id === item.id))
                          .map((transport) => ({
                            ...transport,
                            to: productionsAndWarehouses.filter((location) => transport.edges.includes(location.id)),
                          })),
                      })),
                  }));

                  const totalTransportLinks = transportLinks.flatMap((entity) =>
                    entity.suppliers.flatMap((supplier) => supplier.consumers.flatMap((consumer) => consumer)),
                  ).length;

                  return (
                    totalTransportLinks > 0 && (
                      <>
                        {renderSectionTitle(NodeType.Transport, totalTransportLinks, 'raw_materials')}
                        <div
                          className={cn('text-sm border-x print:block', bottomShadow, {
                            hidden: !expanded.includes(`raw_materials_${NodeType.Transport}`),
                          })}
                        >
                          <div
                            className={cn(
                              'grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_2fr_40px] gap-x-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                              upperShadow,
                            )}
                          >
                            <div className='pl-6'>from</div>
                            <div>to</div>
                            <div>transported good</div>
                            <div>conditions</div>
                            <div>supplier</div>
                            <div>legs</div>
                            <div>distance</div>
                            <div>mode of transport</div>
                            <div />
                          </div>
                          <div className='divide-y'>
                            {transportLinks.map((entity) =>
                              entity.suppliers.map((supplier) =>
                                supplier.consumers.map((consumer) =>
                                  consumer.to.map((location) => (
                                    <div key={location.id}>
                                      <div
                                        onClick={() =>
                                          consumer.legs.length > 1 &&
                                          handleExpandedState(
                                            `raw_materials_${NodeType.Transport}_${supplier.id}_${consumer.id}_${location.id}`,
                                          )
                                        }
                                        key={location.id}
                                        className={cn('grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_2fr_40px] gap-3 items-center py-3', {
                                          'cursor-pointer': consumer.legs.length > 1,
                                        })}
                                      >
                                        <div className='pl-6'>{supplier.displayName}</div>
                                        <div>{location.displayName}</div>
                                        <div>{entity.ingredientOrPackaging.displayName}</div>
                                        <div>{consumer.conservation ? consumer.conservation.name : '—'}</div>
                                        <div>{supplier.displayName}</div>
                                        <div className='flex gap-1 mr-auto rounded-full border border-zinc-300 pl-1 pr-2 py-1'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {consumer.legs.length}
                                          </div>
                                          Legs
                                        </div>
                                        <div>
                                          {consumer.legs.length > 0
                                            ? `${consumer.legs.map((leg) => leg.distance).reduce((a, b) => a + b, 0)}km`
                                            : '—'}
                                        </div>
                                        <div>
                                          {consumer.legs.length === 0 && '—'}
                                          {consumer.legs.length > 1 &&
                                          consumer.legs.find((leg, i, arr) => arr.find(({ mode }) => leg.mode.name !== mode.name))
                                            ? 'Mixed'
                                            : consumer.legs[0]?.mode.name}
                                        </div>
                                        <div className='flex self-center justify-self-center text-zinc-500'>
                                          <FontAwesomeIcon
                                            size='lg'
                                            className={cn({
                                              invisible: consumer.legs.length < 2,
                                              'rotate-90': expanded.includes(
                                                `raw_materials_${NodeType.Transport}_${supplier.id}_${consumer.id}_${location.id}`,
                                              ),
                                            })}
                                            icon={light('circle-chevron-right')}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={cn('print:block', {
                                          hidden: !expanded.includes(
                                            `raw_materials_${NodeType.Transport}_${supplier.id}_${consumer.id}_${location.id}`,
                                          ),
                                        })}
                                      >
                                        {consumer.legs.map((leg, i) => (
                                          <div
                                            key={i}
                                            className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_2fr_40px] gap-3 items-center py-3'
                                          >
                                            <div className='col-span-5' />
                                            <div className='pl-7'>Leg {i + 1}</div>
                                            <div>{leg.distance}km</div>
                                            <div>{leg.mode.name}</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )),
                                ),
                              ),
                            )}
                          </div>
                        </div>
                      </>
                    )
                  );
                })()}
              </div>
            </div>

            {(() => {
              const productionFacilityNodes = getProductionFacilitiesFromAll(nodes);
              const productionWarehouseNodes = getProductionWarehousesFromAll(nodes);

              return (
                <div className='flex flex-col gap-4 rounded-regular shadow-regular p-8'>
                  <div className='text-xl font-semibold'>Production</div>
                  {productionFacilityNodes.length > 0 && (
                    <>
                      {renderSectionTitle(NodeType.Production, productionFacilityNodes.length)}
                      <div
                        className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                          hidden: !expanded.includes(NodeType.Production),
                        })}
                      >
                        <div className={cn('grid grid-cols-4 gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50', upperShadow)}>
                          <div className='pl-6'>name</div>
                          <div>country</div>
                          <div>address</div>
                          <div>production steps</div>
                        </div>
                        <div className='divide-y'>
                          {productionFacilityNodes.map((node) => (
                            <div key={node.id}>
                              <div
                                onClick={() => handleExpandedState(`${NodeType.Production}_${node.id}`)}
                                className='grid grid-cols-4 gap-3 cursor-pointer items-center py-3'
                              >
                                <div className='flex gap-2 items-center pl-6'>
                                  <FontAwesomeIcon
                                    className={cn('w-2 aspect-square transition-all print:hidden', {
                                      'rotate-90': expanded.includes(`${NodeType.Production}_${node.id}`),
                                    })}
                                    icon={regular('chevron-right')}
                                  />
                                  <div className='flex items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                    {node.displayName}
                                    <Link
                                      to={routes.manage.facilities.details('all', node.facility.id)}
                                      target='_blank'
                                      onClick={(e) => e.stopPropagation()}
                                      className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                    >
                                      <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                    </Link>
                                  </div>
                                </div>
                                <div>{node.facility.location.name}</div>
                                <div>{node.facility.address?.text}</div>
                                <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 bg-rose-50 rounded-md'>
                                  <div className='flex items-center justify-center h-5 aspect-square bg-white rounded-full text-rose-900 text-xs'>
                                    {node.steps.length}
                                  </div>
                                  Production steps
                                </div>
                              </div>

                              <div
                                className={cn('divide-y ml-16 mr-10 print:m-0 print:block', {
                                  hidden: !expanded.includes(`${NodeType.Production}_${node.id}`),
                                })}
                              >
                                <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] items-center gap-x-3 text-xs uppercase text-zinc-500 py-3 pl-3 bg-slate-50'>
                                  <div>process</div>
                                  <div>input name/type</div>
                                  <div>amount</div>
                                  <div>output type</div>
                                  <div>name</div>
                                  <div>type</div>
                                  <div>destination</div>
                                  <div>amount</div>
                                  <div />
                                </div>
                                {node.steps.map((step) => {
                                  const needsMandatoryWastewater = !!step.process?.overrides?.water;

                                  return (
                                    <div className='pl-3' key={step.id}>
                                      <div
                                        className={cn('grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] items-center gap-3 py-3', {
                                          'cursor-pointer': step.inputs.length + step.outputs.length > 0,
                                        })}
                                        onClick={() => handleExpandedState(`${NodeType.Production}_${node.id}_${step.id}`)}
                                      >
                                        <div className='flex items-center gap-2'>
                                          <div
                                            className={cn(
                                              'flex justify-center items-center rounded-md h-7 aspect-square',
                                              step.type === NodeType.ProductionIngredient ? 'bg-[#FFF7ED]' : 'bg-[#FEFCE8]',
                                            )}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                step.type === NodeType.ProductionIngredient
                                                  ? duotone('cauldron')
                                                  : duotone('conveyor-belt-boxes')
                                              }
                                              className={cn('h-5 aspect-square relative', {
                                                'bottom-0.5': step.type === NodeType.ProductionIngredient,
                                                'text-[#7C2D12]': step.type === NodeType.ProductionIngredient,
                                                'text-[#713F12]': step.type === NodeType.ProductionPackaging,
                                              })}
                                            />
                                          </div>
                                          {step.displayName}
                                        </div>
                                        <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {step.inputs.length}
                                          </div>
                                          Inputs
                                        </div>

                                        <div />
                                        <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {step.outputs.length + (step.finalStep ? 1 : 0) + (needsMandatoryWastewater ? 1 : 0)}
                                          </div>
                                          Outputs
                                        </div>
                                        <div className='col-span-4' />
                                        <div className='flex self-center justify-self-center text-zinc-500 print:hidden'>
                                          <FontAwesomeIcon
                                            size='lg'
                                            className={cn({
                                              invisible: step.inputs.length + step.outputs.length === 0,
                                              'rotate-90': expanded.includes(`${NodeType.Production}_${node.id}_${step.id}`),
                                            })}
                                            icon={light('circle-chevron-right')}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={cn('print:block', {
                                          hidden: !expanded.includes(`${NodeType.Production}_${node.id}_${step.id}`),
                                        })}
                                      >
                                        <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] gap-3 uppercase text-xs font-semibold bg-slate-50'>
                                          <div className='bg-white'></div>
                                          <div className='col-span-7 py-3'>required</div>
                                          <div className='bg-white'></div>
                                        </div>

                                        {step.outputs.find((output) => output.emission) && (
                                          <>
                                            <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px]'>
                                              <div className='col-span-3' />
                                              <div className='col-span-5 mt-3'>
                                                <div className='flex flex-col gap-1 text-xs bg-amber-50 border-amber-400 rounded-lg border p-2'>
                                                  <div className='flex gap-2 items-center uppercase font-semibold'>
                                                    <div>
                                                      <FontAwesomeIcon className='text-base' icon={regular('memo-circle-info')} />
                                                    </div>
                                                    <div>Incomplete assessment</div>
                                                  </div>
                                                  <div>
                                                    Emissions captured in this product were not included in the impact calculation. This is
                                                    a known limitation of our system and we will be working to add this capability to our
                                                    LCA calculator soon. Since you’ve already captured them, you won’t have to do a thing
                                                    though and we will automatically recalculate this product as soon as we are able to with
                                                    the correct outputs specified. They will automatically become visible in the results
                                                    presented in this section.
                                                  </div>
                                                </div>
                                              </div>
                                              <div />
                                            </div>
                                          </>
                                        )}

                                        <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center'>
                                          {(() => {
                                            const inputsCount = step.inputs.length;
                                            const outputsCount =
                                              step.outputs.length + (step.finalStep ? 1 : 0) + (step.process?.overrides?.water ? 1 : 0);

                                            const rowsCount = inputsCount > outputsCount ? inputsCount : outputsCount;

                                            const allOutputs = [
                                              ...step.outputs,
                                              ...(step.finalStep
                                                ? [
                                                    {
                                                      outputType: {
                                                        name: 'Final product',
                                                      },
                                                      name: props.data.product.name,
                                                      amount: props.data.product.bruttoAmount,
                                                    } as StepOutput,
                                                  ]
                                                : []),
                                              ...(step.process?.overrides?.water
                                                ? [
                                                    {
                                                      outputType: {
                                                        name: lists.outputTypes.find(({ type }) => type === OutputType.Waste)?.name,
                                                      },
                                                      name: lists.wasteTypes
                                                        .flatMap(({ subTypes }) => subTypes)
                                                        .find(({ type }) => type === WasteSubType.Wastewater)?.name,
                                                      waste: {
                                                        name: lists.wasteTypes.find(({ type }) => type === WasteType.Liquid)!.name,
                                                      },
                                                      amount: {
                                                        value: step.process.overrides.water.output.value,
                                                        unit: lists.wasteTypes.find(({ type }) => type === WasteType.Liquid)!.unit,
                                                      },
                                                    } as StepOutput,
                                                  ]
                                                : []),
                                            ];

                                            return new Array(rowsCount).fill(null).map((_, i) => {
                                              const input = step.inputs.length - 1 >= i ? step.inputs[i] : undefined;
                                              const output = allOutputs.length - 1 >= i ? allOutputs[i] : undefined;

                                              const ingredientsAndPackagings = [
                                                ...getIngredientsFromAll(nodes),
                                                ...getPackagingsFromAll(nodes),
                                              ];

                                              const ingredientOrPackagingInput = ingredientsAndPackagings.find(
                                                ({ id }) => input?.id === id,
                                              );

                                              const intermediate = getOutputsFromAll(nodes).find(({ id }) => input?.id === id);

                                              return (
                                                <Fragment key={i}>
                                                  <div />
                                                  {(() => {
                                                    if (ingredientOrPackagingInput) {
                                                      return (
                                                        <>
                                                          <div className='py-3'>{ingredientOrPackagingInput.displayName}</div>
                                                          {renderValueWithAmount(
                                                            ingredientOrPackagingInput.amount.value,
                                                            ingredientOrPackagingInput.amount.unit.name,
                                                          )}
                                                        </>
                                                      );
                                                    } else if (intermediate) {
                                                      return (
                                                        <>
                                                          <div className='py-3'>{intermediate.name}</div>
                                                          {renderValueWithAmount(
                                                            intermediate.amount?.value,
                                                            intermediate.amount?.unit.name,
                                                          )}
                                                        </>
                                                      );
                                                    } else {
                                                      return <div className='col-span-2'></div>;
                                                    }
                                                  })()}
                                                  <div className='py-3'>{output?.outputType.name}</div>

                                                  <div className='py-3'>{output?.name}</div>
                                                  <div className='py-3'>{output?.waste?.name}</div>
                                                  <div className='py-3'>
                                                    {output?.waste &&
                                                      [WasteType.Packaging, WasteType.Solid].includes(output.waste.type) &&
                                                      (output.waste as PackagingWaste | SolidWaste)?.destination?.name}
                                                  </div>
                                                  {renderValueWithAmount(output?.amount?.value, output?.amount?.unit?.name)}
                                                  <div />
                                                </Fragment>
                                              );
                                            });
                                          })()}
                                        </div>

                                        {(() => {
                                          const auxiliaries = step.process.overrides.auxiliaries.map((auxiliary) => ({
                                            ...auxiliary,
                                            unit: lists.processes
                                              .find(({ id }) => step.process.id === id)
                                              ?.overrides?.auxiliaries?.find(({ type }) => type === auxiliary.type)?.unit,
                                          }));
                                          const gas = step.process.overrides.gas
                                            ? {
                                                ...step.process.overrides.gas,
                                                unit: lists.processes.find(({ id }) => step.process.id === id)?.overrides!.gas!.unit,
                                              }
                                            : undefined;

                                          const water = step.process.overrides.water?.input?.value
                                            ? {
                                                ...step.process.overrides.water,
                                                unit: lists.processes.find(({ id }) => step.process.id === id)?.overrides!.water!.input
                                                  .unit,
                                              }
                                            : undefined;
                                          const electricity = step.process.overrides.electricity
                                            ? {
                                                ...step.process.overrides.electricity,
                                                unit: lists.processes.find(({ id }) => step.process.id === id)?.overrides!.electricity!
                                                  .unit,
                                              }
                                            : undefined;

                                          const anyOptional =
                                            auxiliaries.length > 0 ||
                                            gas ||
                                            water?.input?.value ||
                                            (electricity && electricity.types.length > 0);

                                          if (anyOptional) {
                                            return (
                                              <Fragment>
                                                <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] gap-3 uppercase text-xs font-semibold bg-slate-50'>
                                                  <div className='bg-white'></div>
                                                  <div className='py-3 col-span-7'>optional</div>
                                                  <div className='bg-white'></div>
                                                </div>
                                                <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center mb-3'>
                                                  {electricity && electricity.types.length > 0 && (
                                                    <Fragment>
                                                      <div></div>
                                                      <div className='py-3'>Electricity</div>
                                                      <div className='col-span-7'>
                                                        {renderValueWithAmount(electricity.value, electricity.unit?.name)}
                                                      </div>

                                                      {electricity.types.map((type) => (
                                                        <Fragment key={type.type}>
                                                          <div />
                                                          <div className='ml-1 py-2 pl-3 border-l'>{type.name}</div>
                                                          <div className='col-span-7'>{type.percent}%</div>
                                                        </Fragment>
                                                      ))}
                                                    </Fragment>
                                                  )}

                                                  {gas && (
                                                    <Fragment>
                                                      <div />
                                                      <div className='py-3'>Gas</div>
                                                      <div className='col-span-7'>{renderValueWithAmount(gas.value, gas.unit?.name)}</div>
                                                    </Fragment>
                                                  )}

                                                  {water && (
                                                    <Fragment>
                                                      <div />
                                                      <div className='py-3'>Water</div>
                                                      <div className='col-span-7'>
                                                        {renderValueWithAmount(water.input.value, water.unit?.name)}
                                                      </div>
                                                    </Fragment>
                                                  )}

                                                  {auxiliaries.length > 0 && (
                                                    <Fragment>
                                                      <div />
                                                      <div className='py-3 col-span-8'>Auxiliary input</div>

                                                      {auxiliaries.map((auxiliary) => (
                                                        <Fragment key={auxiliary.type}>
                                                          <div />
                                                          <div className='ml-1 py-2 pl-3 border-l'>{auxiliary.name}</div>
                                                          <div className='col-span-7'>
                                                            {renderValueWithAmount(auxiliary.value, auxiliary.unit?.name)}
                                                          </div>
                                                        </Fragment>
                                                      ))}
                                                    </Fragment>
                                                  )}
                                                </div>
                                              </Fragment>
                                            );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) && (
                    <>
                      {productionWarehouseNodes.length > 0 && (
                        <>
                          {renderSectionTitle(NodeType.ProductionWarehouse, productionWarehouseNodes.length)}
                          <div
                            className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                              hidden: !expanded.includes(NodeType.ProductionWarehouse),
                            })}
                          >
                            <div
                              className={cn(
                                'grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-x-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                                upperShadow,
                              )}
                            >
                              <div className='pl-6'>name</div>
                              <div>country</div>
                              <div>address</div>
                              <div>stored items</div>
                              <div></div>
                            </div>
                            <div className='divide-y'>
                              {productionWarehouseNodes.map((node, i) => (
                                <div key={i}>
                                  <div
                                    onClick={() => handleExpandedState(`${NodeType.ProductionWarehouse}_${node.id}`)}
                                    className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-x-3 cursor-pointer items-center py-3'
                                  >
                                    <div className='flex gap-2 items-center pl-6'>
                                      <FontAwesomeIcon
                                        className={cn('w-2 aspect-square transition-all print:hidden', {
                                          'rotate-90': expanded.includes(`${NodeType.ProductionWarehouse}_${node.id}`),
                                        })}
                                        icon={regular('chevron-right')}
                                      />
                                      <div className='flex items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                        {node.displayName}
                                        <Link
                                          to={routes.manage.facilities.details('all', node.facility.id)}
                                          target='_blank'
                                          onClick={(e) => e.stopPropagation()}
                                          className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                        >
                                          <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                        </Link>
                                      </div>
                                    </div>
                                    <div>{node.facility.location?.name}</div>
                                    <div>{node.facility.address?.text}</div>
                                    <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 bg-fuchsia-50 rounded-md'>
                                      <div className='flex items-center justify-center h-5 aspect-square bg-white rounded-full text-rose-900 text-xs'>
                                        {node.items.length}
                                      </div>
                                      Stored items
                                    </div>
                                    <div></div>
                                  </div>

                                  <div
                                    className={cn('print:block', {
                                      hidden: !expanded.includes(`${NodeType.ProductionWarehouse}_${node.id}`),
                                    })}
                                  >
                                    <div className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center text-xs uppercase text-zinc-500 bg-slate-50'>
                                      <div className='bg-white h-full'></div>
                                      <div className='py-3 pl-3'>item name</div>
                                      <div className='py-3'>amount</div>
                                      <div className='py-3'>type</div>
                                      <div className='py-3'>storage conditions</div>
                                      <div className='py-3'>storage duration</div>
                                    </div>

                                    {node.items.map((item) => (
                                      <div key={item.id} className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center'>
                                        <div className='bg-white h-full'></div>
                                        <div className='py-3 pl-3'>{item.name}</div>
                                        <div className='py-3'>{renderValueWithAmount(item.amount.value, item.amount.unit.name)}</div>
                                        <div className='py-3'>{item.type}</div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${node.id}/items/${item.id}/conservation`}
                                          >
                                            {item.conservation.name}
                                          </DefaultableValue>
                                        </div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${node.id}/items/${item.id}/durationWeeks`}
                                          >
                                            {item.durationWeeks} weeks
                                          </DefaultableValue>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {(() => {
                        const productionNodes = [...getProductionFacilitiesFromAll(nodes), ...getProductionWarehousesFromAll(nodes)];

                        const distributionNodes = [
                          ...nodes.filter(({ type }) => type === NodeType.Store),
                          ...nodes.filter(({ type }) => type === NodeType.FinalDestination),
                          ...nodes.filter(({ type }) => type === NodeType.Warehouse),
                        ];

                        const transportLinks = getTransportsFromAll(nodes)
                          .filter((transport) => productionNodes.find((node) => node.edges.find((edge) => edge === transport.id)))
                          .map((transportLink) => ({
                            ...transportLink,
                            from: productionNodes.find((facility) => facility.edges.includes(transportLink.id)),
                            to: [...distributionNodes, ...productionNodes].find(({ id }) => transportLink.edges.includes(id)),
                            supplier: transportLink.supplier,
                            items:
                              transportLink.items.length > 0
                                ? transportLink.items.map((item) => nodes.find(({ id }) => item.id === id)?.displayName)
                                : ['Final product'],
                          }));

                        return (
                          transportLinks.length > 0 && (
                            <>
                              {renderSectionTitle(NodeType.Transport, transportLinks.length, 'production')}
                              <div
                                className={cn('text-sm border-x print:block', bottomShadow, {
                                  hidden: !expanded.includes(`production_${NodeType.Transport}`),
                                })}
                              >
                                <div
                                  className={cn(
                                    'grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                                    upperShadow,
                                  )}
                                >
                                  <div className='pl-3'>from</div>
                                  <div>to</div>
                                  <div>transported good</div>
                                  <div>conditions</div>
                                  <div>supplier</div>
                                  <div>legs</div>
                                  <div>distance</div>
                                  <div>mode of transport</div>
                                  <div></div>
                                </div>
                                <div className='divide-y'>
                                  {transportLinks.map((link, i) => (
                                    <div key={i}>
                                      <div
                                        className={cn('grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center py-3', {
                                          'cursor-pointer': link.legs.length > 1,
                                        })}
                                        onClick={() =>
                                          link.legs.length > 1 && handleExpandedState(`production_${NodeType.Transport}_${link.id}`)
                                        }
                                      >
                                        <div title={link.from?.displayName} className='pl-3 break-words truncate'>
                                          {link.from?.displayName}
                                        </div>
                                        <div title={link.to?.displayName} className='truncate'>
                                          {link.to?.displayName}
                                        </div>
                                        <div>{link.items.join(', ')}</div>
                                        <div>{link.conservation ? link.conservation.name : '—'}</div>
                                        <div>{link.supplier.name}</div>
                                        <div className='flex gap-1 mr-auto rounded-full border border-zinc-300 pl-1 pr-2 py-1'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {link.legs.length}
                                          </div>
                                          Legs
                                        </div>
                                        <div>
                                          {link.legs.length > 0
                                            ? `${link.legs.map((leg) => leg.distance).reduce((a, b) => a + b, 0)}km`
                                            : '—'}
                                        </div>
                                        <div>
                                          {(() => {
                                            if (link.legs.length === 0) {
                                              return '—';
                                            }

                                            if (link.legs.length > 0) {
                                              return link.legs.find((leg, i, arr) => arr.find(({ mode }) => leg.mode.name !== mode.name))
                                                ? 'Mixed'
                                                : link.legs[0].mode.name;
                                            }
                                          })()}
                                        </div>

                                        <div className='flex self-center justify-self-center text-zinc-500 print:hidden'>
                                          <FontAwesomeIcon
                                            size='lg'
                                            className={cn({
                                              invisible: link.legs.length < 2,
                                              'rotate-90': expanded.includes(`production_${NodeType.Transport}_${link.id}`),
                                            })}
                                            icon={light('circle-chevron-right')}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={cn('print:block', {
                                          hidden: !expanded.includes(`production_${NodeType.Transport}_${link.id}`),
                                        })}
                                      >
                                        {link.legs.map((leg, i) => (
                                          <div
                                            key={i}
                                            className={cn('grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center')}
                                          >
                                            <div className='col-span-5' />
                                            <div className='pl-[29px] py-3'>Leg {i + 1}</div>
                                            <div>{leg.distance}km</div>
                                            <div>{leg.mode.name}</div>
                                            <div></div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          )
                        );
                      })()}
                    </>
                  )}
                </div>
              );
            })()}

            {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) && (
              <>
                {(() => {
                  const warehouses = nodes.filter(({ type }) => NodeType.Warehouse === type) as WarehouseNode[];

                  const stores = nodes.filter(({ type }) => NodeType.Store === type) as StoreNode[];

                  const finalDestinationNodes = nodes.filter(({ type }) => NodeType.FinalDestination === type) as FinalDestinationNode[];

                  const distributionNodes = [
                    ...nodes.filter(({ type }) => type === NodeType.Store),
                    ...nodes.filter(({ type }) => type === NodeType.FinalDestination),
                    ...nodes.filter(({ type }) => type === NodeType.Warehouse),
                  ];

                  const disposalNodes = getDisposalsFromAll(nodes);
                  const consumptionNodes = getConsumptionLocationsFromAll(nodes);

                  const transportLinks = getTransportsFromAll(nodes)
                    .filter((transport) => distributionNodes.find((facility) => facility.edges.find((edge) => edge === transport.id)))
                    .map((transportLink) => ({
                      ...transportLink,
                      from: distributionNodes.find((facility) => facility.edges.includes(transportLink.id))!,
                      to: [...distributionNodes, ...disposalNodes, ...consumptionNodes].find(({ id }) => transportLink.edges.includes(id))!,
                      supplierName: transportLink.supplier?.name,
                      items:
                        transportLink.items.length > 0
                          ? transportLink.items.map((item) => nodes.find(({ id }) => item.id === id)!.displayName)
                          : ['Final product'],
                    }));

                  return (
                    <div className='flex flex-col gap-4 rounded-regular shadow-regular p-8'>
                      <div className='text-xl font-semibold'>Distribution</div>
                      {warehouses.length > 0 && (
                        <>
                          {renderSectionTitle(NodeType.Warehouse, warehouses.length, 'distribution')}
                          <div
                            className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                              hidden: !expanded.includes(`distribution_${NodeType.Warehouse}`),
                            })}
                          >
                            <div
                              className={cn(
                                'grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                                upperShadow,
                              )}
                            >
                              <div className='pl-6'>name</div>
                              <div>country</div>
                              <div>address</div>
                              <div>stored items</div>
                              <div></div>
                            </div>
                            <div className='divide-y'>
                              {warehouses.map((warehouse, i) => (
                                <div key={i}>
                                  <div
                                    onClick={() => handleExpandedState(`distribution_${NodeType.Warehouse}_${warehouse.id}`)}
                                    className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-x-3 cursor-pointer items-center py-3'
                                  >
                                    <div className='flex gap-2 items-center pl-6'>
                                      <FontAwesomeIcon
                                        className={cn('w-2 aspect-square transition-all print:hidden', {
                                          'rotate-90': expanded.includes(`distribution_${NodeType.Warehouse}_${warehouse.id}`),
                                        })}
                                        icon={regular('chevron-right')}
                                      />
                                      <div className='flex items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                        {warehouse.displayName}
                                        <Link
                                          to={routes.manage.facilities.details('all', warehouse.facility.id)}
                                          target='_blank'
                                          onClick={(e) => e.stopPropagation()}
                                          className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                        >
                                          <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                        </Link>
                                      </div>
                                    </div>
                                    <div>{warehouse.facility.location.name}</div>
                                    <div>{warehouse.facility.address?.text}</div>
                                    <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 bg-fuchsia-50 rounded-md'>
                                      <div className='flex items-center justify-center h-5 aspect-square bg-white rounded-full text-rose-900 text-xs'>
                                        {warehouse.items.length}
                                      </div>
                                      Stored items
                                    </div>
                                    <div></div>
                                  </div>

                                  <div
                                    className={cn('print:block', {
                                      hidden: !expanded.includes(`distribution_${NodeType.Warehouse}_${warehouse.id}`),
                                    })}
                                  >
                                    <div className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center text-xs uppercase text-zinc-500 bg-slate-50'>
                                      <div className='bg-white h-full'></div>
                                      <div className='py-3 pl-3'>item name</div>
                                      <div className='py-3'>amount</div>
                                      <div className='py-3'>type</div>
                                      <div className='py-3'>storage conditions</div>
                                      <div className='py-3'>storage duration</div>
                                    </div>

                                    {warehouse.items.map((item) => (
                                      <div key={item.id} className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center'>
                                        <div className='bg-white h-full'></div>
                                        <div className='py-3 pl-3'>{item.name}</div>
                                        <div className='py-3'>{renderValueWithAmount(item.amount.value, item.amount.unit.name)}</div>
                                        <div className='py-3'>{item.type}</div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${warehouse.id}/items/${item.id}/conservation`}
                                          >
                                            {item.conservation.name}
                                          </DefaultableValue>
                                        </div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${warehouse.id}/items/${item.id}/durationWeeks`}
                                          >
                                            {item.durationWeeks} weeks
                                          </DefaultableValue>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {stores.length > 0 && (
                        <>
                          {renderSectionTitle(NodeType.Store, stores.length, 'distribution')}
                          <div
                            className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                              hidden: !expanded.includes(`distribution_${NodeType.Store}`),
                            })}
                          >
                            <div
                              className={cn(
                                'grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                                upperShadow,
                              )}
                            >
                              <div className='pl-6'>name</div>
                              <div>country</div>
                              <div>address</div>
                              <div>stored items</div>
                              <div></div>
                            </div>
                            <div className='divide-y'>
                              {stores.map((store, storeIndex) => (
                                <div key={storeIndex}>
                                  <div
                                    onClick={() => handleExpandedState(`distribution_${NodeType.Store}_${store.id}`)}
                                    className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr] gap-x-3 cursor-pointer items-center py-3'
                                  >
                                    <div className='flex gap-2 items-center pl-6'>
                                      <FontAwesomeIcon
                                        className={cn('w-2 aspect-square transition-all print:hidden', {
                                          'rotate-90': expanded.includes(`distribution_${NodeType.Store}_${store.id}`),
                                        })}
                                        icon={regular('chevron-right')}
                                      />
                                      <div className='flex items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                        {store.displayName}
                                        <Link
                                          to={routes.manage.facilities.details('all', store.facility.id)}
                                          target='_blank'
                                          onClick={(e) => e.stopPropagation()}
                                          className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                        >
                                          <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                        </Link>
                                      </div>
                                    </div>
                                    <div>{store.facility.location.name}</div>
                                    <div>{store.facility.address?.text}</div>
                                    <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 bg-fuchsia-50 rounded-md'>
                                      <div className='flex items-center justify-center h-5 aspect-square bg-white rounded-full text-rose-900 text-xs'>
                                        {store.items.length}
                                      </div>
                                      Stored items
                                    </div>
                                    <div></div>
                                  </div>

                                  <div
                                    className={cn('print:block', {
                                      hidden: !expanded.includes(`distribution_${NodeType.Store}_${store.id}`),
                                    })}
                                  >
                                    <div className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center text-xs uppercase text-zinc-500 bg-slate-50'>
                                      <div className='bg-white h-full'></div>
                                      <div className='py-3 pl-3'>item name</div>
                                      <div className='py-3'>amount</div>
                                      <div className='py-3'>type</div>
                                      <div className='py-3'>storage conditions</div>
                                      <div className='py-3'>storage duration</div>
                                    </div>

                                    {store.items.map((item) => (
                                      <div key={item.id} className='grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-x-3 items-center'>
                                        <div className='bg-white h-full'></div>
                                        <div className='py-3 pl-3'>{item.name}</div>
                                        <div className='py-3'>{renderValueWithAmount(item.amount.value, item.amount.unit.name)}</div>
                                        <div className='py-3'>{item.type}</div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${store.id}/items/${item.id}/conservation`}
                                          >
                                            {item.conservation.name}
                                          </DefaultableValue>
                                        </div>
                                        <div className='py-3'>
                                          <DefaultableValue
                                            metadata={props.data.product.metadata}
                                            path={`nodes/${store.id}/items/${item.id}/durationWeeks`}
                                          >
                                            {item.durationWeeks} weeks
                                          </DefaultableValue>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {finalDestinationNodes.length > 0 && (
                        <>
                          {renderSectionTitle(NodeType.FinalDestination, finalDestinationNodes.length, 'final_destination')}
                          <div
                            className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                              hidden: !expanded.includes(`final_destination_${NodeType.FinalDestination}`),
                            })}
                          >
                            <div className={cn('grid grid-cols-4 gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50', upperShadow)}>
                              <div className='pl-6'>name</div>
                              <div>country</div>
                              <div>address</div>
                              <div></div>
                            </div>

                            <div className='grid grid-cols-4 gap-3 items-center'>
                              {finalDestinationNodes.map((node) => (
                                <Fragment key={node.id}>
                                  <div className='flex py-3 pl-6'>
                                    <div className='flex self-start items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                      {node.displayName}
                                      <Link
                                        to={routes.manage.facilities.details('all', node.facility.id)}
                                        target='_blank'
                                        onClick={(e) => e.stopPropagation()}
                                        className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                      >
                                        <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                      </Link>
                                    </div>
                                  </div>
                                  <div>{node.facility.location.name}</div>
                                  <div>{node.facility.address?.text}</div>
                                  <div></div>
                                </Fragment>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {transportLinks.length > 0 && (
                        <>
                          {renderSectionTitle(NodeType.Transport, transportLinks.length, 'distribution')}
                          <div
                            className={cn('text-sm border-x print:block', bottomShadow, {
                              hidden: !expanded.includes(`distribution_${NodeType.Transport}`),
                            })}
                          >
                            <div
                              className={cn(
                                'grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50',
                                upperShadow,
                              )}
                            >
                              <div className='pl-3'>from</div>
                              <div>to</div>
                              <div>transported good</div>
                              <div>conditions</div>
                              <div>supplier</div>
                              <div>legs</div>
                              <div>distance</div>
                              <div>mode of transport</div>
                              <div></div>
                            </div>
                            <div className='divide-y'>
                              {transportLinks.map((link, i) => (
                                <div key={i}>
                                  <div
                                    className={cn('grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center py-3', {
                                      'cursor-pointer': link.legs.length > 1,
                                    })}
                                    onClick={() =>
                                      link.legs.length > 1 && handleExpandedState(`distribution_${NodeType.Transport}_${link.id}`)
                                    }
                                  >
                                    <div title={link.from?.displayName} className='pl-3 break-words truncate'>
                                      {link.from?.displayName}
                                    </div>
                                    <div title={link.to?.displayName} className='truncate'>
                                      {link.to?.displayName}
                                    </div>
                                    <div className='flex'>{link.items.join(', ')}</div>
                                    <div>{link.conservation ? link.conservation.name : '—'}</div>
                                    <div>{link.supplierName}</div>
                                    <div className='flex gap-1 mr-auto rounded-full border border-zinc-300 pl-1 pr-2 py-1'>
                                      <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                        {link.legs.length}
                                      </div>
                                      Legs
                                    </div>
                                    <div>
                                      {link.legs.length > 0 ? `${link.legs.map((leg) => leg.distance).reduce((a, b) => a + b, 0)}km` : '—'}
                                    </div>
                                    <div>
                                      {(() => {
                                        if (link.legs!.length === 0) {
                                          return '—';
                                        }

                                        if (link.legs!.length > 0) {
                                          return link.legs!.find((leg, i, arr) => arr.find(({ mode }) => leg.mode.name !== mode.name))
                                            ? 'Mixed'
                                            : link.legs![0]!.mode.name;
                                        }
                                      })()}
                                    </div>
                                    <div className='flex self-center justify-self-center text-zinc-500 print:hidden'>
                                      <FontAwesomeIcon
                                        size='lg'
                                        className={cn({
                                          invisible: link.legs!.length < 2,
                                          'rotate-90': expanded.includes(`distribution_${NodeType.Transport}_${link.id}`),
                                        })}
                                        icon={light('circle-chevron-right')}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className={cn('print:block', {
                                      hidden: !expanded.includes(`distribution_${NodeType.Transport}_${link.id}`),
                                    })}
                                  >
                                    {link.legs.map((leg, i) => (
                                      <div
                                        key={i}
                                        className={cn('grid grid-cols-[1fr_1fr_2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center')}
                                      >
                                        <div className='col-span-5' />
                                        <div className='pl-7 py-3'>Leg {i + 1}</div>
                                        <div>{leg.distance}km</div>
                                        <div>{leg.mode.name}</div>
                                        <div></div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })()}

                {(() => {
                  const consumptionLocations = getConsumptionLocationsFromAll(nodes);

                  return (
                    consumptionLocations.length > 0 && (
                      <div className='flex flex-col gap-4 rounded-regular shadow-regular p-8'>
                        <div className='text-xl font-semibold'>Use</div>
                        {renderSectionTitle(NodeType.ConsumptionPreparation, consumptionLocations.length)}
                        <div
                          className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                            hidden: !expanded.includes(`${NodeType.ConsumptionPreparation}`),
                          })}
                        >
                          <div className={cn('grid grid-cols-3 gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50', upperShadow)}>
                            <div className='pl-6'>name</div>
                            <div>country</div>
                            <div>preparation steps</div>
                          </div>
                          <div className='divide-y'>
                            {consumptionLocations.map((facility, i) => (
                              <Fragment key={i}>
                                <div
                                  onClick={() =>
                                    facility.steps.length > 0 && handleExpandedState(`${NodeType.ConsumptionPreparation}_${facility.id}`)
                                  }
                                  className={cn('grid grid-cols-3 gap-3 items-center py-3', {
                                    'cursor-pointer': facility.steps.length > 0,
                                  })}
                                >
                                  <div className='flex gap-2 items-center pl-6'>
                                    <FontAwesomeIcon
                                      className={cn('w-2 aspect-square transition-all print:hidden', {
                                        'rotate-90': expanded.includes(`${NodeType.ConsumptionPreparation}_${facility.id}`),
                                        invisible: facility.steps.length === 0,
                                      })}
                                      icon={regular('chevron-right')}
                                    />
                                    <div className='flex items-center gap-2 bg-slate-50 mr-auto py-1 px-2 rounded-md'>
                                      {facility.displayName}
                                      <Link
                                        to={routes.manage.facilities.details('all', facility.facility.id)}
                                        target='_blank'
                                        onClick={(e) => e.stopPropagation()}
                                        className='flex items-center justify-center outline-slate-300 hover:outline hover:bg-white h-5 aspect-square rounded-md print:hidden'
                                      >
                                        <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                                      </Link>
                                    </div>
                                  </div>
                                  <div>{facility.facility.location.name}</div>
                                  <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 bg-blue-50 rounded-md'>
                                    <div className='flex items-center justify-center h-5 aspect-square bg-white rounded-full text-rose-900 text-xs'>
                                      {facility.steps.length}
                                    </div>
                                    Preparation steps
                                  </div>
                                </div>

                                <div
                                  className={cn('divide-y ml-16 mr-10 print:m-0 print:block', {
                                    hidden: !expanded.includes(`${NodeType.ConsumptionPreparation}_${facility.id}`),
                                  })}
                                >
                                  <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] items-center gap-x-3 text-xs uppercase text-zinc-500 py-3 pl-3 bg-slate-50'>
                                    <div>process</div>
                                    <div>input name/type</div>
                                    <div>amount</div>
                                    <div>output type</div>
                                    <div>name</div>
                                    <div>amount</div>
                                  </div>
                                  {facility.steps.map((step: ConsumptionStepNode & { process: ProductionProcess & any }) => (
                                    <div className='pl-3' key={step.id}>
                                      <div
                                        className={cn('grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] items-center gap-3 py-3', {
                                          'cursor-pointer': step.inputs.length + step.outputs.length > 0,
                                        })}
                                        onClick={() => handleExpandedState(`${NodeType.ConsumptionPreparation}_${facility.id}_${step.id}`)}
                                      >
                                        <div className='flex items-center gap-2'>
                                          <div className={cn('flex justify-center items-center rounded-md h-7 aspect-square bg-cyan-50')}>
                                            <FontAwesomeIcon className='group-hover:invisible text-cyan-900' icon={duotone('pan-frying')} />
                                          </div>
                                          {step.displayName}
                                        </div>
                                        <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {step.inputs.length}
                                          </div>
                                          Inputs
                                        </div>

                                        <div />
                                        <div className='flex gap-1 mr-auto pl-1 pr-2 py-1 rounded-full border border-zinc-300'>
                                          <div className='flex items-center justify-center h-5 aspect-square rounded-full bg-slate-100 text-brand text-xs'>
                                            {step.finalStep ? 1 : step.outputs.length}
                                          </div>
                                          Outputs
                                        </div>
                                        <div className='col-span-2' />
                                        <div className='flex self-center justify-self-center print:hidden'>
                                          <FontAwesomeIcon
                                            size='lg'
                                            className={cn({
                                              invisible: step.inputs.length + step.outputs.length === 0,
                                              'rotate-90': expanded.includes(
                                                `${NodeType.ConsumptionPreparation}_${facility.id}_${step.id}`,
                                              ),
                                            })}
                                            icon={light('circle-chevron-right')}
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={cn('print:block', {
                                          hidden: !expanded.includes(`${NodeType.ConsumptionPreparation}_${facility.id}_${step.id}`),
                                        })}
                                      >
                                        <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-3 uppercase text-xs font-semibold bg-slate-50'>
                                          <div className='bg-white'></div>
                                          <div className='py-3 col-span-5'>required</div>
                                          <div className='bg-white'></div>
                                        </div>

                                        <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center'>
                                          {(() => {
                                            const rowsCount =
                                              step.outputs.length > step.inputs.length ? step.outputs.length : step.inputs.length;

                                            return new Array(rowsCount).fill(null).map((_, i) => {
                                              const input = step.inputs.length - 1 >= i ? step.inputs[i] : undefined;
                                              const output = step.outputs.length - 1 >= i ? step.outputs[i] : undefined;

                                              const inputFromNode = getIngredientsFromAll(nodes).find(({ id }) => input?.id === id);

                                              const inputAsFinalSku =
                                                input?.id === props.data.product.id
                                                  ? {
                                                      displayName: props.data.product.name,
                                                      amount: props.data.product.amount,
                                                    }
                                                  : undefined;

                                              const outputFromInputNode = getOutputsFromAll(nodes).find(({ id }) => input?.id === id);

                                              return (
                                                <Fragment key={i}>
                                                  <div />
                                                  {(() => {
                                                    if (inputAsFinalSku) {
                                                      return (
                                                        <Fragment>
                                                          <div className='py-3'>{inputAsFinalSku.displayName}</div>
                                                          <div className='py-3'>
                                                            {renderValueWithAmount(
                                                              inputAsFinalSku.amount.value,
                                                              inputAsFinalSku.amount.unit.name,
                                                            )}
                                                          </div>
                                                        </Fragment>
                                                      );
                                                    } else if (inputFromNode) {
                                                      return (
                                                        <Fragment>
                                                          <div className='py-3'>{inputFromNode.displayName}</div>
                                                          <div className='py-3'>
                                                            {renderValueWithAmount(
                                                              inputFromNode.amount.value,
                                                              inputFromNode.amount.unit.name,
                                                            )}
                                                          </div>
                                                        </Fragment>
                                                      );
                                                    } else if (outputFromInputNode) {
                                                      return (
                                                        <Fragment>
                                                          <div className='py-3'>{outputFromInputNode.name}</div>
                                                          <div className='py-3'>
                                                            {renderValueWithAmount(
                                                              outputFromInputNode.amount?.value,
                                                              outputFromInputNode.amount?.unit.name,
                                                            )}
                                                          </div>
                                                        </Fragment>
                                                      );
                                                    } else {
                                                      return <div className='col-span-2' />;
                                                    }
                                                  })()}
                                                  <div className='py-3'>{step.finalStep ? 'Final product' : output?.outputType.name}</div>
                                                  <div className='py-3'>{step.finalStep ? props.data.product.name : output?.name}</div>
                                                  <div className='py-3'>
                                                    {step.finalStep
                                                      ? renderValueWithAmount(
                                                          props.data.product.bruttoAmount.value,
                                                          props.data.product.bruttoAmount.unit.name,
                                                        )
                                                      : renderValueWithAmount(output?.amount?.value, output?.amount?.unit.name)}
                                                  </div>
                                                  <div />
                                                </Fragment>
                                              );
                                            });
                                          })()}
                                        </div>

                                        {step.process.duration && (
                                          <>
                                            <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-3 uppercase text-xs font-semibold bg-slate-50'>
                                              <div className='bg-white' />
                                              <div className='py-3 col-span-5'>optional</div>
                                              <div className='bg-white' />
                                            </div>

                                            <div className='grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_40px] gap-x-3 items-center'>
                                              <div></div>
                                              <div className='py-3'>Duration</div>
                                              <div>{step.process.duration} min</div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })()}

                {(() => {
                  const disposals = getDisposalsFromAll(nodes).map((disposal) => ({
                    ...disposal,
                    consumptionLocations: getConsumptionLocationsFromAll(nodes).filter((consumption) =>
                      consumption.edges.includes(disposal.id),
                    ),
                    packagingMaterials: getPackagingsFromAll(nodes).find(({ id }) => id === disposal.packagingNodeId)!.materials,
                  }));

                  return (
                    disposals.length > 0 && (
                      <div className='flex flex-col gap-4 rounded-regular shadow-regular p-8'>
                        <div className='text-xl font-semibold'>End of life</div>
                        {renderSectionTitle(NodeType.Disposal, disposals.length)}
                        <div
                          className={cn('text-sm border-x pb-1 print:block', bottomShadow, {
                            hidden: !expanded.includes(`${NodeType.Disposal}`),
                          })}
                        >
                          <div className={cn('grid grid-cols-4 gap-3 text-xs uppercase text-zinc-500 py-3 bg-slate-50', upperShadow)}>
                            <div className='pl-6'>packaging</div>
                            <div>consumption location</div>
                            <div>composition</div>
                            <div>recyclability</div>
                          </div>

                          <div className='divide-y'>
                            {disposals.map((disposal, i) => (
                              <div key={i} className='grid grid-cols-4 gap-3 py-3'>
                                <div className='pl-6'>{disposal.displayName}</div>
                                <div>{disposal.consumptionLocations.map((location) => location.displayName)}</div>
                                <div>
                                  <ul>
                                    {disposal.packagingMaterials.map((material, i) => (
                                      <div key={i}>{`${material.compositionPercent}% ${material.subType.name} (${material.name})`}</div>
                                    ))}
                                  </ul>
                                </div>
                                <div>{disposal.disposalType.name}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })()}
              </>
            )}
          </>
        );
      })()}
    </>
  );
};
