import cn from 'classnames';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import {
  Facility,
  facilityOptions,
  FacilityType,
  FacilityWithSelectedAgreement,
  getFacilities,
  NodeType,
  ProductState,
  ProductV3,
  StoredItem,
  StoreNode,
} from '../../../../api';
import { ModalForm, ModalFormApi, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { AgreementField } from './AgreementField';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { getStores, newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  data?: StoreNode;
  onSave: ModalFormSaveCallback<StoreNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: FacilityWithSelectedAgreement): StoreNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.Store,
  flagged: false,
  edges: new Array<string>(),
  items: new Array<StoredItem>(),
  facility,
});

export const StoreDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm<StoreNode | { facility: FacilityWithSelectedAgreement[] }>
      size='narrow'
      ref={props.modalRef}
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add stores'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Is your product being sold to consumers in physical stores? Make sure you add the stores in, ideally one for each specific store
            but you can also create a generic regional store{' '}
            <NavLink to='/facilities' className='underline hover:text-brand'>
              under facilities &amp; locations
            </NavLink>{' '}
            to act as a proxy for all the individual stores in a given geography.
          </div>
          <div>
            This is important information as there is an impact associated with keeping your product on the store’s shelves but also from
            the customers travelling to the store and back.
          </div>
        </div>
      }
      emptyData={{
        facility: new Array<FacilityWithSelectedAgreement>(),
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'store' : undefined}
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        if (props.data) {
          props.onSave({ values: [values as StoreNode], ...rest });
        } else {
          props.onSave({ values: (values.facility as FacilityWithSelectedAgreement[]).map(toNode), ...rest });
        }
      }}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<StoreNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  const toOption = (facility: Facility) => ({
    ...facility,
    selectedAgreement: facility.ownershipStatus?.agreement
      ? {
          ...facility.ownershipStatus.agreement,
          inherited: true,
        }
      : undefined,
  });

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <Field name='facility'>
        {(model: FieldProps<FacilityWithSelectedAgreement>) => (
          <div className='flex flex-col gap-6'>
            <ExtractedData {...props} />
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>
                Select {props.edit ? 'a ' : ''}store{props.edit ? '' : 's'}
              </div>
              <div className={cn({ 'w-2/3': props.edit })}>
                <TaggableField name={model.field.name}>
                  {() => (
                    <SelectV3<FacilityWithSelectedAgreement>
                      autoFocus
                      multi={!props.edit}
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      loadOptions={(input, callback) => {
                        setNewFacilityName(input);
                        getFacilities({
                          contains: input,
                          type: FacilityType.Store,
                        }).ok(({ facilities }) =>
                          callback(
                            facilities
                              .filter(
                                ({ id }) =>
                                  !getStores(props.productFormik)
                                    .filter(({ id }) => id !== formik.values.id)
                                    .some(({ facility }) => id === facility.id),
                              )
                              .map(toOption),
                          ),
                        );
                      }}
                      renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                      menuFooter={
                        !newFacilityForm && (
                          <SelectFooterAddButton
                            onClick={() => setNewFacilityForm(true)}
                            name={newFacilityName}
                            label={facilityOptions.find(({ id }) => id === FacilityType.Store)!.name.toLowerCase()}
                          />
                        )
                      }
                    />
                  )}
                </TaggableField>
              </div>
            </div>
            {props.edit && <AgreementField for='store' model={model} />}
            {newFacilityForm && (
              <NewFacilityForm
                enableFacilityOwnerForm
                name={newFacilityName}
                typeOfFacility={FacilityType.Store}
                formRef={props.formRef}
                onCancel={() => setNewFacilityForm(false)}
                onCreated={(newFacility) => {
                  formik.setFieldValue(
                    model.field.name,
                    props.edit ? toOption(newFacility) : [...(formik.values.facility as any), toOption(newFacility)],
                  );
                  setNewFacilityForm(false);
                }}
              />
            )}
          </div>
        )}
      </Field>
    </TaggableFieldsContainer>
  );
};
