import { Route, Routes } from 'react-router';
import { Report } from './Report';
import { Helmet } from 'react-helmet-async';

export const Baseline = () => (
  <>
    <Helmet title='Baseline' />
    <Routes>
      <Route path='*' element={<Report />} />
    </Routes>
  </>
);
