import { createUser, Customer, defaultRequiredMessage, NewUserForm } from '../../../../api';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InputV3 } from '../../../../components/InputV3';
import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  customer: Customer;
}
export const AddUser = (props: Props) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const navigate = useNavigate();

  const renderError = (error?: string) => error && <div className='absolute -bottom-1 translate-y-full text-red-500 text-xs'>{error}</div>;

  const generatePassword = () => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const punctuation = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allCharacters = uppercase + lowercase + digits + punctuation;

    const getRandomChar = (str: string) => {
      const randomIndex = crypto.getRandomValues(new Uint32Array(1))[0] % str.length;
      return str[randomIndex];
    };

    const passwordArray = [];

    passwordArray.push(getRandomChar(uppercase));
    passwordArray.push(getRandomChar(lowercase));
    passwordArray.push(getRandomChar(digits));
    passwordArray.push(getRandomChar(punctuation));

    for (let i = 4; i < 16; i++) {
      passwordArray.push(getRandomChar(allCharacters));
    }

    const shuffleArray = (array: string[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = crypto.getRandomValues(new Uint32Array(1))[0] % (i + 1);
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    shuffleArray(passwordArray);

    return passwordArray.join('');
  };

  return (
    <Formik
      validateOnBlur={triedToSubmit}
      validateOnChange={triedToSubmit}
      validationSchema={yup.object().shape({
        email: yup.string().email('Invalid email').nullable().required(defaultRequiredMessage),
        firstName: yup.string().nullable().required(defaultRequiredMessage),
        lastName: yup.string().nullable().required(defaultRequiredMessage),
        company: yup.string().nullable().required(defaultRequiredMessage),
        job: yup.string().nullable().required(defaultRequiredMessage),
        password: yup
          .string()
          .nullable()
          .required(defaultRequiredMessage)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+[\]{}|;:,.<>?]{16,}$/,
            'Password must be at least 16 characters long and contain at least 1 lowercase, 1 uppercase, and 1 number',
          ),
      })}
      initialValues={
        {
          customerSid: props.customer.customerSid,
          company: props.customer.companyName,
          job: props.customer.companyName,
          internalUser: false,
          requirePasswordChange: true,
        } as NewUserForm
      }
      onSubmit={(values, { setSubmitting }) => {
        createUser(values).call({
          ok: ({ errorCode }) => {
            if (errorCode) {
              alert(errorCode);
              setSubmitting(false);
              return;
            }

            navigate(-1);
          },
          fail: () => {
            setSubmitting(false);
          },
        });
      }}
    >
      {(formik) => (
        <Form
          noValidate
          className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'
        >
          <div>
            <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
              <div className='flex items-center gap-x-4 py-6 w-full xl:w-[theme(screens.xl)] px-12 text-black'>
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                >
                  <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                </button>
                <div className='flex items-center gap-x-2 text-lg font-semibold bg-white'>
                  {props.customer.companyName} Organisation <FontAwesomeIcon className='text-xs' icon={regular('chevron-right')} /> New User
                </div>
              </div>
            </div>

            <div className='flex flex-col h-full w-full items-center pt-10'>
              <div className='grid grid-cols-4 gap-6 w-full xl:w-[theme(screens.xl)] px-12 *:text-black'>
                <Field name='email'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-sm'>Email</div>
                      <div className='flex flex-col relative'>
                        <InputV3 type='email' model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='firstName'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-sm'>First Name</div>
                      <div className='flex flex-col relative'>
                        <InputV3 model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='lastName'>
                  {(model: FieldProps<string>) => (
                    <div className='col-span-2 grid grid-cols-2'>
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Last Name</div>
                        <div className='flex flex-col relative'>
                          <InputV3 model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      </div>
                      <div />
                    </div>
                  )}
                </Field>
                <div className='flex flex-col gap-y-6'>
                  <Field name='company'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Company</div>
                        <div className='flex flex-col relative'>
                          <InputV3 model={model} />
                          {renderError(model.meta.error)}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name='internalUser'>
                    {(model: FieldProps<boolean>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Internal user</div>
                        <div className='relative flex items-center gap-4 mt-1.5 pl-1'>
                          <input
                            checked={model.field.value}
                            onChange={(event) => {
                              model.field.onChange(event);
                              if (event.target.value) {
                                formik.setFieldValue('internalUser', !model.field.value);
                              }
                            }}
                            className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0 disabled:cursor-not-allowed'
                            type='checkbox'
                          />
                          <div
                            className={cn(
                              'border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6',
                              model.field.value ? 'bg-brand' : 'bg-lightBg',
                            )}
                          >
                            <div
                              className={cn('absolute rounded-full h-2.5 aspect-square transition-[left] duration-25', {
                                'left-3 bg-white': model.field.value,
                                'left-px bg-brand': !model.field.value,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <Field name='job'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-sm'>Job</div>
                      <div className='flex flex-col relative'>
                        <InputV3 model={model} />
                        {renderError(model.meta.error)}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='password'>
                  {(model: FieldProps<string>) => (
                    <div className='col-span-2 flex flex-col gap-1.5'>
                      <div className='font-semibold text-sm'>Password</div>
                      <div className='grid grid-cols-2'>
                        <div className='flex flex-col relative'>
                          <InputV3 model={model} />
                          {model.meta.error && <div className='relative text-red-500 text-xs'>{model.meta.error}</div>}
                        </div>
                        <div />
                      </div>
                      <button
                        type='button'
                        onClick={() => formik.setFieldValue('password', generatePassword())}
                        className='mt-2 px-4 py-2 border flex self-start bg-slate-200 rounded-lg shadow text-violet-950 text-sm font-semibold leading-tight'
                      >
                        Generate password
                      </button>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div className='border-t py-4 flex items-center justify-center bg-white'>
            <div className='flex justify-end w-full xl:w-[theme(screens.xl)] px-12'>
              <button
                type='submit'
                onClick={() => setTriedToSubmit(true)}
                disabled={formik.isSubmitting}
                className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
              >
                Send credentials
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
