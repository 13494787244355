import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ModalV3 } from '../../../../components/ModalV3';
import { deleteUser, getUsers } from '../../../../api';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { useNavigate } from 'react-router';

interface Props {
  customerId: string;
}

export const Users = (props: Props) => {
  const { data, reload } = useApiQuery(getUsers(props.customerId!));
  const navigate = useNavigate();

  if (!data) {
    return <></>;
  }

  return (
    <>
      <div className='flex items-center justify-between h-10'>
        <div className='font-semibold text-base text-zinc-800'>Users</div>
        <button
          onClick={() => navigate('./new')}
          className='text-violet-950 text-sm font-semibold leading-tight px-4 py-2 bg-slate-200 rounded-lg'
        >
          Add new user
        </button>
      </div>

      <div className='grid grid-cols-[auto_1fr]'>
        <div className='col-span-2 grid grid-cols-subgrid uppercase text-xs text-zinc-600 bg-slate-50 p-2'>
          <div>Name</div>
          <div></div>
        </div>

        <div className='col-span-2 grid grid-cols-subgrid divide-y'>
          {data.map((user, i) => (
            <div className='col-span-2 grid grid-cols-subgrid gap-x-4' key={i}>
              <div className='flex gap-x-3 items-center py-2.5'>
                <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                  <FontAwesomeIcon icon={regular('user')} />
                </div>
                <div className='flex flex-col'>
                  <div>{user.fullName}</div>
                  <div className='text-sm'>{user.email}</div>
                </div>
              </div>
              <div className='flex flex-1 justify-between'>
                <div className='flex'>
                  {user.internalUser && (
                    <div className='flex self-center rounded-lg justify-self-start text-xs py-1 px-2 bg-violet-100'>internal</div>
                  )}
                </div>
                <ModalV3
                  size='narrow-'
                  title='Are you sure?'
                  cancelLabel='Cancel'
                  confirmLabel='Delete'
                  onConfirm={() => deleteUser(user.email).ok(reload)}
                  body={
                    <div className='grid grid-cols-2 gap-2 truncate -mt-6'>
                      <div>Email:</div>
                      <div className='truncate'>{user.email}</div>
                      <div>Full name:</div>
                      <div className='truncate'>{user.fullName}</div>
                      <div>Company:</div>
                      <div className='truncate'>{user.company}</div>
                      <div>Job</div>
                      <div className='truncate'>{user.job}</div>
                      <div>Internal user</div>
                      <div>{user.internalUser ? 'Yes' : 'No'}</div>
                    </div>
                  }
                >
                  <button type='button' className='flex self-center mr-6 text-red-500 text-sm'>
                    Remove
                  </button>
                </ModalV3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
