import { forwardRef, useEffect, useState } from 'react';
import { PrototypeProgressChart, TimeframeTick, TrackingData } from '../../../../components/charts/PrototypeProgressChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { simplify } from '../../shared';
import { useProfile } from '../../../../hooks/useProfile';
import { ImpactTracking } from '../../../../api';
import { trackingDataV2 } from './trackingDataV2';
import { convert } from './trackigDataConverterV2';
import { eachMonthOfInterval, format, isAfter } from 'date-fns';
import cn from 'classnames';

interface Props {
  title: string;
  workspaceIds: string[];
}

export const colors = [
  {
    main: 'bg-purple-200',
    shade: 'bg-purple-200/50',
    empty: 'bg-purple-200/25',
    hex: '#ead6ff',
  },
  {
    main: 'bg-yellow-200',
    shade: 'bg-yellow-200/50',
    empty: 'bg-yellow-200/25',
    hex: '#fff7cc',
  },
  {
    main: 'bg-indigo-200',
    shade: 'bg-indigo-200/50',
    empty: 'bg-indigo-200/25',
    hex: '#d6e4ff',
  },
  {
    main: 'bg-sky-200',
    shade: 'bg-sky-200/50',
    empty: 'bg-sky-200/25',
    hex: '#c4e3ff',
  },
  {
    main: 'bg-green-200',
    shade: 'bg-green-200/50',
    empty: 'bg-green-200/25',
    hex: '#d1f7c4',
  },
];

export const ProgressTracking = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const profile = useProfile();
  const workspaces = profile.workspaces.map(({ workspaceSid, name }) => ({ value: workspaceSid, label: name }));
  const selectedWorkspaces = workspaces.filter(({ value }) => props.workspaceIds.includes(value));
  const showAllBrands = selectedWorkspaces.length === 0;
  const currentWorkspace = profile.selectedWorkspace;
  const [selectedTimeframeTick, setSelectedTimeframeTick] = useState<TimeframeTick | undefined>(undefined);

  useEffect(() => {
    setSelectedTimeframeTick(undefined);
  }, [selectedWorkspaces.length]);

  const allBrandsTrackingData: TrackingData[] = trackingDataV2(workspaces.length).map(
    (item, i): TrackingData => ({
      workspaceSid: workspaces[i].value,
      workspaceName: workspaces[i].label,
      isCurrentWorkspace: workspaces[i].value === currentWorkspace.workspaceSid,
      colors: colors[i]
        ? colors[i]
        : {
            main: 'bg-pink-200',
            shade: 'bg-pink-200/50',
            empty: 'bg-pink-200/25',
            hex: '#ffd6f2',
          },
      ...item,
      reports: convert(item.impacts[0] as ImpactTracking)
        .getReportsRanges()!
        .map((item) => ({
          start: item[0].date,
          end: item[1].date,
          points: item[0].points,
        })),
      gapsBetweenReports: convert(item.impacts[0] as ImpactTracking).getReportsLinks()
        ? convert(item.impacts[0] as ImpactTracking)
            .getReportsLinks()!
            .map((item) => ({
              start: item![0].date,
              end: item![1].date,
              points: item![0].points,
            }))
        : [],
      estimation: convert(item.impacts[0] as ImpactTracking).getPredictionData(),
      target: convert(item.impacts[0] as ImpactTracking).getTargetData(),
      timeframe: convert(item.impacts[0] as ImpactTracking).getAxisValues(),
    }),
  );

  const data: TrackingData[] = showAllBrands
    ? allBrandsTrackingData
    : selectedWorkspaces.map(({ value }) => allBrandsTrackingData.find((item) => item.workspaceSid === value)!);

  const getRangeByMonth = (start: Date, end: Date) => {
    return isAfter(start, end) ? [] : eachMonthOfInterval({ start, end });
  };
  const getImpactByEachMonth = (report?: { start: Date; end: Date; points: number }) => {
    if (!report) return 0;
    return report.points / (getRangeByMonth(report.start, report.end).length - 1);
  };

  return (
    <div ref={ref} className='flex flex-col gap-4 text-dark'>
      <div className='flex items-center justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='grid grid-cols-[3fr_7fr_2fr] bg-white rounded-lg border z-[0] px-3 py-6 w-full h-72'>
        <div className='flex flex-col gap-y-6 overflow-hidden'>
          <div className='text-sm font-semibold'>
            {selectedTimeframeTick ? format(selectedTimeframeTick.date, 'MMMM yyyy') : <span>&nbsp;</span>}
          </div>
          <div className='flex flex-col gap-y-2'>
            <div>Total</div>
            <div className='flex items-center gap-x-2'>
              <div className='text-[22px]'>
                {selectedTimeframeTick
                  ? simplify(selectedTimeframeTick.data.reduce((acc, item) => acc + getImpactByEachMonth(item.report), 0))!
                  : simplify(data.reduce((acc, item) => acc + item.reports.reduce((acc, item) => acc + item.points, 0), 0))!}
              </div>
              <div className='text-[10px] text-zinc-500 uppercase'>impact p.</div>
            </div>
          </div>
          <div className='flex flex-col gap-y-3 overflow-y-scroll'>
            {data.map((brand, i) => (
              <div key={i} className='py-2 flex items-center justify-between gap-x-1 truncate'>
                <div title={brand.workspaceName} className={cn('truncate rounded-full px-1.5 text-xs', brand.colors.main)}>
                  {brand.workspaceName}
                </div>
                <div className='flex items-center gap-x-1.5'>
                  <div>
                    {selectedTimeframeTick &&
                      simplify(
                        getImpactByEachMonth(selectedTimeframeTick.data.find((item) => item.workspaceSid === brand.workspaceSid)?.report),
                      )}
                  </div>
                  <div className='text-[10px] text-zinc-500 uppercase'>impact p.</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='ml-20 mr-6'>
          <PrototypeProgressChart
            selectedTimeframeTick={selectedTimeframeTick}
            setSelectedTimeframeTick={setSelectedTimeframeTick}
            currentWorkspace={currentWorkspace.name}
            data={data}
          />
        </div>
        <div className='flex flex-col justify-between'>
          <div className='flex flex-col gap-y-1.5'>
            <div className='flex gap-x-1.5 items-center'>
              <div title='Baseline' className='size-7 flex items-center justify-center rounded-lg bg-indigo-50'>
                <FontAwesomeIcon icon={light('flag-checkered')} />
              </div>
              <div className='text-base'>
                {(() => {
                  const currentWorkspaceData = data.find((item) => item.isCurrentWorkspace);
                  if (!currentWorkspaceData) return;

                  return simplify(currentWorkspaceData.reports[0].points);
                })()}
              </div>
              <div className='text-[10px] text-zinc-500 uppercase'>impact p.</div>
            </div>

            <div className='flex gap-x-1.5 items-center'>
              <div title='Target' className='size-7 flex items-center justify-center rounded-lg bg-indigo-50'>
                <FontAwesomeIcon icon={light('bullseye-arrow')} />
              </div>
              {(() => {
                const currentWorkspaceData = data.find((item) => item.isCurrentWorkspace);
                if (!currentWorkspaceData?.target) return;

                return <div className='text-base'>{currentWorkspaceData.target.estimate}</div>;
              })()}
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex items-center gap-x-2'>
              <div className='grid grid-cols-4 gap-x-0.5 items-center w-8'>
                {new Array(4).fill(null).map((_, index) => (
                  <div key={index} className='h-[2px] bg-zinc-400' />
                ))}
              </div>
              <div className='text-xs'>Estimated</div>
            </div>
            <div className='flex items-center gap-x-2'>
              <div className='grid grid-cols-4 gap-x-0.5 items-center w-8'>
                {new Array(4).fill(null).map((_, index) => (
                  <div key={index} className='h-[2px] bg-fuchsia-400' />
                ))}
              </div>
              <div className='text-xs'>Target</div>
            </div>
            <div className='flex items-center gap-x-2'>
              <div className='h-[2px] w-8 bg-dark' />
              <div className='text-xs'>Baseline</div>
            </div>
            <div className='flex items-center gap-x-2'>
              <div className='h-[2px] w-8 bg-brand' />
              <div className='text-xs'>Selection</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
