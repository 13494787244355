import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ManufacturingGhgReport, ProductType, ProductWithAmount } from '../../../../../../api';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { CollapsibleSection } from '../../../../Products/Report/CollapsibleSection';
import { categories } from '../../../../Products/Report/Sku/Ghg/dataModel';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { useAppRoutes } from '../../../../../../hooks/useAppRoutes';

interface Props {
  data: ManufacturingGhgReport;
}

export const Appendix = (props: Props) => {
  const [expandedFacilities, setExpandedFacilities] = useState(false);
  const [expandedTransportSuppliers, setExpandedTransportSuppliers] = useState(false);
  const { routes } = useAppRoutes();
  return (
    <div className='flex flex-col gap-8 print:text-sm text-dark'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-y-3 px-3'>
          <div>
            This section of the report contains any appendix that might be relevant when trying to understand the results presented in this
            report.
          </div>
          <div>
            If you are left with some questions pertaining to your scope 3 contribution after reading this report, please{' '}
            <a href='mailto:impact@sustained.com' className='font-semibold underline rounded-sm hover:text-brand'>
              contact us
            </a>{' '}
            and we’ll be happy to help you in any way we can!
          </div>
        </div>
        <NavigationButtons type='icons' back={{ path: '../results' }} />
      </div>
      <CollapsibleSection title='Appendix 1 - Global Warming Potential (GWP) factors'>
        <div className='flex flex-col gap-y-6'>
          <div className='text-gray-500'>
            In order to calculate your scope 3 emissions, individual gas emissions are estimated and then converted to a CO2 equivalent
            number. This is to allow for aggregation across all greenhouse gases included in the analysis. The 100-year Global Warming
            Potential (GWP) of the individual gases is used, as defined by the IPCC Sixth Assessment Report. These are presented below for
            reference.
          </div>
          <div className='grid grid-cols-3 gap-x-2 rounded-xl border divide-y overflow-hidden'>
            <div className='col-span-3 grid  grid-cols-[2fr_1fr_1fr] gap-x-2 bg-slate-100 uppercase text-sm font-semibold py-2 text-gray-500'>
              <div className='pl-6'>Gas</div>
              <div>Chemical Formula</div>
              <div>GWP 100-year (kgCO2e/kg)</div>
            </div>

            {categories.gases.map((gas, index) => (
              <div key={index} className='col-span-3 grid  grid-cols-[2fr_1fr_1fr] gap-x-2 py-2'>
                <div className='pl-6'>{gas.flow}</div>
                <div>{gas.formula}</div>
                <div>{gas.value}</div>
              </div>
            ))}
            <div className='col-span-3 uppercase bg-zinc-100 py-2 pl-6 text-xs text-gray-500'>hydrofluorocarbons</div>
            {categories.hydrofluorocarbons.map((gas, index) => (
              <div key={index} className='col-span-3 grid  grid-cols-[2fr_1fr_1fr] gap-x-2 py-2'>
                <div className='pl-6'>{gas.flow}</div>
                <div>{gas.formula}</div>
                <div>{gas.value}</div>
              </div>
            ))}
            <div className='col-span-3 uppercase bg-zinc-100 py-2 pl-6 text-xs text-gray-500'>perfluorochemicals</div>
            {categories.perfluorochemicals.map((gas, index) => (
              <div key={index} className='col-span-3 grid grid-cols-[2fr_1fr_1fr] gap-x-2 py-2'>
                <div className='pl-6'>{gas.flow}</div>
                <div>{gas.formula}</div>
                <div>{gas.value}</div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Appendix 2 - Organisational boundaries assumptions'>
        <div className='flex flex-col gap-6 text-gray-500'>
          <div className='flex flex-col gap-y-3'>
            <div>
              Sustained Impact assumes a Control Approach to determine the organisational boundary requirements of its Scope 3 estimates
              provided. Further information is required for facilities to understand if they are owned, rented or paid for directly or
              indirectly. This affects which scope 3 category the impacts are assigned to. Users specify the following:
            </div>
            <ul className='pl-5 list-disc'>
              <li>For each supplier, if the customer’s company controls them</li>
              <li>
                For each facility, if the facility is owned, rented or third-party.
                <ul className='pl-5 list-disc'>
                  <li>If a facility is set to third party the user must specify if it is through direct contract or an indirect cost.</li>
                </ul>
              </li>
            </ul>
            <div>
              Based on these supplier and facility level properties, we split the various product level processes into the scope 3
              categories as per the following rules:
            </div>
            <ol className='pl-5 list-decimal flex flex-col gap-2'>
              <li>
                For each raw material in the product,
                <ul className='pl-5 list-disc'>
                  <li>
                    If the supplier is the Customer or controlled by the Customer:
                    <ul className='pl-5 list-disc'>
                      <li>If energy flows are available for those, they should be marked as scope 2</li>
                      <li>Non-energy flows (except waste) should be marked as scope 1</li>
                      <li>Waste flows should be marked as scope 3 Category 5: Waste generated in operations</li>
                    </ul>
                  </li>
                  <li>
                    If the supplier is <span className='underline font-semibold'>not</span> the customer or controlled by the Customer,
                    <ul className='pl-5 list-disc'>
                      <li>The impact of producing that raw material is scope 3 Category 1: Purchased goods and services</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                For each transport link in the product,
                <ul className='pl-5 list-disc'>
                  <li>
                    If the transportation service supplier is the Customer or ‘controlled’ by the Customer
                    <ul className='pl-5 list-disc'>
                      <li>Same as above for raw materials</li>
                    </ul>
                  </li>
                  <li>
                    If the supplier is <span className='underline font-semibold'>not</span> the customer or controlled by the Customer
                    <ul className='pl-5 list-disc'>
                      <li>
                        if a ‘direct contract’ is in place with the supplier, then the flow is Scope 3 Category 4: Upstream transportation
                        and distribution.
                      </li>
                      <li>If this is an ‘indirect cost’, the flow is Scope 3 Category 9: Downstream transportation and distribution</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                For each production facility in the product,
                <ul className='pl-5 list-disc'>
                  <li>
                    If the facility is owned by the customer or a supplier controlled by the customer
                    <ul className='pl-5 list-disc'>
                      <li>Same as above for raw materials</li>
                    </ul>
                  </li>
                  <li>
                    If the facility is <span className='underline font-semibold'>not</span> owned by the customer
                    <ul className='pl-5 list-disc'>
                      <li>if the facility is rented, then the flow is Scope 3 Category 8: Leased assets</li>
                      <li>If it’s third party, the flow is Scope 3 Category 1: Purchased goods and services</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                For each storage in the product,
                <ul className='pl-5 list-disc'>
                  <li>
                    If the facility is owned or a supplier controlled by the customer
                    <ul className='pl-5 list-disc'>
                      <li>Same as above for raw materials</li>
                    </ul>
                  </li>
                  <li>
                    If the facility is <span className='underline font-semibold'>not</span> owned by the customer
                    <ul className='pl-5 list-disc'>
                      <li>if the facility is rented, then the flow is Scope 3 Category 8: Leased assets</li>
                      <li>
                        If it’s third party,
                        <ul className='pl-5 list-disc'>
                          <li>
                            If a ‘Direct contract’ is in place with the supplier, then the flow is Scope 3 Category 4: Upstream
                            transportation and distribution.
                          </li>
                          <li>
                            If this is an ‘indirect cost’, then the flow is Scope 3, Category 9: Downstream transportation and distribution
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                For each store in the product,
                <ul className='pl-5 list-disc'>
                  <li>
                    If the facility is owned or a supplier controlled by the customer
                    <ul className='pl-5 list-disc'>
                      <li>Same as above for raw materials</li>
                    </ul>
                  </li>
                  <li>
                    If the facility is <span className='underline font-semibold'>not</span> owned by the customer
                    <ul className='pl-5 list-disc'>
                      <li>if the facility is rented, then the flow is Scope 3 Category 8: Leased assets</li>
                      <li>
                        If it’s third party,
                        <ul className='pl-5 list-disc'>
                          <li>
                            If a ‘Direct contract’ is in place with the supplier, then the flow is Scope 3 Category 4: Upstream
                            transportation and distribution.
                          </li>
                          <li>
                            If this is an ‘indirect cost’, then the flow is Scope 3, Category 9: Downstream transportation and distribution
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                For each consumption location in the product,
                <ul className='pl-5 list-disc'>
                  <li>The flows are Scope 3 Category 11: Use of sold product</li>
                </ul>
              </li>
              <li>
                For each end of life flow in the product,
                <ul className='pl-5 list-disc'>
                  <li>Food waste is Scope 3 Category 12: EOL treatment of sold products</li>
                  <li>
                    End of life treatment (landfill/recycling etc.) of packaging is Scope 3 Category 12: EOL treatment of sold products
                  </li>
                  <li>
                    Emissions from recycling processes because of ‘recycled content’ are to be included in Scope 3 Category 1: Purchased
                    goods and services
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className='flex flex-col text-dark'>
            <button
              onClick={() => setExpandedFacilities(!expandedFacilities)}
              className='group flex self-start items-center gap-x-1.5 py-3'
            >
              <FontAwesomeIcon className={cn('size-2.5', { 'rotate-90': expandedFacilities })} icon={solid('chevron-right')} />
              <div className='group-hover:underline font-semibold'>Facilities</div>
              <div
                className={cn(
                  'flex items-center justify-center px-1',
                  'h-4 text-xs bg-slate-100 rounded-full text-brand',
                  props.data.analysis.facilities.length > 9 ? 'w-auto' : 'w-4',
                )}
              >
                {props.data.analysis.facilities.length}
              </div>
            </button>

            {(() => {
              const content = (
                <div className='divide-y border-b border-zinc-200'>
                  <div
                    className={cn(
                      'grid grid-cols-[2fr_3fr_2fr_2fr_3fr] gap-x-2 items-center',
                      'py-2 bg-slate-50 uppercase font-semibold text-zinc-500 text-xs',
                    )}
                  >
                    <div className='pl-4'>name</div>
                    <div>address</div>
                    <div>status</div>
                    <div>agreement type</div>
                    <div>Used in</div>
                  </div>
                  {props.data.analysis.facilities.map(({ facility, productIds, productsCount }, index) => (
                    <div key={index} className={cn('grid grid-cols-[2fr_3fr_2fr_2fr_3fr] items-center gap-x-2', 'col-span-5 py-2 text-sm')}>
                      <div title={facility.name} className='flex truncate pl-3'>
                        <div className='flex items-center justify-between gap-0.5 truncate px-1 rounded'>
                          <div className='truncate'>{facility.name}</div>
                          <NavLink
                            className='flex p-0.5 hover:bg-white rounded'
                            to={routes.manage.facilities.details('all', facility.id)}
                            target='_blank'
                          >
                            <FontAwesomeIcon className='rotate-45 text-brand' icon={light('arrow-up')} />
                          </NavLink>
                        </div>
                      </div>
                      <div title={facility.address?.text ?? ''} className='line-clamp-2'>
                        {facility.address?.text}
                      </div>
                      <div className='flex items-center gap-x-2 truncate'>
                        <div className='truncate'>{facility.ownershipStatus?.name}</div>
                        {facility.ownershipStatus?.default && (
                          <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                            default
                          </div>
                        )}
                      </div>
                      <div className='flex items-center gap-x-2 truncate'>
                        <div className='truncate'>{facility.ownershipStatus?.agreement?.name}</div>
                        {facility.ownershipStatus?.default && (
                          <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                            default
                          </div>
                        )}
                      </div>
                      <div className='flex py-2'>
                        <ProductsListModal
                          title={
                            <div className='flex justify-between -mb-6 pl-3'>
                              <div className='flex flex-col gap-y-6'>
                                <div className='font-semibold'>Products using {facility.name}</div>
                                <div className='text-xs font-normal uppercase'>
                                  {facility.ownershipStatus && `status: ${facility.ownershipStatus?.name}`}
                                  {facility.ownershipStatus?.agreement && ` / agreement type: ${facility.ownershipStatus?.agreement?.name}`}
                                </div>
                              </div>
                              <div className='text-sm flex self-end text-zinc-500 px-4 py-2 rounded-lg bg-slate-100'>
                                {productsCount} Product SKU{productsCount > 1 ? "'s" : ''}
                              </div>
                            </div>
                          }
                          products={props.data.products.filter((product) => productIds.includes(product.id))}
                        >
                          <button
                            className={cn('flex self-start items-center gap-x-2 bg-white', 'rounded-full border p-1 pr-2 active:scale-95')}
                          >
                            <div
                              className={cn(
                                'flex items-center justify-center px-1',
                                'h-4 text-xs bg-slate-100 rounded-full text-brand',
                                productsCount > 9 ? 'w-auto' : 'w-4',
                              )}
                            >
                              {productsCount}
                            </div>
                            <div className='text-zinc-500'>Products</div>
                          </button>
                        </ProductsListModal>
                      </div>
                    </div>
                  ))}
                </div>
              );
              return (
                <>
                  <div className='print:hidden'>{expandedFacilities && content}</div>
                  <div className='hidden print:block'>{content}</div>
                </>
              );
            })()}

            <button
              disabled={props.data.analysis.transportSuppliers?.length === 0}
              onClick={() => setExpandedTransportSuppliers(!expandedTransportSuppliers)}
              className='group flex items-center gap-x-1.5 py-3 pt-6'
            >
              <FontAwesomeIcon
                className={cn(
                  'size-2.5',
                  { 'rotate-90': expandedTransportSuppliers },
                  { invisible: props.data.analysis.transportSuppliers?.length === 0 },
                )}
                icon={solid('chevron-right')}
              />
              <div
                className={cn('font-semibold', {
                  'group-hover:underline': props.data.analysis.transportSuppliers?.length > 0,
                })}
              >
                Transport suppliers
              </div>
              <div
                className={cn(
                  'flex items-center justify-center px-1',
                  'h-4 text-xs bg-slate-100 rounded-full text-brand',
                  props.data.analysis.transportSuppliers?.length > 9 ? 'w-auto' : 'w-4',
                )}
              >
                {props.data.analysis.transportSuppliers?.length}
              </div>
            </button>

            {(() => {
              const content = (
                <>
                  <div
                    className={cn(
                      'grid grid-cols-[2fr_3fr_1fr_2fr_3fr] gap-x-2 items-center',
                      'py-2 bg-slate-50 uppercase font-semibold text-zinc-500 text-xs',
                    )}
                  >
                    <div className='pl-4'>Supplier</div>
                    <div>agreement type</div>
                    <div>Used in</div>
                  </div>

                  <div className='col-span-5 divide-y border-b border-zinc-200'>
                    {props.data.analysis.transportSuppliers.map(({ supplier, productsCount, productIds }, index) => (
                      <div key={index} className={cn('grid grid-cols-[2fr_3fr_1fr_2fr_3fr] items-center gap-x-2 col-span-5 text-sm')}>
                        <div title={supplier.name} className='flex truncate pl-3'>
                          <div className='flex items-center justify-between gap-0.5 truncate px-1 rounded'>
                            <div className='truncate'>{supplier.name}</div>
                            <NavLink
                              className='flex p-0.5 rounded'
                              to={routes.manage.suppliers.details('all', supplier.id)}
                              target='_blank'
                            >
                              <FontAwesomeIcon className='rotate-45 text-brand' icon={light('arrow-up')} />
                            </NavLink>
                          </div>
                        </div>
                        <div>{supplier.agreement?.name}</div>
                        <div className='flex py-2'>
                          <ProductsListModal
                            title={
                              <div className='flex justify-between -mb-6 pl-3'>
                                <div className='flex flex-col gap-y-6'>
                                  <div className='font-semibold'>Products using {supplier.name}</div>
                                  <div className='text-xs font-normal uppercase'>
                                    {supplier.agreement && `agreement type: ${supplier.agreement.name}`}
                                  </div>
                                </div>
                                <div className='text-sm flex self-end text-zinc-500 px-4 py-2 rounded-lg bg-slate-100'>
                                  {productsCount} Product SKU{productsCount > 1 ? "'s" : ''}
                                </div>
                              </div>
                            }
                            products={props.data.products.filter((product) => productIds.includes(product.id))}
                          >
                            <button
                              className={cn(
                                'flex self-start items-center gap-x-2 bg-white',
                                'rounded-full border p-1 pr-2 active:scale-95',
                              )}
                            >
                              <div
                                className={cn(
                                  'flex items-center justify-center px-1',
                                  'h-4 text-xs bg-slate-100 rounded-full text-brand',
                                  productsCount > 9 ? 'w-auto' : 'w-4',
                                )}
                              >
                                {productsCount}
                              </div>
                              <div className='text-zinc-500'>Products</div>
                            </button>
                          </ProductsListModal>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              );
              return (
                <div>
                  {expandedTransportSuppliers && <div className='print:hidden'>{content}</div>}
                  <div className='hidden print:block'>{content}</div>
                </div>
              );
            })()}
          </div>
        </div>
      </CollapsibleSection>
      <NavigationButtons type='buttons' back={{ path: '../results', label: 'Results' }} />
    </div>
  );
};

interface ModalProps {
  title: ReactNode;
  products: ProductWithAmount[];
}

const ProductsListModal = (props: PropsWithChildren<ModalProps>) => {
  return (
    <ModalV3
      size='wide'
      hideConfirm
      cancelLabel='Close'
      title={props.title}
      body={
        <div className='grid grid-cols-[4fr_2fr_2fr_2fr_1fr] border rounded-xl gap-x-3 text-base'>
          <div className='grid grid-cols-subgrid col-span-5 border-b border-zinc-200 *:py-2'>
            <div className='font-semibold pl-3'>Name</div>
            <div className='font-semibold'>Product type</div>
            <div className='font-semibold'>Net amount</div>
            <div className='font-semibold'>Sku id</div>
            <div />
          </div>

          <div className='grid grid-cols-subgrid col-span-5'>
            {props.products.map((product, i) => (
              <div className='grid grid-cols-subgrid items-center gap-x-3 col-span-5 *:py-2 even:bg-slate-50' key={i}>
                <div title={product.name} className='pl-3 truncate'>
                  {product.name}
                </div>
                <div>
                  {
                    {
                      [ProductType.Final]: 'Final',
                      [ProductType.Intermediate]: 'Intermediate',
                      [ProductType.Internal]: 'Internal',
                    }[product.productType!]
                  }
                </div>
                <div>
                  {product.amount.value}
                  {product.amount.unit.name}
                </div>
                <div title={product.sku} className='truncate'>
                  {product.sku}
                </div>
                <div className='flex'>
                  <NavLink
                    target='_blank'
                    to={cn(
                      product.parentId
                        ? `/products/${product.parentId}/models/${product.id}/report/production/overview`
                        : `/products/${product.id}/report/production/overview`,
                    )}
                    className='size-8 flex self-start items-center justify-center bg-slate-200 rounded-lg active:scale-95'
                  >
                    <FontAwesomeIcon className='hover:text-brand' icon={regular('file-chart-column')} />
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    >
      {props.children}
    </ModalV3>
  );
};
