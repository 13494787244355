import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { getHistoricalGhgCsv, ManufacturingGhgReport } from '../../../../../../api';
import { GhgGraph } from '../../../../../../components/charts/GhgGraph';
import { CollapsibleSection } from '../../../../Products/Report/CollapsibleSection';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { OptionalReportingEmissionsTable } from '../../../../Products/Report/Sku/components/OptionalReportingEmissionsTable';
import { RequiredReportingEmissionsTable } from '../../../../Products/Report/Sku/components/RequiredReportingEmissionsTable';
import { simplify, roundToLong } from '../../../../shared';
import { WorkspaceContext } from '../../../../../../hooks/useWorspace';

interface Props {
  data: ManufacturingGhgReport;
}

export const Results = (props: Props) => {
  const [exporting, setExporting] = useState(false);
  const { workspaceId } = useContext(WorkspaceContext);
  return (
    <div className='flex flex-col gap-8 print:text-sm text-dark'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          A deep dive into your baseline Scope 3 contribution. It is worth noting that these results only cover emissions from
          production-related facilities, goods or services directly associated with manufacturing the selected products. Everything
          non-production-related would need to be added to these outputs for final scope 3 reporting. Scope 1 and 2 emissions are reported
          as reference only as those are calculated using mostly secondary data at this stage.
        </div>
        <NavigationButtons type='icons' back={{ path: '../goal-and-scope' }} next={{ path: '../appendix' }} />
      </div>
      <CollapsibleSection title='Total Emissions'>
        <div className='flex flex-col gap-y-4'>
          <div>
            When aggregating emissions across the entire product, for scope 1 and 2 (using secondary data mostly) and for the scope 3
            categories in scope of this assessment, a total contribution estimation can be obtained. This would need to be complemented with
            out of scope categories emissions as well as non-production related emissions before being shared with interested parties.
          </div>
          <div className='flex flex-col gap-y-10 text-dark'>
            <div className='text-xl font-semibold text-center'>Estimated GHG emissions</div>
            <div className='grid grid-cols-3 gap-x-4 h-full mx-auto'>
              {props.data.analysis.scopeEmissions.map((item, index) => (
                <div key={index} className='flex flex-col bg-slate-50 justify-between gap-y-4 rounded-2xl border p-6 h-full text-center'>
                  <div>
                    <div className='font-semibold'>{item.name}</div>
                    <div className='uppercase text-zinc-400 font-normal text-xs'>emissions</div>
                  </div>
                  <div className='flex justify-center items-center gap-1'>
                    <div title={roundToLong(item.totalEmission.value)} className='text-[32px] font-normal'>
                      {simplify(item.totalEmission.value)}
                    </div>
                    <div className='text-zinc-500 text-xs uppercase pt-1 font-normal'>{item.totalEmission.unit}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Breakdown by category'>
        <div className='flex flex-col gap-y-6'>
          <div className='flex justify-between gap-x-10'>
            <div>
              This section provides a breakdown by Scope 3 category. Tables are separated between required and optional reporting elements.
            </div>
            <button
              type='button'
              disabled={exporting}
              className='print:hidden whitespace-nowrap flex gap-2 justify-self-end items-center px-4 text-brandDarkPurple2 font-semibold disabled:cursor-wait text-base'
              onClick={() => {
                getHistoricalGhgCsv(
                  {
                    reportId: props.data.id!,
                    fileName: 'Historical Volume Report Scope-3 outputs',
                  },
                  workspaceId,
                ).call({
                  ok: () => setExporting(false),
                  fail: () => setExporting(false),
                });
              }}
            >
              <FontAwesomeIcon icon={solid('download')} />
              Export as CSV
            </button>
          </div>
          <div className='shadow-regular rounded-2xl pt-3'>
            <div className='text-lg font-semibold text-center border-b border-zinc-300 pb-3'>Scope 3 contribution by category</div>
            <GhgGraph analysis={props.data.analysis} />
          </div>
          <div className='shadow-regular rounded-2xl pt-3'>
            <div className='text-lg font-semibold text-center pb-3 border-zinc-200 border-b'>
              Emissions breakdown by category - Required reporting
            </div>
            <RequiredReportingEmissionsTable data={props.data.analysis} />
          </div>
          <div className='shadow-regular rounded-2xl pt-3'>
            <div className='text-lg font-semibold text-center pb-3 border-zinc-200 border-b'>
              Emissions breakdown by category - Optional reporting
            </div>
            <OptionalReportingEmissionsTable data={props.data.analysis} />
          </div>
        </div>
      </CollapsibleSection>
      <NavigationButtons
        type='buttons'
        back={{ path: '../goal-and-scope', label: 'Goal & Scope' }}
        next={{ path: '../appendix', label: 'Appendix' }}
      />
    </div>
  );
};
