import { Cell, Pie, PieChart as RechartsPieChart, Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { simplify, roundToShort } from '../../Root/Main/shared';
import { alignTickPosition } from './shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  size: number;
  data: {
    value: number;
    name: string;
    isMajor: boolean;
    bgColor: string;
    impactPoints?: number;
  }[];
  showTicks: boolean;
  outerRadius: number;
  selectedType?: 'physical' | 'impactPoints';
  unit?: string;
}

export const PieChart = (props: Props) => {
  const label = (props: {
    payload: { value: number; payload: { isMajor: boolean } };
    x: number;
    y: number;
    cx: number;
    cy: number;
    textAnchor: 'start' | 'end' | 'middle';
    coordinate: number;
    innerRadius: number;
    outerRadius: number;
    midAngle: number;
    chartSize: number;
  }) => {
    const radian = Math.PI / 180;
    const radius = props.outerRadius / 1.5;
    const x = props.cx + radius * Math.cos(-props.midAngle * radian);
    const y = props.cy + radius * Math.sin(-props.midAngle * radian);

    return (
      <>
        {props.payload.payload.isMajor && (
          <>
            <svg
              style={{ pointerEvents: 'none' }}
              x={x - props.chartSize / 36}
              y={y - props.chartSize / 36}
              width={props.chartSize * 0.05}
              height={props.chartSize * 0.05}
              viewBox='0 0 512 512'
            >
              <path
                fill='white'
                d='M335.5 64H400C426.5 64 448 85.49 448 112V176.5L493.6 222.1C512.3 240.8 512.3 271.2 493.6 289.9L448 335.5V400C448 426.5 426.5 448 400 448H335.5L289.9 493.6C271.2 512.3 240.8 512.3 222.1 493.6L176.5 448H112C85.49 448 64 426.5 64 400V335.5L18.41 289.9C-.3328 271.2-.3328 240.8 18.41 222.1L64 176.5V112C64 85.49 85.49 64 112 64H176.5L222.1 18.41C240.8-.3309 271.2-.3309 289.9 18.41L335.5 64z'
              ></path>
            </svg>
            <svg
              style={{ pointerEvents: 'none' }}
              x={x - props.chartSize / 36}
              y={y - props.chartSize / 36}
              width={props.chartSize * 0.05}
              height={props.chartSize * 0.05}
              viewBox='0 0 512 512'
            >
              <path
                fill='#fbbf23'
                d='M222.1 17.94C240.8-.8035 271.2-.8035 289.9 17.94L336 64H400C426.5 64 448 85.49 448 112V176L493.6 221.6C512.3 240.3 512.3 270.7 493.6 289.5L448 335.1V400C448 426.5 426.5 448 400 448H335.1L289.9 493.1C271.2 511.9 240.8 511.9 222.1 493.1L176.9 448H112C85.49 448 64 426.5 64 400V335.1L18.41 289.5C-.3328 270.7-.3328 240.3 18.41 221.6L64 176V112C64 85.49 85.49 64 112 64H175.1L222.1 17.94zM232 152V264C232 277.3 242.7 288 256 288C269.3 288 280 277.3 280 264V152C280 138.7 269.3 128 256 128C242.7 128 232 138.7 232 152V152zM256 320C238.3 320 224 334.3 224 352C224 369.7 238.3 384 256 384C273.7 384 288 369.7 288 352C288 334.3 273.7 320 256 320z'
              ></path>
            </svg>
          </>
        )}
        <text
          style={{ fontSize: (props.chartSize / 100) * 4.5, fontWeight: 'bold' }}
          x={alignTickPosition({ x: props.x, y: props.y, textAnchor: props.textAnchor, coordinate: props.coordinate })!.x}
          y={alignTickPosition({ x: props.x, y: props.y, textAnchor: props.textAnchor, coordinate: props.coordinate })!.y}
          fill='#7a7a7a'
          textAnchor='middle'
        >
          {props.payload.value > 0 && `${roundToShort(props.payload.value)}%`}
        </text>
      </>
    );
  };

  return (
    <RechartsPieChart className='flex justify-center items-center outline-none pointer-events-none' width={props.size} height={props.size}>
      <Pie
        dataKey='value'
        labelLine={false}
        isAnimationActive={false}
        data={props.data}
        outerRadius={props.outerRadius}
        innerRadius={0}
        label={(payload) => (props.showTicks ? label({ ...payload, chartSize: props.size }) : false)}
        minAngle={5}
      >
        {props.data.map(({ isMajor, bgColor }, index) => (
          <Cell
            style={{ outline: 'none', pointerEvents: 'auto' }}
            key={index}
            strokeWidth={props.size / 100}
            stroke={isMajor ? '#fbbf23' : ''}
            fill={bgColor}
          />
        ))}
      </Pie>
      <Tooltip
        content={(payload: TooltipProps<ValueType & number, NameType>) => {
          if (payload.active && payload.payload && payload.payload[0].value) {
            return (
              <div
                onClick={(e) => e.preventDefault()}
                className='flex flex-col gap-y-2 bg-white rounded-lg p-3 border shadow text-xs text-zinc-600 min-w-60'
              >
                <div className='flex justify-between items-center gap-x-6'>
                  <div className='font-semibold text-zinc-600'>{payload.payload[0].name}</div>
                  {payload.payload[0].payload.isMajorImpact && (
                    <FontAwesomeIcon className='size-5 text-amber-400' icon={solid('seal-exclamation')} />
                  )}
                </div>
                <div className='grid grid-cols-2 gap-x-3 items-center text-zinc-500'>
                  {props.selectedType && (
                    <>
                      <div>Impact</div>
                      {props.selectedType === 'physical' ? (
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{simplify(payload.payload[0].payload.absPhysicalValue)}</div>
                          <div className='uppercase text-[10px] text-zinc-600 whitespace-nowrap'>{props.unit}</div>
                        </div>
                      ) : (
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{simplify(payload.payload[0].payload.impactPoints)}</div>
                          <div className='uppercase text-[10px] text-zinc-600'>impact p.</div>
                        </div>
                      )}
                    </>
                  )}
                  <div>Contribution</div>
                  <div className='flex items-center gap-x-1'>
                    <div className='text-zinc-900 text-sm'>{roundToShort(payload.payload[0].value)}</div>
                    <div className='uppercase text-zinc-600'>%</div>
                  </div>
                </div>
              </div>
            );
          }

          return null;
        }}
        isAnimationActive={false}
      />
    </RechartsPieChart>
  );
};
