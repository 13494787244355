import cn from 'classnames';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  FacilityType,
  FacilityWithSelectedAgreement,
  NodeType,
  ProductState,
  ProductV3,
  StoredItem,
  WarehouseNode,
  facilityOptions,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormApi, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { AgreementField } from './AgreementField';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { getWarehouses, newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  data?: WarehouseNode;
  onSave: ModalFormSaveCallback<WarehouseNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: FacilityWithSelectedAgreement): WarehouseNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.Warehouse,
  flagged: false,
  edges: new Array<string>(),
  items: new Array<StoredItem>(),
  facility,
});

export const WarehouseDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm<WarehouseNode | { facility: FacilityWithSelectedAgreement[] }>
      size='narrow'
      ref={props.modalRef}
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add storage'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Does your product get stored in various storage facilities on its way to the end consumer? It’s important to know as storing has
            an impact too.
          </div>
          <div>
            Add as many of those as your product requires, and don’t forget others’ storage facilities too, your transport provider or
            retailers may also be storing this product along the way, all contributing to this product’s overall environmental impact!
          </div>
        </div>
      }
      emptyData={{
        facility: new Array<FacilityWithSelectedAgreement>(),
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'storage' : undefined}
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        if (props.data) {
          props.onSave({ values: [values as WarehouseNode], ...rest });
        } else {
          props.onSave({ values: (values.facility as FacilityWithSelectedAgreement[]).map(toNode), ...rest });
        }
      }}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<WarehouseNode>();
  const [showFacilityForm, setShowFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  const toOption = (facility: Facility) => ({
    ...facility,
    selectedAgreement: facility.ownershipStatus?.agreement
      ? {
          ...facility.ownershipStatus.agreement,
          inherited: true,
        }
      : undefined,
  });

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <Field name='facility'>
        {(model: FieldProps<FacilityWithSelectedAgreement>) => (
          <div className='flex flex-col gap-6'>
            <ExtractedData {...props} />
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>
                Select {props.edit ? 'a ' : ''}storage{props.edit ? '' : 's'}
              </div>
              <div className={cn({ 'w-2/3': props.edit })}>
                <TaggableField name={model.field.name}>
                  {() => (
                    <SelectV3<FacilityWithSelectedAgreement>
                      autoFocus
                      multi={!props.edit}
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      loadOptions={(input, callback) => {
                        setNewFacilityName(input);
                        getFacilities({
                          contains: input,
                          type: FacilityType.Warehouse,
                        }).ok(({ facilities }) =>
                          callback(
                            facilities
                              .filter(
                                ({ id }) =>
                                  !getWarehouses(props.productFormik)
                                    .filter(({ id }) => id !== formik.values.id)
                                    .some(({ facility }) => id === facility.id),
                              )
                              .map(toOption),
                          ),
                        );
                      }}
                      renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                      menuFooter={
                        !showFacilityForm && (
                          <SelectFooterAddButton
                            onClick={() => setShowFacilityForm(true)}
                            name={newFacilityName}
                            label={facilityOptions.find(({ id }) => id === FacilityType.Warehouse)!.name.toLowerCase()}
                          />
                        )
                      }
                    />
                  )}
                </TaggableField>
              </div>
            </div>
            {props.edit && <AgreementField for='warehouse' model={model} />}
            {showFacilityForm && (
              <NewFacilityForm
                enableFacilityOwnerForm
                name={newFacilityName}
                typeOfFacility={FacilityType.Warehouse}
                formRef={props.formRef}
                onCancel={() => setShowFacilityForm(false)}
                onCreated={(newFacility) => {
                  formik.setFieldValue(
                    model.field.name,
                    props.edit ? toOption(newFacility) : [...(formik.values.facility as any), toOption(newFacility)],
                  );
                  setShowFacilityForm(false);
                }}
              />
            )}
          </div>
        )}
      </Field>
    </TaggableFieldsContainer>
  );
};
