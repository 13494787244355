import { Blocker } from 'react-router-dom';
import { ForwardedRef, forwardRef, HTMLProps, useImperativeHandle, useState } from 'react';
import { ModalApi } from './ModalV3';
import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

type WarningProps = {
  blocker: Blocker;
};

export const UnsavedChangesModalWarning = forwardRef((props: WarningProps, ref: ForwardedRef<ModalApi>) => {
  const [open, setOpen] = useState(false);

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  const { floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: false, escapeKey: false }),
  ]);

  return (
    <FloatingPortal>
      {open && <WarningModalContent {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
    </FloatingPortal>
  );
});

const WarningModalContent = (
  props: WarningProps & {
    floating: (node: HTMLElement | null) => void;
    getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
    setOpen: (open: boolean) => void;
  },
) => {
  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center bg-neutral-400/75 z-50'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className={cn('max-w-md antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full')}
      >
        <div className='flex flex-col gap-y-4 p-6'>
          <div className='flex items-center gap-x-2'>
            <div className='flex bg-amber-50 p-1 rounded-lg border border-neutral-100'>
              <FontAwesomeIcon className='size-6 text-amber-400' icon={regular('exclamation-triangle')} />
            </div>
            <div className='text-lg font-semibold'>You have unsaved changes</div>
          </div>
          <div className='flex flex-col gap-y-6'>
            <div className='text-base text-zinc-600'>
              You have unsaved changes. Please choose if you want to leave without saving or keep editing to save your changes.
            </div>
            <div className='*:font-semibold *:rounded-full *:py-2 *:px-4 grid grid-cols-2 gap-x-6 justify-between'>
              <button
                onClick={() => {
                  props.blocker.proceed && props.blocker.proceed();
                  props.setOpen(false);
                }}
                className='flex self-start justify-center bg-slate-200 text-violet-900'
              >
                Don’t save
              </button>
              <button
                onClick={() => {
                  props.blocker.reset && props.blocker.reset();
                  props.setOpen(false);
                }}
                className='flex flex-1 justify-center bg-brand rounded-full py-2 font-semibold text-white'
              >
                Keep editing
              </button>
            </div>
          </div>
        </div>
      </div>
    </FloatingOverlay>
  );
};
