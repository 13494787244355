import { Dispatch, RefObject, SetStateAction, useEffect, useReducer, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { Modal } from '../../../../components/Modal';
import { EditSupplierForm } from './EditSupplierForm';
import { OwnedFacilities } from './OwnedFacilities';
import { StatusBadge } from '../StatusBadge';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useNavigate } from 'react-router-dom';
import { useBlocker, useParams } from 'react-router';
import { Formik, useFormikContext } from 'formik';
import { ManageEntity } from '../Overview';
import { UnsavedChangesModalWarning } from '../../../../components/UnsavedChangesModalWarning';
import * as yup from 'yup';
import {
  createSupplier,
  deleteSupplier,
  Facility,
  getSharedSupplierProducts,
  getSupplier,
  SharedProduct,
  Supplier,
  SupplierService,
  supplierServices,
  updateSupplier,
} from '../../../../api';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { SkeletonLoader } from '../SkeletonLoader';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';
import { SuppliersFacilitiesProductListModal } from '../SuppliersFacilitiesProductListModal';

interface Props {
  title?: string;
  fetchNextSuppliersPage: () => void;
  reload: () => void;
  selectedCategory: string;
  suppliersData: ManageEntity<Supplier[]>;
  setSuppliersData: (v: SetStateAction<ManageEntity<Supplier[]>>) => void;
  loading: boolean;
}

export const supplierValidationSchema = yup.object().shape({
  name: yup.string().required(),
  services: yup.array().min(1).required(),
  controlledByCustomer: yup.boolean().required(),
  agreement: yup
    .object()
    .when(['controlledByCustomer', 'services'], (controlledByCustomer, services) =>
      !controlledByCustomer && services.includes(SupplierService.Transport) ? yup.object().required() : yup.object().nullable(),
    ),
});

const defaultProductsSearchParams = {
  pageSize: 50,
  sortBy: 'totalImpact',
  sortAscending: 'false',
};

export const Suppliers = (props: Props) => {
  const [deleting, setDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [keepDefaults, setKeepDefaults] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);

  const [render, setRender] = useReducer((x) => x + 1, 0);
  const containerRef = useRef<HTMLDivElement>(null);
  const mainListHeaderRef = useRef<HTMLDivElement>(null);
  const secondaryListHeaderRef = useRef<HTMLDivElement>(null);

  const [mainListHeight, setMainListHeight] = useState<number>();
  const [secondaryListHeight, setSecondaryListHeight] = useState<number>();

  const productListModalRef = useRef<ModalApi>(null);

  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const { id } = useParams();

  const [productsInitialValues, setProductsInitialValues] = useState<{
    entity: Supplier | Facility | null;
    totalResults: number;
    nextPageToken: string;
    list: SharedProduct[];
  }>({
    entity: null,
    totalResults: 0,
    nextPageToken: '',
    list: [],
  });

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setSelectedSupplier({
          services: new Array<{ id: string; name: string }>(),
          controlledByCustomer: false,
        } as unknown as Supplier);
      } else {
        getSupplier(id).ok((data) => setSelectedSupplier(data));
      }
    } else {
      setSelectedSupplier(null);
    }
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', setRender);
    return () => window.removeEventListener('resize', setRender);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      if (mainListHeaderRef.current) {
        setMainListHeight(containerRef.current.getBoundingClientRect().height - mainListHeaderRef.current.getBoundingClientRect().height);
      }

      if (secondaryListHeaderRef.current) {
        setSecondaryListHeight(
          containerRef.current.getBoundingClientRect().height - secondaryListHeaderRef.current.getBoundingClientRect().height,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplier, render]);

  const gridSchema = props.selectedCategory === 'all' ? 'grid-cols-[2fr_1fr_3fr_1fr_1fr_80px]' : 'grid-cols-[2fr_1fr_1fr_1fr_1fr_80px]';

  return (
    <div ref={containerRef} className='w-full h-[calc(100vh-theme(spacing.20))] bg-zinc-50'>
      <Helmet title='Providers & Suppliers' />
      {!selectedSupplier ? (
        <>
          <div ref={mainListHeaderRef} className='h-20 flex items-center justify-between border-b border-zinc-200 bg-white px-6'>
            <div className='flex items-center gap-x-2 font-semibold text-lg'>
              <div>{props.title}</div>
              <div className='mt-0.5 flex items-center justify-center px-1 rounded-full bg-slate-100 text-brand text-xs'>
                {props.suppliersData.totalResults}
              </div>
            </div>
            <div className='flex gap-x-3'>
              <div className='flex items-center relative'>
                <input
                  type='search'
                  className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                  autoFocus
                  placeholder='Find…'
                  value={props.suppliersData.searchString}
                  onChange={(event) => props.setSuppliersData({ ...props.suppliersData, searchString: event.target.value })}
                />
                <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
              </div>
              <NavLink
                to='../new-supplier'
                className='flex align-middle self-center bg-brand rounded-full px-4 py-2 text-sm text-white font-semibold [&:active:not(:disabled)]:scale-95 disabled:cursor-wait whitespace-nowrap'
              >
                New provider
              </NavLink>
            </div>
          </div>

          {mainListHeight &&
            (props.loading ? (
              <SkeletonLoader height={mainListHeight} />
            ) : (
              <InfiniteScroll
                height={mainListHeight}
                dataLength={props.suppliersData.list.length}
                next={props.fetchNextSuppliersPage}
                hasMore={props.suppliersData.nextPageToken !== ''}
                loader={
                  <div className='py-3 text-center'>
                    <FontAwesomeIcon size='2x' pulse icon={duotone('loader')} />
                  </div>
                }
              >
                <div
                  className={cn(
                    gridSchema,
                    'grid items-center gap-x-2 sticky z-[1] top-0',
                    'bg-white border-b border-zinc-200 font-semibold',
                  )}
                >
                  <button
                    className='flex w-full justify-between items-center gap-2 pl-6 p-3 hover:bg-slate-50'
                    onClick={() =>
                      props.setSuppliersData({
                        ...props.suppliersData,
                        searchParams: {
                          ...props.suppliersData.searchParams,
                          sortAscending: !props.suppliersData.searchParams.sortAscending,
                        },
                      })
                    }
                  >
                    <div>Name</div>
                    <FontAwesomeIcon
                      className={cn('text-brandDark', { 'rotate-180': props.suppliersData.searchParams.sortAscending })}
                      icon={regular('arrow-up')}
                    />
                  </button>
                  <div className='p-3'>ID</div>
                  {props.selectedCategory === 'all' && <div className='p-3'>Provider type</div>}
                  <div className='p-3'>Controlled</div>
                  {props.selectedCategory !== 'all' && <div className='p-3 whitespace-nowrap'>Facilities owned</div>}
                  <div>Used in</div>
                  <div className='p-3'></div>
                </div>

                <div className='divide-y bg-white'>
                  {props.suppliersData.list.map((supplier, i) => {
                    const disabled = supplier.usedInProducts === 0;
                    const onDeleteRequest = (close: () => void) => {
                      setDeleting(true);
                      deleteSupplier(supplier.id).call({
                        ok: (err) => {
                          if (!err) {
                            setDeleting(false);
                            close();
                            props.reload();
                          } else {
                            setHasError(true);
                            setDeleting(false);
                          }
                        },
                        fail: () => {
                          setDeleting(false);
                        },
                      });
                    };

                    return (
                      <div
                        key={i}
                        onClick={() => navigate(routes.manage.suppliers.details(props.selectedCategory, supplier.id))}
                        className={cn(
                          gridSchema,
                          'grid items-center gap-x-2 text-zinc-500 hover:text-dark hover:bg-slate-50 hover:cursor-pointer transition-colors',
                          supplier.pinned ? 'bg-lime-50' : 'odd:bg-slate-50',
                        )}
                      >
                        <div className='p-3 pl-6 truncate'>
                          <div className='flex items-center justify-between'>
                            <div title={supplier.name} className='truncate'>
                              {supplier.name}
                            </div>
                            {supplier.default && (
                              <div className='text-[8px] h-[16px] leading-none font-semibold uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                                default
                              </div>
                            )}
                          </div>
                        </div>
                        <div title={supplier.alias} className='pl-3 truncate'>
                          {supplier.alias}
                        </div>
                        {props.selectedCategory === 'all' && (
                          <div className='pl-3'>
                            <div className='py-2 flex flex-wrap gap-2'>
                              {supplier.services.map((service) => (
                                <div key={service} className='rounded-full text-gray-500 px-2 border border-zinc-300 pb-0.5 bg-white'>
                                  {supplierServices.find(({ id }) => id === service)!.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <div className='pl-3'>{supplier.controlledByCustomer ? 'Yes' : 'No'}</div>
                        {props.selectedCategory !== 'all' && (
                          <div className='pl-3 flex'>
                            <div className='flex items-center rounded-full border gap-1 self-start px-2 py-1'>
                              <div>{supplier.ownedFacilities}</div>
                              Facilities
                            </div>
                          </div>
                        )}
                        <div onClick={(e) => e.stopPropagation()}>
                          <SuppliersFacilitiesProductListModal
                            type='supplier'
                            ref={productListModalRef}
                            initialValues={productsInitialValues}
                          >
                            <div>
                              <button
                                type='button'
                                disabled={disabled}
                                title={supplier.id}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  getSharedSupplierProducts(supplier.id, {
                                    ...defaultProductsSearchParams,
                                  }).call({
                                    ok: (data) => {
                                      setProductsInitialValues({
                                        entity: supplier,
                                        totalResults: data.totalResults,
                                        nextPageToken: data.nextPageToken,
                                        list: data.products,
                                      });
                                      productListModalRef.current?.open();
                                    },
                                  });
                                }}
                                className={cn(
                                  { 'cursor-not-allowed text-zinc-400': disabled },
                                  'flex self-start items-center gap-x-1 bg-white z-50',
                                  'rounded-full border p-1 pr-2 [not:(:disabled)]:active:scale-95',
                                )}
                              >
                                <div
                                  className={cn(
                                    { 'text-brand': !disabled },
                                    'flex items-center justify-center px-1',
                                    'h-4 text-xs bg-slate-100 rounded-full text-brand',
                                    supplier.usedInProducts > 9 ? 'w-auto' : 'w-4',
                                  )}
                                >
                                  {supplier.usedInProducts}
                                </div>
                                <div className={cn(disabled ? 'text-zinc-400' : 'text-zinc-500')}>Products</div>
                              </button>
                            </div>
                          </SuppliersFacilitiesProductListModal>
                        </div>
                        <div className='pr-4'>
                          <div onClick={(event) => event.stopPropagation()} className='flex items-center gap-0.5'>
                            <button
                              type='button'
                              className='flex justify-center items-center w-8 px-1 h-8 aspect-square rounded-md hover:bg-white transition-colors'
                              onClick={() => navigate(routes.manage.suppliers.details(props.selectedCategory, supplier.id))}
                            >
                              <FontAwesomeIcon icon={regular('edit')} />
                            </button>
                            {!supplier.pinned && (
                              <Modal
                                title={hasError ? `${supplier.name} is currently in use` : `Deleting ${supplier.name}`}
                                body={
                                  hasError
                                    ? 'We’re sorry but deletion of this provider is disabled as it is either connected to an existing location/facility or being used in a product.'
                                    : 'Are you sure you want to remove this provider from your workspace? You will not be able to recover it.'
                                }
                                noSubmit={hasError}
                                confirmLabel='Delete'
                                waiting={deleting}
                                wrapperStyle='max-w-lg p-6'
                                onConfirm={(_, close) => onDeleteRequest(close)}
                                onCancel={hasError ? () => setHasError(false) : undefined}
                              >
                                <button
                                  type='button'
                                  className='flex justify-center items-center w-8 px-1 h-8 rounded-md hover:bg-white transition-colors'
                                >
                                  <FontAwesomeIcon icon={regular('trash-can')} />
                                </button>
                              </Modal>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            ))}
        </>
      ) : (
        <div className='grid grid-cols-[250px_auto] bg-zinc-50'>
          <div>
            <div
              ref={secondaryListHeaderRef}
              className='h-20 flex items-center justify-between border-b border-r border-zinc-200 bg-white px-6 uppercase text-zinc-500'
            >
              {props.title === 'suppliers' ? 'Providers & Suppliers' : props.title}
            </div>
            <div className='flex flex-col divide-y border-r border-b border-zinc-200 max-w-[250px]'>
              {secondaryListHeight && (
                <InfiniteScroll
                  height={secondaryListHeight}
                  dataLength={props.suppliersData.list.length}
                  next={props.fetchNextSuppliersPage}
                  hasMore={props.suppliersData.nextPageToken !== ''}
                  loader={<></>}
                >
                  <button
                    className={cn('flex w-full justify-between items-center gap-2 pl-6 p-3 hover:bg-slate-50 border-b border-zinc-200')}
                    onClick={() =>
                      props.setSuppliersData({
                        ...props.suppliersData,
                        searchParams: {
                          ...props.suppliersData.searchParams,
                          sortAscending: !props.suppliersData.searchParams.sortAscending,
                        },
                      })
                    }
                  >
                    <div>Name</div>
                    <FontAwesomeIcon
                      className={cn('text-brandDark', { 'rotate-180': props.suppliersData.searchParams.sortAscending })}
                      icon={regular('arrow-up')}
                    />
                  </button>
                  <div className='divide-y'>
                    {props.suppliersData.list.map((supplier, i) => (
                      <NavLink
                        to={routes.manage.suppliers.details(props.selectedCategory, supplier.id)}
                        className={cn(
                          'flex items-center gap-0 justify-between pl-6 p-3 hover:cursor-pointer',
                          selectedSupplier?.id === supplier.id
                            ? 'hover:bg-slate-200 bg-slate-200'
                            : supplier.pinned
                            ? 'hover:bg-slate-200 bg-lime-50'
                            : 'hover:bg-slate-200 bg-white odd:bg-slate-50',
                        )}
                        key={i}
                      >
                        <div title={supplier.name} className='truncate'>
                          {supplier.name}
                        </div>
                        {supplier.default && (
                          <div
                            className={cn(
                              'text-[8px] h-[16px] px-1.5 py-1 rounded bg-[#E8EAF5]',
                              'leading-none font-semibold uppercase text-zinc-600',
                              { 'bg-gray-300': supplier.id === selectedSupplier?.id },
                            )}
                          >
                            default
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>

          <Formik<Supplier>
            enableReinitialize
            initialValues={selectedSupplier}
            validationSchema={supplierValidationSchema}
            validateOnBlur={triedSubmit}
            validateOnChange={triedSubmit}
            onSubmit={(values) => {
              (id === 'new' ? createSupplier : updateSupplier)({
                ...values,
                agreement: values.agreement
                  ? {
                      ...values.agreement,
                      default: keepDefaults,
                    }
                  : undefined,
              }).call({
                ok: (supplier) => {
                  setSelectedSupplier(supplier);
                  props.reload();
                  setTriedSubmit(false);
                  setWaiting(false);
                  navigate(routes.manage.suppliers.details(props.selectedCategory, supplier.id));
                },
              });
            }}
          >
            <SupplierForm
              waiting={waiting}
              triedSubmit={triedSubmit}
              setTriedSubmit={setTriedSubmit}
              keepDefaults={keepDefaults}
              setWaiting={setWaiting}
              setKeepDefaults={setKeepDefaults}
              setPressedSave={setTriedSubmit}
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
              selectedCategory={props.selectedCategory}
              containerRef={containerRef}
            />
          </Formik>
        </div>
      )}
    </div>
  );
};

interface SupplierFormProps {
  waiting: boolean;
  triedSubmit: boolean;
  setTriedSubmit: Dispatch<SetStateAction<boolean>>;
  keepDefaults: boolean;
  setWaiting: Dispatch<SetStateAction<boolean>>;
  setKeepDefaults: Dispatch<SetStateAction<boolean>>;
  setPressedSave: Dispatch<SetStateAction<boolean>>;
  selectedSupplier: Supplier | null;
  setSelectedSupplier: (v: Supplier | null) => void;
  selectedCategory: string;
  containerRef: RefObject<HTMLDivElement>;
}

const SupplierForm = (props: SupplierFormProps) => {
  const formik = useFormikContext<Supplier>();
  const supplierDetailsRef = useRef<HTMLDivElement>(null);
  const confirmationModalRef = useRef<ModalApi>(null);
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const unsavedChangesModal = useRef<ModalApi>(null);
  const unsavedChanges = !isEqual(formik.initialValues, formik.values);

  const blocker = useBlocker(() => unsavedChanges);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      unsavedChangesModal.current!.open();
    }
  }, [blocker.state]);

  return (
    <div className='overflow-y-auto' style={{ height: props.containerRef.current?.getBoundingClientRect().height }}>
      <UnsavedChangesModalWarning blocker={blocker} ref={unsavedChangesModal} />
      <div ref={supplierDetailsRef}>
        <div className='sticky top-0 z-30 h-20 flex justify-between gap-2 border-b border-zinc-200 px-6 bg-white'>
          <div className='flex items-center gap-2 overflow-hidden'>
            <button
              type='button'
              onClick={() => {
                props.setSelectedSupplier(null);
                navigate(routes.manage.suppliers.root(props.selectedCategory));
              }}
              className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
            >
              <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
            </button>
            <div className='flex gap-2 items-center truncate'>
              <div title={formik.values.name} className='text-xl font-semibold truncate'>
                {formik.values.name}
              </div>
              <StatusBadge state={props.waiting ? 'saving' : unsavedChanges ? 'unsaved' : undefined} />
            </div>
          </div>

          {!(isEqual(formik.initialValues, formik.values) || props.waiting) && (
            <div className='flex gap-3'>
              <button
                type='button'
                className='flex self-center border-2 border-[#220066] rounded-full px-4 py-1 text-[#220066] font-semibold active:scale-95'
                onClick={() => formik.setValues(formik.initialValues)}
              >
                Cancel
              </button>
              <div className='flex gap-2 items-center'>
                <button
                  type='submit'
                  disabled={props.waiting}
                  className={cn(
                    'self-center text-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
                    '[&:active:not(:disabled)]:scale-95',
                    'disabled:bg-transparent disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed',
                  )}
                  onClick={async () => {
                    props.setPressedSave(true);
                    await formik.validateForm();
                    if (await supplierValidationSchema.isValid(formik.values)) {
                      if (formik.values.agreement?.default) {
                        confirmationModalRef.current?.open();
                      } else {
                        props.setKeepDefaults(false);
                        formik.handleSubmit();
                      }
                    }
                  }}
                >
                  Save
                </button>
              </div>
              <ModalV3
                ref={confirmationModalRef}
                confirmLabel='Remove defaults'
                cancelLabel='Skip'
                onConfirm={() => {
                  props.setKeepDefaults(false);
                  formik.handleSubmit();
                }}
                onCancel={() => {
                  props.setKeepDefaults(true);
                  formik.handleSubmit();
                }}
                title='Just a final check before saving your settings.'
                body={
                  <div className='flex flex-col gap-y-6'>
                    <div className='-mt-6 text-base'>
                      You have some default settings on this page. This means that we automatically assigned a value to these fields. If
                      these values are correct, we can remove the default tags or if you’re unsure, we can leave it all as is for now.
                    </div>
                    <div className='grid grid-cols-4'>
                      <div className='col-span-2 grid grid-cols-2 gap-y-3'>
                        {formik.values.agreement?.type && (
                          <div className='col-span-2 grid grid-cols-2'>
                            <div className='text-zinc-500'>Facility status</div>
                            <div className='flex items-center justify-between gap-x-2'>
                              <div>{formik.values.agreement?.name}</div>
                              <div className='text-[8px] font-semibold leading-none tracking-wider uppercase px-1.5 py-1 rounded text-zinc-600 bg-[#E8EAF5]'>
                                default
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-span-2' />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
        <EditSupplierForm />
        {props.selectedSupplier && <OwnedFacilities supplier={props.selectedSupplier} />}
      </div>
    </div>
  );
};
