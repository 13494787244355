import { Route, Routes } from 'react-router';
import { Compare } from './Compare';
import { Details } from './Details';
import { Import } from './Import';
import { List } from './List';
import { Modelling } from './Modelling';
import { Main as ModellingReport } from './Report/Modelling';
import { RevisionOverview } from './Report/RevisionOverview';
import { Main as ProductReport } from './Report/Sku';

export const Products = () => {
  return (
    <Routes>
      <Route path=':productId/revisions/:revisionId/overview' element={<RevisionOverview />} />
      <Route path=':productId/report/*' element={<ProductReport />} />
      <Route path=':productId/models/:modelId/report/*' element={<ModellingReport />} />
      <Route path=':productId/models/:modelId/*' element={<Modelling />} />
      <Route path=':productId/revisions/:revision/*' element={<Details />} />
      <Route path=':productId/*' element={<Details />} />
      <Route path='compare/:product1Id/:model1Id/:product2Id/:model2Id' element={<Compare />} />
      <Route path='import/*' element={<Import />} />
      <Route path='*' element={<List />} />
    </Routes>
  );
};
