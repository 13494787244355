import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ProductImprovementTips } from '../api';
import { getImprovementTipsIcons } from '../icons/ImprovementTips';

interface Props {
  improvementTips: ProductImprovementTips[];
}

export const ProductImprovementTipsV3 = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='bg-amber-50 col-span-2 flex flex-col gap-4 rounded-2xl border p-6'>
      <div className='text-lg font-semibold text-dark'>Top Tips to Improve Your Score</div>
      <div className='h-full justify-evenly flex flex-col gap-y-4'>
        {props.improvementTips.slice(0, 3).map((item, i) => (
          <div key={i} className='flex gap-3 items-center'>
            <div className='h-6 aspect-square'>{getImprovementTipsIcons(item.iconId)}</div>
            <div className='flex items-center'>{item.text}</div>
          </div>
        ))}
        {expanded && (
          <div className='flex flex-col text-lg gap-4'>
            {props.improvementTips.slice(3, props.improvementTips.length).map((item, i) => (
              <div key={i} className='flex gap-3 items-center'>
                <div className='size-6 bg-brand/5 shadow-md text-brand rounded-xl'>{getImprovementTipsIcons(item.iconId)}</div>
                <div className='flex items-center'>{item.text}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      {props.improvementTips.length > 3 && (
        <div className='flex justify-center absolute bottom-2 left-0 right-0'>
          <button type='button' onClick={() => setExpanded(!expanded)} className='flex items-center gap-3 text-brand font-semibold'>
            <FontAwesomeIcon icon={expanded ? solid('chevron-up') : solid('chevron-down')} />
            Show {expanded ? 'Less' : 'More'}
          </button>
        </div>
      )}
    </div>
  );
};
