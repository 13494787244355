import { duotone, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { ReactNode, useEffect, useState } from 'react';
import { HistoricalPefReport, ImpactTracking, Lens, ReportType, Targets } from '../../../../../../api';
import { ContextualExampleTile } from '../../../../../../components/ContextualExampleTile';
import { SingleSelect } from '../../../../../../components/SingleSelect';
import { ViewToggle } from '../../../../../../components/ViewToggle';
import { ImpactValueType } from '../../../../Products/Report/Sku/Overview';
import { ImpactAnalysisResultSummary } from '../../../components/ImpactAnalysisResultSummary';
import { PefDashboardSummary } from '../../../components/PefDashboardSummary';
import { WorstOffenders } from '../../../components/WorstOffenders';
import { Overview } from '../Overview';
import { ProgressChart } from '../../../../../../components/charts/ProgressTracking/ProgressChart';
import { ProgressChartLegend } from '../../../../../../components/charts/ProgressTracking/ProgressChartLegend';
import { ProgressChartPlaceholder } from '../../../../../../components/charts/ProgressTracking/ProgressChartPlaceholder';
import cn from 'classnames';
import { useProgressChartEnabled } from '../../../../../../hooks/useProgressChartEnabled';
import { ImpactTargetHints } from '../../../../../../components/ImpactTargetHints';
import { convert } from '../../../../Prototype/sections/trackigDataConverterV2';
import { Helmet } from 'react-helmet-async';

interface Props {
  data: HistoricalPefReport;
  warning: ReactNode;
  targets: Targets;
}

export const ProductionOverview = (props: Props) => {
  const [selectedImpact, setSelectedImpact] = useState<ImpactTracking>(props.data!.tracking.impacts[0]);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);

  const [focused, setFocused] = useState(false);
  const [expanded, setExpanded] = useState(new Array<string>());

  const enabled = useProgressChartEnabled({
    isBaselineSet: props.data?.tracking.impacts[0].baseline !== undefined,
    data: props.data?.tracking.impacts.map((impact) => ({
      id: impact.id,
      reports: impact.reports,
    })),
    selectedImpact: selectedImpact,
  });

  const handleExpandedState = (item1: string) => {
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));
  };

  useEffect(() => {
    if (!expanded.includes('Impact Analysis Results')) {
      setFocused(false);
    }

    return () => setFocused(false);
  }, [expanded]);

  const collapsible = (title: string, description: string, icon: ReactNode, children: ReactNode) => (
    <div className='print:mt-6'>
      <button
        onClick={() => handleExpandedState(title)}
        className='py-6 w-full flex justify-between items-center px-10 hover:cursor-pointer hover:bg-lightBg/75'
      >
        <div className='flex gap-4 items-center'>
          <div>{icon}</div>
          <div className='flex flex-col gap-2'>
            <div className='text-lg font-semibold'>{title}</div>
            <div>{description}</div>
          </div>
        </div>
        <FontAwesomeIcon
          className='text-xl print:hidden'
          icon={expanded.includes(title) ? solid('chevron-down') : solid('chevron-right')}
        />
      </button>
      <div className='print:hidden'>{expanded.includes(title) && children}</div>
      <div className='hidden print:block'>{children}</div>
    </div>
  );

  return (
    <Overview lens={Lens.Production} data={props.data}>
      <Helmet title={props.data.name} />
      <PefDashboardSummary
        data={props.data}
        reportTimeframe={`${format(new Date(props.data.startDate), 'MMM dd, yyyy')} - ${format(
          new Date(props.data.endDate),
          'MMM dd, yyyy',
        )}`}
        setFocused={setFocused}
        expanded={expanded}
        handleExpandedState={handleExpandedState}
        warning={props.warning}
      />

      {collapsible(
        'Progress tracking',
        'How this historical data point compares to previous uploaded ones, against your baseline impact and set targets',
        <FontAwesomeIcon className='size-8' icon={duotone('chart-line-down')} />,
        <div className='bg-lightBg print:bg-transparent p-6'>
          <div className='border-t flex flex-col gap-6 items-center rounded-2xl border bg-white p-6'>
            {enabled ? (
              <>
                <>
                  <div className='w-60'>
                    <SingleSelect
                      options={props.data.tracking.impacts.map((item) => ({
                        ...item,
                        disabled: false,
                        renderIcon: item.target !== undefined,
                      }))}
                      value={{
                        value: selectedImpact.id,
                        label: selectedImpact.name,
                      }}
                      setSelectedId={(v) => {
                        if (v === 'overall') {
                          setSelectedImpactUnit(ImpactValueType.Points);
                        }
                        setSelectedImpact(props.data!.tracking.impacts.find((impact) => impact.id === v)!);
                      }}
                      selectedId={selectedImpact.id}
                    />
                  </div>
                  <ViewToggle
                    theme='light'
                    button1={{
                      label: 'Final environmental impact',
                      active: selectedImpactUnit === ImpactValueType.Points,
                      disabled: selectedImpact.id === 'overall',
                    }}
                    button2={{
                      label: 'Physical Impact',
                      active: selectedImpactUnit === ImpactValueType.Physical,
                      disabled: selectedImpact.id === 'overall',
                    }}
                    toggleView={() => {
                      setSelectedImpactUnit((current) =>
                        current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                      );
                    }}
                  />
                </>
                <ImpactTargetHints
                  reportType={ReportType.Historical}
                  selectedUnit={selectedImpactUnit}
                  selectedImpact={selectedImpact}
                  targetData={convert(selectedImpact).getTargetData()}
                  targetHint={convert(selectedImpact).getTargetHint()}
                  targets={props.targets}
                />
                <div className='flex w-full gap-6 border rounded-lg p-6 pb-2'>
                  <div className='w-full h-64 text-xs ml-20'>
                    <ProgressChart
                      targets={props.targets}
                      selectedImpact={selectedImpact}
                      selectedType={selectedImpactUnit}
                      impacts={props.data.tracking.impacts}
                    />
                  </div>
                  <div className='text-sm leading-4'>
                    <ProgressChartLegend />
                  </div>
                </div>
              </>
            ) : (
              <div className='flex flex-col gap-y-10'>
                <div className='text-base flex items-center gap-x-4 bg-white p-4 rounded-lg border'>
                  <div className='size-10 bg-brandLime flex shrink-0 items-center justify-center rounded-full'>
                    <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
                  </div>
                  <div>
                    This graph shows progress throughout the years. In order to view it, the baseline needs to be created using one year
                    (either a calendar or tax year) as time frame not custom dates. Uploaded historical data should cover full months as
                    well.
                  </div>
                </div>
                <div className={cn('ml-24 mr-6 h-56', { 'pointer-events-none opacity-50': !enabled })}>
                  <ProgressChartPlaceholder />
                </div>
              </div>
            )}
          </div>
        </div>,
      )}
      {collapsible(
        'Worst offenders',
        'Which products have the highest overall environmental impact across your entire volume output',
        <FontAwesomeIcon className='size-8' icon={duotone('light-emergency-on')} />,
        <WorstOffenders data={props.data} />,
      )}

      {collapsible(
        'Impact Analysis Results',
        'Your product’s impact by category, life cycle stage and process as well as the major contributors to each',
        <FontAwesomeIcon className='size-8' icon={duotone('spinner-third')} />,
        <ImpactAnalysisResultSummary data={props.data} type={ReportType.Historical} focused={focused} setFocused={setFocused} />,
      )}
      {collapsible(
        'Physical Impact - Equivalence in real life',
        'What’s your impact, translated into relatable measures',
        <FontAwesomeIcon className='size-8' icon={duotone('leaf')} />,
        <div className='flex flex-col gap-y-6 bg-lightBg print:bg-transparent p-6'>
          <div className='font-semibold text-xl text-center'>Physical Impacts Equivalent to:</div>
          <div className='grid grid-cols-3 gap-6 print:block'>
            {props.data?.interpretation.contextualExamples.map((contextualExample, i) => (
              <ContextualExampleTile key={i} contextualExample={contextualExample} />
            ))}
          </div>
        </div>,
      )}
    </Overview>
  );
};
