import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Report } from '../index';
import { ProductionLensOnePagerReport } from './Production/ProductionLensOnePagerReport';
import { ConsumerLensOnePagerReport } from './Consumer/ConsumerLensOnePagerReport';
import { GhgLensOnePagerReport } from './Ghg/GhgLensOnePagerReport';
import { TooltipV3 } from '../../../../../components/TooltipV3';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Summary as ProductionSummary } from './Production/1_Summary';
import { Details as ProductionDetails } from './Production/2_Details';
import { GoalAndScope as ProductionGoalAndScope } from './Production/3_GoalAndScope';
import { Lcia as ProductionLcia } from './Production/4_Lcia';
import { Interpretation as ProductionInterpretation } from './Production/5_Interpretation';
import { Appendix as ProductionAppendix } from './Production/6_Appendix';

import { Summary as FESummary } from './Consumer/FoundationEarth/1_Summary';
import { Details as FEDetails } from './Consumer/FoundationEarth/2_Details';
import { GoalAndScope as FEGoalAndScope } from './Consumer/FoundationEarth/3_GoalAndScope';
import { ImpactAnalysis as FEImpactAnalysis } from './Consumer/FoundationEarth/4_ImpactAnalysis';
import { EnvironmentalLabel as FEEnvironmentalLabel } from './Consumer/FoundationEarth/5_EnvironmentalLabel';
import { Appendix as FEAppendix } from './Consumer/FoundationEarth/6_Appendix';

import { Summary as SustainedSummary } from './Consumer/Sustained/1_Summary';
import { Details as SustainedDetails } from './Consumer/Sustained/2_Details';
import { GoalAndScope as SustainedGoalAndScope } from './Consumer/Sustained/3_GoalAndScope';
import { ImpactAnalysis as SustainedImpactAnalysis } from './Consumer/Sustained/4_ImpactAnalysis';
import { EnvironmentalLabel as SustainedEnvironmentalLabel } from './Consumer/Sustained/5_EnvironmentalLabel';
import { Appendix as SustainedAppendix } from './Consumer/Sustained/6_Appendix';

import { Summary as GhgSummary } from './Ghg/1_Summary';
import { Details as GhgDetails } from './Ghg/2_Details';
import { GoalAndScope as GhgGoalAndScope } from './Ghg/3_GoalAndScope';
import { Results as GhgResults } from './Ghg/4_Results';
import { Appendix as GhgAppendix } from './Ghg/5_Appendix';

import { ProductionOverview } from './Production/Overview';
import { ConsumerOverview } from './Consumer/Overview';

import { getGhgProductReport, getProductReport, GhgProductReport, Lens, Methodology, ProductReport, ProductType } from '../../../../../api';
import { useApiQuery } from '../../../../../hooks/useApiQuery';
import { useProfile } from '../../../../../hooks/useProfile';
import { GhgOverview } from './Ghg/Overview';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../../../components/charts/shared';
import cn from 'classnames';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';

export const A4_HEIGHT = 841.89;
export const A4_WIDTH = 595.28;

export const Main = () => {
  const { productId } = useParams();
  const params = useParams();
  const [lens, setLens] = useState<Lens>(params['*']?.split('/')[0] as Lens);
  const location = useLocation();
  const navigate = useNavigate();
  const { routes } = useAppRoutes();

  const productionLoaded = useRef(false);
  const consumerLoaded = useRef(false);
  const ghgLoaded = useRef(false);

  const productionReport = useApiQuery(getProductReport(productId!, { search: { lens: Lens.Production } }), {
    cancel: lens !== Lens.Production || productionLoaded.current,
  });
  const consumerReport = useApiQuery(getProductReport(productId!, { search: { lens: Lens.Consumer } }), {
    cancel: lens !== Lens.Consumer || consumerLoaded.current,
  });
  const ghgReport = useApiQuery(getGhgProductReport(productId!), {
    cancel: lens !== Lens.Ghg || ghgLoaded.current,
  });

  const onePagerReportRef = useRef(null);
  const [scaledTo1kgReportData, setScaledTo1kgReportData] = useState<ProductReport | GhgProductReport>();
  const [loading, setLoading] = useState(false);

  const isFoundationEarthWorkspace = useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location.pathname]);

  useEffect(() => {
    if (params) {
      setLens(params['*']?.split('/')[0] as Lens);
    }
  }, [params]);

  useEffect(() => {
    if (productionReport.data) {
      productionReport.data.analysis.lifecycleStageImpacts.forEach((entity) => {
        entity.bgColor = setColor({ key: entity.name }, lifeCycleStagesColorHexSchema);
      });
      productionReport.data.analysis.impactStagesMatrix.forEach((entity) =>
        entity.stages.map((stage) => ({
          ...stage,
          bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema),
        })),
      );
      productionLoaded.current = true;
    }
    if (consumerReport.data) {
      consumerLoaded.current = true;
    }
    if (ghgReport.data) {
      ghgLoaded.current = true;
    }
  }, [productionReport.data, consumerReport.data, ghgReport.data]);

  const productionTabs = [
    { path: 'summary', Component: ProductionSummary, label: 'Summary' },
    { path: 'details', Component: ProductionDetails, label: 'Product Details' },
    { path: 'goal-and-scope', Component: ProductionGoalAndScope, label: 'Goal & Scope' },
    { path: 'lcia', Component: ProductionLcia, label: 'LCIA' },
    { path: 'interpretation', Component: ProductionInterpretation, label: 'Interpretation' },
    { path: 'appendix', Component: ProductionAppendix, label: 'Appendix' },
  ];

  const consumerTabsSustained = [
    { path: 'summary', Component: SustainedSummary, label: 'Summary' },
    { path: 'details', Component: SustainedDetails, label: 'Product Details' },
    { path: 'goal-and-scope', Component: SustainedGoalAndScope, label: 'Goal & Scope' },
    { path: 'impact-analysis', Component: SustainedImpactAnalysis, label: 'Impact Analysis' },
    { path: 'environmental-label', Component: SustainedEnvironmentalLabel, label: 'Environmental Label' },
    { path: 'appendix', Component: SustainedAppendix, label: 'Appendix' },
  ];

  const consumerTabsFoundationEarth = [
    { path: 'summary', Component: FESummary, label: 'Summary' },
    { path: 'details', Component: FEDetails, label: 'Product Details' },
    { path: 'goal-and-scope', Component: FEGoalAndScope, label: 'Goal & Scope' },
    { path: 'impact-analysis', Component: FEImpactAnalysis, label: 'Impact Analysis' },
    { path: 'environmental-label', Component: FEEnvironmentalLabel, label: 'Environmental Label' },
    { path: 'appendix', Component: FEAppendix, label: 'Appendix' },
  ];

  const ghgTabs = [
    { path: 'summary', Component: GhgSummary, label: 'Summary' },
    { path: 'details', Component: GhgDetails, label: 'Product Details' },
    { path: 'goal-and-scope', Component: GhgGoalAndScope, label: 'Goal & Scope' },
    { path: 'results', Component: GhgResults, label: 'Results' },
    { path: 'appendix', Component: GhgAppendix, label: 'Appendix' },
  ];

  const data = (lens === Lens.Production ? productionReport : lens === Lens.Consumer ? consumerReport : ghgReport).data;

  const title = `${data?.product.name} (${data?.product.amount.value}${data?.product.amount.unit.name})`;

  const header = (
    <div className='sticky top-0 z-30 bg-white'>
      {scaledTo1kgReportData && (
        <div
          style={{
            width: A4_WIDTH,
            height: A4_HEIGHT,
          }}
          className='absolute -translate-y-full text-dark'
        >
          {(() => {
            switch (lens) {
              case Lens.Production:
                return (
                  <ProductionLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as ProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              case Lens.Consumer:
                return (
                  <ConsumerLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as ProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              case Lens.Ghg:
                return (
                  <GhgLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as GhgProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              default:
                return;
            }
          })()}
        </div>
      )}
      <div className='print:hidden flex items-center justify-between gap-2 h-16 border-b border-zinc-200 px-6'>
        <div className='flex items-center gap-3 text-lg font-semibold truncate'>
          <NavLink className='hover:underline' to={routes.products.list}>
            Products
          </NavLink>
          <FontAwesomeIcon size='xs' icon={solid('chevron-right')} />
          <div className='truncate'>{title}</div>
        </div>
        <div className='flex items-center gap-x-4'>
          <NavLink
            className='h-8 flex items-center whitespace-nowrap text-sm font-semibold active:scale-95 shadow-inner border border-[#220065] text-[#220065] px-5 rounded-2xl'
            to='overview'
          >
            Return to overview
          </NavLink>

          {data?.product.productType !== ProductType.Internal && (
            <TooltipV3
              placement='bottom-end'
              content={<div className='bg-[#220065] px-2 py-1 rounded-lg shadow text-white text-xs'>Download summary report</div>}
            >
              <button
                disabled={loading}
                className={cn(
                  'size-8 focus:scale-95 flex self-center items-center justify-center border border-[#220065] rounded-lg',
                  loading && 'cursor-wait',
                )}
                onClick={() => {
                  setLoading(true);
                  getProductReport(data!.product.id, { search: { lens, scaleToAmount: 1, scaleUnit: 'kg' } }).call({
                    ok: (data) => {
                      setLoading(false);
                      setScaledTo1kgReportData(data);
                    },
                  });
                }}
              >
                <FontAwesomeIcon className='size-5 text-[#220065]' icon={light('file-arrow-down')} />
              </button>
            </TooltipV3>
          )}
        </div>
      </div>

      <div className='hidden print:block truncate border-b border-zinc-200 h-20 font-semibold text-lg'>{title}</div>
    </div>
  );

  const navigation = {
    production: () => navigate(routes.products.productOverview.production(productId!)),
    consumer: () => navigate(routes.products.productOverview.consumer(productId!)),
    ghg: () => navigate(routes.products.productOverview.ghg(productId!)),
  };

  const fetchReport = (): Promise<ProductReport | GhgProductReport> =>
    new Promise((resolve) =>
      getProductReport(productId!, { search: { lens, scaleToAmount: 1, scaleUnit: 'kg' } }).ok((data) => resolve(data)),
    );

  return (
    <Routes>
      <Route
        path='production/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <ProductionOverview
                  showHeader
                  fetchReport={fetchReport}
                  navigation={navigation}
                  lens={lens}
                  data={productionReport.data}
                  setSelectedReportType={setLens}
                  isFoundationEarthWorkspace={isFoundationEarthWorkspace}
                />
              }
            />
            <Route
              path='*'
              element={
                <Report data={productionReport.data} tabs={productionTabs}>
                  <Helmet title={title} />
                  {header}
                  {data && (
                    <div className='p-6 text-dark'>
                      This Sustained Impact Report presents the details of the Life Cycle Analysis (LCA) carried out on a single{' '}
                      {(() => {
                        return {
                          [ProductType.Final]: 'final',
                          [ProductType.Intermediate]: 'intermediate',
                          [ProductType.Internal]: 'internal',
                        }[data.product.productType];
                      })()}{' '}
                      product ({`${data.product.name} ${data.product.amount.value} ${data.product.amount.unit.name}`}) in line with ISO
                      14044 and largely following the PEF framework. A summary, list of primary data provided, detailed scope, results and
                      interpretations are included in this report.
                    </div>
                  )}
                </Report>
              }
            />
          </Routes>
        }
      />
      <Route
        path='consumer/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <ConsumerOverview
                  showHeader
                  fetchReport={fetchReport}
                  navigation={navigation}
                  isFoundationEarthWorkspace={isFoundationEarthWorkspace}
                  lens={lens}
                  setSelectedReportType={setLens}
                  data={consumerReport.data}
                />
              }
            />
            <Route
              path='*'
              element={
                <Report data={consumerReport.data} tabs={isFoundationEarthWorkspace ? consumerTabsFoundationEarth : consumerTabsSustained}>
                  <Helmet title={title} />
                  {header}
                  <div className='m-6 text-dark'>
                    {isFoundationEarthWorkspace && (
                      <>
                        This Consumer Report presents the details of the Life Cycle Analysis (LCA) carried out on the Tomato Sauce product
                        following the Foundation Earth methodology largely aligned with PEF ultimately targeting environmental labelling. A
                        summary, list of primary data provided, detailed scope and results are included in this report.
                      </>
                    )}
                    {!isFoundationEarthWorkspace && (
                      <>
                        This Consumer Report presents the details of the Life Cycle Analysis (LCA) carried out on the Tomato Sauce product
                        following the Sustained methodology largely aligned with PEF. A summary, list of primary data provided, detailed
                        scope and results are included in this report.
                      </>
                    )}
                  </div>
                </Report>
              }
            />
          </Routes>
        }
      />
      <Route
        path='ghg/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <GhgOverview
                  fetchReport={fetchReport}
                  showHeader
                  navigation={navigation}
                  isFoundationEarthWorkspace={isFoundationEarthWorkspace}
                  lens={lens}
                  setSelectedReportType={setLens}
                  data={ghgReport.data}
                />
              }
            />
            <Route
              path='*'
              element={
                <Report tabs={ghgTabs} data={ghgReport.data}>
                  <Helmet title={title} />
                  {header}
                  <div className='m-6 text-dark'>
                    This Sustained Impact Report presents the results of the Greenhouse Gas (GHG) Protocol Scope 3 assessment carried out on
                    a single product ({ghgReport.data?.product.name} - {ghgReport.data?.product.amount.value}
                    {ghgReport.data?.product.amount.unit.name}). A summary, list of product data provided, detailed scope, results and
                    appendix are included in this report.
                  </div>
                </Report>
              }
            />
          </Routes>
        }
      />
      <Route path='*' element={<Navigate to='production/overview' replace />} />
    </Routes>
  );
};
