import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Customer } from '../../../../api';

interface Props {
  selectedCustomer: Customer;
}
export const Details = (props: Props) => {
  return (
    <>
      <div className='flex items-center font-semibold text-base text-zinc-800 h-10'>Organisation details</div>
      <div className='grid grid-cols-[auto_1fr] items-center gap-y-6 gap-x-16'>
        <div>Name</div>
        <div className='font-semibold'>{props.selectedCustomer.companyName}</div>
        <div>Id</div>
        <div className='flex gap-x-4 items-center'>
          {props.selectedCustomer.customerSid}
          <button
            className='active:scale-95 hover:text-brand'
            onClick={() => navigator.clipboard.writeText(props.selectedCustomer.customerSid)}
          >
            <FontAwesomeIcon icon={regular('copy')} />
          </button>
        </div>
        <div>Paying customer</div>
        <div>{props.selectedCustomer.defaultWorkspace.billingMode.name}</div>
        <div>Organisation Logo</div>
        <div>
          {props.selectedCustomer.logoUrl ? (
            <img className='shrink-0 size-8 rounded-full overflow-hidden' src={props.selectedCustomer.logoUrl} alt='_' />
          ) : (
            <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
              <FontAwesomeIcon icon={regular('building')} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
