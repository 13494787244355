import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { ReactNode, useContext, useState } from 'react';
import { getProductReportCsv, GhgProductReport, Lens, ProductReport } from '../../../../../../api';
import { ByImpactContributionTable } from '../../../../../../components/ByImpactContributionTable';
import {
  ByStageContributionTable,
  fromMatrixComponent,
  fromStageImpactsComponent,
} from '../../../../../../components/ByStageContributionTable';
import { ContextualExampleTile } from '../../../../../../components/ContextualExampleTile';
import { ImpactSelect } from '../../../../../../components/ImpactSelect';
import { ProductImprovementTipsV3 } from '../../../../../../components/ProductImprovementTipsV3';
import { SingleSelect } from '../../../../../../components/SingleSelect';
import { ViewToggle } from '../../../../../../components/ViewToggle';
import { LifeCycleStagesLegend } from '../../../../../../components/charts/LifeCycleStagesLegend';
import { PieChart } from '../../../../../../components/charts/PieChart';
import { RadarChart } from '../../../../../../components/charts/RadarChart';
import {
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
  SunburstChart,
} from '../../../../../../components/charts/SunburstChart/SunburstChart';
import { useCsvByLifeCycleStageHref } from '../../../../../../hooks/useCsvByLifeCycleStageHref';
import { simplify, roundToLong } from '../../../../shared';
import { getProductionFacilitiesFromAll } from '../../../Details/dataModel';
import { ImpactsRepresentationType, ImpactValueType, Overview } from '../Overview';
import { ProductSkeletonLoader } from '../components/ProductSkeletonLoader';
import { BasicProductDetailsTile } from '../components/BasicProductDetailsTile';
import { WorkspaceContext } from '../../../../../../hooks/useWorspace';

interface Props {
  fetchReport: () => Promise<ProductReport | GhgProductReport>;
  navigation: {
    production: () => void;
    consumer: () => void;
    ghg: () => void;
  };
  showHeader: boolean;
  data?: ProductReport;
  lens: Lens;
  setSelectedReportType: (value: Lens) => void;
  isFoundationEarthWorkspace: boolean;
}

export const ProductionOverview = (props: Props) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact', unit: undefined };
  const [selectedView, setSelectedView] = useState<ImpactsRepresentationType>(ImpactsRepresentationType.Graph);
  const [expanded, setExpanded] = useState(new Array<string>());
  const [exporting, setExporting] = useState(false);
  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string; unit?: string }>(overallImpact);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);
  const { workspaceId } = useContext(WorkspaceContext);

  const handleExpandedState = (item1: string) =>
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));

  const csvByLifeCycleStageHref = useCsvByLifeCycleStageHref({
    data: props.data?.analysis,
    selectedImpact: selectedImpact.id === 'overall_impact' ? undefined : selectedImpact,
  });

  if (!props.data) {
    return <ProductSkeletonLoader lens={props.lens} />;
  }

  const exportImpactByCategoryAsCsvButton = (lens: Lens) => (
    <button
      type='button'
      disabled={exporting}
      className='print:hidden flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold disabled:cursor-wait text-base'
      onClick={() => {
        setExporting(true);
        getProductReportCsv(
          {
            productId: props.data!.product.id,
            fileName: `${props.data!.product.name} - ${props.data!.product.skuId} - Impact by category (${format(new Date(), 'dd.MM.yy')})`,
            lens,
          },
          workspaceId,
        ).call({
          ok: () => setExporting(false),
          fail: () => setExporting(false),
        });
      }}
    >
      <FontAwesomeIcon icon={solid('download')} />
      Export as CSV
    </button>
  );

  const showIncompleteAssessmentWarning = (data: ProductReport) =>
    getProductionFacilitiesFromAll(data.product.nodes).find((facility) =>
      facility.steps.some((step) => step.outputs.find((output) => output.emission)),
    );

  const collapsible = (title: string, description: string, icon: ReactNode, children: ReactNode) => (
    <div className='print:mt-6'>
      <button
        onClick={() => handleExpandedState(title)}
        className='py-6 w-full flex justify-between items-center px-10 hover:cursor-pointer hover:bg-lightBg/75'
      >
        <div className='flex gap-4 items-center'>
          <div>{icon}</div>
          <div className='flex flex-col gap-2'>
            <div className='text-lg font-semibold'>{title}</div>
            <div>{description}</div>
          </div>
        </div>
        <FontAwesomeIcon
          className='text-xl print:hidden'
          icon={expanded.includes(title) ? solid('chevron-down') : solid('chevron-right')}
        />
      </button>
      <div className='print:hidden'>{expanded.includes(title) && children}</div>
      <div className='hidden print:block'>{children}</div>
    </div>
  );

  return (
    <Overview
      fetchReport={props.fetchReport}
      navigation={props.navigation}
      showHeader={props.showHeader}
      data={props.data}
      lens={props.lens}
      setSelectedReportType={props.setSelectedReportType}
      isFoundationEarthWorkspace={props.isFoundationEarthWorkspace}
    >
      <div className='divide-y'>
        <div className='grid grid-cols-3 gap-x-4 my-6'>
          <BasicProductDetailsTile selectedReportType={Lens.Production} data={props.data} />
          <div className='flex flex-col gap-4 col-span-2'>
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
                <div className='font-semibold text-lg'>First Party Data</div>
                <div className='text-[32px]'>{props.data.firstPartyDataPercentage}%</div>
              </div>
              <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
                <div className='font-semibold text-lg'>Product Impact</div>
                <div className='flex items-center gap-x-2' title={roundToLong(props.data.impactPoints)}>
                  <div className='text-[32px]'>{simplify(props.data.impactPoints)}</div>
                  <div className='uppercase text-zinc-500 text-xs'>impact points</div>
                </div>
              </div>
            </div>
            {props.data.improvementTips.length > 0 && <ProductImprovementTipsV3 improvementTips={props.data.improvementTips} />}
          </div>
        </div>

        {collapsible(
          'Impact Analysis Results',
          'Your product’s impact by category, life cycle stage and process as well as the most relevant to each',
          <FontAwesomeIcon className='size-8' icon={duotone('spinner-third')} />,
          <>
            {(() => {
              const tables = () => (
                <div className='flex flex-col gap-6'>
                  <div className='rounded-regular shadow-regular p-6 bg-white'>
                    <div className='flex mb-6'>
                      <div className='flex-1' />
                      <div className='w-fit m-auto py-1 px-4 rounded-md bg-brand/10 font-semibold text-gray-600 shadow-md'>
                        By Impact Category
                      </div>
                      <div className='flex-1 flex justify-end'>{exportImpactByCategoryAsCsvButton(Lens.Production)}</div>
                    </div>
                    <ByImpactContributionTable
                      data={props.data!.analysis.impactStagesMatrix.map((impact) => ({
                        ...impact,
                        id: impact.impactId,
                        name: impact.impactName,
                        contributionPercent: impact.absSharePercent,
                      }))}
                      totalImpact={props.data!.overallImpact}
                      totalPoints={props.data!.impactPoints}
                    />
                  </div>

                  <div className='flex flex-col gap-8 bg-white p-6 rounded-2xl shadow-regular items-center text-dark'>
                    <div className='flex self-stretch'>
                      <div className='flex-1' />
                      <div className='flex self-center px-4 py-1 rounded-md bg-brand/10 font-semibold text-gray-600 print:shadow-none shadow-md'>
                        By Life Cycle Stage & Process
                      </div>
                      <div className='flex-1 flex justify-end'>
                        <a
                          className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base'
                          download={`${props.data!.product.name} - ${props.data!.product.skuId} - Impact by life cycle stage - ${
                            selectedImpact.name
                          } (${format(new Date(), 'dd.MM.yy')}).csv`}
                          href={csvByLifeCycleStageHref}
                        >
                          <FontAwesomeIcon icon={solid('download')} />
                          Export as CSV
                        </a>
                      </div>
                    </div>

                    <div className='print:hidden w-full'>
                      <div className='flex flex-col gap-6 mb-6'>
                        <div className='w-72 self-center'>
                          {(() => {
                            const impacts = [
                              overallImpact,
                              ...props.data!.analysis.impactStagesMatrix.map((impact) => ({
                                ...impact,
                                id: impact.impactId,
                                name: impact.impactName,
                                disabled: false,
                              })),
                            ];
                            return (
                              <SingleSelect
                                selectedId={selectedImpact.id}
                                options={impacts}
                                value={{
                                  value: selectedImpact.id,
                                  label: selectedImpact.name,
                                }}
                                setSelectedId={(v) => {
                                  if (v === 'overall_impact') {
                                    setSelectedImpactValueType(ImpactValueType.Points);
                                  }
                                  setSelectedImpact(impacts.find((impact) => impact.id === v)!);
                                }}
                              />
                            );
                          })()}
                        </div>

                        <ViewToggle
                          theme='dark'
                          button1={{
                            label: 'Final environmental impact',
                            active: selectedImpactValueType === ImpactValueType.Points,
                            disabled: selectedImpact.id === 'overall_impact',
                          }}
                          button2={{
                            label: 'Physical Impact',
                            active: selectedImpactValueType === ImpactValueType.Physical,
                            disabled: selectedImpact.id === 'overall_impact',
                          }}
                          toggleView={() =>
                            setSelectedImpactValueType((current) =>
                              current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                            )
                          }
                        />
                      </div>

                      <ByStageContributionTable
                        selectedImpact={selectedImpact}
                        selectedImpactValueType={selectedImpactValueType}
                        productName={props.data!.product.name}
                        unit={selectedImpact.unit}
                        showPhysicalValues={selectedImpactValueType === ImpactValueType.Physical}
                        data={
                          selectedImpact.id === 'overall_impact'
                            ? props.data!.analysis.lifecycleStageImpacts.map((stage) => ({
                                ...stage,
                                value: stage.impactValue,
                                absSharePercent: stage.absImpactSharePercent,
                                components: fromStageImpactsComponent(stage.components),
                              }))
                            : props
                                .data!.analysis.impactStagesMatrix.find(({ impactId }) => impactId === selectedImpact.id)!
                                .stages.map((stage) => ({
                                  ...stage,
                                  absSharePercent: stage.absSharePercent,
                                  value:
                                    selectedImpactValueType === ImpactValueType.Physical
                                      ? stage.physicalValue
                                      : stage.weightedNormalisedValue,
                                  isMajorImpact: stage.isMajorStage,
                                  components: fromMatrixComponent(stage.components, selectedImpactValueType === 'physical'),
                                }))
                        }
                        totalImpact={
                          selectedImpact.id === 'overall_impact'
                            ? props.data!.overallImpact
                            : selectedImpactValueType === ImpactValueType.Physical
                            ? props
                                .data!.analysis.impactStagesMatrix.find(({ impactId }) => impactId === selectedImpact.id)!
                                .stages.map(({ physicalValue }) => physicalValue)
                                .reduce((a, b) => a + b, 0)
                            : props
                                .data!.analysis.impactStagesMatrix.find(({ impactId }) => impactId === selectedImpact.id)!
                                .stages.map(({ weightedNormalisedValue }) => weightedNormalisedValue)
                                .reduce((a, b) => a + b, 0)
                        }
                        totalPoints={
                          selectedImpact.id === 'overall_impact'
                            ? props.data!.impactPoints
                            : props
                                .data!.analysis.impactStagesMatrix.find(({ impactId }) => impactId === selectedImpact.id)!
                                .stages.map(({ impactPoints }) => impactPoints)
                                .reduce((a, b) => a + b, 0)
                        }
                      />
                    </div>

                    <div className='hidden print:block'>
                      <ByStageContributionTable
                        productName={props.data?.product.name}
                        selectedImpact={selectedImpact}
                        selectedImpactValueType={selectedImpactValueType}
                        data={props.data!.analysis.lifecycleStageImpacts.map((impact) => ({
                          ...impact,
                          value: impact.impactValue,
                          absSharePercent: impact.absImpactSharePercent,
                          components: fromStageImpactsComponent(impact.components),
                        }))}
                        totalImpact={props.data!.overallImpact}
                        totalPoints={props.data!.impactPoints}
                      />
                    </div>
                  </div>
                </div>
              );

              return (
                <div className='flex flex-col gap-6 justify-center bg-lightBg print:bg-transparent p-6'>
                  {showIncompleteAssessmentWarning(props.data) && (
                    <div className='flex flex-col gap-1 text-sm bg-amber-50 border-amber-400 rounded-lg border p-2'>
                      <div className='flex gap-2 items-center uppercase font-semibold'>
                        <FontAwesomeIcon className='text-base' icon={regular('memo-circle-info')} />
                        <div>Incomplete assessment</div>
                      </div>
                      <div>
                        Emissions captured in this product were not included in the impact calculation. This is a known limitation of our
                        system and we will be working to add this capability to our LCA calculator soon. Since you’ve already captured them,
                        you won’t have to do a thing though and we will automatically recalculate this product as soon as we are able to
                        with the correct outputs specified. They will automatically become visible in the results presented in this section.
                      </div>
                    </div>
                  )}
                  <ViewToggle
                    theme='dark'
                    button1={{
                      label: 'Graph view',
                      icon: regular('chart-simple'),
                      active: selectedView === ImpactsRepresentationType.Graph,
                    }}
                    button2={{
                      label: 'Table view',
                      icon: regular('table'),
                      active: selectedView === ImpactsRepresentationType.Table,
                    }}
                    toggleView={() =>
                      setSelectedView((current) =>
                        current === ImpactsRepresentationType.Graph ? ImpactsRepresentationType.Table : ImpactsRepresentationType.Graph,
                      )
                    }
                  />

                  {selectedView === ImpactsRepresentationType.Graph ? (
                    <div className='flex flex-col gap-6'>
                      <div className='flex flex-col gap-8 bg-white p-6 shadow-regular rounded-2xl items-center'>
                        <div className='px-4 py-1 rounded-md bg-brand/10 font-semibold text-gray-600 shadow-md'>By Impact Category</div>
                        <RadarChart
                          height={400}
                          width={650}
                          tooltipType='single'
                          meta={[{ dataKey: 'impactPoints', color: '#4f00ff' }]}
                          payload={props.data.analysis.impactStagesMatrix.map((it) => ({
                            ...it,
                            name: it.impactName,
                            keyName: 'Impact',
                            isMajor: it.isMajorImpact,
                          }))}
                        />
                      </div>
                      <div className='print:hidden grid grid-cols-2 gap-6'>
                        <div className='flex flex-col justify-between items-center break-inside-avoid bg-white shadow-regular rounded-2xl p-6'>
                          <div className='flex self-center px-4 py-1 rounded-md bg-brand/10 font-semibold text-gray-600 shadow-md'>
                            By Life Cycle Stage
                          </div>
                          <div className='flex justify-center h-full'>
                            <PieChart
                              showTicks
                              size={460}
                              outerRadius={150}
                              selectedType='impactPoints'
                              data={props.data.analysis.lifecycleStageImpacts.map((it) => ({
                                name: it.name,
                                bgColor: it.bgColor!,
                                value: it.absImpactSharePercent,
                                isMajor: it.isMajorImpact!,
                                impactPoints: it.impactPoints,
                              }))}
                            />
                          </div>
                          <div className='text-sm pt-6'>
                            <LifeCycleStagesLegend renderMajor />
                          </div>
                        </div>
                        <div className='flex flex-col gap-6 items-center break-inside-avoid bg-white shadow-regular rounded-2xl p-6'>
                          <div className='px-4 py-1 rounded-md bg-brand/10 font-semibold text-gray-600 shadow-md break-before-column'>
                            By Process
                          </div>
                          <div className='flex flex-col justify-center'>
                            <ImpactSelect
                              theme='dark'
                              impacts={[
                                overallImpact,
                                ...props.data.analysis.impactStagesMatrix.map((item) => ({
                                  id: item.impactId,
                                  name: item.impactName,
                                })),
                              ]}
                              selectedImpact={selectedImpact}
                              setSelectedImpact={setSelectedImpact}
                              selectedImpactValueType={selectedImpactValueType}
                              setSelectedImpactValueType={setSelectedImpactValueType}
                            />

                            <div className='flex flex-col items-center'>
                              <div className='mt-6 h-[400px] flex justify-center'>
                                {selectedImpact.id === 'overall_impact' ? (
                                  <SunburstChart
                                    size={350}
                                    data={convertFromLifecycleStageImpacts(props.data.analysis.lifecycleStageImpacts)}
                                  />
                                ) : (
                                  <SunburstChart
                                    size={350}
                                    selectedCategory={props.data.analysis.impactStagesMatrix.find(
                                      ({ impactId }) => selectedImpact.id === impactId,
                                    )}
                                    showPhysicalImpact={selectedImpactValueType === ImpactValueType.Physical}
                                    selectedCategoryPhysicalUnit={
                                      props.data.analysis.impactStagesMatrix.find(({ impactId }) => selectedImpact.id === impactId)!.unit
                                    }
                                    data={convertFromImpactStagesMatrix(
                                      props.data.analysis.impactStagesMatrix.find(({ impactName }) => impactName === selectedImpact.name)!
                                        .stages,
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='text-sm pt-6'>
                              <LifeCycleStagesLegend renderMajor />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='print:hidden'>{tables()}</div>
                  )}

                  <div className='hidden print:block'>{tables()}</div>
                </div>
              );
            })()}
          </>,
        )}
        {collapsible(
          'Physical Impact - Equivalence in real life',
          'What’s your impact, translated into relatable measures',
          <FontAwesomeIcon className='size-8' icon={duotone('leaf')} />,
          <div className='grid grid-cols-3 bg-lightBg print:bg-transparent flex-wrap gap-12 p-10'>
            {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
              <ContextualExampleTile key={i} contextualExample={contextualExample} />
            ))}
          </div>,
        )}
      </div>
    </Overview>
  );
};
