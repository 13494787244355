import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldProps, Form, Formik, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Dispatch, Fragment, MutableRefObject, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ReactSelectAsync from 'react-select/async';
import * as yup from 'yup';
import {
  Entity,
  ExtractionResult,
  ExtractionStatus,
  ExtractionStep,
  IngredientV3,
  ProductImportPayload,
  ProductImportValue,
  ProductStage,
  ProductType,
  pollSpecificationExtraction,
  scheduleSpecificationExtraction,
  searchIngredientsV3,
  submitSpecification,
} from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { Components, SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { useLists } from '../../../../hooks/useLists';
import { IngredientSelectOption } from '../Details/IngredientDetails';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

export const Import = () => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const [uploadStep, setUploadStep] = useState(true);
  const [data, setData] = useState<ProductImportPayload | undefined>(undefined);
  const [submitState, setSubmitState] = useState({ waiting: false, disabled: false });
  const onSubmit = useRef<() => void>();

  return (
    <div className='flex flex-col gap-4 text-neutral-900'>
      <div className='flex justify-between items-center gap-4 px-6'>
        <div className='flex items-center gap-4'>
          <button
            type='button'
            className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-md text-lg text-brand bg-[#E8EAF5] active:scale-90'
            onClick={() => {
              if (uploadStep) {
                navigate(routes.products.list);
              } else {
                setUploadStep(true);
              }
            }}
          >
            <FontAwesomeIcon icon={solid('chevron-left')} />
          </button>
          <div className='flex gap-3'>
            <div className='flex items-center gap-2 text-base'>
              <NavLink to={routes.products.list} className='text-zinc-500'>
                Products
              </NavLink>
              <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
              <div className='text-zinc-500'>Import file</div>
              <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
              <button type='button' className={cn(uploadStep ? 'font-semibold' : 'text-zinc-500')} onClick={() => setUploadStep(true)}>
                File upload
              </button>
              {!uploadStep && (
                <>
                  <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
                  <div className='font-semibold'>Properties mapping</div>
                </>
              )}
            </div>
            <div className='uppercase text-brand text-xs tracking-widest font-semibold'>Beta</div>
          </div>
        </div>
        {!uploadStep && <CreateButton {...submitState} onClick={onSubmit.current} />}
      </div>
      <div className='min-h-[500px] flex justify-center bg-neutral-50 border-y border-zinc-300 py-20 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col gap-12 max-w-screen-xl px-12'>
          <div className='self-center max-w-3xl flex gap-4 p-4 pr-8 shadow-[0_1px_10px_rgba(0,0,0,0.15)] rounded-2xl bg-white'>
            <div className='flex justify-center items-center rounded-full text-zinc-700 h-10 aspect-square bg-[#D6FF00]'>
              <FontAwesomeIcon size='lg' icon={light('lightbulb')} />
            </div>
            <div className='flex flex-col gap-2 text-zinc-900 flex-1'>
              <div className='inline-flex gap-3 text-base font-semibold'>
                <div>This is an experiment</div>
                <div className='uppercase text-brand text-xs tracking-widest font-semibold'>Beta</div>
              </div>
              <div className='text-sm'>
                We are testing our first AI powered feature and need real world testing in order to refine and improve the outputs. Do not
                trust the results blindly and make sure you confirm the correctness of the data extracted before creating the product.
              </div>
            </div>
          </div>
          {uploadStep ? (
            <UploadStep
              onDone={(data) => {
                setData(data);
                setUploadStep(false);
              }}
            />
          ) : (
            <ReviewStep
              data={data!}
              syncSubmitState={(state) => {
                if (!isEqual(state, submitState)) {
                  setSubmitState(state);
                }
              }}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const UploadStep = (props: { onDone: (data: ProductImportPayload) => void }) => {
  const [waiting, setWaiting] = useState(false);
  const [ingredients, setIngredients] = useState(false);
  const [productType, setProductType] = useState(ProductType.Final);
  const [jobState, setJobState] = useState<ExtractionResult & { steps: ExtractionStep[] }>();

  useEffect(() => {
    if (jobState) {
      const intervalId = setInterval(() => {
        pollSpecificationExtraction(jobState.sid).ok((newState) => {
          setJobState((oldState) => ({ ...oldState!, ...newState }));

          if (newState.status === ExtractionStatus.Complete) {
            props.onDone(newState.product!);
          }

          if (newState.status === ExtractionStatus.Failed) {
            setWaiting(false);
            setJobState(undefined);
            alert(newState.summary!);
          }
        });
      }, 2_000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!jobState]);

  return (
    <div className='flex flex-col items-center gap-10'>
      <div className='font-semibold text-xl'>Automatically extract product information from a document</div>
      <div className='w-full max-w-lg text-zinc-500'>
        Harness the power of AI to simplify data ingestion. First, specify what kind of product you would like to create, then upload a file
        (.pdf, .docx, .json) and let our extractor do its magic.
      </div>
      {jobState ? (
        <>
          <ol className='w-full max-w-lg flex flex-col gap-4 justify-center list-decimal pl-4'>
            {jobState.steps.map(({ status, message }, index) => {
              const inProgressIndex = jobState.steps.findIndex(({ status }) => status === jobState.status);
              return (
                <li
                  key={status}
                  className={cn(
                    'transition marker:font-semibold',
                    index <= inProgressIndex ? 'marker:text-brandDarkPurple2' : 'marker:text-zinc-500',
                  )}
                >
                  <div className='flex flex-col gap-4'>
                    <div
                      className={cn(
                        'transition flex justify-between gap-10 font-semibold',
                        index <= inProgressIndex ? 'text-brandDarkPurple2' : 'text-zinc-500',
                      )}
                    >
                      <div>{message}</div>
                      <div className='relative top-0.5'>
                        {inProgressIndex === index && <FontAwesomeIcon icon={duotone('spinner-third')} className='animate-spin' />}
                        {inProgressIndex > index && <FontAwesomeIcon icon={regular('check-circle')} />}
                        {inProgressIndex < index && <FontAwesomeIcon icon={regular('clock')} />}
                      </div>
                    </div>
                    {status === ExtractionStatus.Summarising &&
                      inProgressIndex > jobState.steps.findIndex(({ status }) => status === ExtractionStatus.Summarising) && (
                        <div className='flex flex-col gap-4 p-4 rounded-lg bg-zinc-100 border border-zinc-200'>
                          <div className='font-semibold text-zinc-800 leading-none'>Results so far</div>
                          {(() => {
                            const lines = jobState.summary!.split('\n');
                            const linesToShow = 6;

                            return (
                              <>
                                <div className='flex flex-col gap-3'>
                                  {lines.slice(0, linesToShow).map((line, i) => (
                                    <div key={i} className='text-zinc-700 text-sm'>
                                      {line}
                                    </div>
                                  ))}
                                </div>
                                {lines.length > linesToShow && (
                                  <div className='text-zinc-400 text-sm text-right'>
                                    +{lines.length - linesToShow} more rows of information
                                  </div>
                                )}
                              </>
                            );
                          })()}
                        </div>
                      )}
                    {status === ExtractionStatus.Refining && status === jobState.status && (
                      <div className='flex flex-col gap-4 p-4 rounded-lg bg-zinc-100 border border-zinc-200'>
                        <div className='font-semibold text-zinc-800 leading-none'>Results so far</div>
                        <div className='grid grid-cols-[max-content_1fr] gap-x-8 gap-y-3'>
                          {[
                            { label: 'Product name', value: jobState.product?.name.mapped },
                            {
                              label: 'Net amount',
                              value: `${jobState.product?.amount.mapped?.value ?? ''}${jobState.product?.amount.mapped?.unit.name ?? ''}`,
                            },
                            { label: 'Ingredients', value: jobState.product?.ingredients.length },
                            { label: 'Packagings', value: jobState.product?.packagings.length },
                          ].map(({ label, value }) => (
                            <Fragment key={label}>
                              <div className='uppercase tracking-wider text-zinc-600 text-xs relative top-[3px]'>{label}</div>
                              <div className='text-zinc-700 font-semibold text-sm'>
                                {value || <span className='text-zinc-400 font-normal'>Not found</span>}
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
          <div className='flex items-center gap-1.5 text-zinc-400 text-sm text-center'>
            <FontAwesomeIcon icon={regular('clock')} size='sm' />
            Please wait, this may take a few minutes.
          </div>
        </>
      ) : (
        <>
          <div className='flex flex-col gap-3'>
            {[
              { value: ProductType.Final, label: 'Final product', text: 'Products sold to consumers.' },
              {
                value: ProductType.Intermediate,
                label: 'Intermediate product',
                text: 'Products sold for further processing, usually to other businesses.',
              },
              {
                value: ProductType.Internal,
                label: 'Internal product (not sold)',
                text: 'Products made internally for use as input to other products.',
              },
            ].map(({ value, label, text }) => (
              <button
                key={value}
                type='button'
                disabled={waiting}
                className={cn(
                  'text-zinc-800 rounded-lg border px-5 py-3',
                  productType === value ? 'border-brand bg-slate-50' : 'border-zinc-300 bg-white',
                )}
                onClick={() => setProductType(value)}
              >
                <div className='font-semibold'>{label}</div>
                <div className='opacity-50'>{text}</div>
              </button>
            ))}
          </div>
          {ingredients && !waiting ? (
            <textarea
              autoFocus
              className='w-full max-w-lg rounded-xl border border-zinc-300 bg-white px-5 py-4 text-zinc-900'
              placeholder='Paste your ingredients here…'
              rows={5}
              onChange={(event) => {
                const value = event.target.value;

                if (value.length > 20) {
                  setWaiting(true);
                  scheduleSpecificationExtraction({ productType, ingredients: value }).call({
                    ok: (state) => {
                      if (state.errorCode) {
                        alert(state.message);
                        setWaiting(false);
                      } else {
                        setJobState(state);
                      }
                    },
                    fail: () => setWaiting(false),
                  });
                }
              }}
            />
          ) : (
            <div className='flex flex-col gap-3 items-center'>
              <div className='relative'>
                <button type='button' className='flex items-center gap-2 bg-brand rounded-full pl-8 pr-10 py-2.5 text-white font-semibold'>
                  {waiting ? (
                    <>
                      <FontAwesomeIcon icon={duotone('spinner-third')} className='animate-spin' />
                      <div>Uploading…</div>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={regular('file-upload')} />
                      <div>Upload a file</div>
                    </>
                  )}
                </button>
                <input
                  type='file'
                  accept='.c, .cpp, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .jpeg, .jpg, .js, .gif, .png, .tar, .ts, .xlsx, .xml, .zip'
                  disabled={waiting}
                  className='absolute inset-0 text-transparent file:w-full file:h-full file:opacity-0 file:cursor-pointer file:disabled:cursor-default'
                  onChange={(event) => {
                    const file = event.target.files![0];

                    if (file) {
                      setWaiting(true);
                      scheduleSpecificationExtraction({ productType, file }).call({
                        ok: (state) => {
                          if (state.errorCode) {
                            alert(state.message);
                            setWaiting(false);
                          } else {
                            setJobState(state);
                          }
                        },
                        fail: () => {
                          event.target.value = '';
                          setWaiting(false);
                        },
                      });
                    }
                  }}
                />
              </div>
              {!waiting && (
                <button type='button' className='underline text-zinc-400 text-sm' onClick={() => setIngredients(true)}>
                  I want to paste a list of ingredients instead
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

interface ReviewStepProps {
  data: ProductImportPayload;
  syncSubmitState: (state: { disabled: boolean; waiting: boolean }) => void;
  onSubmit: MutableRefObject<(() => void) | undefined>;
}

const ReviewStep = (props: ReviewStepProps) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const [skuIdExists, setSkuIdExists] = useState(false);

  return (
    <Formik
      initialValues={props.data}
      validateOnMount
      validationSchema={yup.object().shape({
        stage: yup.object().shape({
          mapped: yup.string().required(),
        }),
        name: yup.object().shape({
          mapped: yup.string().required(),
        }),
        skuId: yup.object().shape({
          mapped: yup.string().required(),
        }),
        amount: yup.object().shape({
          mapped: yup.object().shape({
            value: yup.number().positive().required(),
            unit: yup.object().required(),
          }),
        }),
        economicValue: yup.object().shape({
          mapped: yup.object().shape({
            price: yup.number().positive().required(),
            currency: yup.object().required(),
          }),
        }),
        servings: yup.object().shape({
          mapped: yup.number().integer().positive().required(),
        }),
        category: yup.object().shape({
          mapped: yup.object().required(),
        }),
        foodType: yup.object().shape({
          mapped: yup.object().required(),
        }),
        conservation: yup.object().shape({
          mapped: yup.object().required(),
        }),
        ingredients: yup.array().of(
          yup.object({
            ingredient: yup.object().shape({
              mapped: yup.object().required(),
            }),
            amount: yup.object().shape({
              mapped: yup.object().shape({
                value: yup.number().positive().required(),
                unit: yup.object().required(),
              }),
            }),
          }),
        ),
        packagings: yup.array().of(
          yup.object({
            packaging: yup.object().shape({
              mapped: yup.object().required(),
            }),
            amount: yup.object().shape({
              mapped: yup.object().shape({
                value: yup.number().positive().required(),
                unit: yup.object().required(),
              }),
            }),
          }),
        ),
      })}
      onSubmit={(values, formik) => {
        submitSpecification(values).call({
          ok: ({ id, errorCode }) => {
            if (errorCode === 'sku-id-exists') {
              setSkuIdExists(true);
              formik.setSubmitting(false);
            } else {
              navigate(routes.products.productGraph(id));
            }
          },
          fail: () => {
            formik.setSubmitting(false);
          },
        });
      }}
    >
      <ReviewStepForm {...props} apiErrors={{ skuIdExists }} />
    </Formik>
  );
};

const ReviewStepForm = (
  props: ReviewStepProps & {
    apiErrors: {
      skuIdExists: boolean;
    };
  },
) => {
  const lists = useLists();
  const formik = useFormikContext<ProductImportPayload>();
  const skuIdRef = useRef<HTMLInputElement>(null);
  const [productDetailsExpanded, setProductDetailsExpanded] = useState(true);
  const [ingredientsExpanded, setIngredientsExpanded] = useState(false);
  const [packagingsExpanded, setPackagingsExpanded] = useState(false);
  const [facilitiesExpanded, setFacilitiesExpanded] = useState(false);
  const hasApiErrors = Object.values(props.apiErrors).some((value) => value);
  const hasValidationErrors = Object.keys(formik.errors).length > 0;
  const hasErrors = hasApiErrors || hasValidationErrors;
  const submitState = { disabled: formik.isSubmitting || !formik.isValid, waiting: formik.isSubmitting };

  props.syncSubmitState(submitState);

  props.onSubmit.current = () => {
    formik.submitForm();
  };

  useEffect(() => {
    if (props.apiErrors.skuIdExists) {
      skuIdRef.current?.focus({ preventScroll: true });
      skuIdRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [props.apiErrors.skuIdExists]);

  const renderDivider = (props?: { light?: boolean }) => (
    <div className={cn('col-span-3 h-px', props?.light ? 'bg-zinc-100' : 'bg-zinc-300')} />
  );

  const renderRequiredDivider = (props?: { grouped?: boolean; optional?: boolean }) => (
    <>
      <div className='col-span-3 h-2' />
      <div
        className={cn(
          'col-span-3 py-1.5 uppercase tracking-wider font-semibold text-[10px] text-zinc-500 bg-slate-50 border-y border-slate-200',
          props?.grouped ? 'pl-20' : 'pl-10',
        )}
      >
        {props?.optional ? 'Optional' : 'Required'}
      </div>
      <div className='col-span-3 h-2' />
    </>
  );

  const renderErrorsBadge = () => (
    <div className='font-semibold text-[10px] tracking-wider uppercase rounded-md px-2 py-0.5 bg-red-50 text-red-500'>Errors</div>
  );

  const renderSection = (props: {
    label: string;
    count?: number;
    errors?: boolean;
    expanded: boolean;
    setExpanded: Dispatch<SetStateAction<boolean>>;
    renderer: () => ReactNode;
  }) => (
    <>
      <button
        type='button'
        className='text-sm flex justify-between items-center py-4 pl-4 pr-14 col-span-3'
        onClick={() => props.setExpanded((previous) => !previous)}
      >
        <div className='flex items-center gap-2'>
          <div className='w-4 flex items-center'>
            <FontAwesomeIcon size='xs' icon={props.expanded ? regular('chevron-down') : regular('chevron-right')} />
          </div>
          <div className='font-semibold'>{props.label}</div>
          {props.count !== undefined && <div className='rounded-full p-1.5 leading-none bg-slate-50 text-xs text-brand'>{props.count}</div>}
        </div>
        {props.errors && !props.expanded && renderErrorsBadge()}
      </button>
      {props.expanded && (
        <>
          {props.renderer()}
          <div className='col-span-3 h-3' />
        </>
      )}
    </>
  );

  const renderGroupedHeader = (props: { ingredient: boolean; index: number; errors?: boolean }) => (
    <div className='flex justify-between items-center pl-10 pr-14 pt-1 h-14 col-span-3'>
      <div className='flex items-center gap-2.5 text-sm'>
        <div
          className={cn('flex justify-center items-center rounded-md h-7 aspect-square', {
            'bg-green-50 text-green-900': props.ingredient,
            'bg-lime-50 text-lime-900': !props.ingredient,
          })}
        >
          <FontAwesomeIcon icon={props.ingredient ? duotone('carrot') : duotone('box-open')} />
        </div>
        {props.ingredient ? 'Ingredient' : 'Packaging'} {props.index + 1}
        <FieldArray
          name={props.ingredient ? 'ingredients' : 'packagings'}
          render={(arrayModel) => (
            <button type='button' className='text-zinc-300 hover:text-red-500' onClick={arrayModel.handleRemove(props.index)}>
              <FontAwesomeIcon icon={regular('trash-alt')} />
            </button>
          )}
        />
      </div>
      {props.errors && renderErrorsBadge()}
    </div>
  );

  const renderField = (props: {
    label: string;
    value: ProductImportValue<any>;
    grouped?: boolean;
    number?: boolean;
    renderer: () => ReactNode;
  }) => (
    <>
      <div className={cn('text-sm text-zinc-500 flex items-center', props.grouped ? 'pl-20' : 'pl-10')}>{props.label}</div>
      <div className='text-sm flex items-center'>{props.value.extracted ?? '—'}</div>
      <div
        className={cn('text-sm flex flex-col justify-center pr-14 py-2.5', {
          'w-2/3': props.number,
        })}
      >
        {props.renderer()}
      </div>
    </>
  );

  return (
    <Form className='flex flex-col items-center gap-10'>
      <div className='font-semibold text-xl'>Extraction and closest match mapping completed</div>
      <div className='text-zinc-500 px-4'>
        All you have left to do is review our closest selected mappings, fill in any missing information and hit{' '}
        <span className='font-semibold'>Create product</span>. Where optional fields are not specified, defaults will be used to start
        seeing some initial impact assessment as quickly as possible. You’ll later be able to provide all the missing data by simply editing
        the product.
      </div>
      <div
        className={cn(
          'flex items-center gap-3 pl-4 pr-6 py-2 rounded-xl text-sm font-semibold border bg-white',
          hasErrors ? 'text-red-500 border-red-500' : 'text-lime-600 border-lime-600',
        )}
      >
        {hasValidationErrors ? (
          <>
            <FontAwesomeIcon icon={regular('exclamation-circle')} />
            <div>Some properties couldn’t be found or matched successfully.</div>
          </>
        ) : hasApiErrors ? (
          <>
            <FontAwesomeIcon icon={regular('exclamation-circle')} />
            <div>{props.apiErrors.skuIdExists && <>Provided SKU ID already exists in your account.</>}</div>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={regular('check-circle')} />
            <div>All required properties have been mapped — you can now create the product.</div>
          </>
        )}
      </div>
      <div className='bg-white w-full grid grid-cols-[max-content_repeat(2,1fr)] gap-x-20 rounded-lg border border-zinc-300 shadow-[0_0_3px_0_rgba(0,0,0,0.15)]'>
        <div className='font-semibold py-4 pl-4'>Product properties</div>
        <div className='font-semibold py-4'>Extracted from document</div>
        <div className='font-semibold py-4'>Mapped to Sustained Database</div>
        {renderDivider()}
        {renderSection({
          label: 'Product details',
          expanded: productDetailsExpanded,
          setExpanded: setProductDetailsExpanded,
          renderer: () => (
            <>
              {renderRequiredDivider()}
              {renderField({
                label: 'Status',
                value: props.data.stage,
                renderer: () => (
                  <Field name='stage.mapped'>
                    {(model: FieldProps<ProductStage>) => {
                      const options = [
                        { id: ProductStage.Development, name: 'Development' },
                        { id: ProductStage.Production, name: 'Production' },
                      ];

                      return (
                        <SelectV3
                          model={model}
                          options={options}
                          convertOptions={{
                            fromModel: (value: ProductStage) => options.find(({ id }) => id === value),
                            toModel: (value: Entity) => value?.id,
                          }}
                        />
                      );
                    }}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Name',
                value: props.data.name,
                renderer: () => (
                  <Field name='name.mapped'>{(model: FieldProps<string>) => <InputV3 model={model} placeholder='Type…' />}</Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'ID',
                value: props.data.skuId,
                renderer: () => (
                  <Field name='skuId.mapped'>
                    {(model: FieldProps<string>) => (
                      <InputV3 ref={skuIdRef} model={model} error={props.apiErrors.skuIdExists} placeholder='Type…' />
                    )}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Category',
                value: props.data.category,
                renderer: () => (
                  <Field name='category.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.categories} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Food type',
                value: props.data.foodType,
                renderer: () => (
                  <Field name='foodType.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.foodTypes} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Amount',
                value: props.data.amount,
                number: true,
                renderer: () => (
                  <Field name='amount.mapped'>
                    {(containerModel: FieldProps) => (
                      <Field name='amount.mapped.value'>
                        {(model: FieldProps<number>) => (
                          <Field name='amount.mapped.unit'>
                            {(unitModel: FieldProps<Entity>) => (
                              <UnitInputV3
                                model={model}
                                error={typeof containerModel.meta.error === 'string'}
                                unit={{ model: unitModel, options: lists.units }}
                              />
                            )}
                          </Field>
                        )}
                      </Field>
                    )}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Conservation requirements',
                value: props.data.conservation,
                renderer: () => (
                  <Field name='conservation.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.conservationRequirements} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Economic Value',
                value: props.data.economicValue,
                number: true,
                renderer: () => (
                  <Field name='economicValue.mapped'>
                    {(containerModel: FieldProps) => (
                      <Field name='economicValue.mapped.price'>
                        {(model: FieldProps<number>) => (
                          <Field name='economicValue.mapped.currency'>
                            {(unitModel: FieldProps<Entity>) => (
                              <UnitInputV3
                                model={model}
                                error={typeof containerModel.meta.error === 'string'}
                                unit={{ model: unitModel, options: lists.currencies }}
                              />
                            )}
                          </Field>
                        )}
                      </Field>
                    )}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Servings',
                value: props.data.servings,
                number: true,
                renderer: () => (
                  <Field name='servings.mapped'>{(model: FieldProps<string>) => <InputV3 integer positive model={model} />}</Field>
                ),
              })}
              {renderRequiredDivider({ optional: true })}
              {renderField({
                label: 'GTIN',
                value: props.data.gtin,
                renderer: () => (
                  <Field name='gtin.mapped'>
                    {(model: FieldProps<string>) => <InputV3 model={model} placeholder='Specify or leave empty…' />}
                  </Field>
                ),
              })}
            </>
          ),
        })}
        {formik.values.ingredients.length > 0 && (
          <>
            {renderDivider()}
            {renderSection({
              label: 'Ingredients',
              count: formik.values.ingredients.length,
              errors: !!formik.errors?.ingredients,
              expanded: ingredientsExpanded,
              setExpanded: setIngredientsExpanded,
              renderer: () => (
                <>
                  {formik.values.ingredients.map((ingredient, index) => (
                    <Fragment key={index}>
                      {renderDivider({ light: true })}
                      {renderGroupedHeader({
                        index,
                        ingredient: true,
                        errors: !!formik.errors?.ingredients?.[index],
                      })}
                      {renderRequiredDivider({ grouped: true })}
                      {renderField({
                        label: 'Name',
                        value: ingredient.ingredient,
                        grouped: true,
                        renderer: () => (
                          <Field name={`ingredients.${index}.ingredient.mapped`}>
                            {(model: FieldProps<IngredientV3>) => <IngredientSelect model={model} />}
                          </Field>
                        ),
                      })}
                      {renderDivider({ light: true })}
                      {renderField({
                        label: 'Amount',
                        value: ingredient.amount,
                        number: true,
                        grouped: true,
                        renderer: () => (
                          <Field name={`ingredients.${index}.amount.mapped`}>
                            {(containerModel: FieldProps) => (
                              <Field name={`ingredients.${index}.amount.mapped.value`}>
                                {(model: FieldProps<number>) => (
                                  <Field name={`ingredients.${index}.amount.mapped.unit`}>
                                    {(unitModel: FieldProps<Entity>) => (
                                      <UnitInputV3
                                        model={model}
                                        error={typeof containerModel.meta.error === 'string'}
                                        unit={{ model: unitModel, options: lists.units }}
                                      />
                                    )}
                                  </Field>
                                )}
                              </Field>
                            )}
                          </Field>
                        ),
                      })}
                      {renderRequiredDivider({ optional: true, grouped: true })}
                      {renderField({
                        label: 'Countries of origin',
                        value: ingredient.locations,
                        grouped: true,
                        renderer: () => (
                          <Field name={`ingredients.${index}.locations.mapped`}>
                            {(model: FieldProps<Entity>) => <SelectV3 multi model={model} options={lists.countries} />}
                          </Field>
                        ),
                      })}
                      <div className='col-span-3 h-6' />
                    </Fragment>
                  ))}
                </>
              ),
            })}
          </>
        )}
        {formik.values.packagings.length > 0 && (
          <>
            {renderDivider()}
            {renderSection({
              label: 'Packaging',
              count: formik.values.packagings.length,
              errors: !!formik.errors?.packagings,
              expanded: packagingsExpanded,
              setExpanded: setPackagingsExpanded,
              renderer: () => (
                <>
                  {formik.values.packagings.map((packaging, index) => (
                    <Fragment key={index}>
                      {renderGroupedHeader({
                        index,
                        ingredient: false,
                        errors: !!formik.errors?.packagings?.[index],
                      })}
                      {renderRequiredDivider({ grouped: true })}
                      {renderField({
                        label: 'Type',
                        value: packaging.packaging,
                        grouped: true,
                        renderer: () => (
                          <Field name={`packagings.${index}.packaging.mapped`}>
                            {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.packagingTypes} />}
                          </Field>
                        ),
                      })}
                      {renderDivider({ light: true })}
                      {renderField({
                        label: 'Amount',
                        value: packaging.amount,
                        number: true,
                        grouped: true,
                        renderer: () => (
                          <Field name={`packagings.${index}.amount.mapped`}>
                            {(containerModel: FieldProps) => (
                              <Field name={`packagings.${index}.amount.mapped.value`}>
                                {(model: FieldProps<number>) => (
                                  <Field name={`packagings.${index}.amount.mapped.unit`}>
                                    {(unitModel: FieldProps<Entity>) => (
                                      <UnitInputV3
                                        model={model}
                                        error={typeof containerModel.meta.error === 'string'}
                                        unit={{ model: unitModel, options: lists.units }}
                                      />
                                    )}
                                  </Field>
                                )}
                              </Field>
                            )}
                          </Field>
                        ),
                      })}
                      {renderRequiredDivider({ optional: true, grouped: true })}
                      {renderField({
                        label: 'Countries of origin',
                        value: packaging.locations,
                        grouped: true,
                        renderer: () => (
                          <Field name={`packagings.${index}.locations.mapped`}>
                            {(model: FieldProps<Entity>) => <SelectV3 multi model={model} options={lists.countries} />}
                          </Field>
                        ),
                      })}
                      <div className='col-span-3 h-6' />
                    </Fragment>
                  ))}
                </>
              ),
            })}
          </>
        )}
        {renderDivider()}
        {renderSection({
          label: 'Locations and Facilities',
          expanded: facilitiesExpanded,
          setExpanded: setFacilitiesExpanded,
          renderer: () => (
            <>
              {renderRequiredDivider({ optional: true })}
              {renderField({
                label: 'Production facility',
                value: props.data.productionLocation,
                renderer: () => (
                  <Field name='productionLocation.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.countries} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Storage',
                value: props.data.warehouseLocation,
                renderer: () => (
                  <Field name='warehouseLocation.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.countries} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Store',
                value: props.data.storeLocation,
                renderer: () => (
                  <Field name='storeLocation.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.countries} />}
                  </Field>
                ),
              })}
              {renderDivider({ light: true })}
              {renderField({
                label: 'Consumption location',
                value: props.data.consumptionLocation,
                renderer: () => (
                  <Field name='consumptionLocation.mapped'>
                    {(model: FieldProps<Entity>) => <SelectV3 model={model} options={lists.countries} />}
                  </Field>
                ),
              })}
            </>
          ),
        })}
      </div>
      <CreateButton submit {...submitState} />
    </Form>
  );
};

const CreateButton = (props: { disabled: boolean; waiting: boolean; submit?: boolean; onClick?: () => void }) => (
  <button
    type={props.submit ? 'submit' : 'button'}
    disabled={props.disabled}
    className={cn(
      'self-end text-sm bg-brand rounded-full px-4 py-1.5 text-white font-semibold',
      '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
      {
        'disabled:cursor-wait': props.waiting,
      },
    )}
    onClick={props.onClick}
  >
    Create product
  </button>
);

const IngredientSelect = (props: { model: FieldProps<IngredientV3> }) => (
  <ReactSelectAsync
    isClearable
    placeholder='Choose…'
    loadingMessage={() => 'Searching…'}
    noOptionsMessage={() => 'No matches found…'}
    getOptionValue={({ id }) => id}
    getOptionLabel={({ name }) => name}
    value={props.model.field.value}
    onChange={(value) => {
      props.model.form.setFieldValue(props.model.field.name, value);
    }}
    defaultOptions
    loadOptions={(input, callback) => {
      searchIngredientsV3(input).ok(({ customer, sustained }) =>
        callback([
          {
            label: 'Internal Products',
            options: customer,
          },
          {
            label: 'Generic Ingredients',
            options: sustained,
          },
        ]),
      );
    }}
    formatGroupLabel={(data) => (
      <div className='flex items-center gap-1.5'>
        <div>{data.label}</div>
      </div>
    )}
    components={{
      ...Components,
      Option: IngredientSelectOption,
    }}
    {...{ model: props.model }}
  />
);
