import { Fragment } from 'react';
import { Tooltip } from '../Tooltip';
import { Link } from 'react-router-dom';
import { ImpactByProducts, ProductWithAmount } from '../../api';
import { simplify, roundToLong } from '../../Root/Main/shared';
import { ImpactValueType } from '../../Root/Main/Products/Report/Sku/Overview';
import cn from 'classnames';
import { useAppRoutes } from '../../hooks/useAppRoutes';

interface Props {
  impact: ImpactByProducts;
  valueType: ImpactValueType;
  products: ProductWithAmount[];
}

export const HorizontalBarChart = (props: Props) => {
  const { routes } = useAppRoutes();
  const other = props.impact.products.find((it) => !it.id);
  const highestValue =
    props.valueType === 'physical'
      ? props.impact.products.sort((a, b) => b.value - a.value)[0].value
      : props.impact.products.sort((a, b) => b.impactValue - a.impactValue)[0].impactValue;

  return (
    <div className='grid grid-cols-[2fr_4fr] min-w-[400px]'>
      {props.impact.products
        .filter((it) => it.id)
        .concat(other ? other : [])
        .map((it) => ({
          ...it,
          percentShare: props.valueType === 'physical' ? (it.value / highestValue) * 100 : (it.impactValue / highestValue) * 100,
        }))
        .map((product, i2) => {
          const item = props.products.find((product2) => product2.id === product.id);

          return (
            <Fragment key={i2}>
              <div className='flex truncate border-r border-zinc-300 pr-3 font-semibold'>
                <Tooltip
                  content={
                    <div>
                      <div className='p-3 flex flex-col gap-3 min-w-[280px]'>
                        <div className='text-lg'>{product.name}</div>
                        <div className='text-sm text-neutral-300'>ID: {item?.sku ? item?.sku : ''}</div>
                        <div>Manufactured units: {item?.count ? item?.count : ''}</div>
                      </div>
                    </div>
                  }
                >
                  {item && (
                    <Link
                      to={
                        item.parentId
                          ? routes.products.modelOverview(item.parentId, item.id)
                          : routes.products.productOverview.production(item.id)
                        /*? `/products/${item.parentId}/models/${item.id}/report/overview`
                      : `/products/${item?.id}/report/overview`*/
                      }
                      target='_blank'
                      className={cn('max-w-[300px] text-neutral-500 truncate hover:underline block', {
                        'pointer-events-none': product.id === undefined,
                      })}
                    >
                      {product.name}
                    </Link>
                  )}
                </Tooltip>
              </div>
              <div className='flex items-center w-full relative'>
                {product.percentShare > 0 ? (
                  <div style={{ width: product.percentShare.toFixed(2) + '%' }} className='bg-[#b495fb] rounded-sm h-[10px] min-w-[5px]'>
                    <div
                      title={props.valueType === 'physical' ? roundToLong(product.value) : roundToLong(product.impactValue)}
                      className='whitespace-nowrap relative text-center text-dark text-[12px] top-[-18px] font-semibold p-0.5'
                    >
                      {props.valueType === 'physical' ? simplify(product.value) : simplify(product.impactValue)}
                    </div>
                  </div>
                ) : (
                  <div className='text-dark text-[12px] font-semibold pl-0.5'>{'<0 Impact'}</div>
                )}
              </div>
            </Fragment>
          );
        })}
      <div />
      <div className='border-t border-zinc-300 w-full'>
        {(() => {
          return (
            <div className='flex justify-between text-[12px] text-gray-400'>
              <span>0</span>
              <span title={roundToLong(highestValue * 0.25)}>{simplify(highestValue * 0.25)}</span>
              <span title={roundToLong(highestValue * 0.5)}>{simplify(highestValue * 0.5)}</span>
              <span title={roundToLong(highestValue * 0.75)}>{simplify(highestValue * 0.75)}</span>
              <span title={roundToLong(highestValue)}>{simplify(highestValue)}</span>
            </div>
          );
        })()}
        <div className='flex justify-center pt-1 text-gray-500 text-sm'>
          {props.valueType === 'physical' ? <div>{props.impact.unit}</div> : <div>weighted person.year</div>}
        </div>
      </div>
    </div>
  );
};
